/* eslint-disable */

import { AllTypesProps, ReturnTypes } from './const';
type ZEUS_INTERFACES = never
type ZEUS_UNIONS = never

export type ValueTypes = {
    /** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: {
	_eq?:boolean | null,
	_gt?:boolean | null,
	_gte?:boolean | null,
	_in?:boolean[],
	_is_null?:boolean | null,
	_lt?:boolean | null,
	_lte?:boolean | null,
	_neq?:boolean | null,
	_nin?:boolean[]
};
	/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
["Float_comparison_exp"]: {
	_eq?:number | null,
	_gt?:number | null,
	_gte?:number | null,
	_in?:number[],
	_is_null?:boolean | null,
	_lt?:number | null,
	_lte?:number | null,
	_neq?:number | null,
	_nin?:number[]
};
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: {
	_eq?:number | null,
	_gt?:number | null,
	_gte?:number | null,
	_in?:number[],
	_is_null?:boolean | null,
	_lt?:number | null,
	_lte?:number | null,
	_neq?:number | null,
	_nin?:number[]
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: {
	_eq?:string | null,
	_gt?:string | null,
	_gte?:string | null,
	/** does the column match the given case-insensitive pattern */
	_ilike?:string | null,
	_in?:string[],
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?:string | null,
	_is_null?:boolean | null,
	/** does the column match the given pattern */
	_like?:string | null,
	_lt?:string | null,
	_lte?:string | null,
	_neq?:string | null,
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?:string | null,
	_nin?:string[],
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?:string | null,
	/** does the column NOT match the given pattern */
	_nlike?:string | null,
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?:string | null,
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?:string | null,
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?:string | null,
	/** does the column match the given SQL regular expression */
	_similar?:string | null
};
	/** columns and relationships of "color" */
["color"]: AliasType<{
	color?:boolean,
groups?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["group_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["group_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["group_bool_exp"] | null},ValueTypes["group"]],
groups_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["group_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["group_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["group_bool_exp"] | null},ValueTypes["group_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "color" */
["color_aggregate"]: AliasType<{
	aggregate?:ValueTypes["color_aggregate_fields"],
	nodes?:ValueTypes["color"],
		__typename?: boolean
}>;
	/** aggregate fields of "color" */
["color_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["color_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["color_max_fields"],
	min?:ValueTypes["color_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "color". All fields are combined with a logical 'AND'. */
["color_bool_exp"]: {
	_and?:ValueTypes["color_bool_exp"][],
	_not?:ValueTypes["color_bool_exp"] | null,
	_or?:ValueTypes["color_bool_exp"][],
	color?:ValueTypes["String_comparison_exp"] | null,
	groups?:ValueTypes["group_bool_exp"] | null
};
	/** unique or primary key constraints on table "color" */
["color_constraint"]:color_constraint;
	["color_enum"]:color_enum;
	/** Boolean expression to compare columns of type "color_enum". All fields are combined with logical 'AND'. */
["color_enum_comparison_exp"]: {
	_eq?:ValueTypes["color_enum"] | null,
	_in?:ValueTypes["color_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["color_enum"] | null,
	_nin?:ValueTypes["color_enum"][]
};
	/** input type for inserting data into table "color" */
["color_insert_input"]: {
	color?:string | null,
	groups?:ValueTypes["group_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["color_max_fields"]: AliasType<{
	color?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["color_min_fields"]: AliasType<{
	color?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "color" */
["color_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["color"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "color" */
["color_obj_rel_insert_input"]: {
	data:ValueTypes["color_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["color_on_conflict"] | null
};
	/** on conflict condition type for table "color" */
["color_on_conflict"]: {
	constraint:ValueTypes["color_constraint"],
	update_columns:ValueTypes["color_update_column"][],
	where?:ValueTypes["color_bool_exp"] | null
};
	/** Ordering options when selecting data from "color". */
["color_order_by"]: {
	color?:ValueTypes["order_by"] | null,
	groups_aggregate?:ValueTypes["group_aggregate_order_by"] | null
};
	/** primary key columns input for table: color */
["color_pk_columns_input"]: {
	color:string
};
	/** select columns of table "color" */
["color_select_column"]:color_select_column;
	/** input type for updating data in table "color" */
["color_set_input"]: {
	color?:string | null
};
	/** update columns of table "color" */
["color_update_column"]:color_update_column;
	/** columns and relationships of "country_code" */
["country_code"]: AliasType<{
	code?:boolean,
	country?:boolean,
properties?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_bool_exp"] | null},ValueTypes["property"]],
properties_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_bool_exp"] | null},ValueTypes["property_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "country_code" */
["country_code_aggregate"]: AliasType<{
	aggregate?:ValueTypes["country_code_aggregate_fields"],
	nodes?:ValueTypes["country_code"],
		__typename?: boolean
}>;
	/** aggregate fields of "country_code" */
["country_code_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["country_code_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["country_code_max_fields"],
	min?:ValueTypes["country_code_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "country_code". All fields are combined with a logical 'AND'. */
["country_code_bool_exp"]: {
	_and?:ValueTypes["country_code_bool_exp"][],
	_not?:ValueTypes["country_code_bool_exp"] | null,
	_or?:ValueTypes["country_code_bool_exp"][],
	code?:ValueTypes["String_comparison_exp"] | null,
	country?:ValueTypes["String_comparison_exp"] | null,
	properties?:ValueTypes["property_bool_exp"] | null
};
	/** unique or primary key constraints on table "country_code" */
["country_code_constraint"]:country_code_constraint;
	["country_code_enum"]:country_code_enum;
	/** Boolean expression to compare columns of type "country_code_enum". All fields are combined with logical 'AND'. */
["country_code_enum_comparison_exp"]: {
	_eq?:ValueTypes["country_code_enum"] | null,
	_in?:ValueTypes["country_code_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["country_code_enum"] | null,
	_nin?:ValueTypes["country_code_enum"][]
};
	/** input type for inserting data into table "country_code" */
["country_code_insert_input"]: {
	code?:string | null,
	country?:string | null,
	properties?:ValueTypes["property_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["country_code_max_fields"]: AliasType<{
	code?:boolean,
	country?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["country_code_min_fields"]: AliasType<{
	code?:boolean,
	country?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "country_code" */
["country_code_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["country_code"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "country_code" */
["country_code_obj_rel_insert_input"]: {
	data:ValueTypes["country_code_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["country_code_on_conflict"] | null
};
	/** on conflict condition type for table "country_code" */
["country_code_on_conflict"]: {
	constraint:ValueTypes["country_code_constraint"],
	update_columns:ValueTypes["country_code_update_column"][],
	where?:ValueTypes["country_code_bool_exp"] | null
};
	/** Ordering options when selecting data from "country_code". */
["country_code_order_by"]: {
	code?:ValueTypes["order_by"] | null,
	country?:ValueTypes["order_by"] | null,
	properties_aggregate?:ValueTypes["property_aggregate_order_by"] | null
};
	/** primary key columns input for table: country_code */
["country_code_pk_columns_input"]: {
	code:string
};
	/** select columns of table "country_code" */
["country_code_select_column"]:country_code_select_column;
	/** input type for updating data in table "country_code" */
["country_code_set_input"]: {
	code?:string | null,
	country?:string | null
};
	/** update columns of table "country_code" */
["country_code_update_column"]:country_code_update_column;
	["date"]:unknown;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: {
	_eq?:ValueTypes["date"] | null,
	_gt?:ValueTypes["date"] | null,
	_gte?:ValueTypes["date"] | null,
	_in?:ValueTypes["date"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["date"] | null,
	_lte?:ValueTypes["date"] | null,
	_neq?:ValueTypes["date"] | null,
	_nin?:ValueTypes["date"][]
};
	/** columns and relationships of "deposit_rate" */
["deposit_rate"]: AliasType<{
	name?:boolean,
rental_contracts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_contract_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_contract_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_contract_bool_exp"] | null},ValueTypes["rental_contract"]],
rental_contracts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_contract_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_contract_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_contract_bool_exp"] | null},ValueTypes["rental_contract_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "deposit_rate" */
["deposit_rate_aggregate"]: AliasType<{
	aggregate?:ValueTypes["deposit_rate_aggregate_fields"],
	nodes?:ValueTypes["deposit_rate"],
		__typename?: boolean
}>;
	/** aggregate fields of "deposit_rate" */
["deposit_rate_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["deposit_rate_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["deposit_rate_max_fields"],
	min?:ValueTypes["deposit_rate_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "deposit_rate". All fields are combined with a logical 'AND'. */
["deposit_rate_bool_exp"]: {
	_and?:ValueTypes["deposit_rate_bool_exp"][],
	_not?:ValueTypes["deposit_rate_bool_exp"] | null,
	_or?:ValueTypes["deposit_rate_bool_exp"][],
	name?:ValueTypes["String_comparison_exp"] | null,
	rental_contracts?:ValueTypes["rental_contract_bool_exp"] | null
};
	/** unique or primary key constraints on table "deposit_rate" */
["deposit_rate_constraint"]:deposit_rate_constraint;
	["deposit_rate_enum"]:deposit_rate_enum;
	/** Boolean expression to compare columns of type "deposit_rate_enum". All fields are combined with logical 'AND'. */
["deposit_rate_enum_comparison_exp"]: {
	_eq?:ValueTypes["deposit_rate_enum"] | null,
	_in?:ValueTypes["deposit_rate_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["deposit_rate_enum"] | null,
	_nin?:ValueTypes["deposit_rate_enum"][]
};
	/** input type for inserting data into table "deposit_rate" */
["deposit_rate_insert_input"]: {
	name?:string | null,
	rental_contracts?:ValueTypes["rental_contract_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["deposit_rate_max_fields"]: AliasType<{
	name?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["deposit_rate_min_fields"]: AliasType<{
	name?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "deposit_rate" */
["deposit_rate_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["deposit_rate"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "deposit_rate" */
["deposit_rate_obj_rel_insert_input"]: {
	data:ValueTypes["deposit_rate_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["deposit_rate_on_conflict"] | null
};
	/** on conflict condition type for table "deposit_rate" */
["deposit_rate_on_conflict"]: {
	constraint:ValueTypes["deposit_rate_constraint"],
	update_columns:ValueTypes["deposit_rate_update_column"][],
	where?:ValueTypes["deposit_rate_bool_exp"] | null
};
	/** Ordering options when selecting data from "deposit_rate". */
["deposit_rate_order_by"]: {
	name?:ValueTypes["order_by"] | null,
	rental_contracts_aggregate?:ValueTypes["rental_contract_aggregate_order_by"] | null
};
	/** primary key columns input for table: deposit_rate */
["deposit_rate_pk_columns_input"]: {
	name:string
};
	/** select columns of table "deposit_rate" */
["deposit_rate_select_column"]:deposit_rate_select_column;
	/** input type for updating data in table "deposit_rate" */
["deposit_rate_set_input"]: {
	name?:string | null
};
	/** update columns of table "deposit_rate" */
["deposit_rate_update_column"]:deposit_rate_update_column;
	/** columns and relationships of "deposit_type" */
["deposit_type"]: AliasType<{
	name?:boolean,
rental_contracts?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_contract_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_contract_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_contract_bool_exp"] | null},ValueTypes["rental_contract"]],
rental_contracts_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_contract_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_contract_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_contract_bool_exp"] | null},ValueTypes["rental_contract_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "deposit_type" */
["deposit_type_aggregate"]: AliasType<{
	aggregate?:ValueTypes["deposit_type_aggregate_fields"],
	nodes?:ValueTypes["deposit_type"],
		__typename?: boolean
}>;
	/** aggregate fields of "deposit_type" */
["deposit_type_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["deposit_type_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["deposit_type_max_fields"],
	min?:ValueTypes["deposit_type_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "deposit_type". All fields are combined with a logical 'AND'. */
["deposit_type_bool_exp"]: {
	_and?:ValueTypes["deposit_type_bool_exp"][],
	_not?:ValueTypes["deposit_type_bool_exp"] | null,
	_or?:ValueTypes["deposit_type_bool_exp"][],
	name?:ValueTypes["String_comparison_exp"] | null,
	rental_contracts?:ValueTypes["rental_contract_bool_exp"] | null
};
	/** unique or primary key constraints on table "deposit_type" */
["deposit_type_constraint"]:deposit_type_constraint;
	["deposit_type_enum"]:deposit_type_enum;
	/** Boolean expression to compare columns of type "deposit_type_enum". All fields are combined with logical 'AND'. */
["deposit_type_enum_comparison_exp"]: {
	_eq?:ValueTypes["deposit_type_enum"] | null,
	_in?:ValueTypes["deposit_type_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["deposit_type_enum"] | null,
	_nin?:ValueTypes["deposit_type_enum"][]
};
	/** input type for inserting data into table "deposit_type" */
["deposit_type_insert_input"]: {
	name?:string | null,
	rental_contracts?:ValueTypes["rental_contract_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["deposit_type_max_fields"]: AliasType<{
	name?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["deposit_type_min_fields"]: AliasType<{
	name?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "deposit_type" */
["deposit_type_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["deposit_type"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "deposit_type" */
["deposit_type_obj_rel_insert_input"]: {
	data:ValueTypes["deposit_type_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["deposit_type_on_conflict"] | null
};
	/** on conflict condition type for table "deposit_type" */
["deposit_type_on_conflict"]: {
	constraint:ValueTypes["deposit_type_constraint"],
	update_columns:ValueTypes["deposit_type_update_column"][],
	where?:ValueTypes["deposit_type_bool_exp"] | null
};
	/** Ordering options when selecting data from "deposit_type". */
["deposit_type_order_by"]: {
	name?:ValueTypes["order_by"] | null,
	rental_contracts_aggregate?:ValueTypes["rental_contract_aggregate_order_by"] | null
};
	/** primary key columns input for table: deposit_type */
["deposit_type_pk_columns_input"]: {
	name:string
};
	/** select columns of table "deposit_type" */
["deposit_type_select_column"]:deposit_type_select_column;
	/** input type for updating data in table "deposit_type" */
["deposit_type_set_input"]: {
	name?:string | null
};
	/** update columns of table "deposit_type" */
["deposit_type_update_column"]:deposit_type_update_column;
	/** columns and relationships of "group" */
["group"]: AliasType<{
	abbreviation?:boolean,
	color?:boolean,
	id?:boolean,
	name?:boolean,
	/** An object relationship */
	object_color_type?:ValueTypes["color"],
		__typename?: boolean
}>;
	/** aggregated selection of "group" */
["group_aggregate"]: AliasType<{
	aggregate?:ValueTypes["group_aggregate_fields"],
	nodes?:ValueTypes["group"],
		__typename?: boolean
}>;
	/** aggregate fields of "group" */
["group_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["group_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["group_max_fields"],
	min?:ValueTypes["group_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "group" */
["group_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["group_max_order_by"] | null,
	min?:ValueTypes["group_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "group" */
["group_arr_rel_insert_input"]: {
	data:ValueTypes["group_insert_input"][],
	/** on conflict condition */
	on_conflict?:ValueTypes["group_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "group". All fields are combined with a logical 'AND'. */
["group_bool_exp"]: {
	_and?:ValueTypes["group_bool_exp"][],
	_not?:ValueTypes["group_bool_exp"] | null,
	_or?:ValueTypes["group_bool_exp"][],
	abbreviation?:ValueTypes["String_comparison_exp"] | null,
	color?:ValueTypes["color_enum_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	object_color_type?:ValueTypes["color_bool_exp"] | null
};
	/** unique or primary key constraints on table "group" */
["group_constraint"]:group_constraint;
	/** input type for inserting data into table "group" */
["group_insert_input"]: {
	abbreviation?:string | null,
	color?:ValueTypes["color_enum"] | null,
	id?:ValueTypes["uuid"] | null,
	name?:string | null,
	object_color_type?:ValueTypes["color_obj_rel_insert_input"] | null
};
	/** aggregate max on columns */
["group_max_fields"]: AliasType<{
	abbreviation?:boolean,
	id?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "group" */
["group_max_order_by"]: {
	abbreviation?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["group_min_fields"]: AliasType<{
	abbreviation?:boolean,
	id?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "group" */
["group_min_order_by"]: {
	abbreviation?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "group" */
["group_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["group"],
		__typename?: boolean
}>;
	/** on conflict condition type for table "group" */
["group_on_conflict"]: {
	constraint:ValueTypes["group_constraint"],
	update_columns:ValueTypes["group_update_column"][],
	where?:ValueTypes["group_bool_exp"] | null
};
	/** Ordering options when selecting data from "group". */
["group_order_by"]: {
	abbreviation?:ValueTypes["order_by"] | null,
	color?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	object_color_type?:ValueTypes["color_order_by"] | null
};
	/** primary key columns input for table: group */
["group_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "group" */
["group_select_column"]:group_select_column;
	/** input type for updating data in table "group" */
["group_set_input"]: {
	abbreviation?:string | null,
	color?:ValueTypes["color_enum"] | null,
	id?:ValueTypes["uuid"] | null,
	name?:string | null
};
	/** update columns of table "group" */
["group_update_column"]:group_update_column;
	["jsonb"]:unknown;
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: {
	/** is the column contained in the given json value */
	_contained_in?:ValueTypes["jsonb"] | null,
	/** does the column contain the given json value at the top level */
	_contains?:ValueTypes["jsonb"] | null,
	_eq?:ValueTypes["jsonb"] | null,
	_gt?:ValueTypes["jsonb"] | null,
	_gte?:ValueTypes["jsonb"] | null,
	/** does the string exist as a top-level key in the column */
	_has_key?:string | null,
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?:string[],
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?:string[],
	_in?:ValueTypes["jsonb"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["jsonb"] | null,
	_lte?:ValueTypes["jsonb"] | null,
	_neq?:ValueTypes["jsonb"] | null,
	_nin?:ValueTypes["jsonb"][]
};
	/** mutation root */
["mutation_root"]: AliasType<{
delete_color?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["color_bool_exp"]},ValueTypes["color_mutation_response"]],
delete_color_by_pk?: [{	color:string},ValueTypes["color"]],
delete_country_code?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["country_code_bool_exp"]},ValueTypes["country_code_mutation_response"]],
delete_country_code_by_pk?: [{	code:string},ValueTypes["country_code"]],
delete_deposit_rate?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["deposit_rate_bool_exp"]},ValueTypes["deposit_rate_mutation_response"]],
delete_deposit_rate_by_pk?: [{	name:string},ValueTypes["deposit_rate"]],
delete_deposit_type?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["deposit_type_bool_exp"]},ValueTypes["deposit_type_mutation_response"]],
delete_deposit_type_by_pk?: [{	name:string},ValueTypes["deposit_type"]],
delete_group?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["group_bool_exp"]},ValueTypes["group_mutation_response"]],
delete_group_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["group"]],
delete_property?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["property_bool_exp"]},ValueTypes["property_mutation_response"]],
delete_property_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["property"]],
delete_property_type?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["property_type_bool_exp"]},ValueTypes["property_type_mutation_response"]],
delete_property_type_by_pk?: [{	name:string},ValueTypes["property_type"]],
delete_rent_index?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["rent_index_bool_exp"]},ValueTypes["rent_index_mutation_response"]],
delete_rent_index_area?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["rent_index_area_bool_exp"]},ValueTypes["rent_index_area_mutation_response"]],
delete_rent_index_area_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rent_index_area"]],
delete_rent_index_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rent_index"]],
delete_rent_index_layer_type?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["rent_index_layer_type_bool_exp"]},ValueTypes["rent_index_layer_type_mutation_response"]],
delete_rent_index_layer_type_by_pk?: [{	name:string},ValueTypes["rent_index_layer_type"]],
delete_rental_contract?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["rental_contract_bool_exp"]},ValueTypes["rental_contract_mutation_response"]],
delete_rental_contract_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_contract"]],
delete_rental_costs?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["rental_costs_bool_exp"]},ValueTypes["rental_costs_mutation_response"]],
delete_rental_costs_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_costs"]],
delete_rental_costs_type?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["rental_costs_type_bool_exp"]},ValueTypes["rental_costs_type_mutation_response"]],
delete_rental_costs_type_by_pk?: [{	name:string},ValueTypes["rental_costs_type"]],
delete_rental_rent?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["rental_rent_bool_exp"]},ValueTypes["rental_rent_mutation_response"]],
delete_rental_rent_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_rent"]],
delete_unit?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["unit_bool_exp"]},ValueTypes["unit_mutation_response"]],
delete_unit_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["unit"]],
delete_unit_type?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["unit_type_bool_exp"]},ValueTypes["unit_type_mutation_response"]],
delete_unit_type_by_pk?: [{	name:string},ValueTypes["unit_type"]],
delete_user?: [{	/** filter the rows which have to be deleted */
	where:ValueTypes["user_bool_exp"]},ValueTypes["user_mutation_response"]],
delete_user_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["user"]],
insert_color?: [{	/** the rows to be inserted */
	objects:ValueTypes["color_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["color_on_conflict"] | null},ValueTypes["color_mutation_response"]],
insert_color_one?: [{	/** the row to be inserted */
	object:ValueTypes["color_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["color_on_conflict"] | null},ValueTypes["color"]],
insert_country_code?: [{	/** the rows to be inserted */
	objects:ValueTypes["country_code_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["country_code_on_conflict"] | null},ValueTypes["country_code_mutation_response"]],
insert_country_code_one?: [{	/** the row to be inserted */
	object:ValueTypes["country_code_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["country_code_on_conflict"] | null},ValueTypes["country_code"]],
insert_deposit_rate?: [{	/** the rows to be inserted */
	objects:ValueTypes["deposit_rate_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["deposit_rate_on_conflict"] | null},ValueTypes["deposit_rate_mutation_response"]],
insert_deposit_rate_one?: [{	/** the row to be inserted */
	object:ValueTypes["deposit_rate_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["deposit_rate_on_conflict"] | null},ValueTypes["deposit_rate"]],
insert_deposit_type?: [{	/** the rows to be inserted */
	objects:ValueTypes["deposit_type_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["deposit_type_on_conflict"] | null},ValueTypes["deposit_type_mutation_response"]],
insert_deposit_type_one?: [{	/** the row to be inserted */
	object:ValueTypes["deposit_type_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["deposit_type_on_conflict"] | null},ValueTypes["deposit_type"]],
insert_group?: [{	/** the rows to be inserted */
	objects:ValueTypes["group_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["group_on_conflict"] | null},ValueTypes["group_mutation_response"]],
insert_group_one?: [{	/** the row to be inserted */
	object:ValueTypes["group_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["group_on_conflict"] | null},ValueTypes["group"]],
insert_property?: [{	/** the rows to be inserted */
	objects:ValueTypes["property_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["property_on_conflict"] | null},ValueTypes["property_mutation_response"]],
insert_property_one?: [{	/** the row to be inserted */
	object:ValueTypes["property_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["property_on_conflict"] | null},ValueTypes["property"]],
insert_property_type?: [{	/** the rows to be inserted */
	objects:ValueTypes["property_type_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["property_type_on_conflict"] | null},ValueTypes["property_type_mutation_response"]],
insert_property_type_one?: [{	/** the row to be inserted */
	object:ValueTypes["property_type_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["property_type_on_conflict"] | null},ValueTypes["property_type"]],
insert_rent_index?: [{	/** the rows to be inserted */
	objects:ValueTypes["rent_index_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["rent_index_on_conflict"] | null},ValueTypes["rent_index_mutation_response"]],
insert_rent_index_area?: [{	/** the rows to be inserted */
	objects:ValueTypes["rent_index_area_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["rent_index_area_on_conflict"] | null},ValueTypes["rent_index_area_mutation_response"]],
insert_rent_index_area_one?: [{	/** the row to be inserted */
	object:ValueTypes["rent_index_area_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["rent_index_area_on_conflict"] | null},ValueTypes["rent_index_area"]],
insert_rent_index_layer_type?: [{	/** the rows to be inserted */
	objects:ValueTypes["rent_index_layer_type_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["rent_index_layer_type_on_conflict"] | null},ValueTypes["rent_index_layer_type_mutation_response"]],
insert_rent_index_layer_type_one?: [{	/** the row to be inserted */
	object:ValueTypes["rent_index_layer_type_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["rent_index_layer_type_on_conflict"] | null},ValueTypes["rent_index_layer_type"]],
insert_rent_index_one?: [{	/** the row to be inserted */
	object:ValueTypes["rent_index_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["rent_index_on_conflict"] | null},ValueTypes["rent_index"]],
insert_rental_contract?: [{	/** the rows to be inserted */
	objects:ValueTypes["rental_contract_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["rental_contract_on_conflict"] | null},ValueTypes["rental_contract_mutation_response"]],
insert_rental_contract_one?: [{	/** the row to be inserted */
	object:ValueTypes["rental_contract_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["rental_contract_on_conflict"] | null},ValueTypes["rental_contract"]],
insert_rental_costs?: [{	/** the rows to be inserted */
	objects:ValueTypes["rental_costs_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["rental_costs_on_conflict"] | null},ValueTypes["rental_costs_mutation_response"]],
insert_rental_costs_one?: [{	/** the row to be inserted */
	object:ValueTypes["rental_costs_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["rental_costs_on_conflict"] | null},ValueTypes["rental_costs"]],
insert_rental_costs_type?: [{	/** the rows to be inserted */
	objects:ValueTypes["rental_costs_type_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["rental_costs_type_on_conflict"] | null},ValueTypes["rental_costs_type_mutation_response"]],
insert_rental_costs_type_one?: [{	/** the row to be inserted */
	object:ValueTypes["rental_costs_type_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["rental_costs_type_on_conflict"] | null},ValueTypes["rental_costs_type"]],
insert_rental_rent?: [{	/** the rows to be inserted */
	objects:ValueTypes["rental_rent_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["rental_rent_on_conflict"] | null},ValueTypes["rental_rent_mutation_response"]],
insert_rental_rent_one?: [{	/** the row to be inserted */
	object:ValueTypes["rental_rent_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["rental_rent_on_conflict"] | null},ValueTypes["rental_rent"]],
insert_unit?: [{	/** the rows to be inserted */
	objects:ValueTypes["unit_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["unit_on_conflict"] | null},ValueTypes["unit_mutation_response"]],
insert_unit_one?: [{	/** the row to be inserted */
	object:ValueTypes["unit_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["unit_on_conflict"] | null},ValueTypes["unit"]],
insert_unit_type?: [{	/** the rows to be inserted */
	objects:ValueTypes["unit_type_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["unit_type_on_conflict"] | null},ValueTypes["unit_type_mutation_response"]],
insert_unit_type_one?: [{	/** the row to be inserted */
	object:ValueTypes["unit_type_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["unit_type_on_conflict"] | null},ValueTypes["unit_type"]],
insert_user?: [{	/** the rows to be inserted */
	objects:ValueTypes["user_insert_input"][],	/** on conflict condition */
	on_conflict?:ValueTypes["user_on_conflict"] | null},ValueTypes["user_mutation_response"]],
insert_user_one?: [{	/** the row to be inserted */
	object:ValueTypes["user_insert_input"],	/** on conflict condition */
	on_conflict?:ValueTypes["user_on_conflict"] | null},ValueTypes["user"]],
update_color?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["color_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["color_bool_exp"]},ValueTypes["color_mutation_response"]],
update_color_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["color_set_input"] | null,	pk_columns:ValueTypes["color_pk_columns_input"]},ValueTypes["color"]],
update_country_code?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["country_code_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["country_code_bool_exp"]},ValueTypes["country_code_mutation_response"]],
update_country_code_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["country_code_set_input"] | null,	pk_columns:ValueTypes["country_code_pk_columns_input"]},ValueTypes["country_code"]],
update_deposit_rate?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["deposit_rate_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["deposit_rate_bool_exp"]},ValueTypes["deposit_rate_mutation_response"]],
update_deposit_rate_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["deposit_rate_set_input"] | null,	pk_columns:ValueTypes["deposit_rate_pk_columns_input"]},ValueTypes["deposit_rate"]],
update_deposit_type?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["deposit_type_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["deposit_type_bool_exp"]},ValueTypes["deposit_type_mutation_response"]],
update_deposit_type_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["deposit_type_set_input"] | null,	pk_columns:ValueTypes["deposit_type_pk_columns_input"]},ValueTypes["deposit_type"]],
update_group?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["group_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["group_bool_exp"]},ValueTypes["group_mutation_response"]],
update_group_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["group_set_input"] | null,	pk_columns:ValueTypes["group_pk_columns_input"]},ValueTypes["group"]],
update_property?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["property_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["property_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["property_bool_exp"]},ValueTypes["property_mutation_response"]],
update_property_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["property_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["property_set_input"] | null,	pk_columns:ValueTypes["property_pk_columns_input"]},ValueTypes["property"]],
update_property_type?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["property_type_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["property_type_bool_exp"]},ValueTypes["property_type_mutation_response"]],
update_property_type_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["property_type_set_input"] | null,	pk_columns:ValueTypes["property_type_pk_columns_input"]},ValueTypes["property_type"]],
update_rent_index?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["rent_index_append_input"] | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["rent_index_delete_at_path_input"] | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["rent_index_delete_elem_input"] | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["rent_index_delete_key_input"] | null,	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["rent_index_inc_input"] | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["rent_index_prepend_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rent_index_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["rent_index_bool_exp"]},ValueTypes["rent_index_mutation_response"]],
update_rent_index_area?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["rent_index_area_append_input"] | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["rent_index_area_delete_at_path_input"] | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["rent_index_area_delete_elem_input"] | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["rent_index_area_delete_key_input"] | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["rent_index_area_prepend_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rent_index_area_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["rent_index_area_bool_exp"]},ValueTypes["rent_index_area_mutation_response"]],
update_rent_index_area_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["rent_index_area_append_input"] | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["rent_index_area_delete_at_path_input"] | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["rent_index_area_delete_elem_input"] | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["rent_index_area_delete_key_input"] | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["rent_index_area_prepend_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rent_index_area_set_input"] | null,	pk_columns:ValueTypes["rent_index_area_pk_columns_input"]},ValueTypes["rent_index_area"]],
update_rent_index_by_pk?: [{	/** append existing jsonb value of filtered columns with new jsonb value */
	_append?:ValueTypes["rent_index_append_input"] | null,	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
	_delete_at_path?:ValueTypes["rent_index_delete_at_path_input"] | null,	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
	_delete_elem?:ValueTypes["rent_index_delete_elem_input"] | null,	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
	_delete_key?:ValueTypes["rent_index_delete_key_input"] | null,	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["rent_index_inc_input"] | null,	/** prepend existing jsonb value of filtered columns with new jsonb value */
	_prepend?:ValueTypes["rent_index_prepend_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rent_index_set_input"] | null,	pk_columns:ValueTypes["rent_index_pk_columns_input"]},ValueTypes["rent_index"]],
update_rent_index_layer_type?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rent_index_layer_type_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["rent_index_layer_type_bool_exp"]},ValueTypes["rent_index_layer_type_mutation_response"]],
update_rent_index_layer_type_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rent_index_layer_type_set_input"] | null,	pk_columns:ValueTypes["rent_index_layer_type_pk_columns_input"]},ValueTypes["rent_index_layer_type"]],
update_rental_contract?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["rental_contract_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rental_contract_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["rental_contract_bool_exp"]},ValueTypes["rental_contract_mutation_response"]],
update_rental_contract_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["rental_contract_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rental_contract_set_input"] | null,	pk_columns:ValueTypes["rental_contract_pk_columns_input"]},ValueTypes["rental_contract"]],
update_rental_costs?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["rental_costs_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rental_costs_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["rental_costs_bool_exp"]},ValueTypes["rental_costs_mutation_response"]],
update_rental_costs_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["rental_costs_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rental_costs_set_input"] | null,	pk_columns:ValueTypes["rental_costs_pk_columns_input"]},ValueTypes["rental_costs"]],
update_rental_costs_type?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rental_costs_type_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["rental_costs_type_bool_exp"]},ValueTypes["rental_costs_type_mutation_response"]],
update_rental_costs_type_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rental_costs_type_set_input"] | null,	pk_columns:ValueTypes["rental_costs_type_pk_columns_input"]},ValueTypes["rental_costs_type"]],
update_rental_rent?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rental_rent_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["rental_rent_bool_exp"]},ValueTypes["rental_rent_mutation_response"]],
update_rental_rent_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["rental_rent_set_input"] | null,	pk_columns:ValueTypes["rental_rent_pk_columns_input"]},ValueTypes["rental_rent"]],
update_unit?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["unit_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["unit_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["unit_bool_exp"]},ValueTypes["unit_mutation_response"]],
update_unit_by_pk?: [{	/** increments the numeric columns with given value of the filtered values */
	_inc?:ValueTypes["unit_inc_input"] | null,	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["unit_set_input"] | null,	pk_columns:ValueTypes["unit_pk_columns_input"]},ValueTypes["unit"]],
update_unit_type?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["unit_type_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["unit_type_bool_exp"]},ValueTypes["unit_type_mutation_response"]],
update_unit_type_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["unit_type_set_input"] | null,	pk_columns:ValueTypes["unit_type_pk_columns_input"]},ValueTypes["unit_type"]],
update_user?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["user_set_input"] | null,	/** filter the rows which have to be updated */
	where:ValueTypes["user_bool_exp"]},ValueTypes["user_mutation_response"]],
update_user_by_pk?: [{	/** sets the columns of the filtered rows to the given values */
	_set?:ValueTypes["user_set_input"] | null,	pk_columns:ValueTypes["user_pk_columns_input"]},ValueTypes["user"]],
		__typename?: boolean
}>;
	["numeric"]:unknown;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
["numeric_comparison_exp"]: {
	_eq?:ValueTypes["numeric"] | null,
	_gt?:ValueTypes["numeric"] | null,
	_gte?:ValueTypes["numeric"] | null,
	_in?:ValueTypes["numeric"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["numeric"] | null,
	_lte?:ValueTypes["numeric"] | null,
	_neq?:ValueTypes["numeric"] | null,
	_nin?:ValueTypes["numeric"][]
};
	/** column ordering options */
["order_by"]:order_by;
	/** columns and relationships of "property" */
["property"]: AliasType<{
	city?:boolean,
	country?:boolean,
	/** An object relationship */
	country_code?:ValueTypes["country_code"],
	id?:boolean,
	lat?:boolean,
	lng?:boolean,
	name?:boolean,
	number?:boolean,
	postal_code?:boolean,
	/** An object relationship */
	property_type?:ValueTypes["property_type"],
	region?:boolean,
	state?:boolean,
	street?:boolean,
	type?:boolean,
units?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_bool_exp"] | null},ValueTypes["unit"]],
units_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_bool_exp"] | null},ValueTypes["unit_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "property" */
["property_aggregate"]: AliasType<{
	aggregate?:ValueTypes["property_aggregate_fields"],
	nodes?:ValueTypes["property"],
		__typename?: boolean
}>;
	/** aggregate fields of "property" */
["property_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["property_avg_fields"],
count?: [{	columns?:ValueTypes["property_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["property_max_fields"],
	min?:ValueTypes["property_min_fields"],
	stddev?:ValueTypes["property_stddev_fields"],
	stddev_pop?:ValueTypes["property_stddev_pop_fields"],
	stddev_samp?:ValueTypes["property_stddev_samp_fields"],
	sum?:ValueTypes["property_sum_fields"],
	var_pop?:ValueTypes["property_var_pop_fields"],
	var_samp?:ValueTypes["property_var_samp_fields"],
	variance?:ValueTypes["property_variance_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "property" */
["property_aggregate_order_by"]: {
	avg?:ValueTypes["property_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["property_max_order_by"] | null,
	min?:ValueTypes["property_min_order_by"] | null,
	stddev?:ValueTypes["property_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["property_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["property_stddev_samp_order_by"] | null,
	sum?:ValueTypes["property_sum_order_by"] | null,
	var_pop?:ValueTypes["property_var_pop_order_by"] | null,
	var_samp?:ValueTypes["property_var_samp_order_by"] | null,
	variance?:ValueTypes["property_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "property" */
["property_arr_rel_insert_input"]: {
	data:ValueTypes["property_insert_input"][],
	/** on conflict condition */
	on_conflict?:ValueTypes["property_on_conflict"] | null
};
	/** aggregate avg on columns */
["property_avg_fields"]: AliasType<{
	lat?:boolean,
	lng?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** order by avg() on columns of table "property" */
["property_avg_order_by"]: {
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
["property_bool_exp"]: {
	_and?:ValueTypes["property_bool_exp"][],
	_not?:ValueTypes["property_bool_exp"] | null,
	_or?:ValueTypes["property_bool_exp"][],
	city?:ValueTypes["String_comparison_exp"] | null,
	country?:ValueTypes["country_code_enum_comparison_exp"] | null,
	country_code?:ValueTypes["country_code_bool_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	lat?:ValueTypes["Int_comparison_exp"] | null,
	lng?:ValueTypes["Int_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	number?:ValueTypes["String_comparison_exp"] | null,
	postal_code?:ValueTypes["numeric_comparison_exp"] | null,
	property_type?:ValueTypes["property_type_bool_exp"] | null,
	region?:ValueTypes["String_comparison_exp"] | null,
	state?:ValueTypes["String_comparison_exp"] | null,
	street?:ValueTypes["String_comparison_exp"] | null,
	type?:ValueTypes["property_type_enum_comparison_exp"] | null,
	units?:ValueTypes["unit_bool_exp"] | null
};
	/** unique or primary key constraints on table "property" */
["property_constraint"]:property_constraint;
	/** input type for incrementing numeric columns in table "property" */
["property_inc_input"]: {
	lat?:number | null,
	lng?:number | null,
	postal_code?:ValueTypes["numeric"] | null
};
	/** input type for inserting data into table "property" */
["property_insert_input"]: {
	city?:string | null,
	country?:ValueTypes["country_code_enum"] | null,
	country_code?:ValueTypes["country_code_obj_rel_insert_input"] | null,
	id?:ValueTypes["uuid"] | null,
	lat?:number | null,
	lng?:number | null,
	name?:string | null,
	number?:string | null,
	postal_code?:ValueTypes["numeric"] | null,
	property_type?:ValueTypes["property_type_obj_rel_insert_input"] | null,
	region?:string | null,
	state?:string | null,
	street?:string | null,
	type?:ValueTypes["property_type_enum"] | null,
	units?:ValueTypes["unit_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["property_max_fields"]: AliasType<{
	city?:boolean,
	id?:boolean,
	lat?:boolean,
	lng?:boolean,
	name?:boolean,
	number?:boolean,
	postal_code?:boolean,
	region?:boolean,
	state?:boolean,
	street?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "property" */
["property_max_order_by"]: {
	city?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	number?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null,
	region?:ValueTypes["order_by"] | null,
	state?:ValueTypes["order_by"] | null,
	street?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["property_min_fields"]: AliasType<{
	city?:boolean,
	id?:boolean,
	lat?:boolean,
	lng?:boolean,
	name?:boolean,
	number?:boolean,
	postal_code?:boolean,
	region?:boolean,
	state?:boolean,
	street?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "property" */
["property_min_order_by"]: {
	city?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	number?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null,
	region?:ValueTypes["order_by"] | null,
	state?:ValueTypes["order_by"] | null,
	street?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "property" */
["property_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["property"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "property" */
["property_obj_rel_insert_input"]: {
	data:ValueTypes["property_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["property_on_conflict"] | null
};
	/** on conflict condition type for table "property" */
["property_on_conflict"]: {
	constraint:ValueTypes["property_constraint"],
	update_columns:ValueTypes["property_update_column"][],
	where?:ValueTypes["property_bool_exp"] | null
};
	/** Ordering options when selecting data from "property". */
["property_order_by"]: {
	city?:ValueTypes["order_by"] | null,
	country?:ValueTypes["order_by"] | null,
	country_code?:ValueTypes["country_code_order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	number?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null,
	property_type?:ValueTypes["property_type_order_by"] | null,
	region?:ValueTypes["order_by"] | null,
	state?:ValueTypes["order_by"] | null,
	street?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	units_aggregate?:ValueTypes["unit_aggregate_order_by"] | null
};
	/** primary key columns input for table: property */
["property_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "property" */
["property_select_column"]:property_select_column;
	/** input type for updating data in table "property" */
["property_set_input"]: {
	city?:string | null,
	country?:ValueTypes["country_code_enum"] | null,
	id?:ValueTypes["uuid"] | null,
	lat?:number | null,
	lng?:number | null,
	name?:string | null,
	number?:string | null,
	postal_code?:ValueTypes["numeric"] | null,
	region?:string | null,
	state?:string | null,
	street?:string | null,
	type?:ValueTypes["property_type_enum"] | null
};
	/** aggregate stddev on columns */
["property_stddev_fields"]: AliasType<{
	lat?:boolean,
	lng?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** order by stddev() on columns of table "property" */
["property_stddev_order_by"]: {
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_pop on columns */
["property_stddev_pop_fields"]: AliasType<{
	lat?:boolean,
	lng?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_pop() on columns of table "property" */
["property_stddev_pop_order_by"]: {
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_samp on columns */
["property_stddev_samp_fields"]: AliasType<{
	lat?:boolean,
	lng?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_samp() on columns of table "property" */
["property_stddev_samp_order_by"]: {
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	/** aggregate sum on columns */
["property_sum_fields"]: AliasType<{
	lat?:boolean,
	lng?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** order by sum() on columns of table "property" */
["property_sum_order_by"]: {
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "property_type" */
["property_type"]: AliasType<{
	description?:boolean,
	name?:boolean,
properties?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_bool_exp"] | null},ValueTypes["property"]],
properties_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_bool_exp"] | null},ValueTypes["property_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "property_type" */
["property_type_aggregate"]: AliasType<{
	aggregate?:ValueTypes["property_type_aggregate_fields"],
	nodes?:ValueTypes["property_type"],
		__typename?: boolean
}>;
	/** aggregate fields of "property_type" */
["property_type_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["property_type_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["property_type_max_fields"],
	min?:ValueTypes["property_type_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
["property_type_bool_exp"]: {
	_and?:ValueTypes["property_type_bool_exp"][],
	_not?:ValueTypes["property_type_bool_exp"] | null,
	_or?:ValueTypes["property_type_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	properties?:ValueTypes["property_bool_exp"] | null
};
	/** unique or primary key constraints on table "property_type" */
["property_type_constraint"]:property_type_constraint;
	["property_type_enum"]:property_type_enum;
	/** Boolean expression to compare columns of type "property_type_enum". All fields are combined with logical 'AND'. */
["property_type_enum_comparison_exp"]: {
	_eq?:ValueTypes["property_type_enum"] | null,
	_in?:ValueTypes["property_type_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["property_type_enum"] | null,
	_nin?:ValueTypes["property_type_enum"][]
};
	/** input type for inserting data into table "property_type" */
["property_type_insert_input"]: {
	description?:string | null,
	name?:string | null,
	properties?:ValueTypes["property_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["property_type_max_fields"]: AliasType<{
	description?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["property_type_min_fields"]: AliasType<{
	description?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "property_type" */
["property_type_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["property_type"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "property_type" */
["property_type_obj_rel_insert_input"]: {
	data:ValueTypes["property_type_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["property_type_on_conflict"] | null
};
	/** on conflict condition type for table "property_type" */
["property_type_on_conflict"]: {
	constraint:ValueTypes["property_type_constraint"],
	update_columns:ValueTypes["property_type_update_column"][],
	where?:ValueTypes["property_type_bool_exp"] | null
};
	/** Ordering options when selecting data from "property_type". */
["property_type_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	properties_aggregate?:ValueTypes["property_aggregate_order_by"] | null
};
	/** primary key columns input for table: property_type */
["property_type_pk_columns_input"]: {
	name:string
};
	/** select columns of table "property_type" */
["property_type_select_column"]:property_type_select_column;
	/** input type for updating data in table "property_type" */
["property_type_set_input"]: {
	description?:string | null,
	name?:string | null
};
	/** update columns of table "property_type" */
["property_type_update_column"]:property_type_update_column;
	/** update columns of table "property" */
["property_update_column"]:property_update_column;
	/** aggregate var_pop on columns */
["property_var_pop_fields"]: AliasType<{
	lat?:boolean,
	lng?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** order by var_pop() on columns of table "property" */
["property_var_pop_order_by"]: {
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	/** aggregate var_samp on columns */
["property_var_samp_fields"]: AliasType<{
	lat?:boolean,
	lng?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** order by var_samp() on columns of table "property" */
["property_var_samp_order_by"]: {
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	/** aggregate variance on columns */
["property_variance_fields"]: AliasType<{
	lat?:boolean,
	lng?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** order by variance() on columns of table "property" */
["property_variance_order_by"]: {
	lat?:ValueTypes["order_by"] | null,
	lng?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	["query_root"]: AliasType<{
color?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["color_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["color_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["color_bool_exp"] | null},ValueTypes["color"]],
color_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["color_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["color_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["color_bool_exp"] | null},ValueTypes["color_aggregate"]],
color_by_pk?: [{	color:string},ValueTypes["color"]],
country_code?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["country_code_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["country_code_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["country_code_bool_exp"] | null},ValueTypes["country_code"]],
country_code_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["country_code_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["country_code_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["country_code_bool_exp"] | null},ValueTypes["country_code_aggregate"]],
country_code_by_pk?: [{	code:string},ValueTypes["country_code"]],
deposit_rate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["deposit_rate_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["deposit_rate_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["deposit_rate_bool_exp"] | null},ValueTypes["deposit_rate"]],
deposit_rate_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["deposit_rate_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["deposit_rate_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["deposit_rate_bool_exp"] | null},ValueTypes["deposit_rate_aggregate"]],
deposit_rate_by_pk?: [{	name:string},ValueTypes["deposit_rate"]],
deposit_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["deposit_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["deposit_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["deposit_type_bool_exp"] | null},ValueTypes["deposit_type"]],
deposit_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["deposit_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["deposit_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["deposit_type_bool_exp"] | null},ValueTypes["deposit_type_aggregate"]],
deposit_type_by_pk?: [{	name:string},ValueTypes["deposit_type"]],
group?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["group_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["group_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["group_bool_exp"] | null},ValueTypes["group"]],
group_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["group_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["group_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["group_bool_exp"] | null},ValueTypes["group_aggregate"]],
group_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["group"]],
property?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_bool_exp"] | null},ValueTypes["property"]],
property_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_bool_exp"] | null},ValueTypes["property_aggregate"]],
property_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["property"]],
property_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_type_bool_exp"] | null},ValueTypes["property_type"]],
property_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_type_bool_exp"] | null},ValueTypes["property_type_aggregate"]],
property_type_by_pk?: [{	name:string},ValueTypes["property_type"]],
rent_index?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_bool_exp"] | null},ValueTypes["rent_index"]],
rent_index_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_bool_exp"] | null},ValueTypes["rent_index_aggregate"]],
rent_index_area?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_area_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_area_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_area_bool_exp"] | null},ValueTypes["rent_index_area"]],
rent_index_area_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_area_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_area_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_area_bool_exp"] | null},ValueTypes["rent_index_area_aggregate"]],
rent_index_area_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rent_index_area"]],
rent_index_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rent_index"]],
rent_index_layer_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_layer_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_layer_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_layer_type_bool_exp"] | null},ValueTypes["rent_index_layer_type"]],
rent_index_layer_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_layer_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_layer_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_layer_type_bool_exp"] | null},ValueTypes["rent_index_layer_type_aggregate"]],
rent_index_layer_type_by_pk?: [{	name:string},ValueTypes["rent_index_layer_type"]],
rental_contract?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_contract_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_contract_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_contract_bool_exp"] | null},ValueTypes["rental_contract"]],
rental_contract_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_contract_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_contract_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_contract_bool_exp"] | null},ValueTypes["rental_contract_aggregate"]],
rental_contract_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_contract"]],
rental_costs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs"]],
rental_costs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs_aggregate"]],
rental_costs_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_costs"]],
rental_costs_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_type_bool_exp"] | null},ValueTypes["rental_costs_type"]],
rental_costs_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_type_bool_exp"] | null},ValueTypes["rental_costs_type_aggregate"]],
rental_costs_type_by_pk?: [{	name:string},ValueTypes["rental_costs_type"]],
rental_rent?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_rent_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_rent_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_rent_bool_exp"] | null},ValueTypes["rental_rent"]],
rental_rent_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_rent_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_rent_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_rent_bool_exp"] | null},ValueTypes["rental_rent_aggregate"]],
rental_rent_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_rent"]],
unit?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_bool_exp"] | null},ValueTypes["unit"]],
unit_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_bool_exp"] | null},ValueTypes["unit_aggregate"]],
unit_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["unit"]],
unit_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_type_bool_exp"] | null},ValueTypes["unit_type"]],
unit_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_type_bool_exp"] | null},ValueTypes["unit_type_aggregate"]],
unit_type_by_pk?: [{	name:string},ValueTypes["unit_type"]],
user?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["user_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["user_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["user_bool_exp"] | null},ValueTypes["user"]],
user_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["user_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["user_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["user_bool_exp"] | null},ValueTypes["user_aggregate"]],
user_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["user"]],
		__typename?: boolean
}>;
	/** columns and relationships of "rent_index" */
["rent_index"]: AliasType<{
	city?:boolean,
data?: [{	/** JSON select path */
	path?:string | null},boolean],
	date?:boolean,
	id?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** aggregated selection of "rent_index" */
["rent_index_aggregate"]: AliasType<{
	aggregate?:ValueTypes["rent_index_aggregate_fields"],
	nodes?:ValueTypes["rent_index"],
		__typename?: boolean
}>;
	/** aggregate fields of "rent_index" */
["rent_index_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["rent_index_avg_fields"],
count?: [{	columns?:ValueTypes["rent_index_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["rent_index_max_fields"],
	min?:ValueTypes["rent_index_min_fields"],
	stddev?:ValueTypes["rent_index_stddev_fields"],
	stddev_pop?:ValueTypes["rent_index_stddev_pop_fields"],
	stddev_samp?:ValueTypes["rent_index_stddev_samp_fields"],
	sum?:ValueTypes["rent_index_sum_fields"],
	var_pop?:ValueTypes["rent_index_var_pop_fields"],
	var_samp?:ValueTypes["rent_index_var_samp_fields"],
	variance?:ValueTypes["rent_index_variance_fields"],
		__typename?: boolean
}>;
	/** append existing jsonb value of filtered columns with new jsonb value */
["rent_index_append_input"]: {
	data?:ValueTypes["jsonb"] | null
};
	/** columns and relationships of "rent_index_area" */
["rent_index_area"]: AliasType<{
	id?:boolean,
	layer_type?:boolean,
polygons?: [{	/** JSON select path */
	path?:string | null},boolean],
	/** An object relationship */
	rent_index_layer_type?:ValueTypes["rent_index_layer_type"],
		__typename?: boolean
}>;
	/** aggregated selection of "rent_index_area" */
["rent_index_area_aggregate"]: AliasType<{
	aggregate?:ValueTypes["rent_index_area_aggregate_fields"],
	nodes?:ValueTypes["rent_index_area"],
		__typename?: boolean
}>;
	/** aggregate fields of "rent_index_area" */
["rent_index_area_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["rent_index_area_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["rent_index_area_max_fields"],
	min?:ValueTypes["rent_index_area_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "rent_index_area" */
["rent_index_area_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["rent_index_area_max_order_by"] | null,
	min?:ValueTypes["rent_index_area_min_order_by"] | null
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["rent_index_area_append_input"]: {
	polygons?:ValueTypes["jsonb"] | null
};
	/** input type for inserting array relation for remote table "rent_index_area" */
["rent_index_area_arr_rel_insert_input"]: {
	data:ValueTypes["rent_index_area_insert_input"][],
	/** on conflict condition */
	on_conflict?:ValueTypes["rent_index_area_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "rent_index_area". All fields are combined with a logical 'AND'. */
["rent_index_area_bool_exp"]: {
	_and?:ValueTypes["rent_index_area_bool_exp"][],
	_not?:ValueTypes["rent_index_area_bool_exp"] | null,
	_or?:ValueTypes["rent_index_area_bool_exp"][],
	id?:ValueTypes["uuid_comparison_exp"] | null,
	layer_type?:ValueTypes["rent_index_layer_type_enum_comparison_exp"] | null,
	polygons?:ValueTypes["jsonb_comparison_exp"] | null,
	rent_index_layer_type?:ValueTypes["rent_index_layer_type_bool_exp"] | null
};
	/** unique or primary key constraints on table "rent_index_area" */
["rent_index_area_constraint"]:rent_index_area_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["rent_index_area_delete_at_path_input"]: {
	polygons?:string[]
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["rent_index_area_delete_elem_input"]: {
	polygons?:number | null
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["rent_index_area_delete_key_input"]: {
	polygons?:string | null
};
	/** input type for inserting data into table "rent_index_area" */
["rent_index_area_insert_input"]: {
	id?:ValueTypes["uuid"] | null,
	layer_type?:ValueTypes["rent_index_layer_type_enum"] | null,
	polygons?:ValueTypes["jsonb"] | null,
	rent_index_layer_type?:ValueTypes["rent_index_layer_type_obj_rel_insert_input"] | null
};
	/** aggregate max on columns */
["rent_index_area_max_fields"]: AliasType<{
	id?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "rent_index_area" */
["rent_index_area_max_order_by"]: {
	id?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["rent_index_area_min_fields"]: AliasType<{
	id?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "rent_index_area" */
["rent_index_area_min_order_by"]: {
	id?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "rent_index_area" */
["rent_index_area_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["rent_index_area"],
		__typename?: boolean
}>;
	/** on conflict condition type for table "rent_index_area" */
["rent_index_area_on_conflict"]: {
	constraint:ValueTypes["rent_index_area_constraint"],
	update_columns:ValueTypes["rent_index_area_update_column"][],
	where?:ValueTypes["rent_index_area_bool_exp"] | null
};
	/** Ordering options when selecting data from "rent_index_area". */
["rent_index_area_order_by"]: {
	id?:ValueTypes["order_by"] | null,
	layer_type?:ValueTypes["order_by"] | null,
	polygons?:ValueTypes["order_by"] | null,
	rent_index_layer_type?:ValueTypes["rent_index_layer_type_order_by"] | null
};
	/** primary key columns input for table: rent_index_area */
["rent_index_area_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["rent_index_area_prepend_input"]: {
	polygons?:ValueTypes["jsonb"] | null
};
	/** select columns of table "rent_index_area" */
["rent_index_area_select_column"]:rent_index_area_select_column;
	/** input type for updating data in table "rent_index_area" */
["rent_index_area_set_input"]: {
	id?:ValueTypes["uuid"] | null,
	layer_type?:ValueTypes["rent_index_layer_type_enum"] | null,
	polygons?:ValueTypes["jsonb"] | null
};
	/** update columns of table "rent_index_area" */
["rent_index_area_update_column"]:rent_index_area_update_column;
	/** aggregate avg on columns */
["rent_index_avg_fields"]: AliasType<{
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "rent_index". All fields are combined with a logical 'AND'. */
["rent_index_bool_exp"]: {
	_and?:ValueTypes["rent_index_bool_exp"][],
	_not?:ValueTypes["rent_index_bool_exp"] | null,
	_or?:ValueTypes["rent_index_bool_exp"][],
	city?:ValueTypes["String_comparison_exp"] | null,
	data?:ValueTypes["jsonb_comparison_exp"] | null,
	date?:ValueTypes["date_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	postal_code?:ValueTypes["numeric_comparison_exp"] | null
};
	/** unique or primary key constraints on table "rent_index" */
["rent_index_constraint"]:rent_index_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["rent_index_delete_at_path_input"]: {
	data?:string[]
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["rent_index_delete_elem_input"]: {
	data?:number | null
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["rent_index_delete_key_input"]: {
	data?:string | null
};
	/** input type for incrementing numeric columns in table "rent_index" */
["rent_index_inc_input"]: {
	postal_code?:ValueTypes["numeric"] | null
};
	/** input type for inserting data into table "rent_index" */
["rent_index_insert_input"]: {
	city?:string | null,
	data?:ValueTypes["jsonb"] | null,
	date?:ValueTypes["date"] | null,
	id?:ValueTypes["uuid"] | null,
	postal_code?:ValueTypes["numeric"] | null
};
	/** columns and relationships of "rent_index_layer_type" */
["rent_index_layer_type"]: AliasType<{
	description?:boolean,
	name?:boolean,
rent_index_areas?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_area_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_area_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_area_bool_exp"] | null},ValueTypes["rent_index_area"]],
rent_index_areas_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_area_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_area_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_area_bool_exp"] | null},ValueTypes["rent_index_area_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "rent_index_layer_type" */
["rent_index_layer_type_aggregate"]: AliasType<{
	aggregate?:ValueTypes["rent_index_layer_type_aggregate_fields"],
	nodes?:ValueTypes["rent_index_layer_type"],
		__typename?: boolean
}>;
	/** aggregate fields of "rent_index_layer_type" */
["rent_index_layer_type_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["rent_index_layer_type_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["rent_index_layer_type_max_fields"],
	min?:ValueTypes["rent_index_layer_type_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "rent_index_layer_type". All fields are combined with a logical 'AND'. */
["rent_index_layer_type_bool_exp"]: {
	_and?:ValueTypes["rent_index_layer_type_bool_exp"][],
	_not?:ValueTypes["rent_index_layer_type_bool_exp"] | null,
	_or?:ValueTypes["rent_index_layer_type_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	rent_index_areas?:ValueTypes["rent_index_area_bool_exp"] | null
};
	/** unique or primary key constraints on table "rent_index_layer_type" */
["rent_index_layer_type_constraint"]:rent_index_layer_type_constraint;
	["rent_index_layer_type_enum"]:rent_index_layer_type_enum;
	/** Boolean expression to compare columns of type "rent_index_layer_type_enum". All fields are combined with logical 'AND'. */
["rent_index_layer_type_enum_comparison_exp"]: {
	_eq?:ValueTypes["rent_index_layer_type_enum"] | null,
	_in?:ValueTypes["rent_index_layer_type_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["rent_index_layer_type_enum"] | null,
	_nin?:ValueTypes["rent_index_layer_type_enum"][]
};
	/** input type for inserting data into table "rent_index_layer_type" */
["rent_index_layer_type_insert_input"]: {
	description?:string | null,
	name?:string | null,
	rent_index_areas?:ValueTypes["rent_index_area_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["rent_index_layer_type_max_fields"]: AliasType<{
	description?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["rent_index_layer_type_min_fields"]: AliasType<{
	description?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "rent_index_layer_type" */
["rent_index_layer_type_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["rent_index_layer_type"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "rent_index_layer_type" */
["rent_index_layer_type_obj_rel_insert_input"]: {
	data:ValueTypes["rent_index_layer_type_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["rent_index_layer_type_on_conflict"] | null
};
	/** on conflict condition type for table "rent_index_layer_type" */
["rent_index_layer_type_on_conflict"]: {
	constraint:ValueTypes["rent_index_layer_type_constraint"],
	update_columns:ValueTypes["rent_index_layer_type_update_column"][],
	where?:ValueTypes["rent_index_layer_type_bool_exp"] | null
};
	/** Ordering options when selecting data from "rent_index_layer_type". */
["rent_index_layer_type_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	rent_index_areas_aggregate?:ValueTypes["rent_index_area_aggregate_order_by"] | null
};
	/** primary key columns input for table: rent_index_layer_type */
["rent_index_layer_type_pk_columns_input"]: {
	name:string
};
	/** select columns of table "rent_index_layer_type" */
["rent_index_layer_type_select_column"]:rent_index_layer_type_select_column;
	/** input type for updating data in table "rent_index_layer_type" */
["rent_index_layer_type_set_input"]: {
	description?:string | null,
	name?:string | null
};
	/** update columns of table "rent_index_layer_type" */
["rent_index_layer_type_update_column"]:rent_index_layer_type_update_column;
	/** aggregate max on columns */
["rent_index_max_fields"]: AliasType<{
	city?:boolean,
	date?:boolean,
	id?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["rent_index_min_fields"]: AliasType<{
	city?:boolean,
	date?:boolean,
	id?:boolean,
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "rent_index" */
["rent_index_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["rent_index"],
		__typename?: boolean
}>;
	/** on conflict condition type for table "rent_index" */
["rent_index_on_conflict"]: {
	constraint:ValueTypes["rent_index_constraint"],
	update_columns:ValueTypes["rent_index_update_column"][],
	where?:ValueTypes["rent_index_bool_exp"] | null
};
	/** Ordering options when selecting data from "rent_index". */
["rent_index_order_by"]: {
	city?:ValueTypes["order_by"] | null,
	data?:ValueTypes["order_by"] | null,
	date?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	postal_code?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: rent_index */
["rent_index_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["rent_index_prepend_input"]: {
	data?:ValueTypes["jsonb"] | null
};
	/** select columns of table "rent_index" */
["rent_index_select_column"]:rent_index_select_column;
	/** input type for updating data in table "rent_index" */
["rent_index_set_input"]: {
	city?:string | null,
	data?:ValueTypes["jsonb"] | null,
	date?:ValueTypes["date"] | null,
	id?:ValueTypes["uuid"] | null,
	postal_code?:ValueTypes["numeric"] | null
};
	/** aggregate stddev on columns */
["rent_index_stddev_fields"]: AliasType<{
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** aggregate stddev_pop on columns */
["rent_index_stddev_pop_fields"]: AliasType<{
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** aggregate stddev_samp on columns */
["rent_index_stddev_samp_fields"]: AliasType<{
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** aggregate sum on columns */
["rent_index_sum_fields"]: AliasType<{
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** update columns of table "rent_index" */
["rent_index_update_column"]:rent_index_update_column;
	/** aggregate var_pop on columns */
["rent_index_var_pop_fields"]: AliasType<{
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** aggregate var_samp on columns */
["rent_index_var_samp_fields"]: AliasType<{
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** aggregate variance on columns */
["rent_index_variance_fields"]: AliasType<{
	postal_code?:boolean,
		__typename?: boolean
}>;
	/** columns and relationships of "rental_contract" */
["rental_contract"]: AliasType<{
	begin?:boolean,
	cancelation_effective?:boolean,
	cancelation_period?:boolean,
	cancelation_pronounced?:boolean,
	date?:boolean,
	deposit?:boolean,
	/** An object relationship */
	depositRate?:ValueTypes["deposit_rate"],
	/** An object relationship */
	depositType?:ValueTypes["deposit_type"],
	deposit_payable?:boolean,
	deposit_rate?:boolean,
	deposit_received?:boolean,
	deposit_type?:boolean,
	end?:boolean,
	handover_move_in?:boolean,
	handover_move_out?:boolean,
	id?:boolean,
	min?:boolean,
	other_agreements?:boolean,
rental_rents?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_rent_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_rent_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_rent_bool_exp"] | null},ValueTypes["rental_rent"]],
rental_rents_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_rent_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_rent_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_rent_bool_exp"] | null},ValueTypes["rental_rent_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "rental_contract" */
["rental_contract_aggregate"]: AliasType<{
	aggregate?:ValueTypes["rental_contract_aggregate_fields"],
	nodes?:ValueTypes["rental_contract"],
		__typename?: boolean
}>;
	/** aggregate fields of "rental_contract" */
["rental_contract_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["rental_contract_avg_fields"],
count?: [{	columns?:ValueTypes["rental_contract_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["rental_contract_max_fields"],
	min?:ValueTypes["rental_contract_min_fields"],
	stddev?:ValueTypes["rental_contract_stddev_fields"],
	stddev_pop?:ValueTypes["rental_contract_stddev_pop_fields"],
	stddev_samp?:ValueTypes["rental_contract_stddev_samp_fields"],
	sum?:ValueTypes["rental_contract_sum_fields"],
	var_pop?:ValueTypes["rental_contract_var_pop_fields"],
	var_samp?:ValueTypes["rental_contract_var_samp_fields"],
	variance?:ValueTypes["rental_contract_variance_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "rental_contract" */
["rental_contract_aggregate_order_by"]: {
	avg?:ValueTypes["rental_contract_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["rental_contract_max_order_by"] | null,
	min?:ValueTypes["rental_contract_min_order_by"] | null,
	stddev?:ValueTypes["rental_contract_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["rental_contract_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["rental_contract_stddev_samp_order_by"] | null,
	sum?:ValueTypes["rental_contract_sum_order_by"] | null,
	var_pop?:ValueTypes["rental_contract_var_pop_order_by"] | null,
	var_samp?:ValueTypes["rental_contract_var_samp_order_by"] | null,
	variance?:ValueTypes["rental_contract_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "rental_contract" */
["rental_contract_arr_rel_insert_input"]: {
	data:ValueTypes["rental_contract_insert_input"][],
	/** on conflict condition */
	on_conflict?:ValueTypes["rental_contract_on_conflict"] | null
};
	/** aggregate avg on columns */
["rental_contract_avg_fields"]: AliasType<{
	cancelation_period?:boolean,
	deposit?:boolean,
		__typename?: boolean
}>;
	/** order by avg() on columns of table "rental_contract" */
["rental_contract_avg_order_by"]: {
	cancelation_period?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "rental_contract". All fields are combined with a logical 'AND'. */
["rental_contract_bool_exp"]: {
	_and?:ValueTypes["rental_contract_bool_exp"][],
	_not?:ValueTypes["rental_contract_bool_exp"] | null,
	_or?:ValueTypes["rental_contract_bool_exp"][],
	begin?:ValueTypes["timestamptz_comparison_exp"] | null,
	cancelation_effective?:ValueTypes["timestamptz_comparison_exp"] | null,
	cancelation_period?:ValueTypes["Int_comparison_exp"] | null,
	cancelation_pronounced?:ValueTypes["timestamptz_comparison_exp"] | null,
	date?:ValueTypes["timestamptz_comparison_exp"] | null,
	deposit?:ValueTypes["Int_comparison_exp"] | null,
	depositRate?:ValueTypes["deposit_rate_bool_exp"] | null,
	depositType?:ValueTypes["deposit_type_bool_exp"] | null,
	deposit_payable?:ValueTypes["timestamptz_comparison_exp"] | null,
	deposit_rate?:ValueTypes["deposit_rate_enum_comparison_exp"] | null,
	deposit_received?:ValueTypes["timestamptz_comparison_exp"] | null,
	deposit_type?:ValueTypes["deposit_type_enum_comparison_exp"] | null,
	end?:ValueTypes["timestamptz_comparison_exp"] | null,
	handover_move_in?:ValueTypes["timestamptz_comparison_exp"] | null,
	handover_move_out?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	min?:ValueTypes["timestamptz_comparison_exp"] | null,
	other_agreements?:ValueTypes["String_comparison_exp"] | null,
	rental_rents?:ValueTypes["rental_rent_bool_exp"] | null
};
	/** unique or primary key constraints on table "rental_contract" */
["rental_contract_constraint"]:rental_contract_constraint;
	/** input type for incrementing numeric columns in table "rental_contract" */
["rental_contract_inc_input"]: {
	cancelation_period?:number | null,
	deposit?:number | null
};
	/** input type for inserting data into table "rental_contract" */
["rental_contract_insert_input"]: {
	begin?:ValueTypes["timestamptz"] | null,
	cancelation_effective?:ValueTypes["timestamptz"] | null,
	cancelation_period?:number | null,
	cancelation_pronounced?:ValueTypes["timestamptz"] | null,
	date?:ValueTypes["timestamptz"] | null,
	deposit?:number | null,
	depositRate?:ValueTypes["deposit_rate_obj_rel_insert_input"] | null,
	depositType?:ValueTypes["deposit_type_obj_rel_insert_input"] | null,
	deposit_payable?:ValueTypes["timestamptz"] | null,
	deposit_rate?:ValueTypes["deposit_rate_enum"] | null,
	deposit_received?:ValueTypes["timestamptz"] | null,
	deposit_type?:ValueTypes["deposit_type_enum"] | null,
	end?:ValueTypes["timestamptz"] | null,
	handover_move_in?:ValueTypes["timestamptz"] | null,
	handover_move_out?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	min?:ValueTypes["timestamptz"] | null,
	other_agreements?:string | null,
	rental_rents?:ValueTypes["rental_rent_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["rental_contract_max_fields"]: AliasType<{
	begin?:boolean,
	cancelation_effective?:boolean,
	cancelation_period?:boolean,
	cancelation_pronounced?:boolean,
	date?:boolean,
	deposit?:boolean,
	deposit_payable?:boolean,
	deposit_received?:boolean,
	end?:boolean,
	handover_move_in?:boolean,
	handover_move_out?:boolean,
	id?:boolean,
	min?:boolean,
	other_agreements?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "rental_contract" */
["rental_contract_max_order_by"]: {
	begin?:ValueTypes["order_by"] | null,
	cancelation_effective?:ValueTypes["order_by"] | null,
	cancelation_period?:ValueTypes["order_by"] | null,
	cancelation_pronounced?:ValueTypes["order_by"] | null,
	date?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null,
	deposit_payable?:ValueTypes["order_by"] | null,
	deposit_received?:ValueTypes["order_by"] | null,
	end?:ValueTypes["order_by"] | null,
	handover_move_in?:ValueTypes["order_by"] | null,
	handover_move_out?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	min?:ValueTypes["order_by"] | null,
	other_agreements?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["rental_contract_min_fields"]: AliasType<{
	begin?:boolean,
	cancelation_effective?:boolean,
	cancelation_period?:boolean,
	cancelation_pronounced?:boolean,
	date?:boolean,
	deposit?:boolean,
	deposit_payable?:boolean,
	deposit_received?:boolean,
	end?:boolean,
	handover_move_in?:boolean,
	handover_move_out?:boolean,
	id?:boolean,
	min?:boolean,
	other_agreements?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "rental_contract" */
["rental_contract_min_order_by"]: {
	begin?:ValueTypes["order_by"] | null,
	cancelation_effective?:ValueTypes["order_by"] | null,
	cancelation_period?:ValueTypes["order_by"] | null,
	cancelation_pronounced?:ValueTypes["order_by"] | null,
	date?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null,
	deposit_payable?:ValueTypes["order_by"] | null,
	deposit_received?:ValueTypes["order_by"] | null,
	end?:ValueTypes["order_by"] | null,
	handover_move_in?:ValueTypes["order_by"] | null,
	handover_move_out?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	min?:ValueTypes["order_by"] | null,
	other_agreements?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "rental_contract" */
["rental_contract_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["rental_contract"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "rental_contract" */
["rental_contract_obj_rel_insert_input"]: {
	data:ValueTypes["rental_contract_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["rental_contract_on_conflict"] | null
};
	/** on conflict condition type for table "rental_contract" */
["rental_contract_on_conflict"]: {
	constraint:ValueTypes["rental_contract_constraint"],
	update_columns:ValueTypes["rental_contract_update_column"][],
	where?:ValueTypes["rental_contract_bool_exp"] | null
};
	/** Ordering options when selecting data from "rental_contract". */
["rental_contract_order_by"]: {
	begin?:ValueTypes["order_by"] | null,
	cancelation_effective?:ValueTypes["order_by"] | null,
	cancelation_period?:ValueTypes["order_by"] | null,
	cancelation_pronounced?:ValueTypes["order_by"] | null,
	date?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null,
	depositRate?:ValueTypes["deposit_rate_order_by"] | null,
	depositType?:ValueTypes["deposit_type_order_by"] | null,
	deposit_payable?:ValueTypes["order_by"] | null,
	deposit_rate?:ValueTypes["order_by"] | null,
	deposit_received?:ValueTypes["order_by"] | null,
	deposit_type?:ValueTypes["order_by"] | null,
	end?:ValueTypes["order_by"] | null,
	handover_move_in?:ValueTypes["order_by"] | null,
	handover_move_out?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	min?:ValueTypes["order_by"] | null,
	other_agreements?:ValueTypes["order_by"] | null,
	rental_rents_aggregate?:ValueTypes["rental_rent_aggregate_order_by"] | null
};
	/** primary key columns input for table: rental_contract */
["rental_contract_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "rental_contract" */
["rental_contract_select_column"]:rental_contract_select_column;
	/** input type for updating data in table "rental_contract" */
["rental_contract_set_input"]: {
	begin?:ValueTypes["timestamptz"] | null,
	cancelation_effective?:ValueTypes["timestamptz"] | null,
	cancelation_period?:number | null,
	cancelation_pronounced?:ValueTypes["timestamptz"] | null,
	date?:ValueTypes["timestamptz"] | null,
	deposit?:number | null,
	deposit_payable?:ValueTypes["timestamptz"] | null,
	deposit_rate?:ValueTypes["deposit_rate_enum"] | null,
	deposit_received?:ValueTypes["timestamptz"] | null,
	deposit_type?:ValueTypes["deposit_type_enum"] | null,
	end?:ValueTypes["timestamptz"] | null,
	handover_move_in?:ValueTypes["timestamptz"] | null,
	handover_move_out?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	min?:ValueTypes["timestamptz"] | null,
	other_agreements?:string | null
};
	/** aggregate stddev on columns */
["rental_contract_stddev_fields"]: AliasType<{
	cancelation_period?:boolean,
	deposit?:boolean,
		__typename?: boolean
}>;
	/** order by stddev() on columns of table "rental_contract" */
["rental_contract_stddev_order_by"]: {
	cancelation_period?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_pop on columns */
["rental_contract_stddev_pop_fields"]: AliasType<{
	cancelation_period?:boolean,
	deposit?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_pop() on columns of table "rental_contract" */
["rental_contract_stddev_pop_order_by"]: {
	cancelation_period?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_samp on columns */
["rental_contract_stddev_samp_fields"]: AliasType<{
	cancelation_period?:boolean,
	deposit?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_samp() on columns of table "rental_contract" */
["rental_contract_stddev_samp_order_by"]: {
	cancelation_period?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null
};
	/** aggregate sum on columns */
["rental_contract_sum_fields"]: AliasType<{
	cancelation_period?:boolean,
	deposit?:boolean,
		__typename?: boolean
}>;
	/** order by sum() on columns of table "rental_contract" */
["rental_contract_sum_order_by"]: {
	cancelation_period?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null
};
	/** update columns of table "rental_contract" */
["rental_contract_update_column"]:rental_contract_update_column;
	/** aggregate var_pop on columns */
["rental_contract_var_pop_fields"]: AliasType<{
	cancelation_period?:boolean,
	deposit?:boolean,
		__typename?: boolean
}>;
	/** order by var_pop() on columns of table "rental_contract" */
["rental_contract_var_pop_order_by"]: {
	cancelation_period?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null
};
	/** aggregate var_samp on columns */
["rental_contract_var_samp_fields"]: AliasType<{
	cancelation_period?:boolean,
	deposit?:boolean,
		__typename?: boolean
}>;
	/** order by var_samp() on columns of table "rental_contract" */
["rental_contract_var_samp_order_by"]: {
	cancelation_period?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null
};
	/** aggregate variance on columns */
["rental_contract_variance_fields"]: AliasType<{
	cancelation_period?:boolean,
	deposit?:boolean,
		__typename?: boolean
}>;
	/** order by variance() on columns of table "rental_contract" */
["rental_contract_variance_order_by"]: {
	cancelation_period?:ValueTypes["order_by"] | null,
	deposit?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "rental_costs" */
["rental_costs"]: AliasType<{
	costs?:boolean,
	id?:boolean,
	name?:boolean,
	/** An object relationship */
	rent?:ValueTypes["rental_rent"],
	rent_id?:boolean,
	/** An object relationship */
	rental_costs_type?:ValueTypes["rental_costs_type"],
	type?:boolean,
	/** An object relationship */
	unit?:ValueTypes["unit"],
	unit_id?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** aggregated selection of "rental_costs" */
["rental_costs_aggregate"]: AliasType<{
	aggregate?:ValueTypes["rental_costs_aggregate_fields"],
	nodes?:ValueTypes["rental_costs"],
		__typename?: boolean
}>;
	/** aggregate fields of "rental_costs" */
["rental_costs_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["rental_costs_avg_fields"],
count?: [{	columns?:ValueTypes["rental_costs_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["rental_costs_max_fields"],
	min?:ValueTypes["rental_costs_min_fields"],
	stddev?:ValueTypes["rental_costs_stddev_fields"],
	stddev_pop?:ValueTypes["rental_costs_stddev_pop_fields"],
	stddev_samp?:ValueTypes["rental_costs_stddev_samp_fields"],
	sum?:ValueTypes["rental_costs_sum_fields"],
	var_pop?:ValueTypes["rental_costs_var_pop_fields"],
	var_samp?:ValueTypes["rental_costs_var_samp_fields"],
	variance?:ValueTypes["rental_costs_variance_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "rental_costs" */
["rental_costs_aggregate_order_by"]: {
	avg?:ValueTypes["rental_costs_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["rental_costs_max_order_by"] | null,
	min?:ValueTypes["rental_costs_min_order_by"] | null,
	stddev?:ValueTypes["rental_costs_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["rental_costs_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["rental_costs_stddev_samp_order_by"] | null,
	sum?:ValueTypes["rental_costs_sum_order_by"] | null,
	var_pop?:ValueTypes["rental_costs_var_pop_order_by"] | null,
	var_samp?:ValueTypes["rental_costs_var_samp_order_by"] | null,
	variance?:ValueTypes["rental_costs_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "rental_costs" */
["rental_costs_arr_rel_insert_input"]: {
	data:ValueTypes["rental_costs_insert_input"][],
	/** on conflict condition */
	on_conflict?:ValueTypes["rental_costs_on_conflict"] | null
};
	/** aggregate avg on columns */
["rental_costs_avg_fields"]: AliasType<{
	costs?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by avg() on columns of table "rental_costs" */
["rental_costs_avg_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "rental_costs". All fields are combined with a logical 'AND'. */
["rental_costs_bool_exp"]: {
	_and?:ValueTypes["rental_costs_bool_exp"][],
	_not?:ValueTypes["rental_costs_bool_exp"] | null,
	_or?:ValueTypes["rental_costs_bool_exp"][],
	costs?:ValueTypes["Int_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	rent?:ValueTypes["rental_rent_bool_exp"] | null,
	rent_id?:ValueTypes["uuid_comparison_exp"] | null,
	rental_costs_type?:ValueTypes["rental_costs_type_bool_exp"] | null,
	type?:ValueTypes["rental_costs_type_enum_comparison_exp"] | null,
	unit?:ValueTypes["unit_bool_exp"] | null,
	unit_id?:ValueTypes["uuid_comparison_exp"] | null,
	vat?:ValueTypes["Int_comparison_exp"] | null
};
	/** unique or primary key constraints on table "rental_costs" */
["rental_costs_constraint"]:rental_costs_constraint;
	/** input type for incrementing numeric columns in table "rental_costs" */
["rental_costs_inc_input"]: {
	costs?:number | null,
	vat?:number | null
};
	/** input type for inserting data into table "rental_costs" */
["rental_costs_insert_input"]: {
	costs?:number | null,
	id?:ValueTypes["uuid"] | null,
	name?:string | null,
	rent?:ValueTypes["rental_rent_obj_rel_insert_input"] | null,
	rent_id?:ValueTypes["uuid"] | null,
	rental_costs_type?:ValueTypes["rental_costs_type_obj_rel_insert_input"] | null,
	type?:ValueTypes["rental_costs_type_enum"] | null,
	unit?:ValueTypes["unit_obj_rel_insert_input"] | null,
	unit_id?:ValueTypes["uuid"] | null,
	vat?:number | null
};
	/** aggregate max on columns */
["rental_costs_max_fields"]: AliasType<{
	costs?:boolean,
	id?:boolean,
	name?:boolean,
	rent_id?:boolean,
	unit_id?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "rental_costs" */
["rental_costs_max_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	rent_id?:ValueTypes["order_by"] | null,
	unit_id?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["rental_costs_min_fields"]: AliasType<{
	costs?:boolean,
	id?:boolean,
	name?:boolean,
	rent_id?:boolean,
	unit_id?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "rental_costs" */
["rental_costs_min_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	rent_id?:ValueTypes["order_by"] | null,
	unit_id?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "rental_costs" */
["rental_costs_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["rental_costs"],
		__typename?: boolean
}>;
	/** on conflict condition type for table "rental_costs" */
["rental_costs_on_conflict"]: {
	constraint:ValueTypes["rental_costs_constraint"],
	update_columns:ValueTypes["rental_costs_update_column"][],
	where?:ValueTypes["rental_costs_bool_exp"] | null
};
	/** Ordering options when selecting data from "rental_costs". */
["rental_costs_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	rent?:ValueTypes["rental_rent_order_by"] | null,
	rent_id?:ValueTypes["order_by"] | null,
	rental_costs_type?:ValueTypes["rental_costs_type_order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	unit?:ValueTypes["unit_order_by"] | null,
	unit_id?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: rental_costs */
["rental_costs_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "rental_costs" */
["rental_costs_select_column"]:rental_costs_select_column;
	/** input type for updating data in table "rental_costs" */
["rental_costs_set_input"]: {
	costs?:number | null,
	id?:ValueTypes["uuid"] | null,
	name?:string | null,
	rent_id?:ValueTypes["uuid"] | null,
	type?:ValueTypes["rental_costs_type_enum"] | null,
	unit_id?:ValueTypes["uuid"] | null,
	vat?:number | null
};
	/** aggregate stddev on columns */
["rental_costs_stddev_fields"]: AliasType<{
	costs?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by stddev() on columns of table "rental_costs" */
["rental_costs_stddev_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_pop on columns */
["rental_costs_stddev_pop_fields"]: AliasType<{
	costs?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_pop() on columns of table "rental_costs" */
["rental_costs_stddev_pop_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_samp on columns */
["rental_costs_stddev_samp_fields"]: AliasType<{
	costs?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_samp() on columns of table "rental_costs" */
["rental_costs_stddev_samp_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** aggregate sum on columns */
["rental_costs_sum_fields"]: AliasType<{
	costs?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by sum() on columns of table "rental_costs" */
["rental_costs_sum_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "rental_costs_type" */
["rental_costs_type"]: AliasType<{
	name?:boolean,
rental_costs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs"]],
rental_costs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "rental_costs_type" */
["rental_costs_type_aggregate"]: AliasType<{
	aggregate?:ValueTypes["rental_costs_type_aggregate_fields"],
	nodes?:ValueTypes["rental_costs_type"],
		__typename?: boolean
}>;
	/** aggregate fields of "rental_costs_type" */
["rental_costs_type_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["rental_costs_type_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["rental_costs_type_max_fields"],
	min?:ValueTypes["rental_costs_type_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "rental_costs_type". All fields are combined with a logical 'AND'. */
["rental_costs_type_bool_exp"]: {
	_and?:ValueTypes["rental_costs_type_bool_exp"][],
	_not?:ValueTypes["rental_costs_type_bool_exp"] | null,
	_or?:ValueTypes["rental_costs_type_bool_exp"][],
	name?:ValueTypes["String_comparison_exp"] | null,
	rental_costs?:ValueTypes["rental_costs_bool_exp"] | null
};
	/** unique or primary key constraints on table "rental_costs_type" */
["rental_costs_type_constraint"]:rental_costs_type_constraint;
	["rental_costs_type_enum"]:rental_costs_type_enum;
	/** Boolean expression to compare columns of type "rental_costs_type_enum". All fields are combined with logical 'AND'. */
["rental_costs_type_enum_comparison_exp"]: {
	_eq?:ValueTypes["rental_costs_type_enum"] | null,
	_in?:ValueTypes["rental_costs_type_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["rental_costs_type_enum"] | null,
	_nin?:ValueTypes["rental_costs_type_enum"][]
};
	/** input type for inserting data into table "rental_costs_type" */
["rental_costs_type_insert_input"]: {
	name?:string | null,
	rental_costs?:ValueTypes["rental_costs_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["rental_costs_type_max_fields"]: AliasType<{
	name?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["rental_costs_type_min_fields"]: AliasType<{
	name?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "rental_costs_type" */
["rental_costs_type_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["rental_costs_type"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "rental_costs_type" */
["rental_costs_type_obj_rel_insert_input"]: {
	data:ValueTypes["rental_costs_type_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["rental_costs_type_on_conflict"] | null
};
	/** on conflict condition type for table "rental_costs_type" */
["rental_costs_type_on_conflict"]: {
	constraint:ValueTypes["rental_costs_type_constraint"],
	update_columns:ValueTypes["rental_costs_type_update_column"][],
	where?:ValueTypes["rental_costs_type_bool_exp"] | null
};
	/** Ordering options when selecting data from "rental_costs_type". */
["rental_costs_type_order_by"]: {
	name?:ValueTypes["order_by"] | null,
	rental_costs_aggregate?:ValueTypes["rental_costs_aggregate_order_by"] | null
};
	/** primary key columns input for table: rental_costs_type */
["rental_costs_type_pk_columns_input"]: {
	name:string
};
	/** select columns of table "rental_costs_type" */
["rental_costs_type_select_column"]:rental_costs_type_select_column;
	/** input type for updating data in table "rental_costs_type" */
["rental_costs_type_set_input"]: {
	name?:string | null
};
	/** update columns of table "rental_costs_type" */
["rental_costs_type_update_column"]:rental_costs_type_update_column;
	/** update columns of table "rental_costs" */
["rental_costs_update_column"]:rental_costs_update_column;
	/** aggregate var_pop on columns */
["rental_costs_var_pop_fields"]: AliasType<{
	costs?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by var_pop() on columns of table "rental_costs" */
["rental_costs_var_pop_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** aggregate var_samp on columns */
["rental_costs_var_samp_fields"]: AliasType<{
	costs?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by var_samp() on columns of table "rental_costs" */
["rental_costs_var_samp_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** aggregate variance on columns */
["rental_costs_variance_fields"]: AliasType<{
	costs?:boolean,
	vat?:boolean,
		__typename?: boolean
}>;
	/** order by variance() on columns of table "rental_costs" */
["rental_costs_variance_order_by"]: {
	costs?:ValueTypes["order_by"] | null,
	vat?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "rental_rent" */
["rental_rent"]: AliasType<{
	announced?:boolean,
	begin?:boolean,
	id?:boolean,
	/** An object relationship */
	rental_contract?:ValueTypes["rental_contract"],
	rental_contract_id?:boolean,
rental_costs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs"]],
rental_costs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "rental_rent" */
["rental_rent_aggregate"]: AliasType<{
	aggregate?:ValueTypes["rental_rent_aggregate_fields"],
	nodes?:ValueTypes["rental_rent"],
		__typename?: boolean
}>;
	/** aggregate fields of "rental_rent" */
["rental_rent_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["rental_rent_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["rental_rent_max_fields"],
	min?:ValueTypes["rental_rent_min_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "rental_rent" */
["rental_rent_aggregate_order_by"]: {
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["rental_rent_max_order_by"] | null,
	min?:ValueTypes["rental_rent_min_order_by"] | null
};
	/** input type for inserting array relation for remote table "rental_rent" */
["rental_rent_arr_rel_insert_input"]: {
	data:ValueTypes["rental_rent_insert_input"][],
	/** on conflict condition */
	on_conflict?:ValueTypes["rental_rent_on_conflict"] | null
};
	/** Boolean expression to filter rows from the table "rental_rent". All fields are combined with a logical 'AND'. */
["rental_rent_bool_exp"]: {
	_and?:ValueTypes["rental_rent_bool_exp"][],
	_not?:ValueTypes["rental_rent_bool_exp"] | null,
	_or?:ValueTypes["rental_rent_bool_exp"][],
	announced?:ValueTypes["timestamptz_comparison_exp"] | null,
	begin?:ValueTypes["timestamptz_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	rental_contract?:ValueTypes["rental_contract_bool_exp"] | null,
	rental_contract_id?:ValueTypes["uuid_comparison_exp"] | null,
	rental_costs?:ValueTypes["rental_costs_bool_exp"] | null
};
	/** unique or primary key constraints on table "rental_rent" */
["rental_rent_constraint"]:rental_rent_constraint;
	/** input type for inserting data into table "rental_rent" */
["rental_rent_insert_input"]: {
	announced?:ValueTypes["timestamptz"] | null,
	begin?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	rental_contract?:ValueTypes["rental_contract_obj_rel_insert_input"] | null,
	rental_contract_id?:ValueTypes["uuid"] | null,
	rental_costs?:ValueTypes["rental_costs_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["rental_rent_max_fields"]: AliasType<{
	announced?:boolean,
	begin?:boolean,
	id?:boolean,
	rental_contract_id?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "rental_rent" */
["rental_rent_max_order_by"]: {
	announced?:ValueTypes["order_by"] | null,
	begin?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rental_contract_id?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["rental_rent_min_fields"]: AliasType<{
	announced?:boolean,
	begin?:boolean,
	id?:boolean,
	rental_contract_id?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "rental_rent" */
["rental_rent_min_order_by"]: {
	announced?:ValueTypes["order_by"] | null,
	begin?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rental_contract_id?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "rental_rent" */
["rental_rent_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["rental_rent"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "rental_rent" */
["rental_rent_obj_rel_insert_input"]: {
	data:ValueTypes["rental_rent_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["rental_rent_on_conflict"] | null
};
	/** on conflict condition type for table "rental_rent" */
["rental_rent_on_conflict"]: {
	constraint:ValueTypes["rental_rent_constraint"],
	update_columns:ValueTypes["rental_rent_update_column"][],
	where?:ValueTypes["rental_rent_bool_exp"] | null
};
	/** Ordering options when selecting data from "rental_rent". */
["rental_rent_order_by"]: {
	announced?:ValueTypes["order_by"] | null,
	begin?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	rental_contract?:ValueTypes["rental_contract_order_by"] | null,
	rental_contract_id?:ValueTypes["order_by"] | null,
	rental_costs_aggregate?:ValueTypes["rental_costs_aggregate_order_by"] | null
};
	/** primary key columns input for table: rental_rent */
["rental_rent_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "rental_rent" */
["rental_rent_select_column"]:rental_rent_select_column;
	/** input type for updating data in table "rental_rent" */
["rental_rent_set_input"]: {
	announced?:ValueTypes["timestamptz"] | null,
	begin?:ValueTypes["timestamptz"] | null,
	id?:ValueTypes["uuid"] | null,
	rental_contract_id?:ValueTypes["uuid"] | null
};
	/** update columns of table "rental_rent" */
["rental_rent_update_column"]:rental_rent_update_column;
	["subscription_root"]: AliasType<{
color?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["color_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["color_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["color_bool_exp"] | null},ValueTypes["color"]],
color_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["color_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["color_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["color_bool_exp"] | null},ValueTypes["color_aggregate"]],
color_by_pk?: [{	color:string},ValueTypes["color"]],
country_code?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["country_code_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["country_code_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["country_code_bool_exp"] | null},ValueTypes["country_code"]],
country_code_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["country_code_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["country_code_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["country_code_bool_exp"] | null},ValueTypes["country_code_aggregate"]],
country_code_by_pk?: [{	code:string},ValueTypes["country_code"]],
deposit_rate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["deposit_rate_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["deposit_rate_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["deposit_rate_bool_exp"] | null},ValueTypes["deposit_rate"]],
deposit_rate_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["deposit_rate_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["deposit_rate_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["deposit_rate_bool_exp"] | null},ValueTypes["deposit_rate_aggregate"]],
deposit_rate_by_pk?: [{	name:string},ValueTypes["deposit_rate"]],
deposit_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["deposit_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["deposit_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["deposit_type_bool_exp"] | null},ValueTypes["deposit_type"]],
deposit_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["deposit_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["deposit_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["deposit_type_bool_exp"] | null},ValueTypes["deposit_type_aggregate"]],
deposit_type_by_pk?: [{	name:string},ValueTypes["deposit_type"]],
group?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["group_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["group_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["group_bool_exp"] | null},ValueTypes["group"]],
group_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["group_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["group_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["group_bool_exp"] | null},ValueTypes["group_aggregate"]],
group_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["group"]],
property?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_bool_exp"] | null},ValueTypes["property"]],
property_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_bool_exp"] | null},ValueTypes["property_aggregate"]],
property_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["property"]],
property_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_type_bool_exp"] | null},ValueTypes["property_type"]],
property_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["property_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["property_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["property_type_bool_exp"] | null},ValueTypes["property_type_aggregate"]],
property_type_by_pk?: [{	name:string},ValueTypes["property_type"]],
rent_index?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_bool_exp"] | null},ValueTypes["rent_index"]],
rent_index_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_bool_exp"] | null},ValueTypes["rent_index_aggregate"]],
rent_index_area?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_area_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_area_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_area_bool_exp"] | null},ValueTypes["rent_index_area"]],
rent_index_area_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_area_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_area_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_area_bool_exp"] | null},ValueTypes["rent_index_area_aggregate"]],
rent_index_area_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rent_index_area"]],
rent_index_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rent_index"]],
rent_index_layer_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_layer_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_layer_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_layer_type_bool_exp"] | null},ValueTypes["rent_index_layer_type"]],
rent_index_layer_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rent_index_layer_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rent_index_layer_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rent_index_layer_type_bool_exp"] | null},ValueTypes["rent_index_layer_type_aggregate"]],
rent_index_layer_type_by_pk?: [{	name:string},ValueTypes["rent_index_layer_type"]],
rental_contract?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_contract_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_contract_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_contract_bool_exp"] | null},ValueTypes["rental_contract"]],
rental_contract_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_contract_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_contract_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_contract_bool_exp"] | null},ValueTypes["rental_contract_aggregate"]],
rental_contract_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_contract"]],
rental_costs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs"]],
rental_costs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs_aggregate"]],
rental_costs_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_costs"]],
rental_costs_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_type_bool_exp"] | null},ValueTypes["rental_costs_type"]],
rental_costs_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_type_bool_exp"] | null},ValueTypes["rental_costs_type_aggregate"]],
rental_costs_type_by_pk?: [{	name:string},ValueTypes["rental_costs_type"]],
rental_rent?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_rent_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_rent_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_rent_bool_exp"] | null},ValueTypes["rental_rent"]],
rental_rent_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_rent_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_rent_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_rent_bool_exp"] | null},ValueTypes["rental_rent_aggregate"]],
rental_rent_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["rental_rent"]],
unit?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_bool_exp"] | null},ValueTypes["unit"]],
unit_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_bool_exp"] | null},ValueTypes["unit_aggregate"]],
unit_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["unit"]],
unit_type?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_type_bool_exp"] | null},ValueTypes["unit_type"]],
unit_type_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_type_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_type_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_type_bool_exp"] | null},ValueTypes["unit_type_aggregate"]],
unit_type_by_pk?: [{	name:string},ValueTypes["unit_type"]],
user?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["user_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["user_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["user_bool_exp"] | null},ValueTypes["user"]],
user_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["user_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["user_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["user_bool_exp"] | null},ValueTypes["user_aggregate"]],
user_by_pk?: [{	id:ValueTypes["uuid"]},ValueTypes["user"]],
		__typename?: boolean
}>;
	["timestamptz"]:unknown;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: {
	_eq?:ValueTypes["timestamptz"] | null,
	_gt?:ValueTypes["timestamptz"] | null,
	_gte?:ValueTypes["timestamptz"] | null,
	_in?:ValueTypes["timestamptz"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["timestamptz"] | null,
	_lte?:ValueTypes["timestamptz"] | null,
	_neq?:ValueTypes["timestamptz"] | null,
	_nin?:ValueTypes["timestamptz"][]
};
	/** columns and relationships of "unit" */
["unit"]: AliasType<{
	id?:boolean,
	level?:boolean,
	name?:boolean,
	/** An object relationship */
	property?:ValueTypes["property"],
	property_id?:boolean,
rental_costs?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs"]],
rental_costs_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["rental_costs_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["rental_costs_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["rental_costs_bool_exp"] | null},ValueTypes["rental_costs_aggregate"]],
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
	type?:boolean,
	/** An object relationship */
	unit_type?:ValueTypes["unit_type"],
		__typename?: boolean
}>;
	/** aggregated selection of "unit" */
["unit_aggregate"]: AliasType<{
	aggregate?:ValueTypes["unit_aggregate_fields"],
	nodes?:ValueTypes["unit"],
		__typename?: boolean
}>;
	/** aggregate fields of "unit" */
["unit_aggregate_fields"]: AliasType<{
	avg?:ValueTypes["unit_avg_fields"],
count?: [{	columns?:ValueTypes["unit_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["unit_max_fields"],
	min?:ValueTypes["unit_min_fields"],
	stddev?:ValueTypes["unit_stddev_fields"],
	stddev_pop?:ValueTypes["unit_stddev_pop_fields"],
	stddev_samp?:ValueTypes["unit_stddev_samp_fields"],
	sum?:ValueTypes["unit_sum_fields"],
	var_pop?:ValueTypes["unit_var_pop_fields"],
	var_samp?:ValueTypes["unit_var_samp_fields"],
	variance?:ValueTypes["unit_variance_fields"],
		__typename?: boolean
}>;
	/** order by aggregate values of table "unit" */
["unit_aggregate_order_by"]: {
	avg?:ValueTypes["unit_avg_order_by"] | null,
	count?:ValueTypes["order_by"] | null,
	max?:ValueTypes["unit_max_order_by"] | null,
	min?:ValueTypes["unit_min_order_by"] | null,
	stddev?:ValueTypes["unit_stddev_order_by"] | null,
	stddev_pop?:ValueTypes["unit_stddev_pop_order_by"] | null,
	stddev_samp?:ValueTypes["unit_stddev_samp_order_by"] | null,
	sum?:ValueTypes["unit_sum_order_by"] | null,
	var_pop?:ValueTypes["unit_var_pop_order_by"] | null,
	var_samp?:ValueTypes["unit_var_samp_order_by"] | null,
	variance?:ValueTypes["unit_variance_order_by"] | null
};
	/** input type for inserting array relation for remote table "unit" */
["unit_arr_rel_insert_input"]: {
	data:ValueTypes["unit_insert_input"][],
	/** on conflict condition */
	on_conflict?:ValueTypes["unit_on_conflict"] | null
};
	/** aggregate avg on columns */
["unit_avg_fields"]: AliasType<{
	level?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by avg() on columns of table "unit" */
["unit_avg_order_by"]: {
	level?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** Boolean expression to filter rows from the table "unit". All fields are combined with a logical 'AND'. */
["unit_bool_exp"]: {
	_and?:ValueTypes["unit_bool_exp"][],
	_not?:ValueTypes["unit_bool_exp"] | null,
	_or?:ValueTypes["unit_bool_exp"][],
	id?:ValueTypes["uuid_comparison_exp"] | null,
	level?:ValueTypes["Float_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	property?:ValueTypes["property_bool_exp"] | null,
	property_id?:ValueTypes["uuid_comparison_exp"] | null,
	rental_costs?:ValueTypes["rental_costs_bool_exp"] | null,
	rooms?:ValueTypes["Float_comparison_exp"] | null,
	size?:ValueTypes["Float_comparison_exp"] | null,
	size_heated?:ValueTypes["Float_comparison_exp"] | null,
	type?:ValueTypes["unit_type_enum_comparison_exp"] | null,
	unit_type?:ValueTypes["unit_type_bool_exp"] | null
};
	/** unique or primary key constraints on table "unit" */
["unit_constraint"]:unit_constraint;
	/** input type for incrementing numeric columns in table "unit" */
["unit_inc_input"]: {
	level?:number | null,
	rooms?:number | null,
	size?:number | null,
	size_heated?:number | null
};
	/** input type for inserting data into table "unit" */
["unit_insert_input"]: {
	id?:ValueTypes["uuid"] | null,
	level?:number | null,
	name?:string | null,
	property?:ValueTypes["property_obj_rel_insert_input"] | null,
	property_id?:ValueTypes["uuid"] | null,
	rental_costs?:ValueTypes["rental_costs_arr_rel_insert_input"] | null,
	rooms?:number | null,
	size?:number | null,
	size_heated?:number | null,
	type?:ValueTypes["unit_type_enum"] | null,
	unit_type?:ValueTypes["unit_type_obj_rel_insert_input"] | null
};
	/** aggregate max on columns */
["unit_max_fields"]: AliasType<{
	id?:boolean,
	level?:boolean,
	name?:boolean,
	property_id?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by max() on columns of table "unit" */
["unit_max_order_by"]: {
	id?:ValueTypes["order_by"] | null,
	level?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	property_id?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** aggregate min on columns */
["unit_min_fields"]: AliasType<{
	id?:boolean,
	level?:boolean,
	name?:boolean,
	property_id?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by min() on columns of table "unit" */
["unit_min_order_by"]: {
	id?:ValueTypes["order_by"] | null,
	level?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	property_id?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** response of any mutation on the table "unit" */
["unit_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["unit"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "unit" */
["unit_obj_rel_insert_input"]: {
	data:ValueTypes["unit_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["unit_on_conflict"] | null
};
	/** on conflict condition type for table "unit" */
["unit_on_conflict"]: {
	constraint:ValueTypes["unit_constraint"],
	update_columns:ValueTypes["unit_update_column"][],
	where?:ValueTypes["unit_bool_exp"] | null
};
	/** Ordering options when selecting data from "unit". */
["unit_order_by"]: {
	id?:ValueTypes["order_by"] | null,
	level?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	property?:ValueTypes["property_order_by"] | null,
	property_id?:ValueTypes["order_by"] | null,
	rental_costs_aggregate?:ValueTypes["rental_costs_aggregate_order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null,
	type?:ValueTypes["order_by"] | null,
	unit_type?:ValueTypes["unit_type_order_by"] | null
};
	/** primary key columns input for table: unit */
["unit_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "unit" */
["unit_select_column"]:unit_select_column;
	/** input type for updating data in table "unit" */
["unit_set_input"]: {
	id?:ValueTypes["uuid"] | null,
	level?:number | null,
	name?:string | null,
	property_id?:ValueTypes["uuid"] | null,
	rooms?:number | null,
	size?:number | null,
	size_heated?:number | null,
	type?:ValueTypes["unit_type_enum"] | null
};
	/** aggregate stddev on columns */
["unit_stddev_fields"]: AliasType<{
	level?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by stddev() on columns of table "unit" */
["unit_stddev_order_by"]: {
	level?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_pop on columns */
["unit_stddev_pop_fields"]: AliasType<{
	level?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_pop() on columns of table "unit" */
["unit_stddev_pop_order_by"]: {
	level?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** aggregate stddev_samp on columns */
["unit_stddev_samp_fields"]: AliasType<{
	level?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by stddev_samp() on columns of table "unit" */
["unit_stddev_samp_order_by"]: {
	level?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** aggregate sum on columns */
["unit_sum_fields"]: AliasType<{
	level?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by sum() on columns of table "unit" */
["unit_sum_order_by"]: {
	level?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "unit_type" */
["unit_type"]: AliasType<{
	description?:boolean,
	name?:boolean,
units?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_bool_exp"] | null},ValueTypes["unit"]],
units_aggregate?: [{	/** distinct select on columns */
	distinct_on?:ValueTypes["unit_select_column"][],	/** limit the number of rows returned */
	limit?:number | null,	/** skip the first n rows. Use only with order_by */
	offset?:number | null,	/** sort the rows by one or more columns */
	order_by?:ValueTypes["unit_order_by"][],	/** filter the rows returned */
	where?:ValueTypes["unit_bool_exp"] | null},ValueTypes["unit_aggregate"]],
		__typename?: boolean
}>;
	/** aggregated selection of "unit_type" */
["unit_type_aggregate"]: AliasType<{
	aggregate?:ValueTypes["unit_type_aggregate_fields"],
	nodes?:ValueTypes["unit_type"],
		__typename?: boolean
}>;
	/** aggregate fields of "unit_type" */
["unit_type_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["unit_type_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["unit_type_max_fields"],
	min?:ValueTypes["unit_type_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "unit_type". All fields are combined with a logical 'AND'. */
["unit_type_bool_exp"]: {
	_and?:ValueTypes["unit_type_bool_exp"][],
	_not?:ValueTypes["unit_type_bool_exp"] | null,
	_or?:ValueTypes["unit_type_bool_exp"][],
	description?:ValueTypes["String_comparison_exp"] | null,
	name?:ValueTypes["String_comparison_exp"] | null,
	units?:ValueTypes["unit_bool_exp"] | null
};
	/** unique or primary key constraints on table "unit_type" */
["unit_type_constraint"]:unit_type_constraint;
	["unit_type_enum"]:unit_type_enum;
	/** Boolean expression to compare columns of type "unit_type_enum". All fields are combined with logical 'AND'. */
["unit_type_enum_comparison_exp"]: {
	_eq?:ValueTypes["unit_type_enum"] | null,
	_in?:ValueTypes["unit_type_enum"][],
	_is_null?:boolean | null,
	_neq?:ValueTypes["unit_type_enum"] | null,
	_nin?:ValueTypes["unit_type_enum"][]
};
	/** input type for inserting data into table "unit_type" */
["unit_type_insert_input"]: {
	description?:string | null,
	name?:string | null,
	units?:ValueTypes["unit_arr_rel_insert_input"] | null
};
	/** aggregate max on columns */
["unit_type_max_fields"]: AliasType<{
	description?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["unit_type_min_fields"]: AliasType<{
	description?:boolean,
	name?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "unit_type" */
["unit_type_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["unit_type"],
		__typename?: boolean
}>;
	/** input type for inserting object relation for remote table "unit_type" */
["unit_type_obj_rel_insert_input"]: {
	data:ValueTypes["unit_type_insert_input"],
	/** on conflict condition */
	on_conflict?:ValueTypes["unit_type_on_conflict"] | null
};
	/** on conflict condition type for table "unit_type" */
["unit_type_on_conflict"]: {
	constraint:ValueTypes["unit_type_constraint"],
	update_columns:ValueTypes["unit_type_update_column"][],
	where?:ValueTypes["unit_type_bool_exp"] | null
};
	/** Ordering options when selecting data from "unit_type". */
["unit_type_order_by"]: {
	description?:ValueTypes["order_by"] | null,
	name?:ValueTypes["order_by"] | null,
	units_aggregate?:ValueTypes["unit_aggregate_order_by"] | null
};
	/** primary key columns input for table: unit_type */
["unit_type_pk_columns_input"]: {
	name:string
};
	/** select columns of table "unit_type" */
["unit_type_select_column"]:unit_type_select_column;
	/** input type for updating data in table "unit_type" */
["unit_type_set_input"]: {
	description?:string | null,
	name?:string | null
};
	/** update columns of table "unit_type" */
["unit_type_update_column"]:unit_type_update_column;
	/** update columns of table "unit" */
["unit_update_column"]:unit_update_column;
	/** aggregate var_pop on columns */
["unit_var_pop_fields"]: AliasType<{
	level?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by var_pop() on columns of table "unit" */
["unit_var_pop_order_by"]: {
	level?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** aggregate var_samp on columns */
["unit_var_samp_fields"]: AliasType<{
	level?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by var_samp() on columns of table "unit" */
["unit_var_samp_order_by"]: {
	level?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** aggregate variance on columns */
["unit_variance_fields"]: AliasType<{
	level?:boolean,
	rooms?:boolean,
	size?:boolean,
	size_heated?:boolean,
		__typename?: boolean
}>;
	/** order by variance() on columns of table "unit" */
["unit_variance_order_by"]: {
	level?:ValueTypes["order_by"] | null,
	rooms?:ValueTypes["order_by"] | null,
	size?:ValueTypes["order_by"] | null,
	size_heated?:ValueTypes["order_by"] | null
};
	/** columns and relationships of "user" */
["user"]: AliasType<{
	active?:boolean,
	email?:boolean,
	id?:boolean,
	password?:boolean,
		__typename?: boolean
}>;
	/** aggregated selection of "user" */
["user_aggregate"]: AliasType<{
	aggregate?:ValueTypes["user_aggregate_fields"],
	nodes?:ValueTypes["user"],
		__typename?: boolean
}>;
	/** aggregate fields of "user" */
["user_aggregate_fields"]: AliasType<{
count?: [{	columns?:ValueTypes["user_select_column"][],	distinct?:boolean | null},boolean],
	max?:ValueTypes["user_max_fields"],
	min?:ValueTypes["user_min_fields"],
		__typename?: boolean
}>;
	/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
["user_bool_exp"]: {
	_and?:ValueTypes["user_bool_exp"][],
	_not?:ValueTypes["user_bool_exp"] | null,
	_or?:ValueTypes["user_bool_exp"][],
	active?:ValueTypes["Boolean_comparison_exp"] | null,
	email?:ValueTypes["String_comparison_exp"] | null,
	id?:ValueTypes["uuid_comparison_exp"] | null,
	password?:ValueTypes["String_comparison_exp"] | null
};
	/** unique or primary key constraints on table "user" */
["user_constraint"]:user_constraint;
	/** input type for inserting data into table "user" */
["user_insert_input"]: {
	active?:boolean | null,
	email?:string | null,
	id?:ValueTypes["uuid"] | null,
	password?:string | null
};
	/** aggregate max on columns */
["user_max_fields"]: AliasType<{
	email?:boolean,
	id?:boolean,
	password?:boolean,
		__typename?: boolean
}>;
	/** aggregate min on columns */
["user_min_fields"]: AliasType<{
	email?:boolean,
	id?:boolean,
	password?:boolean,
		__typename?: boolean
}>;
	/** response of any mutation on the table "user" */
["user_mutation_response"]: AliasType<{
	/** number of rows affected by the mutation */
	affected_rows?:boolean,
	/** data from the rows affected by the mutation */
	returning?:ValueTypes["user"],
		__typename?: boolean
}>;
	/** on conflict condition type for table "user" */
["user_on_conflict"]: {
	constraint:ValueTypes["user_constraint"],
	update_columns:ValueTypes["user_update_column"][],
	where?:ValueTypes["user_bool_exp"] | null
};
	/** Ordering options when selecting data from "user". */
["user_order_by"]: {
	active?:ValueTypes["order_by"] | null,
	email?:ValueTypes["order_by"] | null,
	id?:ValueTypes["order_by"] | null,
	password?:ValueTypes["order_by"] | null
};
	/** primary key columns input for table: user */
["user_pk_columns_input"]: {
	id:ValueTypes["uuid"]
};
	/** select columns of table "user" */
["user_select_column"]:user_select_column;
	/** input type for updating data in table "user" */
["user_set_input"]: {
	active?:boolean | null,
	email?:string | null,
	id?:ValueTypes["uuid"] | null,
	password?:string | null
};
	/** update columns of table "user" */
["user_update_column"]:user_update_column;
	["uuid"]:unknown;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: {
	_eq?:ValueTypes["uuid"] | null,
	_gt?:ValueTypes["uuid"] | null,
	_gte?:ValueTypes["uuid"] | null,
	_in?:ValueTypes["uuid"][],
	_is_null?:boolean | null,
	_lt?:ValueTypes["uuid"] | null,
	_lte?:ValueTypes["uuid"] | null,
	_neq?:ValueTypes["uuid"] | null,
	_nin?:ValueTypes["uuid"][]
}
  }

export type ModelTypes = {
    /** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: GraphQLTypes["Boolean_comparison_exp"];
	/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
["Float_comparison_exp"]: GraphQLTypes["Float_comparison_exp"];
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: GraphQLTypes["Int_comparison_exp"];
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: GraphQLTypes["String_comparison_exp"];
	/** columns and relationships of "color" */
["color"]: {
		color:string,
	/** An array relationship */
	groups:ModelTypes["group"][],
	/** An aggregate relationship */
	groups_aggregate:ModelTypes["group_aggregate"]
};
	/** aggregated selection of "color" */
["color_aggregate"]: {
		aggregate?:ModelTypes["color_aggregate_fields"],
	nodes:ModelTypes["color"][]
};
	/** aggregate fields of "color" */
["color_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["color_max_fields"],
	min?:ModelTypes["color_min_fields"]
};
	/** Boolean expression to filter rows from the table "color". All fields are combined with a logical 'AND'. */
["color_bool_exp"]: GraphQLTypes["color_bool_exp"];
	/** unique or primary key constraints on table "color" */
["color_constraint"]: GraphQLTypes["color_constraint"];
	["color_enum"]: GraphQLTypes["color_enum"];
	/** Boolean expression to compare columns of type "color_enum". All fields are combined with logical 'AND'. */
["color_enum_comparison_exp"]: GraphQLTypes["color_enum_comparison_exp"];
	/** input type for inserting data into table "color" */
["color_insert_input"]: GraphQLTypes["color_insert_input"];
	/** aggregate max on columns */
["color_max_fields"]: {
		color?:string
};
	/** aggregate min on columns */
["color_min_fields"]: {
		color?:string
};
	/** response of any mutation on the table "color" */
["color_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["color"][]
};
	/** input type for inserting object relation for remote table "color" */
["color_obj_rel_insert_input"]: GraphQLTypes["color_obj_rel_insert_input"];
	/** on conflict condition type for table "color" */
["color_on_conflict"]: GraphQLTypes["color_on_conflict"];
	/** Ordering options when selecting data from "color". */
["color_order_by"]: GraphQLTypes["color_order_by"];
	/** primary key columns input for table: color */
["color_pk_columns_input"]: GraphQLTypes["color_pk_columns_input"];
	/** select columns of table "color" */
["color_select_column"]: GraphQLTypes["color_select_column"];
	/** input type for updating data in table "color" */
["color_set_input"]: GraphQLTypes["color_set_input"];
	/** update columns of table "color" */
["color_update_column"]: GraphQLTypes["color_update_column"];
	/** columns and relationships of "country_code" */
["country_code"]: {
		code:string,
	country:string,
	/** An array relationship */
	properties:ModelTypes["property"][],
	/** An aggregate relationship */
	properties_aggregate:ModelTypes["property_aggregate"]
};
	/** aggregated selection of "country_code" */
["country_code_aggregate"]: {
		aggregate?:ModelTypes["country_code_aggregate_fields"],
	nodes:ModelTypes["country_code"][]
};
	/** aggregate fields of "country_code" */
["country_code_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["country_code_max_fields"],
	min?:ModelTypes["country_code_min_fields"]
};
	/** Boolean expression to filter rows from the table "country_code". All fields are combined with a logical 'AND'. */
["country_code_bool_exp"]: GraphQLTypes["country_code_bool_exp"];
	/** unique or primary key constraints on table "country_code" */
["country_code_constraint"]: GraphQLTypes["country_code_constraint"];
	["country_code_enum"]: GraphQLTypes["country_code_enum"];
	/** Boolean expression to compare columns of type "country_code_enum". All fields are combined with logical 'AND'. */
["country_code_enum_comparison_exp"]: GraphQLTypes["country_code_enum_comparison_exp"];
	/** input type for inserting data into table "country_code" */
["country_code_insert_input"]: GraphQLTypes["country_code_insert_input"];
	/** aggregate max on columns */
["country_code_max_fields"]: {
		code?:string,
	country?:string
};
	/** aggregate min on columns */
["country_code_min_fields"]: {
		code?:string,
	country?:string
};
	/** response of any mutation on the table "country_code" */
["country_code_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["country_code"][]
};
	/** input type for inserting object relation for remote table "country_code" */
["country_code_obj_rel_insert_input"]: GraphQLTypes["country_code_obj_rel_insert_input"];
	/** on conflict condition type for table "country_code" */
["country_code_on_conflict"]: GraphQLTypes["country_code_on_conflict"];
	/** Ordering options when selecting data from "country_code". */
["country_code_order_by"]: GraphQLTypes["country_code_order_by"];
	/** primary key columns input for table: country_code */
["country_code_pk_columns_input"]: GraphQLTypes["country_code_pk_columns_input"];
	/** select columns of table "country_code" */
["country_code_select_column"]: GraphQLTypes["country_code_select_column"];
	/** input type for updating data in table "country_code" */
["country_code_set_input"]: GraphQLTypes["country_code_set_input"];
	/** update columns of table "country_code" */
["country_code_update_column"]: GraphQLTypes["country_code_update_column"];
	["date"]:any;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: GraphQLTypes["date_comparison_exp"];
	/** columns and relationships of "deposit_rate" */
["deposit_rate"]: {
		name:string,
	/** An array relationship */
	rental_contracts:ModelTypes["rental_contract"][],
	/** An aggregate relationship */
	rental_contracts_aggregate:ModelTypes["rental_contract_aggregate"]
};
	/** aggregated selection of "deposit_rate" */
["deposit_rate_aggregate"]: {
		aggregate?:ModelTypes["deposit_rate_aggregate_fields"],
	nodes:ModelTypes["deposit_rate"][]
};
	/** aggregate fields of "deposit_rate" */
["deposit_rate_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["deposit_rate_max_fields"],
	min?:ModelTypes["deposit_rate_min_fields"]
};
	/** Boolean expression to filter rows from the table "deposit_rate". All fields are combined with a logical 'AND'. */
["deposit_rate_bool_exp"]: GraphQLTypes["deposit_rate_bool_exp"];
	/** unique or primary key constraints on table "deposit_rate" */
["deposit_rate_constraint"]: GraphQLTypes["deposit_rate_constraint"];
	["deposit_rate_enum"]: GraphQLTypes["deposit_rate_enum"];
	/** Boolean expression to compare columns of type "deposit_rate_enum". All fields are combined with logical 'AND'. */
["deposit_rate_enum_comparison_exp"]: GraphQLTypes["deposit_rate_enum_comparison_exp"];
	/** input type for inserting data into table "deposit_rate" */
["deposit_rate_insert_input"]: GraphQLTypes["deposit_rate_insert_input"];
	/** aggregate max on columns */
["deposit_rate_max_fields"]: {
		name?:string
};
	/** aggregate min on columns */
["deposit_rate_min_fields"]: {
		name?:string
};
	/** response of any mutation on the table "deposit_rate" */
["deposit_rate_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["deposit_rate"][]
};
	/** input type for inserting object relation for remote table "deposit_rate" */
["deposit_rate_obj_rel_insert_input"]: GraphQLTypes["deposit_rate_obj_rel_insert_input"];
	/** on conflict condition type for table "deposit_rate" */
["deposit_rate_on_conflict"]: GraphQLTypes["deposit_rate_on_conflict"];
	/** Ordering options when selecting data from "deposit_rate". */
["deposit_rate_order_by"]: GraphQLTypes["deposit_rate_order_by"];
	/** primary key columns input for table: deposit_rate */
["deposit_rate_pk_columns_input"]: GraphQLTypes["deposit_rate_pk_columns_input"];
	/** select columns of table "deposit_rate" */
["deposit_rate_select_column"]: GraphQLTypes["deposit_rate_select_column"];
	/** input type for updating data in table "deposit_rate" */
["deposit_rate_set_input"]: GraphQLTypes["deposit_rate_set_input"];
	/** update columns of table "deposit_rate" */
["deposit_rate_update_column"]: GraphQLTypes["deposit_rate_update_column"];
	/** columns and relationships of "deposit_type" */
["deposit_type"]: {
		name:string,
	/** An array relationship */
	rental_contracts:ModelTypes["rental_contract"][],
	/** An aggregate relationship */
	rental_contracts_aggregate:ModelTypes["rental_contract_aggregate"]
};
	/** aggregated selection of "deposit_type" */
["deposit_type_aggregate"]: {
		aggregate?:ModelTypes["deposit_type_aggregate_fields"],
	nodes:ModelTypes["deposit_type"][]
};
	/** aggregate fields of "deposit_type" */
["deposit_type_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["deposit_type_max_fields"],
	min?:ModelTypes["deposit_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "deposit_type". All fields are combined with a logical 'AND'. */
["deposit_type_bool_exp"]: GraphQLTypes["deposit_type_bool_exp"];
	/** unique or primary key constraints on table "deposit_type" */
["deposit_type_constraint"]: GraphQLTypes["deposit_type_constraint"];
	["deposit_type_enum"]: GraphQLTypes["deposit_type_enum"];
	/** Boolean expression to compare columns of type "deposit_type_enum". All fields are combined with logical 'AND'. */
["deposit_type_enum_comparison_exp"]: GraphQLTypes["deposit_type_enum_comparison_exp"];
	/** input type for inserting data into table "deposit_type" */
["deposit_type_insert_input"]: GraphQLTypes["deposit_type_insert_input"];
	/** aggregate max on columns */
["deposit_type_max_fields"]: {
		name?:string
};
	/** aggregate min on columns */
["deposit_type_min_fields"]: {
		name?:string
};
	/** response of any mutation on the table "deposit_type" */
["deposit_type_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["deposit_type"][]
};
	/** input type for inserting object relation for remote table "deposit_type" */
["deposit_type_obj_rel_insert_input"]: GraphQLTypes["deposit_type_obj_rel_insert_input"];
	/** on conflict condition type for table "deposit_type" */
["deposit_type_on_conflict"]: GraphQLTypes["deposit_type_on_conflict"];
	/** Ordering options when selecting data from "deposit_type". */
["deposit_type_order_by"]: GraphQLTypes["deposit_type_order_by"];
	/** primary key columns input for table: deposit_type */
["deposit_type_pk_columns_input"]: GraphQLTypes["deposit_type_pk_columns_input"];
	/** select columns of table "deposit_type" */
["deposit_type_select_column"]: GraphQLTypes["deposit_type_select_column"];
	/** input type for updating data in table "deposit_type" */
["deposit_type_set_input"]: GraphQLTypes["deposit_type_set_input"];
	/** update columns of table "deposit_type" */
["deposit_type_update_column"]: GraphQLTypes["deposit_type_update_column"];
	/** columns and relationships of "group" */
["group"]: {
		abbreviation:string,
	color?:ModelTypes["color_enum"],
	id:ModelTypes["uuid"],
	name:string,
	/** An object relationship */
	object_color_type?:ModelTypes["color"]
};
	/** aggregated selection of "group" */
["group_aggregate"]: {
		aggregate?:ModelTypes["group_aggregate_fields"],
	nodes:ModelTypes["group"][]
};
	/** aggregate fields of "group" */
["group_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["group_max_fields"],
	min?:ModelTypes["group_min_fields"]
};
	/** order by aggregate values of table "group" */
["group_aggregate_order_by"]: GraphQLTypes["group_aggregate_order_by"];
	/** input type for inserting array relation for remote table "group" */
["group_arr_rel_insert_input"]: GraphQLTypes["group_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "group". All fields are combined with a logical 'AND'. */
["group_bool_exp"]: GraphQLTypes["group_bool_exp"];
	/** unique or primary key constraints on table "group" */
["group_constraint"]: GraphQLTypes["group_constraint"];
	/** input type for inserting data into table "group" */
["group_insert_input"]: GraphQLTypes["group_insert_input"];
	/** aggregate max on columns */
["group_max_fields"]: {
		abbreviation?:string,
	id?:ModelTypes["uuid"],
	name?:string
};
	/** order by max() on columns of table "group" */
["group_max_order_by"]: GraphQLTypes["group_max_order_by"];
	/** aggregate min on columns */
["group_min_fields"]: {
		abbreviation?:string,
	id?:ModelTypes["uuid"],
	name?:string
};
	/** order by min() on columns of table "group" */
["group_min_order_by"]: GraphQLTypes["group_min_order_by"];
	/** response of any mutation on the table "group" */
["group_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["group"][]
};
	/** on conflict condition type for table "group" */
["group_on_conflict"]: GraphQLTypes["group_on_conflict"];
	/** Ordering options when selecting data from "group". */
["group_order_by"]: GraphQLTypes["group_order_by"];
	/** primary key columns input for table: group */
["group_pk_columns_input"]: GraphQLTypes["group_pk_columns_input"];
	/** select columns of table "group" */
["group_select_column"]: GraphQLTypes["group_select_column"];
	/** input type for updating data in table "group" */
["group_set_input"]: GraphQLTypes["group_set_input"];
	/** update columns of table "group" */
["group_update_column"]: GraphQLTypes["group_update_column"];
	["jsonb"]:any;
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: GraphQLTypes["jsonb_comparison_exp"];
	/** mutation root */
["mutation_root"]: {
		/** delete data from the table: "color" */
	delete_color?:ModelTypes["color_mutation_response"],
	/** delete single row from the table: "color" */
	delete_color_by_pk?:ModelTypes["color"],
	/** delete data from the table: "country_code" */
	delete_country_code?:ModelTypes["country_code_mutation_response"],
	/** delete single row from the table: "country_code" */
	delete_country_code_by_pk?:ModelTypes["country_code"],
	/** delete data from the table: "deposit_rate" */
	delete_deposit_rate?:ModelTypes["deposit_rate_mutation_response"],
	/** delete single row from the table: "deposit_rate" */
	delete_deposit_rate_by_pk?:ModelTypes["deposit_rate"],
	/** delete data from the table: "deposit_type" */
	delete_deposit_type?:ModelTypes["deposit_type_mutation_response"],
	/** delete single row from the table: "deposit_type" */
	delete_deposit_type_by_pk?:ModelTypes["deposit_type"],
	/** delete data from the table: "group" */
	delete_group?:ModelTypes["group_mutation_response"],
	/** delete single row from the table: "group" */
	delete_group_by_pk?:ModelTypes["group"],
	/** delete data from the table: "property" */
	delete_property?:ModelTypes["property_mutation_response"],
	/** delete single row from the table: "property" */
	delete_property_by_pk?:ModelTypes["property"],
	/** delete data from the table: "property_type" */
	delete_property_type?:ModelTypes["property_type_mutation_response"],
	/** delete single row from the table: "property_type" */
	delete_property_type_by_pk?:ModelTypes["property_type"],
	/** delete data from the table: "rent_index" */
	delete_rent_index?:ModelTypes["rent_index_mutation_response"],
	/** delete data from the table: "rent_index_area" */
	delete_rent_index_area?:ModelTypes["rent_index_area_mutation_response"],
	/** delete single row from the table: "rent_index_area" */
	delete_rent_index_area_by_pk?:ModelTypes["rent_index_area"],
	/** delete single row from the table: "rent_index" */
	delete_rent_index_by_pk?:ModelTypes["rent_index"],
	/** delete data from the table: "rent_index_layer_type" */
	delete_rent_index_layer_type?:ModelTypes["rent_index_layer_type_mutation_response"],
	/** delete single row from the table: "rent_index_layer_type" */
	delete_rent_index_layer_type_by_pk?:ModelTypes["rent_index_layer_type"],
	/** delete data from the table: "rental_contract" */
	delete_rental_contract?:ModelTypes["rental_contract_mutation_response"],
	/** delete single row from the table: "rental_contract" */
	delete_rental_contract_by_pk?:ModelTypes["rental_contract"],
	/** delete data from the table: "rental_costs" */
	delete_rental_costs?:ModelTypes["rental_costs_mutation_response"],
	/** delete single row from the table: "rental_costs" */
	delete_rental_costs_by_pk?:ModelTypes["rental_costs"],
	/** delete data from the table: "rental_costs_type" */
	delete_rental_costs_type?:ModelTypes["rental_costs_type_mutation_response"],
	/** delete single row from the table: "rental_costs_type" */
	delete_rental_costs_type_by_pk?:ModelTypes["rental_costs_type"],
	/** delete data from the table: "rental_rent" */
	delete_rental_rent?:ModelTypes["rental_rent_mutation_response"],
	/** delete single row from the table: "rental_rent" */
	delete_rental_rent_by_pk?:ModelTypes["rental_rent"],
	/** delete data from the table: "unit" */
	delete_unit?:ModelTypes["unit_mutation_response"],
	/** delete single row from the table: "unit" */
	delete_unit_by_pk?:ModelTypes["unit"],
	/** delete data from the table: "unit_type" */
	delete_unit_type?:ModelTypes["unit_type_mutation_response"],
	/** delete single row from the table: "unit_type" */
	delete_unit_type_by_pk?:ModelTypes["unit_type"],
	/** delete data from the table: "user" */
	delete_user?:ModelTypes["user_mutation_response"],
	/** delete single row from the table: "user" */
	delete_user_by_pk?:ModelTypes["user"],
	/** insert data into the table: "color" */
	insert_color?:ModelTypes["color_mutation_response"],
	/** insert a single row into the table: "color" */
	insert_color_one?:ModelTypes["color"],
	/** insert data into the table: "country_code" */
	insert_country_code?:ModelTypes["country_code_mutation_response"],
	/** insert a single row into the table: "country_code" */
	insert_country_code_one?:ModelTypes["country_code"],
	/** insert data into the table: "deposit_rate" */
	insert_deposit_rate?:ModelTypes["deposit_rate_mutation_response"],
	/** insert a single row into the table: "deposit_rate" */
	insert_deposit_rate_one?:ModelTypes["deposit_rate"],
	/** insert data into the table: "deposit_type" */
	insert_deposit_type?:ModelTypes["deposit_type_mutation_response"],
	/** insert a single row into the table: "deposit_type" */
	insert_deposit_type_one?:ModelTypes["deposit_type"],
	/** insert data into the table: "group" */
	insert_group?:ModelTypes["group_mutation_response"],
	/** insert a single row into the table: "group" */
	insert_group_one?:ModelTypes["group"],
	/** insert data into the table: "property" */
	insert_property?:ModelTypes["property_mutation_response"],
	/** insert a single row into the table: "property" */
	insert_property_one?:ModelTypes["property"],
	/** insert data into the table: "property_type" */
	insert_property_type?:ModelTypes["property_type_mutation_response"],
	/** insert a single row into the table: "property_type" */
	insert_property_type_one?:ModelTypes["property_type"],
	/** insert data into the table: "rent_index" */
	insert_rent_index?:ModelTypes["rent_index_mutation_response"],
	/** insert data into the table: "rent_index_area" */
	insert_rent_index_area?:ModelTypes["rent_index_area_mutation_response"],
	/** insert a single row into the table: "rent_index_area" */
	insert_rent_index_area_one?:ModelTypes["rent_index_area"],
	/** insert data into the table: "rent_index_layer_type" */
	insert_rent_index_layer_type?:ModelTypes["rent_index_layer_type_mutation_response"],
	/** insert a single row into the table: "rent_index_layer_type" */
	insert_rent_index_layer_type_one?:ModelTypes["rent_index_layer_type"],
	/** insert a single row into the table: "rent_index" */
	insert_rent_index_one?:ModelTypes["rent_index"],
	/** insert data into the table: "rental_contract" */
	insert_rental_contract?:ModelTypes["rental_contract_mutation_response"],
	/** insert a single row into the table: "rental_contract" */
	insert_rental_contract_one?:ModelTypes["rental_contract"],
	/** insert data into the table: "rental_costs" */
	insert_rental_costs?:ModelTypes["rental_costs_mutation_response"],
	/** insert a single row into the table: "rental_costs" */
	insert_rental_costs_one?:ModelTypes["rental_costs"],
	/** insert data into the table: "rental_costs_type" */
	insert_rental_costs_type?:ModelTypes["rental_costs_type_mutation_response"],
	/** insert a single row into the table: "rental_costs_type" */
	insert_rental_costs_type_one?:ModelTypes["rental_costs_type"],
	/** insert data into the table: "rental_rent" */
	insert_rental_rent?:ModelTypes["rental_rent_mutation_response"],
	/** insert a single row into the table: "rental_rent" */
	insert_rental_rent_one?:ModelTypes["rental_rent"],
	/** insert data into the table: "unit" */
	insert_unit?:ModelTypes["unit_mutation_response"],
	/** insert a single row into the table: "unit" */
	insert_unit_one?:ModelTypes["unit"],
	/** insert data into the table: "unit_type" */
	insert_unit_type?:ModelTypes["unit_type_mutation_response"],
	/** insert a single row into the table: "unit_type" */
	insert_unit_type_one?:ModelTypes["unit_type"],
	/** insert data into the table: "user" */
	insert_user?:ModelTypes["user_mutation_response"],
	/** insert a single row into the table: "user" */
	insert_user_one?:ModelTypes["user"],
	/** update data of the table: "color" */
	update_color?:ModelTypes["color_mutation_response"],
	/** update single row of the table: "color" */
	update_color_by_pk?:ModelTypes["color"],
	/** update data of the table: "country_code" */
	update_country_code?:ModelTypes["country_code_mutation_response"],
	/** update single row of the table: "country_code" */
	update_country_code_by_pk?:ModelTypes["country_code"],
	/** update data of the table: "deposit_rate" */
	update_deposit_rate?:ModelTypes["deposit_rate_mutation_response"],
	/** update single row of the table: "deposit_rate" */
	update_deposit_rate_by_pk?:ModelTypes["deposit_rate"],
	/** update data of the table: "deposit_type" */
	update_deposit_type?:ModelTypes["deposit_type_mutation_response"],
	/** update single row of the table: "deposit_type" */
	update_deposit_type_by_pk?:ModelTypes["deposit_type"],
	/** update data of the table: "group" */
	update_group?:ModelTypes["group_mutation_response"],
	/** update single row of the table: "group" */
	update_group_by_pk?:ModelTypes["group"],
	/** update data of the table: "property" */
	update_property?:ModelTypes["property_mutation_response"],
	/** update single row of the table: "property" */
	update_property_by_pk?:ModelTypes["property"],
	/** update data of the table: "property_type" */
	update_property_type?:ModelTypes["property_type_mutation_response"],
	/** update single row of the table: "property_type" */
	update_property_type_by_pk?:ModelTypes["property_type"],
	/** update data of the table: "rent_index" */
	update_rent_index?:ModelTypes["rent_index_mutation_response"],
	/** update data of the table: "rent_index_area" */
	update_rent_index_area?:ModelTypes["rent_index_area_mutation_response"],
	/** update single row of the table: "rent_index_area" */
	update_rent_index_area_by_pk?:ModelTypes["rent_index_area"],
	/** update single row of the table: "rent_index" */
	update_rent_index_by_pk?:ModelTypes["rent_index"],
	/** update data of the table: "rent_index_layer_type" */
	update_rent_index_layer_type?:ModelTypes["rent_index_layer_type_mutation_response"],
	/** update single row of the table: "rent_index_layer_type" */
	update_rent_index_layer_type_by_pk?:ModelTypes["rent_index_layer_type"],
	/** update data of the table: "rental_contract" */
	update_rental_contract?:ModelTypes["rental_contract_mutation_response"],
	/** update single row of the table: "rental_contract" */
	update_rental_contract_by_pk?:ModelTypes["rental_contract"],
	/** update data of the table: "rental_costs" */
	update_rental_costs?:ModelTypes["rental_costs_mutation_response"],
	/** update single row of the table: "rental_costs" */
	update_rental_costs_by_pk?:ModelTypes["rental_costs"],
	/** update data of the table: "rental_costs_type" */
	update_rental_costs_type?:ModelTypes["rental_costs_type_mutation_response"],
	/** update single row of the table: "rental_costs_type" */
	update_rental_costs_type_by_pk?:ModelTypes["rental_costs_type"],
	/** update data of the table: "rental_rent" */
	update_rental_rent?:ModelTypes["rental_rent_mutation_response"],
	/** update single row of the table: "rental_rent" */
	update_rental_rent_by_pk?:ModelTypes["rental_rent"],
	/** update data of the table: "unit" */
	update_unit?:ModelTypes["unit_mutation_response"],
	/** update single row of the table: "unit" */
	update_unit_by_pk?:ModelTypes["unit"],
	/** update data of the table: "unit_type" */
	update_unit_type?:ModelTypes["unit_type_mutation_response"],
	/** update single row of the table: "unit_type" */
	update_unit_type_by_pk?:ModelTypes["unit_type"],
	/** update data of the table: "user" */
	update_user?:ModelTypes["user_mutation_response"],
	/** update single row of the table: "user" */
	update_user_by_pk?:ModelTypes["user"]
};
	["numeric"]:any;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
["numeric_comparison_exp"]: GraphQLTypes["numeric_comparison_exp"];
	/** column ordering options */
["order_by"]: GraphQLTypes["order_by"];
	/** columns and relationships of "property" */
["property"]: {
		city:string,
	country:ModelTypes["country_code_enum"],
	/** An object relationship */
	country_code:ModelTypes["country_code"],
	id:ModelTypes["uuid"],
	lat?:number,
	lng?:number,
	name?:string,
	number:string,
	postal_code:ModelTypes["numeric"],
	/** An object relationship */
	property_type:ModelTypes["property_type"],
	region?:string,
	state?:string,
	street:string,
	type:ModelTypes["property_type_enum"],
	/** An array relationship */
	units:ModelTypes["unit"][],
	/** An aggregate relationship */
	units_aggregate:ModelTypes["unit_aggregate"]
};
	/** aggregated selection of "property" */
["property_aggregate"]: {
		aggregate?:ModelTypes["property_aggregate_fields"],
	nodes:ModelTypes["property"][]
};
	/** aggregate fields of "property" */
["property_aggregate_fields"]: {
		avg?:ModelTypes["property_avg_fields"],
	count:number,
	max?:ModelTypes["property_max_fields"],
	min?:ModelTypes["property_min_fields"],
	stddev?:ModelTypes["property_stddev_fields"],
	stddev_pop?:ModelTypes["property_stddev_pop_fields"],
	stddev_samp?:ModelTypes["property_stddev_samp_fields"],
	sum?:ModelTypes["property_sum_fields"],
	var_pop?:ModelTypes["property_var_pop_fields"],
	var_samp?:ModelTypes["property_var_samp_fields"],
	variance?:ModelTypes["property_variance_fields"]
};
	/** order by aggregate values of table "property" */
["property_aggregate_order_by"]: GraphQLTypes["property_aggregate_order_by"];
	/** input type for inserting array relation for remote table "property" */
["property_arr_rel_insert_input"]: GraphQLTypes["property_arr_rel_insert_input"];
	/** aggregate avg on columns */
["property_avg_fields"]: {
		lat?:number,
	lng?:number,
	postal_code?:number
};
	/** order by avg() on columns of table "property" */
["property_avg_order_by"]: GraphQLTypes["property_avg_order_by"];
	/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
["property_bool_exp"]: GraphQLTypes["property_bool_exp"];
	/** unique or primary key constraints on table "property" */
["property_constraint"]: GraphQLTypes["property_constraint"];
	/** input type for incrementing numeric columns in table "property" */
["property_inc_input"]: GraphQLTypes["property_inc_input"];
	/** input type for inserting data into table "property" */
["property_insert_input"]: GraphQLTypes["property_insert_input"];
	/** aggregate max on columns */
["property_max_fields"]: {
		city?:string,
	id?:ModelTypes["uuid"],
	lat?:number,
	lng?:number,
	name?:string,
	number?:string,
	postal_code?:ModelTypes["numeric"],
	region?:string,
	state?:string,
	street?:string
};
	/** order by max() on columns of table "property" */
["property_max_order_by"]: GraphQLTypes["property_max_order_by"];
	/** aggregate min on columns */
["property_min_fields"]: {
		city?:string,
	id?:ModelTypes["uuid"],
	lat?:number,
	lng?:number,
	name?:string,
	number?:string,
	postal_code?:ModelTypes["numeric"],
	region?:string,
	state?:string,
	street?:string
};
	/** order by min() on columns of table "property" */
["property_min_order_by"]: GraphQLTypes["property_min_order_by"];
	/** response of any mutation on the table "property" */
["property_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["property"][]
};
	/** input type for inserting object relation for remote table "property" */
["property_obj_rel_insert_input"]: GraphQLTypes["property_obj_rel_insert_input"];
	/** on conflict condition type for table "property" */
["property_on_conflict"]: GraphQLTypes["property_on_conflict"];
	/** Ordering options when selecting data from "property". */
["property_order_by"]: GraphQLTypes["property_order_by"];
	/** primary key columns input for table: property */
["property_pk_columns_input"]: GraphQLTypes["property_pk_columns_input"];
	/** select columns of table "property" */
["property_select_column"]: GraphQLTypes["property_select_column"];
	/** input type for updating data in table "property" */
["property_set_input"]: GraphQLTypes["property_set_input"];
	/** aggregate stddev on columns */
["property_stddev_fields"]: {
		lat?:number,
	lng?:number,
	postal_code?:number
};
	/** order by stddev() on columns of table "property" */
["property_stddev_order_by"]: GraphQLTypes["property_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["property_stddev_pop_fields"]: {
		lat?:number,
	lng?:number,
	postal_code?:number
};
	/** order by stddev_pop() on columns of table "property" */
["property_stddev_pop_order_by"]: GraphQLTypes["property_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["property_stddev_samp_fields"]: {
		lat?:number,
	lng?:number,
	postal_code?:number
};
	/** order by stddev_samp() on columns of table "property" */
["property_stddev_samp_order_by"]: GraphQLTypes["property_stddev_samp_order_by"];
	/** aggregate sum on columns */
["property_sum_fields"]: {
		lat?:number,
	lng?:number,
	postal_code?:ModelTypes["numeric"]
};
	/** order by sum() on columns of table "property" */
["property_sum_order_by"]: GraphQLTypes["property_sum_order_by"];
	/** columns and relationships of "property_type" */
["property_type"]: {
		description:string,
	name:string,
	/** An array relationship */
	properties:ModelTypes["property"][],
	/** An aggregate relationship */
	properties_aggregate:ModelTypes["property_aggregate"]
};
	/** aggregated selection of "property_type" */
["property_type_aggregate"]: {
		aggregate?:ModelTypes["property_type_aggregate_fields"],
	nodes:ModelTypes["property_type"][]
};
	/** aggregate fields of "property_type" */
["property_type_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["property_type_max_fields"],
	min?:ModelTypes["property_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
["property_type_bool_exp"]: GraphQLTypes["property_type_bool_exp"];
	/** unique or primary key constraints on table "property_type" */
["property_type_constraint"]: GraphQLTypes["property_type_constraint"];
	["property_type_enum"]: GraphQLTypes["property_type_enum"];
	/** Boolean expression to compare columns of type "property_type_enum". All fields are combined with logical 'AND'. */
["property_type_enum_comparison_exp"]: GraphQLTypes["property_type_enum_comparison_exp"];
	/** input type for inserting data into table "property_type" */
["property_type_insert_input"]: GraphQLTypes["property_type_insert_input"];
	/** aggregate max on columns */
["property_type_max_fields"]: {
		description?:string,
	name?:string
};
	/** aggregate min on columns */
["property_type_min_fields"]: {
		description?:string,
	name?:string
};
	/** response of any mutation on the table "property_type" */
["property_type_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["property_type"][]
};
	/** input type for inserting object relation for remote table "property_type" */
["property_type_obj_rel_insert_input"]: GraphQLTypes["property_type_obj_rel_insert_input"];
	/** on conflict condition type for table "property_type" */
["property_type_on_conflict"]: GraphQLTypes["property_type_on_conflict"];
	/** Ordering options when selecting data from "property_type". */
["property_type_order_by"]: GraphQLTypes["property_type_order_by"];
	/** primary key columns input for table: property_type */
["property_type_pk_columns_input"]: GraphQLTypes["property_type_pk_columns_input"];
	/** select columns of table "property_type" */
["property_type_select_column"]: GraphQLTypes["property_type_select_column"];
	/** input type for updating data in table "property_type" */
["property_type_set_input"]: GraphQLTypes["property_type_set_input"];
	/** update columns of table "property_type" */
["property_type_update_column"]: GraphQLTypes["property_type_update_column"];
	/** update columns of table "property" */
["property_update_column"]: GraphQLTypes["property_update_column"];
	/** aggregate var_pop on columns */
["property_var_pop_fields"]: {
		lat?:number,
	lng?:number,
	postal_code?:number
};
	/** order by var_pop() on columns of table "property" */
["property_var_pop_order_by"]: GraphQLTypes["property_var_pop_order_by"];
	/** aggregate var_samp on columns */
["property_var_samp_fields"]: {
		lat?:number,
	lng?:number,
	postal_code?:number
};
	/** order by var_samp() on columns of table "property" */
["property_var_samp_order_by"]: GraphQLTypes["property_var_samp_order_by"];
	/** aggregate variance on columns */
["property_variance_fields"]: {
		lat?:number,
	lng?:number,
	postal_code?:number
};
	/** order by variance() on columns of table "property" */
["property_variance_order_by"]: GraphQLTypes["property_variance_order_by"];
	["query_root"]: {
		/** fetch data from the table: "color" */
	color:ModelTypes["color"][],
	/** fetch aggregated fields from the table: "color" */
	color_aggregate:ModelTypes["color_aggregate"],
	/** fetch data from the table: "color" using primary key columns */
	color_by_pk?:ModelTypes["color"],
	/** fetch data from the table: "country_code" */
	country_code:ModelTypes["country_code"][],
	/** fetch aggregated fields from the table: "country_code" */
	country_code_aggregate:ModelTypes["country_code_aggregate"],
	/** fetch data from the table: "country_code" using primary key columns */
	country_code_by_pk?:ModelTypes["country_code"],
	/** fetch data from the table: "deposit_rate" */
	deposit_rate:ModelTypes["deposit_rate"][],
	/** fetch aggregated fields from the table: "deposit_rate" */
	deposit_rate_aggregate:ModelTypes["deposit_rate_aggregate"],
	/** fetch data from the table: "deposit_rate" using primary key columns */
	deposit_rate_by_pk?:ModelTypes["deposit_rate"],
	/** fetch data from the table: "deposit_type" */
	deposit_type:ModelTypes["deposit_type"][],
	/** fetch aggregated fields from the table: "deposit_type" */
	deposit_type_aggregate:ModelTypes["deposit_type_aggregate"],
	/** fetch data from the table: "deposit_type" using primary key columns */
	deposit_type_by_pk?:ModelTypes["deposit_type"],
	/** fetch data from the table: "group" */
	group:ModelTypes["group"][],
	/** fetch aggregated fields from the table: "group" */
	group_aggregate:ModelTypes["group_aggregate"],
	/** fetch data from the table: "group" using primary key columns */
	group_by_pk?:ModelTypes["group"],
	/** fetch data from the table: "property" */
	property:ModelTypes["property"][],
	/** fetch aggregated fields from the table: "property" */
	property_aggregate:ModelTypes["property_aggregate"],
	/** fetch data from the table: "property" using primary key columns */
	property_by_pk?:ModelTypes["property"],
	/** fetch data from the table: "property_type" */
	property_type:ModelTypes["property_type"][],
	/** fetch aggregated fields from the table: "property_type" */
	property_type_aggregate:ModelTypes["property_type_aggregate"],
	/** fetch data from the table: "property_type" using primary key columns */
	property_type_by_pk?:ModelTypes["property_type"],
	/** fetch data from the table: "rent_index" */
	rent_index:ModelTypes["rent_index"][],
	/** fetch aggregated fields from the table: "rent_index" */
	rent_index_aggregate:ModelTypes["rent_index_aggregate"],
	/** fetch data from the table: "rent_index_area" */
	rent_index_area:ModelTypes["rent_index_area"][],
	/** fetch aggregated fields from the table: "rent_index_area" */
	rent_index_area_aggregate:ModelTypes["rent_index_area_aggregate"],
	/** fetch data from the table: "rent_index_area" using primary key columns */
	rent_index_area_by_pk?:ModelTypes["rent_index_area"],
	/** fetch data from the table: "rent_index" using primary key columns */
	rent_index_by_pk?:ModelTypes["rent_index"],
	/** fetch data from the table: "rent_index_layer_type" */
	rent_index_layer_type:ModelTypes["rent_index_layer_type"][],
	/** fetch aggregated fields from the table: "rent_index_layer_type" */
	rent_index_layer_type_aggregate:ModelTypes["rent_index_layer_type_aggregate"],
	/** fetch data from the table: "rent_index_layer_type" using primary key columns */
	rent_index_layer_type_by_pk?:ModelTypes["rent_index_layer_type"],
	/** fetch data from the table: "rental_contract" */
	rental_contract:ModelTypes["rental_contract"][],
	/** fetch aggregated fields from the table: "rental_contract" */
	rental_contract_aggregate:ModelTypes["rental_contract_aggregate"],
	/** fetch data from the table: "rental_contract" using primary key columns */
	rental_contract_by_pk?:ModelTypes["rental_contract"],
	/** An array relationship */
	rental_costs:ModelTypes["rental_costs"][],
	/** An aggregate relationship */
	rental_costs_aggregate:ModelTypes["rental_costs_aggregate"],
	/** fetch data from the table: "rental_costs" using primary key columns */
	rental_costs_by_pk?:ModelTypes["rental_costs"],
	/** fetch data from the table: "rental_costs_type" */
	rental_costs_type:ModelTypes["rental_costs_type"][],
	/** fetch aggregated fields from the table: "rental_costs_type" */
	rental_costs_type_aggregate:ModelTypes["rental_costs_type_aggregate"],
	/** fetch data from the table: "rental_costs_type" using primary key columns */
	rental_costs_type_by_pk?:ModelTypes["rental_costs_type"],
	/** fetch data from the table: "rental_rent" */
	rental_rent:ModelTypes["rental_rent"][],
	/** fetch aggregated fields from the table: "rental_rent" */
	rental_rent_aggregate:ModelTypes["rental_rent_aggregate"],
	/** fetch data from the table: "rental_rent" using primary key columns */
	rental_rent_by_pk?:ModelTypes["rental_rent"],
	/** fetch data from the table: "unit" */
	unit:ModelTypes["unit"][],
	/** fetch aggregated fields from the table: "unit" */
	unit_aggregate:ModelTypes["unit_aggregate"],
	/** fetch data from the table: "unit" using primary key columns */
	unit_by_pk?:ModelTypes["unit"],
	/** fetch data from the table: "unit_type" */
	unit_type:ModelTypes["unit_type"][],
	/** fetch aggregated fields from the table: "unit_type" */
	unit_type_aggregate:ModelTypes["unit_type_aggregate"],
	/** fetch data from the table: "unit_type" using primary key columns */
	unit_type_by_pk?:ModelTypes["unit_type"],
	/** fetch data from the table: "user" */
	user:ModelTypes["user"][],
	/** fetch aggregated fields from the table: "user" */
	user_aggregate:ModelTypes["user_aggregate"],
	/** fetch data from the table: "user" using primary key columns */
	user_by_pk?:ModelTypes["user"]
};
	/** columns and relationships of "rent_index" */
["rent_index"]: {
		city:string,
	data:ModelTypes["jsonb"],
	date?:ModelTypes["date"],
	id:ModelTypes["uuid"],
	postal_code?:ModelTypes["numeric"]
};
	/** aggregated selection of "rent_index" */
["rent_index_aggregate"]: {
		aggregate?:ModelTypes["rent_index_aggregate_fields"],
	nodes:ModelTypes["rent_index"][]
};
	/** aggregate fields of "rent_index" */
["rent_index_aggregate_fields"]: {
		avg?:ModelTypes["rent_index_avg_fields"],
	count:number,
	max?:ModelTypes["rent_index_max_fields"],
	min?:ModelTypes["rent_index_min_fields"],
	stddev?:ModelTypes["rent_index_stddev_fields"],
	stddev_pop?:ModelTypes["rent_index_stddev_pop_fields"],
	stddev_samp?:ModelTypes["rent_index_stddev_samp_fields"],
	sum?:ModelTypes["rent_index_sum_fields"],
	var_pop?:ModelTypes["rent_index_var_pop_fields"],
	var_samp?:ModelTypes["rent_index_var_samp_fields"],
	variance?:ModelTypes["rent_index_variance_fields"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["rent_index_append_input"]: GraphQLTypes["rent_index_append_input"];
	/** columns and relationships of "rent_index_area" */
["rent_index_area"]: {
		id:ModelTypes["uuid"],
	layer_type:ModelTypes["rent_index_layer_type_enum"],
	polygons:ModelTypes["jsonb"],
	/** An object relationship */
	rent_index_layer_type:ModelTypes["rent_index_layer_type"]
};
	/** aggregated selection of "rent_index_area" */
["rent_index_area_aggregate"]: {
		aggregate?:ModelTypes["rent_index_area_aggregate_fields"],
	nodes:ModelTypes["rent_index_area"][]
};
	/** aggregate fields of "rent_index_area" */
["rent_index_area_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["rent_index_area_max_fields"],
	min?:ModelTypes["rent_index_area_min_fields"]
};
	/** order by aggregate values of table "rent_index_area" */
["rent_index_area_aggregate_order_by"]: GraphQLTypes["rent_index_area_aggregate_order_by"];
	/** append existing jsonb value of filtered columns with new jsonb value */
["rent_index_area_append_input"]: GraphQLTypes["rent_index_area_append_input"];
	/** input type for inserting array relation for remote table "rent_index_area" */
["rent_index_area_arr_rel_insert_input"]: GraphQLTypes["rent_index_area_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "rent_index_area". All fields are combined with a logical 'AND'. */
["rent_index_area_bool_exp"]: GraphQLTypes["rent_index_area_bool_exp"];
	/** unique or primary key constraints on table "rent_index_area" */
["rent_index_area_constraint"]: GraphQLTypes["rent_index_area_constraint"];
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["rent_index_area_delete_at_path_input"]: GraphQLTypes["rent_index_area_delete_at_path_input"];
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["rent_index_area_delete_elem_input"]: GraphQLTypes["rent_index_area_delete_elem_input"];
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["rent_index_area_delete_key_input"]: GraphQLTypes["rent_index_area_delete_key_input"];
	/** input type for inserting data into table "rent_index_area" */
["rent_index_area_insert_input"]: GraphQLTypes["rent_index_area_insert_input"];
	/** aggregate max on columns */
["rent_index_area_max_fields"]: {
		id?:ModelTypes["uuid"]
};
	/** order by max() on columns of table "rent_index_area" */
["rent_index_area_max_order_by"]: GraphQLTypes["rent_index_area_max_order_by"];
	/** aggregate min on columns */
["rent_index_area_min_fields"]: {
		id?:ModelTypes["uuid"]
};
	/** order by min() on columns of table "rent_index_area" */
["rent_index_area_min_order_by"]: GraphQLTypes["rent_index_area_min_order_by"];
	/** response of any mutation on the table "rent_index_area" */
["rent_index_area_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["rent_index_area"][]
};
	/** on conflict condition type for table "rent_index_area" */
["rent_index_area_on_conflict"]: GraphQLTypes["rent_index_area_on_conflict"];
	/** Ordering options when selecting data from "rent_index_area". */
["rent_index_area_order_by"]: GraphQLTypes["rent_index_area_order_by"];
	/** primary key columns input for table: rent_index_area */
["rent_index_area_pk_columns_input"]: GraphQLTypes["rent_index_area_pk_columns_input"];
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["rent_index_area_prepend_input"]: GraphQLTypes["rent_index_area_prepend_input"];
	/** select columns of table "rent_index_area" */
["rent_index_area_select_column"]: GraphQLTypes["rent_index_area_select_column"];
	/** input type for updating data in table "rent_index_area" */
["rent_index_area_set_input"]: GraphQLTypes["rent_index_area_set_input"];
	/** update columns of table "rent_index_area" */
["rent_index_area_update_column"]: GraphQLTypes["rent_index_area_update_column"];
	/** aggregate avg on columns */
["rent_index_avg_fields"]: {
		postal_code?:number
};
	/** Boolean expression to filter rows from the table "rent_index". All fields are combined with a logical 'AND'. */
["rent_index_bool_exp"]: GraphQLTypes["rent_index_bool_exp"];
	/** unique or primary key constraints on table "rent_index" */
["rent_index_constraint"]: GraphQLTypes["rent_index_constraint"];
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["rent_index_delete_at_path_input"]: GraphQLTypes["rent_index_delete_at_path_input"];
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["rent_index_delete_elem_input"]: GraphQLTypes["rent_index_delete_elem_input"];
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["rent_index_delete_key_input"]: GraphQLTypes["rent_index_delete_key_input"];
	/** input type for incrementing numeric columns in table "rent_index" */
["rent_index_inc_input"]: GraphQLTypes["rent_index_inc_input"];
	/** input type for inserting data into table "rent_index" */
["rent_index_insert_input"]: GraphQLTypes["rent_index_insert_input"];
	/** columns and relationships of "rent_index_layer_type" */
["rent_index_layer_type"]: {
		description:string,
	name:string,
	/** An array relationship */
	rent_index_areas:ModelTypes["rent_index_area"][],
	/** An aggregate relationship */
	rent_index_areas_aggregate:ModelTypes["rent_index_area_aggregate"]
};
	/** aggregated selection of "rent_index_layer_type" */
["rent_index_layer_type_aggregate"]: {
		aggregate?:ModelTypes["rent_index_layer_type_aggregate_fields"],
	nodes:ModelTypes["rent_index_layer_type"][]
};
	/** aggregate fields of "rent_index_layer_type" */
["rent_index_layer_type_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["rent_index_layer_type_max_fields"],
	min?:ModelTypes["rent_index_layer_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "rent_index_layer_type". All fields are combined with a logical 'AND'. */
["rent_index_layer_type_bool_exp"]: GraphQLTypes["rent_index_layer_type_bool_exp"];
	/** unique or primary key constraints on table "rent_index_layer_type" */
["rent_index_layer_type_constraint"]: GraphQLTypes["rent_index_layer_type_constraint"];
	["rent_index_layer_type_enum"]: GraphQLTypes["rent_index_layer_type_enum"];
	/** Boolean expression to compare columns of type "rent_index_layer_type_enum". All fields are combined with logical 'AND'. */
["rent_index_layer_type_enum_comparison_exp"]: GraphQLTypes["rent_index_layer_type_enum_comparison_exp"];
	/** input type for inserting data into table "rent_index_layer_type" */
["rent_index_layer_type_insert_input"]: GraphQLTypes["rent_index_layer_type_insert_input"];
	/** aggregate max on columns */
["rent_index_layer_type_max_fields"]: {
		description?:string,
	name?:string
};
	/** aggregate min on columns */
["rent_index_layer_type_min_fields"]: {
		description?:string,
	name?:string
};
	/** response of any mutation on the table "rent_index_layer_type" */
["rent_index_layer_type_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["rent_index_layer_type"][]
};
	/** input type for inserting object relation for remote table "rent_index_layer_type" */
["rent_index_layer_type_obj_rel_insert_input"]: GraphQLTypes["rent_index_layer_type_obj_rel_insert_input"];
	/** on conflict condition type for table "rent_index_layer_type" */
["rent_index_layer_type_on_conflict"]: GraphQLTypes["rent_index_layer_type_on_conflict"];
	/** Ordering options when selecting data from "rent_index_layer_type". */
["rent_index_layer_type_order_by"]: GraphQLTypes["rent_index_layer_type_order_by"];
	/** primary key columns input for table: rent_index_layer_type */
["rent_index_layer_type_pk_columns_input"]: GraphQLTypes["rent_index_layer_type_pk_columns_input"];
	/** select columns of table "rent_index_layer_type" */
["rent_index_layer_type_select_column"]: GraphQLTypes["rent_index_layer_type_select_column"];
	/** input type for updating data in table "rent_index_layer_type" */
["rent_index_layer_type_set_input"]: GraphQLTypes["rent_index_layer_type_set_input"];
	/** update columns of table "rent_index_layer_type" */
["rent_index_layer_type_update_column"]: GraphQLTypes["rent_index_layer_type_update_column"];
	/** aggregate max on columns */
["rent_index_max_fields"]: {
		city?:string,
	date?:ModelTypes["date"],
	id?:ModelTypes["uuid"],
	postal_code?:ModelTypes["numeric"]
};
	/** aggregate min on columns */
["rent_index_min_fields"]: {
		city?:string,
	date?:ModelTypes["date"],
	id?:ModelTypes["uuid"],
	postal_code?:ModelTypes["numeric"]
};
	/** response of any mutation on the table "rent_index" */
["rent_index_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["rent_index"][]
};
	/** on conflict condition type for table "rent_index" */
["rent_index_on_conflict"]: GraphQLTypes["rent_index_on_conflict"];
	/** Ordering options when selecting data from "rent_index". */
["rent_index_order_by"]: GraphQLTypes["rent_index_order_by"];
	/** primary key columns input for table: rent_index */
["rent_index_pk_columns_input"]: GraphQLTypes["rent_index_pk_columns_input"];
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["rent_index_prepend_input"]: GraphQLTypes["rent_index_prepend_input"];
	/** select columns of table "rent_index" */
["rent_index_select_column"]: GraphQLTypes["rent_index_select_column"];
	/** input type for updating data in table "rent_index" */
["rent_index_set_input"]: GraphQLTypes["rent_index_set_input"];
	/** aggregate stddev on columns */
["rent_index_stddev_fields"]: {
		postal_code?:number
};
	/** aggregate stddev_pop on columns */
["rent_index_stddev_pop_fields"]: {
		postal_code?:number
};
	/** aggregate stddev_samp on columns */
["rent_index_stddev_samp_fields"]: {
		postal_code?:number
};
	/** aggregate sum on columns */
["rent_index_sum_fields"]: {
		postal_code?:ModelTypes["numeric"]
};
	/** update columns of table "rent_index" */
["rent_index_update_column"]: GraphQLTypes["rent_index_update_column"];
	/** aggregate var_pop on columns */
["rent_index_var_pop_fields"]: {
		postal_code?:number
};
	/** aggregate var_samp on columns */
["rent_index_var_samp_fields"]: {
		postal_code?:number
};
	/** aggregate variance on columns */
["rent_index_variance_fields"]: {
		postal_code?:number
};
	/** columns and relationships of "rental_contract" */
["rental_contract"]: {
		begin?:ModelTypes["timestamptz"],
	cancelation_effective?:ModelTypes["timestamptz"],
	cancelation_period?:number,
	cancelation_pronounced?:ModelTypes["timestamptz"],
	date?:ModelTypes["timestamptz"],
	deposit?:number,
	/** An object relationship */
	depositRate?:ModelTypes["deposit_rate"],
	/** An object relationship */
	depositType?:ModelTypes["deposit_type"],
	deposit_payable?:ModelTypes["timestamptz"],
	deposit_rate?:ModelTypes["deposit_rate_enum"],
	deposit_received?:ModelTypes["timestamptz"],
	deposit_type?:ModelTypes["deposit_type_enum"],
	end?:ModelTypes["timestamptz"],
	handover_move_in?:ModelTypes["timestamptz"],
	handover_move_out?:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	min?:ModelTypes["timestamptz"],
	other_agreements?:string,
	/** An array relationship */
	rental_rents:ModelTypes["rental_rent"][],
	/** An aggregate relationship */
	rental_rents_aggregate:ModelTypes["rental_rent_aggregate"]
};
	/** aggregated selection of "rental_contract" */
["rental_contract_aggregate"]: {
		aggregate?:ModelTypes["rental_contract_aggregate_fields"],
	nodes:ModelTypes["rental_contract"][]
};
	/** aggregate fields of "rental_contract" */
["rental_contract_aggregate_fields"]: {
		avg?:ModelTypes["rental_contract_avg_fields"],
	count:number,
	max?:ModelTypes["rental_contract_max_fields"],
	min?:ModelTypes["rental_contract_min_fields"],
	stddev?:ModelTypes["rental_contract_stddev_fields"],
	stddev_pop?:ModelTypes["rental_contract_stddev_pop_fields"],
	stddev_samp?:ModelTypes["rental_contract_stddev_samp_fields"],
	sum?:ModelTypes["rental_contract_sum_fields"],
	var_pop?:ModelTypes["rental_contract_var_pop_fields"],
	var_samp?:ModelTypes["rental_contract_var_samp_fields"],
	variance?:ModelTypes["rental_contract_variance_fields"]
};
	/** order by aggregate values of table "rental_contract" */
["rental_contract_aggregate_order_by"]: GraphQLTypes["rental_contract_aggregate_order_by"];
	/** input type for inserting array relation for remote table "rental_contract" */
["rental_contract_arr_rel_insert_input"]: GraphQLTypes["rental_contract_arr_rel_insert_input"];
	/** aggregate avg on columns */
["rental_contract_avg_fields"]: {
		cancelation_period?:number,
	deposit?:number
};
	/** order by avg() on columns of table "rental_contract" */
["rental_contract_avg_order_by"]: GraphQLTypes["rental_contract_avg_order_by"];
	/** Boolean expression to filter rows from the table "rental_contract". All fields are combined with a logical 'AND'. */
["rental_contract_bool_exp"]: GraphQLTypes["rental_contract_bool_exp"];
	/** unique or primary key constraints on table "rental_contract" */
["rental_contract_constraint"]: GraphQLTypes["rental_contract_constraint"];
	/** input type for incrementing numeric columns in table "rental_contract" */
["rental_contract_inc_input"]: GraphQLTypes["rental_contract_inc_input"];
	/** input type for inserting data into table "rental_contract" */
["rental_contract_insert_input"]: GraphQLTypes["rental_contract_insert_input"];
	/** aggregate max on columns */
["rental_contract_max_fields"]: {
		begin?:ModelTypes["timestamptz"],
	cancelation_effective?:ModelTypes["timestamptz"],
	cancelation_period?:number,
	cancelation_pronounced?:ModelTypes["timestamptz"],
	date?:ModelTypes["timestamptz"],
	deposit?:number,
	deposit_payable?:ModelTypes["timestamptz"],
	deposit_received?:ModelTypes["timestamptz"],
	end?:ModelTypes["timestamptz"],
	handover_move_in?:ModelTypes["timestamptz"],
	handover_move_out?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	min?:ModelTypes["timestamptz"],
	other_agreements?:string
};
	/** order by max() on columns of table "rental_contract" */
["rental_contract_max_order_by"]: GraphQLTypes["rental_contract_max_order_by"];
	/** aggregate min on columns */
["rental_contract_min_fields"]: {
		begin?:ModelTypes["timestamptz"],
	cancelation_effective?:ModelTypes["timestamptz"],
	cancelation_period?:number,
	cancelation_pronounced?:ModelTypes["timestamptz"],
	date?:ModelTypes["timestamptz"],
	deposit?:number,
	deposit_payable?:ModelTypes["timestamptz"],
	deposit_received?:ModelTypes["timestamptz"],
	end?:ModelTypes["timestamptz"],
	handover_move_in?:ModelTypes["timestamptz"],
	handover_move_out?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	min?:ModelTypes["timestamptz"],
	other_agreements?:string
};
	/** order by min() on columns of table "rental_contract" */
["rental_contract_min_order_by"]: GraphQLTypes["rental_contract_min_order_by"];
	/** response of any mutation on the table "rental_contract" */
["rental_contract_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["rental_contract"][]
};
	/** input type for inserting object relation for remote table "rental_contract" */
["rental_contract_obj_rel_insert_input"]: GraphQLTypes["rental_contract_obj_rel_insert_input"];
	/** on conflict condition type for table "rental_contract" */
["rental_contract_on_conflict"]: GraphQLTypes["rental_contract_on_conflict"];
	/** Ordering options when selecting data from "rental_contract". */
["rental_contract_order_by"]: GraphQLTypes["rental_contract_order_by"];
	/** primary key columns input for table: rental_contract */
["rental_contract_pk_columns_input"]: GraphQLTypes["rental_contract_pk_columns_input"];
	/** select columns of table "rental_contract" */
["rental_contract_select_column"]: GraphQLTypes["rental_contract_select_column"];
	/** input type for updating data in table "rental_contract" */
["rental_contract_set_input"]: GraphQLTypes["rental_contract_set_input"];
	/** aggregate stddev on columns */
["rental_contract_stddev_fields"]: {
		cancelation_period?:number,
	deposit?:number
};
	/** order by stddev() on columns of table "rental_contract" */
["rental_contract_stddev_order_by"]: GraphQLTypes["rental_contract_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["rental_contract_stddev_pop_fields"]: {
		cancelation_period?:number,
	deposit?:number
};
	/** order by stddev_pop() on columns of table "rental_contract" */
["rental_contract_stddev_pop_order_by"]: GraphQLTypes["rental_contract_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["rental_contract_stddev_samp_fields"]: {
		cancelation_period?:number,
	deposit?:number
};
	/** order by stddev_samp() on columns of table "rental_contract" */
["rental_contract_stddev_samp_order_by"]: GraphQLTypes["rental_contract_stddev_samp_order_by"];
	/** aggregate sum on columns */
["rental_contract_sum_fields"]: {
		cancelation_period?:number,
	deposit?:number
};
	/** order by sum() on columns of table "rental_contract" */
["rental_contract_sum_order_by"]: GraphQLTypes["rental_contract_sum_order_by"];
	/** update columns of table "rental_contract" */
["rental_contract_update_column"]: GraphQLTypes["rental_contract_update_column"];
	/** aggregate var_pop on columns */
["rental_contract_var_pop_fields"]: {
		cancelation_period?:number,
	deposit?:number
};
	/** order by var_pop() on columns of table "rental_contract" */
["rental_contract_var_pop_order_by"]: GraphQLTypes["rental_contract_var_pop_order_by"];
	/** aggregate var_samp on columns */
["rental_contract_var_samp_fields"]: {
		cancelation_period?:number,
	deposit?:number
};
	/** order by var_samp() on columns of table "rental_contract" */
["rental_contract_var_samp_order_by"]: GraphQLTypes["rental_contract_var_samp_order_by"];
	/** aggregate variance on columns */
["rental_contract_variance_fields"]: {
		cancelation_period?:number,
	deposit?:number
};
	/** order by variance() on columns of table "rental_contract" */
["rental_contract_variance_order_by"]: GraphQLTypes["rental_contract_variance_order_by"];
	/** columns and relationships of "rental_costs" */
["rental_costs"]: {
		costs:number,
	id:ModelTypes["uuid"],
	name:string,
	/** An object relationship */
	rent:ModelTypes["rental_rent"],
	rent_id:ModelTypes["uuid"],
	/** An object relationship */
	rental_costs_type:ModelTypes["rental_costs_type"],
	type:ModelTypes["rental_costs_type_enum"],
	/** An object relationship */
	unit:ModelTypes["unit"],
	unit_id:ModelTypes["uuid"],
	vat?:number
};
	/** aggregated selection of "rental_costs" */
["rental_costs_aggregate"]: {
		aggregate?:ModelTypes["rental_costs_aggregate_fields"],
	nodes:ModelTypes["rental_costs"][]
};
	/** aggregate fields of "rental_costs" */
["rental_costs_aggregate_fields"]: {
		avg?:ModelTypes["rental_costs_avg_fields"],
	count:number,
	max?:ModelTypes["rental_costs_max_fields"],
	min?:ModelTypes["rental_costs_min_fields"],
	stddev?:ModelTypes["rental_costs_stddev_fields"],
	stddev_pop?:ModelTypes["rental_costs_stddev_pop_fields"],
	stddev_samp?:ModelTypes["rental_costs_stddev_samp_fields"],
	sum?:ModelTypes["rental_costs_sum_fields"],
	var_pop?:ModelTypes["rental_costs_var_pop_fields"],
	var_samp?:ModelTypes["rental_costs_var_samp_fields"],
	variance?:ModelTypes["rental_costs_variance_fields"]
};
	/** order by aggregate values of table "rental_costs" */
["rental_costs_aggregate_order_by"]: GraphQLTypes["rental_costs_aggregate_order_by"];
	/** input type for inserting array relation for remote table "rental_costs" */
["rental_costs_arr_rel_insert_input"]: GraphQLTypes["rental_costs_arr_rel_insert_input"];
	/** aggregate avg on columns */
["rental_costs_avg_fields"]: {
		costs?:number,
	vat?:number
};
	/** order by avg() on columns of table "rental_costs" */
["rental_costs_avg_order_by"]: GraphQLTypes["rental_costs_avg_order_by"];
	/** Boolean expression to filter rows from the table "rental_costs". All fields are combined with a logical 'AND'. */
["rental_costs_bool_exp"]: GraphQLTypes["rental_costs_bool_exp"];
	/** unique or primary key constraints on table "rental_costs" */
["rental_costs_constraint"]: GraphQLTypes["rental_costs_constraint"];
	/** input type for incrementing numeric columns in table "rental_costs" */
["rental_costs_inc_input"]: GraphQLTypes["rental_costs_inc_input"];
	/** input type for inserting data into table "rental_costs" */
["rental_costs_insert_input"]: GraphQLTypes["rental_costs_insert_input"];
	/** aggregate max on columns */
["rental_costs_max_fields"]: {
		costs?:number,
	id?:ModelTypes["uuid"],
	name?:string,
	rent_id?:ModelTypes["uuid"],
	unit_id?:ModelTypes["uuid"],
	vat?:number
};
	/** order by max() on columns of table "rental_costs" */
["rental_costs_max_order_by"]: GraphQLTypes["rental_costs_max_order_by"];
	/** aggregate min on columns */
["rental_costs_min_fields"]: {
		costs?:number,
	id?:ModelTypes["uuid"],
	name?:string,
	rent_id?:ModelTypes["uuid"],
	unit_id?:ModelTypes["uuid"],
	vat?:number
};
	/** order by min() on columns of table "rental_costs" */
["rental_costs_min_order_by"]: GraphQLTypes["rental_costs_min_order_by"];
	/** response of any mutation on the table "rental_costs" */
["rental_costs_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["rental_costs"][]
};
	/** on conflict condition type for table "rental_costs" */
["rental_costs_on_conflict"]: GraphQLTypes["rental_costs_on_conflict"];
	/** Ordering options when selecting data from "rental_costs". */
["rental_costs_order_by"]: GraphQLTypes["rental_costs_order_by"];
	/** primary key columns input for table: rental_costs */
["rental_costs_pk_columns_input"]: GraphQLTypes["rental_costs_pk_columns_input"];
	/** select columns of table "rental_costs" */
["rental_costs_select_column"]: GraphQLTypes["rental_costs_select_column"];
	/** input type for updating data in table "rental_costs" */
["rental_costs_set_input"]: GraphQLTypes["rental_costs_set_input"];
	/** aggregate stddev on columns */
["rental_costs_stddev_fields"]: {
		costs?:number,
	vat?:number
};
	/** order by stddev() on columns of table "rental_costs" */
["rental_costs_stddev_order_by"]: GraphQLTypes["rental_costs_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["rental_costs_stddev_pop_fields"]: {
		costs?:number,
	vat?:number
};
	/** order by stddev_pop() on columns of table "rental_costs" */
["rental_costs_stddev_pop_order_by"]: GraphQLTypes["rental_costs_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["rental_costs_stddev_samp_fields"]: {
		costs?:number,
	vat?:number
};
	/** order by stddev_samp() on columns of table "rental_costs" */
["rental_costs_stddev_samp_order_by"]: GraphQLTypes["rental_costs_stddev_samp_order_by"];
	/** aggregate sum on columns */
["rental_costs_sum_fields"]: {
		costs?:number,
	vat?:number
};
	/** order by sum() on columns of table "rental_costs" */
["rental_costs_sum_order_by"]: GraphQLTypes["rental_costs_sum_order_by"];
	/** columns and relationships of "rental_costs_type" */
["rental_costs_type"]: {
		name:string,
	/** An array relationship */
	rental_costs:ModelTypes["rental_costs"][],
	/** An aggregate relationship */
	rental_costs_aggregate:ModelTypes["rental_costs_aggregate"]
};
	/** aggregated selection of "rental_costs_type" */
["rental_costs_type_aggregate"]: {
		aggregate?:ModelTypes["rental_costs_type_aggregate_fields"],
	nodes:ModelTypes["rental_costs_type"][]
};
	/** aggregate fields of "rental_costs_type" */
["rental_costs_type_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["rental_costs_type_max_fields"],
	min?:ModelTypes["rental_costs_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "rental_costs_type". All fields are combined with a logical 'AND'. */
["rental_costs_type_bool_exp"]: GraphQLTypes["rental_costs_type_bool_exp"];
	/** unique or primary key constraints on table "rental_costs_type" */
["rental_costs_type_constraint"]: GraphQLTypes["rental_costs_type_constraint"];
	["rental_costs_type_enum"]: GraphQLTypes["rental_costs_type_enum"];
	/** Boolean expression to compare columns of type "rental_costs_type_enum". All fields are combined with logical 'AND'. */
["rental_costs_type_enum_comparison_exp"]: GraphQLTypes["rental_costs_type_enum_comparison_exp"];
	/** input type for inserting data into table "rental_costs_type" */
["rental_costs_type_insert_input"]: GraphQLTypes["rental_costs_type_insert_input"];
	/** aggregate max on columns */
["rental_costs_type_max_fields"]: {
		name?:string
};
	/** aggregate min on columns */
["rental_costs_type_min_fields"]: {
		name?:string
};
	/** response of any mutation on the table "rental_costs_type" */
["rental_costs_type_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["rental_costs_type"][]
};
	/** input type for inserting object relation for remote table "rental_costs_type" */
["rental_costs_type_obj_rel_insert_input"]: GraphQLTypes["rental_costs_type_obj_rel_insert_input"];
	/** on conflict condition type for table "rental_costs_type" */
["rental_costs_type_on_conflict"]: GraphQLTypes["rental_costs_type_on_conflict"];
	/** Ordering options when selecting data from "rental_costs_type". */
["rental_costs_type_order_by"]: GraphQLTypes["rental_costs_type_order_by"];
	/** primary key columns input for table: rental_costs_type */
["rental_costs_type_pk_columns_input"]: GraphQLTypes["rental_costs_type_pk_columns_input"];
	/** select columns of table "rental_costs_type" */
["rental_costs_type_select_column"]: GraphQLTypes["rental_costs_type_select_column"];
	/** input type for updating data in table "rental_costs_type" */
["rental_costs_type_set_input"]: GraphQLTypes["rental_costs_type_set_input"];
	/** update columns of table "rental_costs_type" */
["rental_costs_type_update_column"]: GraphQLTypes["rental_costs_type_update_column"];
	/** update columns of table "rental_costs" */
["rental_costs_update_column"]: GraphQLTypes["rental_costs_update_column"];
	/** aggregate var_pop on columns */
["rental_costs_var_pop_fields"]: {
		costs?:number,
	vat?:number
};
	/** order by var_pop() on columns of table "rental_costs" */
["rental_costs_var_pop_order_by"]: GraphQLTypes["rental_costs_var_pop_order_by"];
	/** aggregate var_samp on columns */
["rental_costs_var_samp_fields"]: {
		costs?:number,
	vat?:number
};
	/** order by var_samp() on columns of table "rental_costs" */
["rental_costs_var_samp_order_by"]: GraphQLTypes["rental_costs_var_samp_order_by"];
	/** aggregate variance on columns */
["rental_costs_variance_fields"]: {
		costs?:number,
	vat?:number
};
	/** order by variance() on columns of table "rental_costs" */
["rental_costs_variance_order_by"]: GraphQLTypes["rental_costs_variance_order_by"];
	/** columns and relationships of "rental_rent" */
["rental_rent"]: {
		announced?:ModelTypes["timestamptz"],
	begin:ModelTypes["timestamptz"],
	id:ModelTypes["uuid"],
	/** An object relationship */
	rental_contract:ModelTypes["rental_contract"],
	rental_contract_id:ModelTypes["uuid"],
	/** An array relationship */
	rental_costs:ModelTypes["rental_costs"][],
	/** An aggregate relationship */
	rental_costs_aggregate:ModelTypes["rental_costs_aggregate"]
};
	/** aggregated selection of "rental_rent" */
["rental_rent_aggregate"]: {
		aggregate?:ModelTypes["rental_rent_aggregate_fields"],
	nodes:ModelTypes["rental_rent"][]
};
	/** aggregate fields of "rental_rent" */
["rental_rent_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["rental_rent_max_fields"],
	min?:ModelTypes["rental_rent_min_fields"]
};
	/** order by aggregate values of table "rental_rent" */
["rental_rent_aggregate_order_by"]: GraphQLTypes["rental_rent_aggregate_order_by"];
	/** input type for inserting array relation for remote table "rental_rent" */
["rental_rent_arr_rel_insert_input"]: GraphQLTypes["rental_rent_arr_rel_insert_input"];
	/** Boolean expression to filter rows from the table "rental_rent". All fields are combined with a logical 'AND'. */
["rental_rent_bool_exp"]: GraphQLTypes["rental_rent_bool_exp"];
	/** unique or primary key constraints on table "rental_rent" */
["rental_rent_constraint"]: GraphQLTypes["rental_rent_constraint"];
	/** input type for inserting data into table "rental_rent" */
["rental_rent_insert_input"]: GraphQLTypes["rental_rent_insert_input"];
	/** aggregate max on columns */
["rental_rent_max_fields"]: {
		announced?:ModelTypes["timestamptz"],
	begin?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	rental_contract_id?:ModelTypes["uuid"]
};
	/** order by max() on columns of table "rental_rent" */
["rental_rent_max_order_by"]: GraphQLTypes["rental_rent_max_order_by"];
	/** aggregate min on columns */
["rental_rent_min_fields"]: {
		announced?:ModelTypes["timestamptz"],
	begin?:ModelTypes["timestamptz"],
	id?:ModelTypes["uuid"],
	rental_contract_id?:ModelTypes["uuid"]
};
	/** order by min() on columns of table "rental_rent" */
["rental_rent_min_order_by"]: GraphQLTypes["rental_rent_min_order_by"];
	/** response of any mutation on the table "rental_rent" */
["rental_rent_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["rental_rent"][]
};
	/** input type for inserting object relation for remote table "rental_rent" */
["rental_rent_obj_rel_insert_input"]: GraphQLTypes["rental_rent_obj_rel_insert_input"];
	/** on conflict condition type for table "rental_rent" */
["rental_rent_on_conflict"]: GraphQLTypes["rental_rent_on_conflict"];
	/** Ordering options when selecting data from "rental_rent". */
["rental_rent_order_by"]: GraphQLTypes["rental_rent_order_by"];
	/** primary key columns input for table: rental_rent */
["rental_rent_pk_columns_input"]: GraphQLTypes["rental_rent_pk_columns_input"];
	/** select columns of table "rental_rent" */
["rental_rent_select_column"]: GraphQLTypes["rental_rent_select_column"];
	/** input type for updating data in table "rental_rent" */
["rental_rent_set_input"]: GraphQLTypes["rental_rent_set_input"];
	/** update columns of table "rental_rent" */
["rental_rent_update_column"]: GraphQLTypes["rental_rent_update_column"];
	["subscription_root"]: {
		/** fetch data from the table: "color" */
	color:ModelTypes["color"][],
	/** fetch aggregated fields from the table: "color" */
	color_aggregate:ModelTypes["color_aggregate"],
	/** fetch data from the table: "color" using primary key columns */
	color_by_pk?:ModelTypes["color"],
	/** fetch data from the table: "country_code" */
	country_code:ModelTypes["country_code"][],
	/** fetch aggregated fields from the table: "country_code" */
	country_code_aggregate:ModelTypes["country_code_aggregate"],
	/** fetch data from the table: "country_code" using primary key columns */
	country_code_by_pk?:ModelTypes["country_code"],
	/** fetch data from the table: "deposit_rate" */
	deposit_rate:ModelTypes["deposit_rate"][],
	/** fetch aggregated fields from the table: "deposit_rate" */
	deposit_rate_aggregate:ModelTypes["deposit_rate_aggregate"],
	/** fetch data from the table: "deposit_rate" using primary key columns */
	deposit_rate_by_pk?:ModelTypes["deposit_rate"],
	/** fetch data from the table: "deposit_type" */
	deposit_type:ModelTypes["deposit_type"][],
	/** fetch aggregated fields from the table: "deposit_type" */
	deposit_type_aggregate:ModelTypes["deposit_type_aggregate"],
	/** fetch data from the table: "deposit_type" using primary key columns */
	deposit_type_by_pk?:ModelTypes["deposit_type"],
	/** fetch data from the table: "group" */
	group:ModelTypes["group"][],
	/** fetch aggregated fields from the table: "group" */
	group_aggregate:ModelTypes["group_aggregate"],
	/** fetch data from the table: "group" using primary key columns */
	group_by_pk?:ModelTypes["group"],
	/** fetch data from the table: "property" */
	property:ModelTypes["property"][],
	/** fetch aggregated fields from the table: "property" */
	property_aggregate:ModelTypes["property_aggregate"],
	/** fetch data from the table: "property" using primary key columns */
	property_by_pk?:ModelTypes["property"],
	/** fetch data from the table: "property_type" */
	property_type:ModelTypes["property_type"][],
	/** fetch aggregated fields from the table: "property_type" */
	property_type_aggregate:ModelTypes["property_type_aggregate"],
	/** fetch data from the table: "property_type" using primary key columns */
	property_type_by_pk?:ModelTypes["property_type"],
	/** fetch data from the table: "rent_index" */
	rent_index:ModelTypes["rent_index"][],
	/** fetch aggregated fields from the table: "rent_index" */
	rent_index_aggregate:ModelTypes["rent_index_aggregate"],
	/** fetch data from the table: "rent_index_area" */
	rent_index_area:ModelTypes["rent_index_area"][],
	/** fetch aggregated fields from the table: "rent_index_area" */
	rent_index_area_aggregate:ModelTypes["rent_index_area_aggregate"],
	/** fetch data from the table: "rent_index_area" using primary key columns */
	rent_index_area_by_pk?:ModelTypes["rent_index_area"],
	/** fetch data from the table: "rent_index" using primary key columns */
	rent_index_by_pk?:ModelTypes["rent_index"],
	/** fetch data from the table: "rent_index_layer_type" */
	rent_index_layer_type:ModelTypes["rent_index_layer_type"][],
	/** fetch aggregated fields from the table: "rent_index_layer_type" */
	rent_index_layer_type_aggregate:ModelTypes["rent_index_layer_type_aggregate"],
	/** fetch data from the table: "rent_index_layer_type" using primary key columns */
	rent_index_layer_type_by_pk?:ModelTypes["rent_index_layer_type"],
	/** fetch data from the table: "rental_contract" */
	rental_contract:ModelTypes["rental_contract"][],
	/** fetch aggregated fields from the table: "rental_contract" */
	rental_contract_aggregate:ModelTypes["rental_contract_aggregate"],
	/** fetch data from the table: "rental_contract" using primary key columns */
	rental_contract_by_pk?:ModelTypes["rental_contract"],
	/** An array relationship */
	rental_costs:ModelTypes["rental_costs"][],
	/** An aggregate relationship */
	rental_costs_aggregate:ModelTypes["rental_costs_aggregate"],
	/** fetch data from the table: "rental_costs" using primary key columns */
	rental_costs_by_pk?:ModelTypes["rental_costs"],
	/** fetch data from the table: "rental_costs_type" */
	rental_costs_type:ModelTypes["rental_costs_type"][],
	/** fetch aggregated fields from the table: "rental_costs_type" */
	rental_costs_type_aggregate:ModelTypes["rental_costs_type_aggregate"],
	/** fetch data from the table: "rental_costs_type" using primary key columns */
	rental_costs_type_by_pk?:ModelTypes["rental_costs_type"],
	/** fetch data from the table: "rental_rent" */
	rental_rent:ModelTypes["rental_rent"][],
	/** fetch aggregated fields from the table: "rental_rent" */
	rental_rent_aggregate:ModelTypes["rental_rent_aggregate"],
	/** fetch data from the table: "rental_rent" using primary key columns */
	rental_rent_by_pk?:ModelTypes["rental_rent"],
	/** fetch data from the table: "unit" */
	unit:ModelTypes["unit"][],
	/** fetch aggregated fields from the table: "unit" */
	unit_aggregate:ModelTypes["unit_aggregate"],
	/** fetch data from the table: "unit" using primary key columns */
	unit_by_pk?:ModelTypes["unit"],
	/** fetch data from the table: "unit_type" */
	unit_type:ModelTypes["unit_type"][],
	/** fetch aggregated fields from the table: "unit_type" */
	unit_type_aggregate:ModelTypes["unit_type_aggregate"],
	/** fetch data from the table: "unit_type" using primary key columns */
	unit_type_by_pk?:ModelTypes["unit_type"],
	/** fetch data from the table: "user" */
	user:ModelTypes["user"][],
	/** fetch aggregated fields from the table: "user" */
	user_aggregate:ModelTypes["user_aggregate"],
	/** fetch data from the table: "user" using primary key columns */
	user_by_pk?:ModelTypes["user"]
};
	["timestamptz"]:any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: GraphQLTypes["timestamptz_comparison_exp"];
	/** columns and relationships of "unit" */
["unit"]: {
		id:ModelTypes["uuid"],
	level?:number,
	name?:string,
	/** An object relationship */
	property:ModelTypes["property"],
	property_id:ModelTypes["uuid"],
	/** An array relationship */
	rental_costs:ModelTypes["rental_costs"][],
	/** An aggregate relationship */
	rental_costs_aggregate:ModelTypes["rental_costs_aggregate"],
	rooms?:number,
	size?:number,
	size_heated?:number,
	type?:ModelTypes["unit_type_enum"],
	/** An object relationship */
	unit_type?:ModelTypes["unit_type"]
};
	/** aggregated selection of "unit" */
["unit_aggregate"]: {
		aggregate?:ModelTypes["unit_aggregate_fields"],
	nodes:ModelTypes["unit"][]
};
	/** aggregate fields of "unit" */
["unit_aggregate_fields"]: {
		avg?:ModelTypes["unit_avg_fields"],
	count:number,
	max?:ModelTypes["unit_max_fields"],
	min?:ModelTypes["unit_min_fields"],
	stddev?:ModelTypes["unit_stddev_fields"],
	stddev_pop?:ModelTypes["unit_stddev_pop_fields"],
	stddev_samp?:ModelTypes["unit_stddev_samp_fields"],
	sum?:ModelTypes["unit_sum_fields"],
	var_pop?:ModelTypes["unit_var_pop_fields"],
	var_samp?:ModelTypes["unit_var_samp_fields"],
	variance?:ModelTypes["unit_variance_fields"]
};
	/** order by aggregate values of table "unit" */
["unit_aggregate_order_by"]: GraphQLTypes["unit_aggregate_order_by"];
	/** input type for inserting array relation for remote table "unit" */
["unit_arr_rel_insert_input"]: GraphQLTypes["unit_arr_rel_insert_input"];
	/** aggregate avg on columns */
["unit_avg_fields"]: {
		level?:number,
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by avg() on columns of table "unit" */
["unit_avg_order_by"]: GraphQLTypes["unit_avg_order_by"];
	/** Boolean expression to filter rows from the table "unit". All fields are combined with a logical 'AND'. */
["unit_bool_exp"]: GraphQLTypes["unit_bool_exp"];
	/** unique or primary key constraints on table "unit" */
["unit_constraint"]: GraphQLTypes["unit_constraint"];
	/** input type for incrementing numeric columns in table "unit" */
["unit_inc_input"]: GraphQLTypes["unit_inc_input"];
	/** input type for inserting data into table "unit" */
["unit_insert_input"]: GraphQLTypes["unit_insert_input"];
	/** aggregate max on columns */
["unit_max_fields"]: {
		id?:ModelTypes["uuid"],
	level?:number,
	name?:string,
	property_id?:ModelTypes["uuid"],
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by max() on columns of table "unit" */
["unit_max_order_by"]: GraphQLTypes["unit_max_order_by"];
	/** aggregate min on columns */
["unit_min_fields"]: {
		id?:ModelTypes["uuid"],
	level?:number,
	name?:string,
	property_id?:ModelTypes["uuid"],
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by min() on columns of table "unit" */
["unit_min_order_by"]: GraphQLTypes["unit_min_order_by"];
	/** response of any mutation on the table "unit" */
["unit_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["unit"][]
};
	/** input type for inserting object relation for remote table "unit" */
["unit_obj_rel_insert_input"]: GraphQLTypes["unit_obj_rel_insert_input"];
	/** on conflict condition type for table "unit" */
["unit_on_conflict"]: GraphQLTypes["unit_on_conflict"];
	/** Ordering options when selecting data from "unit". */
["unit_order_by"]: GraphQLTypes["unit_order_by"];
	/** primary key columns input for table: unit */
["unit_pk_columns_input"]: GraphQLTypes["unit_pk_columns_input"];
	/** select columns of table "unit" */
["unit_select_column"]: GraphQLTypes["unit_select_column"];
	/** input type for updating data in table "unit" */
["unit_set_input"]: GraphQLTypes["unit_set_input"];
	/** aggregate stddev on columns */
["unit_stddev_fields"]: {
		level?:number,
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by stddev() on columns of table "unit" */
["unit_stddev_order_by"]: GraphQLTypes["unit_stddev_order_by"];
	/** aggregate stddev_pop on columns */
["unit_stddev_pop_fields"]: {
		level?:number,
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by stddev_pop() on columns of table "unit" */
["unit_stddev_pop_order_by"]: GraphQLTypes["unit_stddev_pop_order_by"];
	/** aggregate stddev_samp on columns */
["unit_stddev_samp_fields"]: {
		level?:number,
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by stddev_samp() on columns of table "unit" */
["unit_stddev_samp_order_by"]: GraphQLTypes["unit_stddev_samp_order_by"];
	/** aggregate sum on columns */
["unit_sum_fields"]: {
		level?:number,
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by sum() on columns of table "unit" */
["unit_sum_order_by"]: GraphQLTypes["unit_sum_order_by"];
	/** columns and relationships of "unit_type" */
["unit_type"]: {
		description?:string,
	name:string,
	/** An array relationship */
	units:ModelTypes["unit"][],
	/** An aggregate relationship */
	units_aggregate:ModelTypes["unit_aggregate"]
};
	/** aggregated selection of "unit_type" */
["unit_type_aggregate"]: {
		aggregate?:ModelTypes["unit_type_aggregate_fields"],
	nodes:ModelTypes["unit_type"][]
};
	/** aggregate fields of "unit_type" */
["unit_type_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["unit_type_max_fields"],
	min?:ModelTypes["unit_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "unit_type". All fields are combined with a logical 'AND'. */
["unit_type_bool_exp"]: GraphQLTypes["unit_type_bool_exp"];
	/** unique or primary key constraints on table "unit_type" */
["unit_type_constraint"]: GraphQLTypes["unit_type_constraint"];
	["unit_type_enum"]: GraphQLTypes["unit_type_enum"];
	/** Boolean expression to compare columns of type "unit_type_enum". All fields are combined with logical 'AND'. */
["unit_type_enum_comparison_exp"]: GraphQLTypes["unit_type_enum_comparison_exp"];
	/** input type for inserting data into table "unit_type" */
["unit_type_insert_input"]: GraphQLTypes["unit_type_insert_input"];
	/** aggregate max on columns */
["unit_type_max_fields"]: {
		description?:string,
	name?:string
};
	/** aggregate min on columns */
["unit_type_min_fields"]: {
		description?:string,
	name?:string
};
	/** response of any mutation on the table "unit_type" */
["unit_type_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["unit_type"][]
};
	/** input type for inserting object relation for remote table "unit_type" */
["unit_type_obj_rel_insert_input"]: GraphQLTypes["unit_type_obj_rel_insert_input"];
	/** on conflict condition type for table "unit_type" */
["unit_type_on_conflict"]: GraphQLTypes["unit_type_on_conflict"];
	/** Ordering options when selecting data from "unit_type". */
["unit_type_order_by"]: GraphQLTypes["unit_type_order_by"];
	/** primary key columns input for table: unit_type */
["unit_type_pk_columns_input"]: GraphQLTypes["unit_type_pk_columns_input"];
	/** select columns of table "unit_type" */
["unit_type_select_column"]: GraphQLTypes["unit_type_select_column"];
	/** input type for updating data in table "unit_type" */
["unit_type_set_input"]: GraphQLTypes["unit_type_set_input"];
	/** update columns of table "unit_type" */
["unit_type_update_column"]: GraphQLTypes["unit_type_update_column"];
	/** update columns of table "unit" */
["unit_update_column"]: GraphQLTypes["unit_update_column"];
	/** aggregate var_pop on columns */
["unit_var_pop_fields"]: {
		level?:number,
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by var_pop() on columns of table "unit" */
["unit_var_pop_order_by"]: GraphQLTypes["unit_var_pop_order_by"];
	/** aggregate var_samp on columns */
["unit_var_samp_fields"]: {
		level?:number,
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by var_samp() on columns of table "unit" */
["unit_var_samp_order_by"]: GraphQLTypes["unit_var_samp_order_by"];
	/** aggregate variance on columns */
["unit_variance_fields"]: {
		level?:number,
	rooms?:number,
	size?:number,
	size_heated?:number
};
	/** order by variance() on columns of table "unit" */
["unit_variance_order_by"]: GraphQLTypes["unit_variance_order_by"];
	/** columns and relationships of "user" */
["user"]: {
		active:boolean,
	email:string,
	id:ModelTypes["uuid"],
	password:string
};
	/** aggregated selection of "user" */
["user_aggregate"]: {
		aggregate?:ModelTypes["user_aggregate_fields"],
	nodes:ModelTypes["user"][]
};
	/** aggregate fields of "user" */
["user_aggregate_fields"]: {
		count:number,
	max?:ModelTypes["user_max_fields"],
	min?:ModelTypes["user_min_fields"]
};
	/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
["user_bool_exp"]: GraphQLTypes["user_bool_exp"];
	/** unique or primary key constraints on table "user" */
["user_constraint"]: GraphQLTypes["user_constraint"];
	/** input type for inserting data into table "user" */
["user_insert_input"]: GraphQLTypes["user_insert_input"];
	/** aggregate max on columns */
["user_max_fields"]: {
		email?:string,
	id?:ModelTypes["uuid"],
	password?:string
};
	/** aggregate min on columns */
["user_min_fields"]: {
		email?:string,
	id?:ModelTypes["uuid"],
	password?:string
};
	/** response of any mutation on the table "user" */
["user_mutation_response"]: {
		/** number of rows affected by the mutation */
	affected_rows:number,
	/** data from the rows affected by the mutation */
	returning:ModelTypes["user"][]
};
	/** on conflict condition type for table "user" */
["user_on_conflict"]: GraphQLTypes["user_on_conflict"];
	/** Ordering options when selecting data from "user". */
["user_order_by"]: GraphQLTypes["user_order_by"];
	/** primary key columns input for table: user */
["user_pk_columns_input"]: GraphQLTypes["user_pk_columns_input"];
	/** select columns of table "user" */
["user_select_column"]: GraphQLTypes["user_select_column"];
	/** input type for updating data in table "user" */
["user_set_input"]: GraphQLTypes["user_set_input"];
	/** update columns of table "user" */
["user_update_column"]: GraphQLTypes["user_update_column"];
	["uuid"]:any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: GraphQLTypes["uuid_comparison_exp"]
    }

export type GraphQLTypes = {
    /** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
["Boolean_comparison_exp"]: {
		_eq?: boolean,
	_gt?: boolean,
	_gte?: boolean,
	_in?: Array<boolean>,
	_is_null?: boolean,
	_lt?: boolean,
	_lte?: boolean,
	_neq?: boolean,
	_nin?: Array<boolean>
};
	/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
["Float_comparison_exp"]: {
		_eq?: number,
	_gt?: number,
	_gte?: number,
	_in?: Array<number>,
	_is_null?: boolean,
	_lt?: number,
	_lte?: number,
	_neq?: number,
	_nin?: Array<number>
};
	/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
["Int_comparison_exp"]: {
		_eq?: number,
	_gt?: number,
	_gte?: number,
	_in?: Array<number>,
	_is_null?: boolean,
	_lt?: number,
	_lte?: number,
	_neq?: number,
	_nin?: Array<number>
};
	/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
["String_comparison_exp"]: {
		_eq?: string,
	_gt?: string,
	_gte?: string,
	/** does the column match the given case-insensitive pattern */
	_ilike?: string,
	_in?: Array<string>,
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: string,
	_is_null?: boolean,
	/** does the column match the given pattern */
	_like?: string,
	_lt?: string,
	_lte?: string,
	_neq?: string,
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: string,
	_nin?: Array<string>,
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: string,
	/** does the column NOT match the given pattern */
	_nlike?: string,
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: string,
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: string,
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: string,
	/** does the column match the given SQL regular expression */
	_similar?: string
};
	/** columns and relationships of "color" */
["color"]: {
	__typename: "color",
	color: string,
	/** An array relationship */
	groups: Array<GraphQLTypes["group"]>,
	/** An aggregate relationship */
	groups_aggregate: GraphQLTypes["group_aggregate"]
};
	/** aggregated selection of "color" */
["color_aggregate"]: {
	__typename: "color_aggregate",
	aggregate?: GraphQLTypes["color_aggregate_fields"],
	nodes: Array<GraphQLTypes["color"]>
};
	/** aggregate fields of "color" */
["color_aggregate_fields"]: {
	__typename: "color_aggregate_fields",
	count: number,
	max?: GraphQLTypes["color_max_fields"],
	min?: GraphQLTypes["color_min_fields"]
};
	/** Boolean expression to filter rows from the table "color". All fields are combined with a logical 'AND'. */
["color_bool_exp"]: {
		_and?: Array<GraphQLTypes["color_bool_exp"]>,
	_not?: GraphQLTypes["color_bool_exp"],
	_or?: Array<GraphQLTypes["color_bool_exp"]>,
	color?: GraphQLTypes["String_comparison_exp"],
	groups?: GraphQLTypes["group_bool_exp"]
};
	/** unique or primary key constraints on table "color" */
["color_constraint"]: color_constraint;
	["color_enum"]: color_enum;
	/** Boolean expression to compare columns of type "color_enum". All fields are combined with logical 'AND'. */
["color_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["color_enum"],
	_in?: Array<GraphQLTypes["color_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["color_enum"],
	_nin?: Array<GraphQLTypes["color_enum"]>
};
	/** input type for inserting data into table "color" */
["color_insert_input"]: {
		color?: string,
	groups?: GraphQLTypes["group_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["color_max_fields"]: {
	__typename: "color_max_fields",
	color?: string
};
	/** aggregate min on columns */
["color_min_fields"]: {
	__typename: "color_min_fields",
	color?: string
};
	/** response of any mutation on the table "color" */
["color_mutation_response"]: {
	__typename: "color_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["color"]>
};
	/** input type for inserting object relation for remote table "color" */
["color_obj_rel_insert_input"]: {
		data: GraphQLTypes["color_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["color_on_conflict"]
};
	/** on conflict condition type for table "color" */
["color_on_conflict"]: {
		constraint: GraphQLTypes["color_constraint"],
	update_columns: Array<GraphQLTypes["color_update_column"]>,
	where?: GraphQLTypes["color_bool_exp"]
};
	/** Ordering options when selecting data from "color". */
["color_order_by"]: {
		color?: GraphQLTypes["order_by"],
	groups_aggregate?: GraphQLTypes["group_aggregate_order_by"]
};
	/** primary key columns input for table: color */
["color_pk_columns_input"]: {
		color: string
};
	/** select columns of table "color" */
["color_select_column"]: color_select_column;
	/** input type for updating data in table "color" */
["color_set_input"]: {
		color?: string
};
	/** update columns of table "color" */
["color_update_column"]: color_update_column;
	/** columns and relationships of "country_code" */
["country_code"]: {
	__typename: "country_code",
	code: string,
	country: string,
	/** An array relationship */
	properties: Array<GraphQLTypes["property"]>,
	/** An aggregate relationship */
	properties_aggregate: GraphQLTypes["property_aggregate"]
};
	/** aggregated selection of "country_code" */
["country_code_aggregate"]: {
	__typename: "country_code_aggregate",
	aggregate?: GraphQLTypes["country_code_aggregate_fields"],
	nodes: Array<GraphQLTypes["country_code"]>
};
	/** aggregate fields of "country_code" */
["country_code_aggregate_fields"]: {
	__typename: "country_code_aggregate_fields",
	count: number,
	max?: GraphQLTypes["country_code_max_fields"],
	min?: GraphQLTypes["country_code_min_fields"]
};
	/** Boolean expression to filter rows from the table "country_code". All fields are combined with a logical 'AND'. */
["country_code_bool_exp"]: {
		_and?: Array<GraphQLTypes["country_code_bool_exp"]>,
	_not?: GraphQLTypes["country_code_bool_exp"],
	_or?: Array<GraphQLTypes["country_code_bool_exp"]>,
	code?: GraphQLTypes["String_comparison_exp"],
	country?: GraphQLTypes["String_comparison_exp"],
	properties?: GraphQLTypes["property_bool_exp"]
};
	/** unique or primary key constraints on table "country_code" */
["country_code_constraint"]: country_code_constraint;
	["country_code_enum"]: country_code_enum;
	/** Boolean expression to compare columns of type "country_code_enum". All fields are combined with logical 'AND'. */
["country_code_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["country_code_enum"],
	_in?: Array<GraphQLTypes["country_code_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["country_code_enum"],
	_nin?: Array<GraphQLTypes["country_code_enum"]>
};
	/** input type for inserting data into table "country_code" */
["country_code_insert_input"]: {
		code?: string,
	country?: string,
	properties?: GraphQLTypes["property_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["country_code_max_fields"]: {
	__typename: "country_code_max_fields",
	code?: string,
	country?: string
};
	/** aggregate min on columns */
["country_code_min_fields"]: {
	__typename: "country_code_min_fields",
	code?: string,
	country?: string
};
	/** response of any mutation on the table "country_code" */
["country_code_mutation_response"]: {
	__typename: "country_code_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["country_code"]>
};
	/** input type for inserting object relation for remote table "country_code" */
["country_code_obj_rel_insert_input"]: {
		data: GraphQLTypes["country_code_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["country_code_on_conflict"]
};
	/** on conflict condition type for table "country_code" */
["country_code_on_conflict"]: {
		constraint: GraphQLTypes["country_code_constraint"],
	update_columns: Array<GraphQLTypes["country_code_update_column"]>,
	where?: GraphQLTypes["country_code_bool_exp"]
};
	/** Ordering options when selecting data from "country_code". */
["country_code_order_by"]: {
		code?: GraphQLTypes["order_by"],
	country?: GraphQLTypes["order_by"],
	properties_aggregate?: GraphQLTypes["property_aggregate_order_by"]
};
	/** primary key columns input for table: country_code */
["country_code_pk_columns_input"]: {
		code: string
};
	/** select columns of table "country_code" */
["country_code_select_column"]: country_code_select_column;
	/** input type for updating data in table "country_code" */
["country_code_set_input"]: {
		code?: string,
	country?: string
};
	/** update columns of table "country_code" */
["country_code_update_column"]: country_code_update_column;
	["date"]:any;
	/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
["date_comparison_exp"]: {
		_eq?: GraphQLTypes["date"],
	_gt?: GraphQLTypes["date"],
	_gte?: GraphQLTypes["date"],
	_in?: Array<GraphQLTypes["date"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["date"],
	_lte?: GraphQLTypes["date"],
	_neq?: GraphQLTypes["date"],
	_nin?: Array<GraphQLTypes["date"]>
};
	/** columns and relationships of "deposit_rate" */
["deposit_rate"]: {
	__typename: "deposit_rate",
	name: string,
	/** An array relationship */
	rental_contracts: Array<GraphQLTypes["rental_contract"]>,
	/** An aggregate relationship */
	rental_contracts_aggregate: GraphQLTypes["rental_contract_aggregate"]
};
	/** aggregated selection of "deposit_rate" */
["deposit_rate_aggregate"]: {
	__typename: "deposit_rate_aggregate",
	aggregate?: GraphQLTypes["deposit_rate_aggregate_fields"],
	nodes: Array<GraphQLTypes["deposit_rate"]>
};
	/** aggregate fields of "deposit_rate" */
["deposit_rate_aggregate_fields"]: {
	__typename: "deposit_rate_aggregate_fields",
	count: number,
	max?: GraphQLTypes["deposit_rate_max_fields"],
	min?: GraphQLTypes["deposit_rate_min_fields"]
};
	/** Boolean expression to filter rows from the table "deposit_rate". All fields are combined with a logical 'AND'. */
["deposit_rate_bool_exp"]: {
		_and?: Array<GraphQLTypes["deposit_rate_bool_exp"]>,
	_not?: GraphQLTypes["deposit_rate_bool_exp"],
	_or?: Array<GraphQLTypes["deposit_rate_bool_exp"]>,
	name?: GraphQLTypes["String_comparison_exp"],
	rental_contracts?: GraphQLTypes["rental_contract_bool_exp"]
};
	/** unique or primary key constraints on table "deposit_rate" */
["deposit_rate_constraint"]: deposit_rate_constraint;
	["deposit_rate_enum"]: deposit_rate_enum;
	/** Boolean expression to compare columns of type "deposit_rate_enum". All fields are combined with logical 'AND'. */
["deposit_rate_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["deposit_rate_enum"],
	_in?: Array<GraphQLTypes["deposit_rate_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["deposit_rate_enum"],
	_nin?: Array<GraphQLTypes["deposit_rate_enum"]>
};
	/** input type for inserting data into table "deposit_rate" */
["deposit_rate_insert_input"]: {
		name?: string,
	rental_contracts?: GraphQLTypes["rental_contract_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["deposit_rate_max_fields"]: {
	__typename: "deposit_rate_max_fields",
	name?: string
};
	/** aggregate min on columns */
["deposit_rate_min_fields"]: {
	__typename: "deposit_rate_min_fields",
	name?: string
};
	/** response of any mutation on the table "deposit_rate" */
["deposit_rate_mutation_response"]: {
	__typename: "deposit_rate_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["deposit_rate"]>
};
	/** input type for inserting object relation for remote table "deposit_rate" */
["deposit_rate_obj_rel_insert_input"]: {
		data: GraphQLTypes["deposit_rate_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["deposit_rate_on_conflict"]
};
	/** on conflict condition type for table "deposit_rate" */
["deposit_rate_on_conflict"]: {
		constraint: GraphQLTypes["deposit_rate_constraint"],
	update_columns: Array<GraphQLTypes["deposit_rate_update_column"]>,
	where?: GraphQLTypes["deposit_rate_bool_exp"]
};
	/** Ordering options when selecting data from "deposit_rate". */
["deposit_rate_order_by"]: {
		name?: GraphQLTypes["order_by"],
	rental_contracts_aggregate?: GraphQLTypes["rental_contract_aggregate_order_by"]
};
	/** primary key columns input for table: deposit_rate */
["deposit_rate_pk_columns_input"]: {
		name: string
};
	/** select columns of table "deposit_rate" */
["deposit_rate_select_column"]: deposit_rate_select_column;
	/** input type for updating data in table "deposit_rate" */
["deposit_rate_set_input"]: {
		name?: string
};
	/** update columns of table "deposit_rate" */
["deposit_rate_update_column"]: deposit_rate_update_column;
	/** columns and relationships of "deposit_type" */
["deposit_type"]: {
	__typename: "deposit_type",
	name: string,
	/** An array relationship */
	rental_contracts: Array<GraphQLTypes["rental_contract"]>,
	/** An aggregate relationship */
	rental_contracts_aggregate: GraphQLTypes["rental_contract_aggregate"]
};
	/** aggregated selection of "deposit_type" */
["deposit_type_aggregate"]: {
	__typename: "deposit_type_aggregate",
	aggregate?: GraphQLTypes["deposit_type_aggregate_fields"],
	nodes: Array<GraphQLTypes["deposit_type"]>
};
	/** aggregate fields of "deposit_type" */
["deposit_type_aggregate_fields"]: {
	__typename: "deposit_type_aggregate_fields",
	count: number,
	max?: GraphQLTypes["deposit_type_max_fields"],
	min?: GraphQLTypes["deposit_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "deposit_type". All fields are combined with a logical 'AND'. */
["deposit_type_bool_exp"]: {
		_and?: Array<GraphQLTypes["deposit_type_bool_exp"]>,
	_not?: GraphQLTypes["deposit_type_bool_exp"],
	_or?: Array<GraphQLTypes["deposit_type_bool_exp"]>,
	name?: GraphQLTypes["String_comparison_exp"],
	rental_contracts?: GraphQLTypes["rental_contract_bool_exp"]
};
	/** unique or primary key constraints on table "deposit_type" */
["deposit_type_constraint"]: deposit_type_constraint;
	["deposit_type_enum"]: deposit_type_enum;
	/** Boolean expression to compare columns of type "deposit_type_enum". All fields are combined with logical 'AND'. */
["deposit_type_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["deposit_type_enum"],
	_in?: Array<GraphQLTypes["deposit_type_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["deposit_type_enum"],
	_nin?: Array<GraphQLTypes["deposit_type_enum"]>
};
	/** input type for inserting data into table "deposit_type" */
["deposit_type_insert_input"]: {
		name?: string,
	rental_contracts?: GraphQLTypes["rental_contract_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["deposit_type_max_fields"]: {
	__typename: "deposit_type_max_fields",
	name?: string
};
	/** aggregate min on columns */
["deposit_type_min_fields"]: {
	__typename: "deposit_type_min_fields",
	name?: string
};
	/** response of any mutation on the table "deposit_type" */
["deposit_type_mutation_response"]: {
	__typename: "deposit_type_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["deposit_type"]>
};
	/** input type for inserting object relation for remote table "deposit_type" */
["deposit_type_obj_rel_insert_input"]: {
		data: GraphQLTypes["deposit_type_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["deposit_type_on_conflict"]
};
	/** on conflict condition type for table "deposit_type" */
["deposit_type_on_conflict"]: {
		constraint: GraphQLTypes["deposit_type_constraint"],
	update_columns: Array<GraphQLTypes["deposit_type_update_column"]>,
	where?: GraphQLTypes["deposit_type_bool_exp"]
};
	/** Ordering options when selecting data from "deposit_type". */
["deposit_type_order_by"]: {
		name?: GraphQLTypes["order_by"],
	rental_contracts_aggregate?: GraphQLTypes["rental_contract_aggregate_order_by"]
};
	/** primary key columns input for table: deposit_type */
["deposit_type_pk_columns_input"]: {
		name: string
};
	/** select columns of table "deposit_type" */
["deposit_type_select_column"]: deposit_type_select_column;
	/** input type for updating data in table "deposit_type" */
["deposit_type_set_input"]: {
		name?: string
};
	/** update columns of table "deposit_type" */
["deposit_type_update_column"]: deposit_type_update_column;
	/** columns and relationships of "group" */
["group"]: {
	__typename: "group",
	abbreviation: string,
	color?: GraphQLTypes["color_enum"],
	id: GraphQLTypes["uuid"],
	name: string,
	/** An object relationship */
	object_color_type?: GraphQLTypes["color"]
};
	/** aggregated selection of "group" */
["group_aggregate"]: {
	__typename: "group_aggregate",
	aggregate?: GraphQLTypes["group_aggregate_fields"],
	nodes: Array<GraphQLTypes["group"]>
};
	/** aggregate fields of "group" */
["group_aggregate_fields"]: {
	__typename: "group_aggregate_fields",
	count: number,
	max?: GraphQLTypes["group_max_fields"],
	min?: GraphQLTypes["group_min_fields"]
};
	/** order by aggregate values of table "group" */
["group_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["group_max_order_by"],
	min?: GraphQLTypes["group_min_order_by"]
};
	/** input type for inserting array relation for remote table "group" */
["group_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["group_insert_input"]>,
	/** on conflict condition */
	on_conflict?: GraphQLTypes["group_on_conflict"]
};
	/** Boolean expression to filter rows from the table "group". All fields are combined with a logical 'AND'. */
["group_bool_exp"]: {
		_and?: Array<GraphQLTypes["group_bool_exp"]>,
	_not?: GraphQLTypes["group_bool_exp"],
	_or?: Array<GraphQLTypes["group_bool_exp"]>,
	abbreviation?: GraphQLTypes["String_comparison_exp"],
	color?: GraphQLTypes["color_enum_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	object_color_type?: GraphQLTypes["color_bool_exp"]
};
	/** unique or primary key constraints on table "group" */
["group_constraint"]: group_constraint;
	/** input type for inserting data into table "group" */
["group_insert_input"]: {
		abbreviation?: string,
	color?: GraphQLTypes["color_enum"],
	id?: GraphQLTypes["uuid"],
	name?: string,
	object_color_type?: GraphQLTypes["color_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["group_max_fields"]: {
	__typename: "group_max_fields",
	abbreviation?: string,
	id?: GraphQLTypes["uuid"],
	name?: string
};
	/** order by max() on columns of table "group" */
["group_max_order_by"]: {
		abbreviation?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["group_min_fields"]: {
	__typename: "group_min_fields",
	abbreviation?: string,
	id?: GraphQLTypes["uuid"],
	name?: string
};
	/** order by min() on columns of table "group" */
["group_min_order_by"]: {
		abbreviation?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "group" */
["group_mutation_response"]: {
	__typename: "group_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["group"]>
};
	/** on conflict condition type for table "group" */
["group_on_conflict"]: {
		constraint: GraphQLTypes["group_constraint"],
	update_columns: Array<GraphQLTypes["group_update_column"]>,
	where?: GraphQLTypes["group_bool_exp"]
};
	/** Ordering options when selecting data from "group". */
["group_order_by"]: {
		abbreviation?: GraphQLTypes["order_by"],
	color?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	object_color_type?: GraphQLTypes["color_order_by"]
};
	/** primary key columns input for table: group */
["group_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "group" */
["group_select_column"]: group_select_column;
	/** input type for updating data in table "group" */
["group_set_input"]: {
		abbreviation?: string,
	color?: GraphQLTypes["color_enum"],
	id?: GraphQLTypes["uuid"],
	name?: string
};
	/** update columns of table "group" */
["group_update_column"]: group_update_column;
	["jsonb"]:any;
	/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
["jsonb_comparison_exp"]: {
		/** is the column contained in the given json value */
	_contained_in?: GraphQLTypes["jsonb"],
	/** does the column contain the given json value at the top level */
	_contains?: GraphQLTypes["jsonb"],
	_eq?: GraphQLTypes["jsonb"],
	_gt?: GraphQLTypes["jsonb"],
	_gte?: GraphQLTypes["jsonb"],
	/** does the string exist as a top-level key in the column */
	_has_key?: string,
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?: Array<string>,
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?: Array<string>,
	_in?: Array<GraphQLTypes["jsonb"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["jsonb"],
	_lte?: GraphQLTypes["jsonb"],
	_neq?: GraphQLTypes["jsonb"],
	_nin?: Array<GraphQLTypes["jsonb"]>
};
	/** mutation root */
["mutation_root"]: {
	__typename: "mutation_root",
	/** delete data from the table: "color" */
	delete_color?: GraphQLTypes["color_mutation_response"],
	/** delete single row from the table: "color" */
	delete_color_by_pk?: GraphQLTypes["color"],
	/** delete data from the table: "country_code" */
	delete_country_code?: GraphQLTypes["country_code_mutation_response"],
	/** delete single row from the table: "country_code" */
	delete_country_code_by_pk?: GraphQLTypes["country_code"],
	/** delete data from the table: "deposit_rate" */
	delete_deposit_rate?: GraphQLTypes["deposit_rate_mutation_response"],
	/** delete single row from the table: "deposit_rate" */
	delete_deposit_rate_by_pk?: GraphQLTypes["deposit_rate"],
	/** delete data from the table: "deposit_type" */
	delete_deposit_type?: GraphQLTypes["deposit_type_mutation_response"],
	/** delete single row from the table: "deposit_type" */
	delete_deposit_type_by_pk?: GraphQLTypes["deposit_type"],
	/** delete data from the table: "group" */
	delete_group?: GraphQLTypes["group_mutation_response"],
	/** delete single row from the table: "group" */
	delete_group_by_pk?: GraphQLTypes["group"],
	/** delete data from the table: "property" */
	delete_property?: GraphQLTypes["property_mutation_response"],
	/** delete single row from the table: "property" */
	delete_property_by_pk?: GraphQLTypes["property"],
	/** delete data from the table: "property_type" */
	delete_property_type?: GraphQLTypes["property_type_mutation_response"],
	/** delete single row from the table: "property_type" */
	delete_property_type_by_pk?: GraphQLTypes["property_type"],
	/** delete data from the table: "rent_index" */
	delete_rent_index?: GraphQLTypes["rent_index_mutation_response"],
	/** delete data from the table: "rent_index_area" */
	delete_rent_index_area?: GraphQLTypes["rent_index_area_mutation_response"],
	/** delete single row from the table: "rent_index_area" */
	delete_rent_index_area_by_pk?: GraphQLTypes["rent_index_area"],
	/** delete single row from the table: "rent_index" */
	delete_rent_index_by_pk?: GraphQLTypes["rent_index"],
	/** delete data from the table: "rent_index_layer_type" */
	delete_rent_index_layer_type?: GraphQLTypes["rent_index_layer_type_mutation_response"],
	/** delete single row from the table: "rent_index_layer_type" */
	delete_rent_index_layer_type_by_pk?: GraphQLTypes["rent_index_layer_type"],
	/** delete data from the table: "rental_contract" */
	delete_rental_contract?: GraphQLTypes["rental_contract_mutation_response"],
	/** delete single row from the table: "rental_contract" */
	delete_rental_contract_by_pk?: GraphQLTypes["rental_contract"],
	/** delete data from the table: "rental_costs" */
	delete_rental_costs?: GraphQLTypes["rental_costs_mutation_response"],
	/** delete single row from the table: "rental_costs" */
	delete_rental_costs_by_pk?: GraphQLTypes["rental_costs"],
	/** delete data from the table: "rental_costs_type" */
	delete_rental_costs_type?: GraphQLTypes["rental_costs_type_mutation_response"],
	/** delete single row from the table: "rental_costs_type" */
	delete_rental_costs_type_by_pk?: GraphQLTypes["rental_costs_type"],
	/** delete data from the table: "rental_rent" */
	delete_rental_rent?: GraphQLTypes["rental_rent_mutation_response"],
	/** delete single row from the table: "rental_rent" */
	delete_rental_rent_by_pk?: GraphQLTypes["rental_rent"],
	/** delete data from the table: "unit" */
	delete_unit?: GraphQLTypes["unit_mutation_response"],
	/** delete single row from the table: "unit" */
	delete_unit_by_pk?: GraphQLTypes["unit"],
	/** delete data from the table: "unit_type" */
	delete_unit_type?: GraphQLTypes["unit_type_mutation_response"],
	/** delete single row from the table: "unit_type" */
	delete_unit_type_by_pk?: GraphQLTypes["unit_type"],
	/** delete data from the table: "user" */
	delete_user?: GraphQLTypes["user_mutation_response"],
	/** delete single row from the table: "user" */
	delete_user_by_pk?: GraphQLTypes["user"],
	/** insert data into the table: "color" */
	insert_color?: GraphQLTypes["color_mutation_response"],
	/** insert a single row into the table: "color" */
	insert_color_one?: GraphQLTypes["color"],
	/** insert data into the table: "country_code" */
	insert_country_code?: GraphQLTypes["country_code_mutation_response"],
	/** insert a single row into the table: "country_code" */
	insert_country_code_one?: GraphQLTypes["country_code"],
	/** insert data into the table: "deposit_rate" */
	insert_deposit_rate?: GraphQLTypes["deposit_rate_mutation_response"],
	/** insert a single row into the table: "deposit_rate" */
	insert_deposit_rate_one?: GraphQLTypes["deposit_rate"],
	/** insert data into the table: "deposit_type" */
	insert_deposit_type?: GraphQLTypes["deposit_type_mutation_response"],
	/** insert a single row into the table: "deposit_type" */
	insert_deposit_type_one?: GraphQLTypes["deposit_type"],
	/** insert data into the table: "group" */
	insert_group?: GraphQLTypes["group_mutation_response"],
	/** insert a single row into the table: "group" */
	insert_group_one?: GraphQLTypes["group"],
	/** insert data into the table: "property" */
	insert_property?: GraphQLTypes["property_mutation_response"],
	/** insert a single row into the table: "property" */
	insert_property_one?: GraphQLTypes["property"],
	/** insert data into the table: "property_type" */
	insert_property_type?: GraphQLTypes["property_type_mutation_response"],
	/** insert a single row into the table: "property_type" */
	insert_property_type_one?: GraphQLTypes["property_type"],
	/** insert data into the table: "rent_index" */
	insert_rent_index?: GraphQLTypes["rent_index_mutation_response"],
	/** insert data into the table: "rent_index_area" */
	insert_rent_index_area?: GraphQLTypes["rent_index_area_mutation_response"],
	/** insert a single row into the table: "rent_index_area" */
	insert_rent_index_area_one?: GraphQLTypes["rent_index_area"],
	/** insert data into the table: "rent_index_layer_type" */
	insert_rent_index_layer_type?: GraphQLTypes["rent_index_layer_type_mutation_response"],
	/** insert a single row into the table: "rent_index_layer_type" */
	insert_rent_index_layer_type_one?: GraphQLTypes["rent_index_layer_type"],
	/** insert a single row into the table: "rent_index" */
	insert_rent_index_one?: GraphQLTypes["rent_index"],
	/** insert data into the table: "rental_contract" */
	insert_rental_contract?: GraphQLTypes["rental_contract_mutation_response"],
	/** insert a single row into the table: "rental_contract" */
	insert_rental_contract_one?: GraphQLTypes["rental_contract"],
	/** insert data into the table: "rental_costs" */
	insert_rental_costs?: GraphQLTypes["rental_costs_mutation_response"],
	/** insert a single row into the table: "rental_costs" */
	insert_rental_costs_one?: GraphQLTypes["rental_costs"],
	/** insert data into the table: "rental_costs_type" */
	insert_rental_costs_type?: GraphQLTypes["rental_costs_type_mutation_response"],
	/** insert a single row into the table: "rental_costs_type" */
	insert_rental_costs_type_one?: GraphQLTypes["rental_costs_type"],
	/** insert data into the table: "rental_rent" */
	insert_rental_rent?: GraphQLTypes["rental_rent_mutation_response"],
	/** insert a single row into the table: "rental_rent" */
	insert_rental_rent_one?: GraphQLTypes["rental_rent"],
	/** insert data into the table: "unit" */
	insert_unit?: GraphQLTypes["unit_mutation_response"],
	/** insert a single row into the table: "unit" */
	insert_unit_one?: GraphQLTypes["unit"],
	/** insert data into the table: "unit_type" */
	insert_unit_type?: GraphQLTypes["unit_type_mutation_response"],
	/** insert a single row into the table: "unit_type" */
	insert_unit_type_one?: GraphQLTypes["unit_type"],
	/** insert data into the table: "user" */
	insert_user?: GraphQLTypes["user_mutation_response"],
	/** insert a single row into the table: "user" */
	insert_user_one?: GraphQLTypes["user"],
	/** update data of the table: "color" */
	update_color?: GraphQLTypes["color_mutation_response"],
	/** update single row of the table: "color" */
	update_color_by_pk?: GraphQLTypes["color"],
	/** update data of the table: "country_code" */
	update_country_code?: GraphQLTypes["country_code_mutation_response"],
	/** update single row of the table: "country_code" */
	update_country_code_by_pk?: GraphQLTypes["country_code"],
	/** update data of the table: "deposit_rate" */
	update_deposit_rate?: GraphQLTypes["deposit_rate_mutation_response"],
	/** update single row of the table: "deposit_rate" */
	update_deposit_rate_by_pk?: GraphQLTypes["deposit_rate"],
	/** update data of the table: "deposit_type" */
	update_deposit_type?: GraphQLTypes["deposit_type_mutation_response"],
	/** update single row of the table: "deposit_type" */
	update_deposit_type_by_pk?: GraphQLTypes["deposit_type"],
	/** update data of the table: "group" */
	update_group?: GraphQLTypes["group_mutation_response"],
	/** update single row of the table: "group" */
	update_group_by_pk?: GraphQLTypes["group"],
	/** update data of the table: "property" */
	update_property?: GraphQLTypes["property_mutation_response"],
	/** update single row of the table: "property" */
	update_property_by_pk?: GraphQLTypes["property"],
	/** update data of the table: "property_type" */
	update_property_type?: GraphQLTypes["property_type_mutation_response"],
	/** update single row of the table: "property_type" */
	update_property_type_by_pk?: GraphQLTypes["property_type"],
	/** update data of the table: "rent_index" */
	update_rent_index?: GraphQLTypes["rent_index_mutation_response"],
	/** update data of the table: "rent_index_area" */
	update_rent_index_area?: GraphQLTypes["rent_index_area_mutation_response"],
	/** update single row of the table: "rent_index_area" */
	update_rent_index_area_by_pk?: GraphQLTypes["rent_index_area"],
	/** update single row of the table: "rent_index" */
	update_rent_index_by_pk?: GraphQLTypes["rent_index"],
	/** update data of the table: "rent_index_layer_type" */
	update_rent_index_layer_type?: GraphQLTypes["rent_index_layer_type_mutation_response"],
	/** update single row of the table: "rent_index_layer_type" */
	update_rent_index_layer_type_by_pk?: GraphQLTypes["rent_index_layer_type"],
	/** update data of the table: "rental_contract" */
	update_rental_contract?: GraphQLTypes["rental_contract_mutation_response"],
	/** update single row of the table: "rental_contract" */
	update_rental_contract_by_pk?: GraphQLTypes["rental_contract"],
	/** update data of the table: "rental_costs" */
	update_rental_costs?: GraphQLTypes["rental_costs_mutation_response"],
	/** update single row of the table: "rental_costs" */
	update_rental_costs_by_pk?: GraphQLTypes["rental_costs"],
	/** update data of the table: "rental_costs_type" */
	update_rental_costs_type?: GraphQLTypes["rental_costs_type_mutation_response"],
	/** update single row of the table: "rental_costs_type" */
	update_rental_costs_type_by_pk?: GraphQLTypes["rental_costs_type"],
	/** update data of the table: "rental_rent" */
	update_rental_rent?: GraphQLTypes["rental_rent_mutation_response"],
	/** update single row of the table: "rental_rent" */
	update_rental_rent_by_pk?: GraphQLTypes["rental_rent"],
	/** update data of the table: "unit" */
	update_unit?: GraphQLTypes["unit_mutation_response"],
	/** update single row of the table: "unit" */
	update_unit_by_pk?: GraphQLTypes["unit"],
	/** update data of the table: "unit_type" */
	update_unit_type?: GraphQLTypes["unit_type_mutation_response"],
	/** update single row of the table: "unit_type" */
	update_unit_type_by_pk?: GraphQLTypes["unit_type"],
	/** update data of the table: "user" */
	update_user?: GraphQLTypes["user_mutation_response"],
	/** update single row of the table: "user" */
	update_user_by_pk?: GraphQLTypes["user"]
};
	["numeric"]:any;
	/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
["numeric_comparison_exp"]: {
		_eq?: GraphQLTypes["numeric"],
	_gt?: GraphQLTypes["numeric"],
	_gte?: GraphQLTypes["numeric"],
	_in?: Array<GraphQLTypes["numeric"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["numeric"],
	_lte?: GraphQLTypes["numeric"],
	_neq?: GraphQLTypes["numeric"],
	_nin?: Array<GraphQLTypes["numeric"]>
};
	/** column ordering options */
["order_by"]: order_by;
	/** columns and relationships of "property" */
["property"]: {
	__typename: "property",
	city: string,
	country: GraphQLTypes["country_code_enum"],
	/** An object relationship */
	country_code: GraphQLTypes["country_code"],
	id: GraphQLTypes["uuid"],
	lat?: number,
	lng?: number,
	name?: string,
	number: string,
	postal_code: GraphQLTypes["numeric"],
	/** An object relationship */
	property_type: GraphQLTypes["property_type"],
	region?: string,
	state?: string,
	street: string,
	type: GraphQLTypes["property_type_enum"],
	/** An array relationship */
	units: Array<GraphQLTypes["unit"]>,
	/** An aggregate relationship */
	units_aggregate: GraphQLTypes["unit_aggregate"]
};
	/** aggregated selection of "property" */
["property_aggregate"]: {
	__typename: "property_aggregate",
	aggregate?: GraphQLTypes["property_aggregate_fields"],
	nodes: Array<GraphQLTypes["property"]>
};
	/** aggregate fields of "property" */
["property_aggregate_fields"]: {
	__typename: "property_aggregate_fields",
	avg?: GraphQLTypes["property_avg_fields"],
	count: number,
	max?: GraphQLTypes["property_max_fields"],
	min?: GraphQLTypes["property_min_fields"],
	stddev?: GraphQLTypes["property_stddev_fields"],
	stddev_pop?: GraphQLTypes["property_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["property_stddev_samp_fields"],
	sum?: GraphQLTypes["property_sum_fields"],
	var_pop?: GraphQLTypes["property_var_pop_fields"],
	var_samp?: GraphQLTypes["property_var_samp_fields"],
	variance?: GraphQLTypes["property_variance_fields"]
};
	/** order by aggregate values of table "property" */
["property_aggregate_order_by"]: {
		avg?: GraphQLTypes["property_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["property_max_order_by"],
	min?: GraphQLTypes["property_min_order_by"],
	stddev?: GraphQLTypes["property_stddev_order_by"],
	stddev_pop?: GraphQLTypes["property_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["property_stddev_samp_order_by"],
	sum?: GraphQLTypes["property_sum_order_by"],
	var_pop?: GraphQLTypes["property_var_pop_order_by"],
	var_samp?: GraphQLTypes["property_var_samp_order_by"],
	variance?: GraphQLTypes["property_variance_order_by"]
};
	/** input type for inserting array relation for remote table "property" */
["property_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["property_insert_input"]>,
	/** on conflict condition */
	on_conflict?: GraphQLTypes["property_on_conflict"]
};
	/** aggregate avg on columns */
["property_avg_fields"]: {
	__typename: "property_avg_fields",
	lat?: number,
	lng?: number,
	postal_code?: number
};
	/** order by avg() on columns of table "property" */
["property_avg_order_by"]: {
		lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "property". All fields are combined with a logical 'AND'. */
["property_bool_exp"]: {
		_and?: Array<GraphQLTypes["property_bool_exp"]>,
	_not?: GraphQLTypes["property_bool_exp"],
	_or?: Array<GraphQLTypes["property_bool_exp"]>,
	city?: GraphQLTypes["String_comparison_exp"],
	country?: GraphQLTypes["country_code_enum_comparison_exp"],
	country_code?: GraphQLTypes["country_code_bool_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	lat?: GraphQLTypes["Int_comparison_exp"],
	lng?: GraphQLTypes["Int_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	number?: GraphQLTypes["String_comparison_exp"],
	postal_code?: GraphQLTypes["numeric_comparison_exp"],
	property_type?: GraphQLTypes["property_type_bool_exp"],
	region?: GraphQLTypes["String_comparison_exp"],
	state?: GraphQLTypes["String_comparison_exp"],
	street?: GraphQLTypes["String_comparison_exp"],
	type?: GraphQLTypes["property_type_enum_comparison_exp"],
	units?: GraphQLTypes["unit_bool_exp"]
};
	/** unique or primary key constraints on table "property" */
["property_constraint"]: property_constraint;
	/** input type for incrementing numeric columns in table "property" */
["property_inc_input"]: {
		lat?: number,
	lng?: number,
	postal_code?: GraphQLTypes["numeric"]
};
	/** input type for inserting data into table "property" */
["property_insert_input"]: {
		city?: string,
	country?: GraphQLTypes["country_code_enum"],
	country_code?: GraphQLTypes["country_code_obj_rel_insert_input"],
	id?: GraphQLTypes["uuid"],
	lat?: number,
	lng?: number,
	name?: string,
	number?: string,
	postal_code?: GraphQLTypes["numeric"],
	property_type?: GraphQLTypes["property_type_obj_rel_insert_input"],
	region?: string,
	state?: string,
	street?: string,
	type?: GraphQLTypes["property_type_enum"],
	units?: GraphQLTypes["unit_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["property_max_fields"]: {
	__typename: "property_max_fields",
	city?: string,
	id?: GraphQLTypes["uuid"],
	lat?: number,
	lng?: number,
	name?: string,
	number?: string,
	postal_code?: GraphQLTypes["numeric"],
	region?: string,
	state?: string,
	street?: string
};
	/** order by max() on columns of table "property" */
["property_max_order_by"]: {
		city?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	number?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"],
	region?: GraphQLTypes["order_by"],
	state?: GraphQLTypes["order_by"],
	street?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["property_min_fields"]: {
	__typename: "property_min_fields",
	city?: string,
	id?: GraphQLTypes["uuid"],
	lat?: number,
	lng?: number,
	name?: string,
	number?: string,
	postal_code?: GraphQLTypes["numeric"],
	region?: string,
	state?: string,
	street?: string
};
	/** order by min() on columns of table "property" */
["property_min_order_by"]: {
		city?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	number?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"],
	region?: GraphQLTypes["order_by"],
	state?: GraphQLTypes["order_by"],
	street?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "property" */
["property_mutation_response"]: {
	__typename: "property_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["property"]>
};
	/** input type for inserting object relation for remote table "property" */
["property_obj_rel_insert_input"]: {
		data: GraphQLTypes["property_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["property_on_conflict"]
};
	/** on conflict condition type for table "property" */
["property_on_conflict"]: {
		constraint: GraphQLTypes["property_constraint"],
	update_columns: Array<GraphQLTypes["property_update_column"]>,
	where?: GraphQLTypes["property_bool_exp"]
};
	/** Ordering options when selecting data from "property". */
["property_order_by"]: {
		city?: GraphQLTypes["order_by"],
	country?: GraphQLTypes["order_by"],
	country_code?: GraphQLTypes["country_code_order_by"],
	id?: GraphQLTypes["order_by"],
	lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	number?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"],
	property_type?: GraphQLTypes["property_type_order_by"],
	region?: GraphQLTypes["order_by"],
	state?: GraphQLTypes["order_by"],
	street?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	units_aggregate?: GraphQLTypes["unit_aggregate_order_by"]
};
	/** primary key columns input for table: property */
["property_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "property" */
["property_select_column"]: property_select_column;
	/** input type for updating data in table "property" */
["property_set_input"]: {
		city?: string,
	country?: GraphQLTypes["country_code_enum"],
	id?: GraphQLTypes["uuid"],
	lat?: number,
	lng?: number,
	name?: string,
	number?: string,
	postal_code?: GraphQLTypes["numeric"],
	region?: string,
	state?: string,
	street?: string,
	type?: GraphQLTypes["property_type_enum"]
};
	/** aggregate stddev on columns */
["property_stddev_fields"]: {
	__typename: "property_stddev_fields",
	lat?: number,
	lng?: number,
	postal_code?: number
};
	/** order by stddev() on columns of table "property" */
["property_stddev_order_by"]: {
		lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["property_stddev_pop_fields"]: {
	__typename: "property_stddev_pop_fields",
	lat?: number,
	lng?: number,
	postal_code?: number
};
	/** order by stddev_pop() on columns of table "property" */
["property_stddev_pop_order_by"]: {
		lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["property_stddev_samp_fields"]: {
	__typename: "property_stddev_samp_fields",
	lat?: number,
	lng?: number,
	postal_code?: number
};
	/** order by stddev_samp() on columns of table "property" */
["property_stddev_samp_order_by"]: {
		lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	/** aggregate sum on columns */
["property_sum_fields"]: {
	__typename: "property_sum_fields",
	lat?: number,
	lng?: number,
	postal_code?: GraphQLTypes["numeric"]
};
	/** order by sum() on columns of table "property" */
["property_sum_order_by"]: {
		lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "property_type" */
["property_type"]: {
	__typename: "property_type",
	description: string,
	name: string,
	/** An array relationship */
	properties: Array<GraphQLTypes["property"]>,
	/** An aggregate relationship */
	properties_aggregate: GraphQLTypes["property_aggregate"]
};
	/** aggregated selection of "property_type" */
["property_type_aggregate"]: {
	__typename: "property_type_aggregate",
	aggregate?: GraphQLTypes["property_type_aggregate_fields"],
	nodes: Array<GraphQLTypes["property_type"]>
};
	/** aggregate fields of "property_type" */
["property_type_aggregate_fields"]: {
	__typename: "property_type_aggregate_fields",
	count: number,
	max?: GraphQLTypes["property_type_max_fields"],
	min?: GraphQLTypes["property_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "property_type". All fields are combined with a logical 'AND'. */
["property_type_bool_exp"]: {
		_and?: Array<GraphQLTypes["property_type_bool_exp"]>,
	_not?: GraphQLTypes["property_type_bool_exp"],
	_or?: Array<GraphQLTypes["property_type_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	properties?: GraphQLTypes["property_bool_exp"]
};
	/** unique or primary key constraints on table "property_type" */
["property_type_constraint"]: property_type_constraint;
	["property_type_enum"]: property_type_enum;
	/** Boolean expression to compare columns of type "property_type_enum". All fields are combined with logical 'AND'. */
["property_type_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["property_type_enum"],
	_in?: Array<GraphQLTypes["property_type_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["property_type_enum"],
	_nin?: Array<GraphQLTypes["property_type_enum"]>
};
	/** input type for inserting data into table "property_type" */
["property_type_insert_input"]: {
		description?: string,
	name?: string,
	properties?: GraphQLTypes["property_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["property_type_max_fields"]: {
	__typename: "property_type_max_fields",
	description?: string,
	name?: string
};
	/** aggregate min on columns */
["property_type_min_fields"]: {
	__typename: "property_type_min_fields",
	description?: string,
	name?: string
};
	/** response of any mutation on the table "property_type" */
["property_type_mutation_response"]: {
	__typename: "property_type_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["property_type"]>
};
	/** input type for inserting object relation for remote table "property_type" */
["property_type_obj_rel_insert_input"]: {
		data: GraphQLTypes["property_type_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["property_type_on_conflict"]
};
	/** on conflict condition type for table "property_type" */
["property_type_on_conflict"]: {
		constraint: GraphQLTypes["property_type_constraint"],
	update_columns: Array<GraphQLTypes["property_type_update_column"]>,
	where?: GraphQLTypes["property_type_bool_exp"]
};
	/** Ordering options when selecting data from "property_type". */
["property_type_order_by"]: {
		description?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	properties_aggregate?: GraphQLTypes["property_aggregate_order_by"]
};
	/** primary key columns input for table: property_type */
["property_type_pk_columns_input"]: {
		name: string
};
	/** select columns of table "property_type" */
["property_type_select_column"]: property_type_select_column;
	/** input type for updating data in table "property_type" */
["property_type_set_input"]: {
		description?: string,
	name?: string
};
	/** update columns of table "property_type" */
["property_type_update_column"]: property_type_update_column;
	/** update columns of table "property" */
["property_update_column"]: property_update_column;
	/** aggregate var_pop on columns */
["property_var_pop_fields"]: {
	__typename: "property_var_pop_fields",
	lat?: number,
	lng?: number,
	postal_code?: number
};
	/** order by var_pop() on columns of table "property" */
["property_var_pop_order_by"]: {
		lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["property_var_samp_fields"]: {
	__typename: "property_var_samp_fields",
	lat?: number,
	lng?: number,
	postal_code?: number
};
	/** order by var_samp() on columns of table "property" */
["property_var_samp_order_by"]: {
		lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["property_variance_fields"]: {
	__typename: "property_variance_fields",
	lat?: number,
	lng?: number,
	postal_code?: number
};
	/** order by variance() on columns of table "property" */
["property_variance_order_by"]: {
		lat?: GraphQLTypes["order_by"],
	lng?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	["query_root"]: {
	__typename: "query_root",
	/** fetch data from the table: "color" */
	color: Array<GraphQLTypes["color"]>,
	/** fetch aggregated fields from the table: "color" */
	color_aggregate: GraphQLTypes["color_aggregate"],
	/** fetch data from the table: "color" using primary key columns */
	color_by_pk?: GraphQLTypes["color"],
	/** fetch data from the table: "country_code" */
	country_code: Array<GraphQLTypes["country_code"]>,
	/** fetch aggregated fields from the table: "country_code" */
	country_code_aggregate: GraphQLTypes["country_code_aggregate"],
	/** fetch data from the table: "country_code" using primary key columns */
	country_code_by_pk?: GraphQLTypes["country_code"],
	/** fetch data from the table: "deposit_rate" */
	deposit_rate: Array<GraphQLTypes["deposit_rate"]>,
	/** fetch aggregated fields from the table: "deposit_rate" */
	deposit_rate_aggregate: GraphQLTypes["deposit_rate_aggregate"],
	/** fetch data from the table: "deposit_rate" using primary key columns */
	deposit_rate_by_pk?: GraphQLTypes["deposit_rate"],
	/** fetch data from the table: "deposit_type" */
	deposit_type: Array<GraphQLTypes["deposit_type"]>,
	/** fetch aggregated fields from the table: "deposit_type" */
	deposit_type_aggregate: GraphQLTypes["deposit_type_aggregate"],
	/** fetch data from the table: "deposit_type" using primary key columns */
	deposit_type_by_pk?: GraphQLTypes["deposit_type"],
	/** fetch data from the table: "group" */
	group: Array<GraphQLTypes["group"]>,
	/** fetch aggregated fields from the table: "group" */
	group_aggregate: GraphQLTypes["group_aggregate"],
	/** fetch data from the table: "group" using primary key columns */
	group_by_pk?: GraphQLTypes["group"],
	/** fetch data from the table: "property" */
	property: Array<GraphQLTypes["property"]>,
	/** fetch aggregated fields from the table: "property" */
	property_aggregate: GraphQLTypes["property_aggregate"],
	/** fetch data from the table: "property" using primary key columns */
	property_by_pk?: GraphQLTypes["property"],
	/** fetch data from the table: "property_type" */
	property_type: Array<GraphQLTypes["property_type"]>,
	/** fetch aggregated fields from the table: "property_type" */
	property_type_aggregate: GraphQLTypes["property_type_aggregate"],
	/** fetch data from the table: "property_type" using primary key columns */
	property_type_by_pk?: GraphQLTypes["property_type"],
	/** fetch data from the table: "rent_index" */
	rent_index: Array<GraphQLTypes["rent_index"]>,
	/** fetch aggregated fields from the table: "rent_index" */
	rent_index_aggregate: GraphQLTypes["rent_index_aggregate"],
	/** fetch data from the table: "rent_index_area" */
	rent_index_area: Array<GraphQLTypes["rent_index_area"]>,
	/** fetch aggregated fields from the table: "rent_index_area" */
	rent_index_area_aggregate: GraphQLTypes["rent_index_area_aggregate"],
	/** fetch data from the table: "rent_index_area" using primary key columns */
	rent_index_area_by_pk?: GraphQLTypes["rent_index_area"],
	/** fetch data from the table: "rent_index" using primary key columns */
	rent_index_by_pk?: GraphQLTypes["rent_index"],
	/** fetch data from the table: "rent_index_layer_type" */
	rent_index_layer_type: Array<GraphQLTypes["rent_index_layer_type"]>,
	/** fetch aggregated fields from the table: "rent_index_layer_type" */
	rent_index_layer_type_aggregate: GraphQLTypes["rent_index_layer_type_aggregate"],
	/** fetch data from the table: "rent_index_layer_type" using primary key columns */
	rent_index_layer_type_by_pk?: GraphQLTypes["rent_index_layer_type"],
	/** fetch data from the table: "rental_contract" */
	rental_contract: Array<GraphQLTypes["rental_contract"]>,
	/** fetch aggregated fields from the table: "rental_contract" */
	rental_contract_aggregate: GraphQLTypes["rental_contract_aggregate"],
	/** fetch data from the table: "rental_contract" using primary key columns */
	rental_contract_by_pk?: GraphQLTypes["rental_contract"],
	/** An array relationship */
	rental_costs: Array<GraphQLTypes["rental_costs"]>,
	/** An aggregate relationship */
	rental_costs_aggregate: GraphQLTypes["rental_costs_aggregate"],
	/** fetch data from the table: "rental_costs" using primary key columns */
	rental_costs_by_pk?: GraphQLTypes["rental_costs"],
	/** fetch data from the table: "rental_costs_type" */
	rental_costs_type: Array<GraphQLTypes["rental_costs_type"]>,
	/** fetch aggregated fields from the table: "rental_costs_type" */
	rental_costs_type_aggregate: GraphQLTypes["rental_costs_type_aggregate"],
	/** fetch data from the table: "rental_costs_type" using primary key columns */
	rental_costs_type_by_pk?: GraphQLTypes["rental_costs_type"],
	/** fetch data from the table: "rental_rent" */
	rental_rent: Array<GraphQLTypes["rental_rent"]>,
	/** fetch aggregated fields from the table: "rental_rent" */
	rental_rent_aggregate: GraphQLTypes["rental_rent_aggregate"],
	/** fetch data from the table: "rental_rent" using primary key columns */
	rental_rent_by_pk?: GraphQLTypes["rental_rent"],
	/** fetch data from the table: "unit" */
	unit: Array<GraphQLTypes["unit"]>,
	/** fetch aggregated fields from the table: "unit" */
	unit_aggregate: GraphQLTypes["unit_aggregate"],
	/** fetch data from the table: "unit" using primary key columns */
	unit_by_pk?: GraphQLTypes["unit"],
	/** fetch data from the table: "unit_type" */
	unit_type: Array<GraphQLTypes["unit_type"]>,
	/** fetch aggregated fields from the table: "unit_type" */
	unit_type_aggregate: GraphQLTypes["unit_type_aggregate"],
	/** fetch data from the table: "unit_type" using primary key columns */
	unit_type_by_pk?: GraphQLTypes["unit_type"],
	/** fetch data from the table: "user" */
	user: Array<GraphQLTypes["user"]>,
	/** fetch aggregated fields from the table: "user" */
	user_aggregate: GraphQLTypes["user_aggregate"],
	/** fetch data from the table: "user" using primary key columns */
	user_by_pk?: GraphQLTypes["user"]
};
	/** columns and relationships of "rent_index" */
["rent_index"]: {
	__typename: "rent_index",
	city: string,
	data: GraphQLTypes["jsonb"],
	date?: GraphQLTypes["date"],
	id: GraphQLTypes["uuid"],
	postal_code?: GraphQLTypes["numeric"]
};
	/** aggregated selection of "rent_index" */
["rent_index_aggregate"]: {
	__typename: "rent_index_aggregate",
	aggregate?: GraphQLTypes["rent_index_aggregate_fields"],
	nodes: Array<GraphQLTypes["rent_index"]>
};
	/** aggregate fields of "rent_index" */
["rent_index_aggregate_fields"]: {
	__typename: "rent_index_aggregate_fields",
	avg?: GraphQLTypes["rent_index_avg_fields"],
	count: number,
	max?: GraphQLTypes["rent_index_max_fields"],
	min?: GraphQLTypes["rent_index_min_fields"],
	stddev?: GraphQLTypes["rent_index_stddev_fields"],
	stddev_pop?: GraphQLTypes["rent_index_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["rent_index_stddev_samp_fields"],
	sum?: GraphQLTypes["rent_index_sum_fields"],
	var_pop?: GraphQLTypes["rent_index_var_pop_fields"],
	var_samp?: GraphQLTypes["rent_index_var_samp_fields"],
	variance?: GraphQLTypes["rent_index_variance_fields"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["rent_index_append_input"]: {
		data?: GraphQLTypes["jsonb"]
};
	/** columns and relationships of "rent_index_area" */
["rent_index_area"]: {
	__typename: "rent_index_area",
	id: GraphQLTypes["uuid"],
	layer_type: GraphQLTypes["rent_index_layer_type_enum"],
	polygons: GraphQLTypes["jsonb"],
	/** An object relationship */
	rent_index_layer_type: GraphQLTypes["rent_index_layer_type"]
};
	/** aggregated selection of "rent_index_area" */
["rent_index_area_aggregate"]: {
	__typename: "rent_index_area_aggregate",
	aggregate?: GraphQLTypes["rent_index_area_aggregate_fields"],
	nodes: Array<GraphQLTypes["rent_index_area"]>
};
	/** aggregate fields of "rent_index_area" */
["rent_index_area_aggregate_fields"]: {
	__typename: "rent_index_area_aggregate_fields",
	count: number,
	max?: GraphQLTypes["rent_index_area_max_fields"],
	min?: GraphQLTypes["rent_index_area_min_fields"]
};
	/** order by aggregate values of table "rent_index_area" */
["rent_index_area_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["rent_index_area_max_order_by"],
	min?: GraphQLTypes["rent_index_area_min_order_by"]
};
	/** append existing jsonb value of filtered columns with new jsonb value */
["rent_index_area_append_input"]: {
		polygons?: GraphQLTypes["jsonb"]
};
	/** input type for inserting array relation for remote table "rent_index_area" */
["rent_index_area_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["rent_index_area_insert_input"]>,
	/** on conflict condition */
	on_conflict?: GraphQLTypes["rent_index_area_on_conflict"]
};
	/** Boolean expression to filter rows from the table "rent_index_area". All fields are combined with a logical 'AND'. */
["rent_index_area_bool_exp"]: {
		_and?: Array<GraphQLTypes["rent_index_area_bool_exp"]>,
	_not?: GraphQLTypes["rent_index_area_bool_exp"],
	_or?: Array<GraphQLTypes["rent_index_area_bool_exp"]>,
	id?: GraphQLTypes["uuid_comparison_exp"],
	layer_type?: GraphQLTypes["rent_index_layer_type_enum_comparison_exp"],
	polygons?: GraphQLTypes["jsonb_comparison_exp"],
	rent_index_layer_type?: GraphQLTypes["rent_index_layer_type_bool_exp"]
};
	/** unique or primary key constraints on table "rent_index_area" */
["rent_index_area_constraint"]: rent_index_area_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["rent_index_area_delete_at_path_input"]: {
		polygons?: Array<string>
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["rent_index_area_delete_elem_input"]: {
		polygons?: number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["rent_index_area_delete_key_input"]: {
		polygons?: string
};
	/** input type for inserting data into table "rent_index_area" */
["rent_index_area_insert_input"]: {
		id?: GraphQLTypes["uuid"],
	layer_type?: GraphQLTypes["rent_index_layer_type_enum"],
	polygons?: GraphQLTypes["jsonb"],
	rent_index_layer_type?: GraphQLTypes["rent_index_layer_type_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["rent_index_area_max_fields"]: {
	__typename: "rent_index_area_max_fields",
	id?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "rent_index_area" */
["rent_index_area_max_order_by"]: {
		id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["rent_index_area_min_fields"]: {
	__typename: "rent_index_area_min_fields",
	id?: GraphQLTypes["uuid"]
};
	/** order by min() on columns of table "rent_index_area" */
["rent_index_area_min_order_by"]: {
		id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "rent_index_area" */
["rent_index_area_mutation_response"]: {
	__typename: "rent_index_area_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["rent_index_area"]>
};
	/** on conflict condition type for table "rent_index_area" */
["rent_index_area_on_conflict"]: {
		constraint: GraphQLTypes["rent_index_area_constraint"],
	update_columns: Array<GraphQLTypes["rent_index_area_update_column"]>,
	where?: GraphQLTypes["rent_index_area_bool_exp"]
};
	/** Ordering options when selecting data from "rent_index_area". */
["rent_index_area_order_by"]: {
		id?: GraphQLTypes["order_by"],
	layer_type?: GraphQLTypes["order_by"],
	polygons?: GraphQLTypes["order_by"],
	rent_index_layer_type?: GraphQLTypes["rent_index_layer_type_order_by"]
};
	/** primary key columns input for table: rent_index_area */
["rent_index_area_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["rent_index_area_prepend_input"]: {
		polygons?: GraphQLTypes["jsonb"]
};
	/** select columns of table "rent_index_area" */
["rent_index_area_select_column"]: rent_index_area_select_column;
	/** input type for updating data in table "rent_index_area" */
["rent_index_area_set_input"]: {
		id?: GraphQLTypes["uuid"],
	layer_type?: GraphQLTypes["rent_index_layer_type_enum"],
	polygons?: GraphQLTypes["jsonb"]
};
	/** update columns of table "rent_index_area" */
["rent_index_area_update_column"]: rent_index_area_update_column;
	/** aggregate avg on columns */
["rent_index_avg_fields"]: {
	__typename: "rent_index_avg_fields",
	postal_code?: number
};
	/** Boolean expression to filter rows from the table "rent_index". All fields are combined with a logical 'AND'. */
["rent_index_bool_exp"]: {
		_and?: Array<GraphQLTypes["rent_index_bool_exp"]>,
	_not?: GraphQLTypes["rent_index_bool_exp"],
	_or?: Array<GraphQLTypes["rent_index_bool_exp"]>,
	city?: GraphQLTypes["String_comparison_exp"],
	data?: GraphQLTypes["jsonb_comparison_exp"],
	date?: GraphQLTypes["date_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	postal_code?: GraphQLTypes["numeric_comparison_exp"]
};
	/** unique or primary key constraints on table "rent_index" */
["rent_index_constraint"]: rent_index_constraint;
	/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
["rent_index_delete_at_path_input"]: {
		data?: Array<string>
};
	/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
["rent_index_delete_elem_input"]: {
		data?: number
};
	/** delete key/value pair or string element. key/value pairs are matched based on their key value */
["rent_index_delete_key_input"]: {
		data?: string
};
	/** input type for incrementing numeric columns in table "rent_index" */
["rent_index_inc_input"]: {
		postal_code?: GraphQLTypes["numeric"]
};
	/** input type for inserting data into table "rent_index" */
["rent_index_insert_input"]: {
		city?: string,
	data?: GraphQLTypes["jsonb"],
	date?: GraphQLTypes["date"],
	id?: GraphQLTypes["uuid"],
	postal_code?: GraphQLTypes["numeric"]
};
	/** columns and relationships of "rent_index_layer_type" */
["rent_index_layer_type"]: {
	__typename: "rent_index_layer_type",
	description: string,
	name: string,
	/** An array relationship */
	rent_index_areas: Array<GraphQLTypes["rent_index_area"]>,
	/** An aggregate relationship */
	rent_index_areas_aggregate: GraphQLTypes["rent_index_area_aggregate"]
};
	/** aggregated selection of "rent_index_layer_type" */
["rent_index_layer_type_aggregate"]: {
	__typename: "rent_index_layer_type_aggregate",
	aggregate?: GraphQLTypes["rent_index_layer_type_aggregate_fields"],
	nodes: Array<GraphQLTypes["rent_index_layer_type"]>
};
	/** aggregate fields of "rent_index_layer_type" */
["rent_index_layer_type_aggregate_fields"]: {
	__typename: "rent_index_layer_type_aggregate_fields",
	count: number,
	max?: GraphQLTypes["rent_index_layer_type_max_fields"],
	min?: GraphQLTypes["rent_index_layer_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "rent_index_layer_type". All fields are combined with a logical 'AND'. */
["rent_index_layer_type_bool_exp"]: {
		_and?: Array<GraphQLTypes["rent_index_layer_type_bool_exp"]>,
	_not?: GraphQLTypes["rent_index_layer_type_bool_exp"],
	_or?: Array<GraphQLTypes["rent_index_layer_type_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	rent_index_areas?: GraphQLTypes["rent_index_area_bool_exp"]
};
	/** unique or primary key constraints on table "rent_index_layer_type" */
["rent_index_layer_type_constraint"]: rent_index_layer_type_constraint;
	["rent_index_layer_type_enum"]: rent_index_layer_type_enum;
	/** Boolean expression to compare columns of type "rent_index_layer_type_enum". All fields are combined with logical 'AND'. */
["rent_index_layer_type_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["rent_index_layer_type_enum"],
	_in?: Array<GraphQLTypes["rent_index_layer_type_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["rent_index_layer_type_enum"],
	_nin?: Array<GraphQLTypes["rent_index_layer_type_enum"]>
};
	/** input type for inserting data into table "rent_index_layer_type" */
["rent_index_layer_type_insert_input"]: {
		description?: string,
	name?: string,
	rent_index_areas?: GraphQLTypes["rent_index_area_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["rent_index_layer_type_max_fields"]: {
	__typename: "rent_index_layer_type_max_fields",
	description?: string,
	name?: string
};
	/** aggregate min on columns */
["rent_index_layer_type_min_fields"]: {
	__typename: "rent_index_layer_type_min_fields",
	description?: string,
	name?: string
};
	/** response of any mutation on the table "rent_index_layer_type" */
["rent_index_layer_type_mutation_response"]: {
	__typename: "rent_index_layer_type_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["rent_index_layer_type"]>
};
	/** input type for inserting object relation for remote table "rent_index_layer_type" */
["rent_index_layer_type_obj_rel_insert_input"]: {
		data: GraphQLTypes["rent_index_layer_type_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["rent_index_layer_type_on_conflict"]
};
	/** on conflict condition type for table "rent_index_layer_type" */
["rent_index_layer_type_on_conflict"]: {
		constraint: GraphQLTypes["rent_index_layer_type_constraint"],
	update_columns: Array<GraphQLTypes["rent_index_layer_type_update_column"]>,
	where?: GraphQLTypes["rent_index_layer_type_bool_exp"]
};
	/** Ordering options when selecting data from "rent_index_layer_type". */
["rent_index_layer_type_order_by"]: {
		description?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	rent_index_areas_aggregate?: GraphQLTypes["rent_index_area_aggregate_order_by"]
};
	/** primary key columns input for table: rent_index_layer_type */
["rent_index_layer_type_pk_columns_input"]: {
		name: string
};
	/** select columns of table "rent_index_layer_type" */
["rent_index_layer_type_select_column"]: rent_index_layer_type_select_column;
	/** input type for updating data in table "rent_index_layer_type" */
["rent_index_layer_type_set_input"]: {
		description?: string,
	name?: string
};
	/** update columns of table "rent_index_layer_type" */
["rent_index_layer_type_update_column"]: rent_index_layer_type_update_column;
	/** aggregate max on columns */
["rent_index_max_fields"]: {
	__typename: "rent_index_max_fields",
	city?: string,
	date?: GraphQLTypes["date"],
	id?: GraphQLTypes["uuid"],
	postal_code?: GraphQLTypes["numeric"]
};
	/** aggregate min on columns */
["rent_index_min_fields"]: {
	__typename: "rent_index_min_fields",
	city?: string,
	date?: GraphQLTypes["date"],
	id?: GraphQLTypes["uuid"],
	postal_code?: GraphQLTypes["numeric"]
};
	/** response of any mutation on the table "rent_index" */
["rent_index_mutation_response"]: {
	__typename: "rent_index_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["rent_index"]>
};
	/** on conflict condition type for table "rent_index" */
["rent_index_on_conflict"]: {
		constraint: GraphQLTypes["rent_index_constraint"],
	update_columns: Array<GraphQLTypes["rent_index_update_column"]>,
	where?: GraphQLTypes["rent_index_bool_exp"]
};
	/** Ordering options when selecting data from "rent_index". */
["rent_index_order_by"]: {
		city?: GraphQLTypes["order_by"],
	data?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	postal_code?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: rent_index */
["rent_index_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** prepend existing jsonb value of filtered columns with new jsonb value */
["rent_index_prepend_input"]: {
		data?: GraphQLTypes["jsonb"]
};
	/** select columns of table "rent_index" */
["rent_index_select_column"]: rent_index_select_column;
	/** input type for updating data in table "rent_index" */
["rent_index_set_input"]: {
		city?: string,
	data?: GraphQLTypes["jsonb"],
	date?: GraphQLTypes["date"],
	id?: GraphQLTypes["uuid"],
	postal_code?: GraphQLTypes["numeric"]
};
	/** aggregate stddev on columns */
["rent_index_stddev_fields"]: {
	__typename: "rent_index_stddev_fields",
	postal_code?: number
};
	/** aggregate stddev_pop on columns */
["rent_index_stddev_pop_fields"]: {
	__typename: "rent_index_stddev_pop_fields",
	postal_code?: number
};
	/** aggregate stddev_samp on columns */
["rent_index_stddev_samp_fields"]: {
	__typename: "rent_index_stddev_samp_fields",
	postal_code?: number
};
	/** aggregate sum on columns */
["rent_index_sum_fields"]: {
	__typename: "rent_index_sum_fields",
	postal_code?: GraphQLTypes["numeric"]
};
	/** update columns of table "rent_index" */
["rent_index_update_column"]: rent_index_update_column;
	/** aggregate var_pop on columns */
["rent_index_var_pop_fields"]: {
	__typename: "rent_index_var_pop_fields",
	postal_code?: number
};
	/** aggregate var_samp on columns */
["rent_index_var_samp_fields"]: {
	__typename: "rent_index_var_samp_fields",
	postal_code?: number
};
	/** aggregate variance on columns */
["rent_index_variance_fields"]: {
	__typename: "rent_index_variance_fields",
	postal_code?: number
};
	/** columns and relationships of "rental_contract" */
["rental_contract"]: {
	__typename: "rental_contract",
	begin?: GraphQLTypes["timestamptz"],
	cancelation_effective?: GraphQLTypes["timestamptz"],
	cancelation_period?: number,
	cancelation_pronounced?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["timestamptz"],
	deposit?: number,
	/** An object relationship */
	depositRate?: GraphQLTypes["deposit_rate"],
	/** An object relationship */
	depositType?: GraphQLTypes["deposit_type"],
	deposit_payable?: GraphQLTypes["timestamptz"],
	deposit_rate?: GraphQLTypes["deposit_rate_enum"],
	deposit_received?: GraphQLTypes["timestamptz"],
	deposit_type?: GraphQLTypes["deposit_type_enum"],
	end?: GraphQLTypes["timestamptz"],
	handover_move_in?: GraphQLTypes["timestamptz"],
	handover_move_out?: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	min?: GraphQLTypes["timestamptz"],
	other_agreements?: string,
	/** An array relationship */
	rental_rents: Array<GraphQLTypes["rental_rent"]>,
	/** An aggregate relationship */
	rental_rents_aggregate: GraphQLTypes["rental_rent_aggregate"]
};
	/** aggregated selection of "rental_contract" */
["rental_contract_aggregate"]: {
	__typename: "rental_contract_aggregate",
	aggregate?: GraphQLTypes["rental_contract_aggregate_fields"],
	nodes: Array<GraphQLTypes["rental_contract"]>
};
	/** aggregate fields of "rental_contract" */
["rental_contract_aggregate_fields"]: {
	__typename: "rental_contract_aggregate_fields",
	avg?: GraphQLTypes["rental_contract_avg_fields"],
	count: number,
	max?: GraphQLTypes["rental_contract_max_fields"],
	min?: GraphQLTypes["rental_contract_min_fields"],
	stddev?: GraphQLTypes["rental_contract_stddev_fields"],
	stddev_pop?: GraphQLTypes["rental_contract_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["rental_contract_stddev_samp_fields"],
	sum?: GraphQLTypes["rental_contract_sum_fields"],
	var_pop?: GraphQLTypes["rental_contract_var_pop_fields"],
	var_samp?: GraphQLTypes["rental_contract_var_samp_fields"],
	variance?: GraphQLTypes["rental_contract_variance_fields"]
};
	/** order by aggregate values of table "rental_contract" */
["rental_contract_aggregate_order_by"]: {
		avg?: GraphQLTypes["rental_contract_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["rental_contract_max_order_by"],
	min?: GraphQLTypes["rental_contract_min_order_by"],
	stddev?: GraphQLTypes["rental_contract_stddev_order_by"],
	stddev_pop?: GraphQLTypes["rental_contract_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["rental_contract_stddev_samp_order_by"],
	sum?: GraphQLTypes["rental_contract_sum_order_by"],
	var_pop?: GraphQLTypes["rental_contract_var_pop_order_by"],
	var_samp?: GraphQLTypes["rental_contract_var_samp_order_by"],
	variance?: GraphQLTypes["rental_contract_variance_order_by"]
};
	/** input type for inserting array relation for remote table "rental_contract" */
["rental_contract_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["rental_contract_insert_input"]>,
	/** on conflict condition */
	on_conflict?: GraphQLTypes["rental_contract_on_conflict"]
};
	/** aggregate avg on columns */
["rental_contract_avg_fields"]: {
	__typename: "rental_contract_avg_fields",
	cancelation_period?: number,
	deposit?: number
};
	/** order by avg() on columns of table "rental_contract" */
["rental_contract_avg_order_by"]: {
		cancelation_period?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "rental_contract". All fields are combined with a logical 'AND'. */
["rental_contract_bool_exp"]: {
		_and?: Array<GraphQLTypes["rental_contract_bool_exp"]>,
	_not?: GraphQLTypes["rental_contract_bool_exp"],
	_or?: Array<GraphQLTypes["rental_contract_bool_exp"]>,
	begin?: GraphQLTypes["timestamptz_comparison_exp"],
	cancelation_effective?: GraphQLTypes["timestamptz_comparison_exp"],
	cancelation_period?: GraphQLTypes["Int_comparison_exp"],
	cancelation_pronounced?: GraphQLTypes["timestamptz_comparison_exp"],
	date?: GraphQLTypes["timestamptz_comparison_exp"],
	deposit?: GraphQLTypes["Int_comparison_exp"],
	depositRate?: GraphQLTypes["deposit_rate_bool_exp"],
	depositType?: GraphQLTypes["deposit_type_bool_exp"],
	deposit_payable?: GraphQLTypes["timestamptz_comparison_exp"],
	deposit_rate?: GraphQLTypes["deposit_rate_enum_comparison_exp"],
	deposit_received?: GraphQLTypes["timestamptz_comparison_exp"],
	deposit_type?: GraphQLTypes["deposit_type_enum_comparison_exp"],
	end?: GraphQLTypes["timestamptz_comparison_exp"],
	handover_move_in?: GraphQLTypes["timestamptz_comparison_exp"],
	handover_move_out?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	min?: GraphQLTypes["timestamptz_comparison_exp"],
	other_agreements?: GraphQLTypes["String_comparison_exp"],
	rental_rents?: GraphQLTypes["rental_rent_bool_exp"]
};
	/** unique or primary key constraints on table "rental_contract" */
["rental_contract_constraint"]: rental_contract_constraint;
	/** input type for incrementing numeric columns in table "rental_contract" */
["rental_contract_inc_input"]: {
		cancelation_period?: number,
	deposit?: number
};
	/** input type for inserting data into table "rental_contract" */
["rental_contract_insert_input"]: {
		begin?: GraphQLTypes["timestamptz"],
	cancelation_effective?: GraphQLTypes["timestamptz"],
	cancelation_period?: number,
	cancelation_pronounced?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["timestamptz"],
	deposit?: number,
	depositRate?: GraphQLTypes["deposit_rate_obj_rel_insert_input"],
	depositType?: GraphQLTypes["deposit_type_obj_rel_insert_input"],
	deposit_payable?: GraphQLTypes["timestamptz"],
	deposit_rate?: GraphQLTypes["deposit_rate_enum"],
	deposit_received?: GraphQLTypes["timestamptz"],
	deposit_type?: GraphQLTypes["deposit_type_enum"],
	end?: GraphQLTypes["timestamptz"],
	handover_move_in?: GraphQLTypes["timestamptz"],
	handover_move_out?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	min?: GraphQLTypes["timestamptz"],
	other_agreements?: string,
	rental_rents?: GraphQLTypes["rental_rent_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["rental_contract_max_fields"]: {
	__typename: "rental_contract_max_fields",
	begin?: GraphQLTypes["timestamptz"],
	cancelation_effective?: GraphQLTypes["timestamptz"],
	cancelation_period?: number,
	cancelation_pronounced?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["timestamptz"],
	deposit?: number,
	deposit_payable?: GraphQLTypes["timestamptz"],
	deposit_received?: GraphQLTypes["timestamptz"],
	end?: GraphQLTypes["timestamptz"],
	handover_move_in?: GraphQLTypes["timestamptz"],
	handover_move_out?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	min?: GraphQLTypes["timestamptz"],
	other_agreements?: string
};
	/** order by max() on columns of table "rental_contract" */
["rental_contract_max_order_by"]: {
		begin?: GraphQLTypes["order_by"],
	cancelation_effective?: GraphQLTypes["order_by"],
	cancelation_period?: GraphQLTypes["order_by"],
	cancelation_pronounced?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"],
	deposit_payable?: GraphQLTypes["order_by"],
	deposit_received?: GraphQLTypes["order_by"],
	end?: GraphQLTypes["order_by"],
	handover_move_in?: GraphQLTypes["order_by"],
	handover_move_out?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	min?: GraphQLTypes["order_by"],
	other_agreements?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["rental_contract_min_fields"]: {
	__typename: "rental_contract_min_fields",
	begin?: GraphQLTypes["timestamptz"],
	cancelation_effective?: GraphQLTypes["timestamptz"],
	cancelation_period?: number,
	cancelation_pronounced?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["timestamptz"],
	deposit?: number,
	deposit_payable?: GraphQLTypes["timestamptz"],
	deposit_received?: GraphQLTypes["timestamptz"],
	end?: GraphQLTypes["timestamptz"],
	handover_move_in?: GraphQLTypes["timestamptz"],
	handover_move_out?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	min?: GraphQLTypes["timestamptz"],
	other_agreements?: string
};
	/** order by min() on columns of table "rental_contract" */
["rental_contract_min_order_by"]: {
		begin?: GraphQLTypes["order_by"],
	cancelation_effective?: GraphQLTypes["order_by"],
	cancelation_period?: GraphQLTypes["order_by"],
	cancelation_pronounced?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"],
	deposit_payable?: GraphQLTypes["order_by"],
	deposit_received?: GraphQLTypes["order_by"],
	end?: GraphQLTypes["order_by"],
	handover_move_in?: GraphQLTypes["order_by"],
	handover_move_out?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	min?: GraphQLTypes["order_by"],
	other_agreements?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "rental_contract" */
["rental_contract_mutation_response"]: {
	__typename: "rental_contract_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["rental_contract"]>
};
	/** input type for inserting object relation for remote table "rental_contract" */
["rental_contract_obj_rel_insert_input"]: {
		data: GraphQLTypes["rental_contract_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["rental_contract_on_conflict"]
};
	/** on conflict condition type for table "rental_contract" */
["rental_contract_on_conflict"]: {
		constraint: GraphQLTypes["rental_contract_constraint"],
	update_columns: Array<GraphQLTypes["rental_contract_update_column"]>,
	where?: GraphQLTypes["rental_contract_bool_exp"]
};
	/** Ordering options when selecting data from "rental_contract". */
["rental_contract_order_by"]: {
		begin?: GraphQLTypes["order_by"],
	cancelation_effective?: GraphQLTypes["order_by"],
	cancelation_period?: GraphQLTypes["order_by"],
	cancelation_pronounced?: GraphQLTypes["order_by"],
	date?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"],
	depositRate?: GraphQLTypes["deposit_rate_order_by"],
	depositType?: GraphQLTypes["deposit_type_order_by"],
	deposit_payable?: GraphQLTypes["order_by"],
	deposit_rate?: GraphQLTypes["order_by"],
	deposit_received?: GraphQLTypes["order_by"],
	deposit_type?: GraphQLTypes["order_by"],
	end?: GraphQLTypes["order_by"],
	handover_move_in?: GraphQLTypes["order_by"],
	handover_move_out?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	min?: GraphQLTypes["order_by"],
	other_agreements?: GraphQLTypes["order_by"],
	rental_rents_aggregate?: GraphQLTypes["rental_rent_aggregate_order_by"]
};
	/** primary key columns input for table: rental_contract */
["rental_contract_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "rental_contract" */
["rental_contract_select_column"]: rental_contract_select_column;
	/** input type for updating data in table "rental_contract" */
["rental_contract_set_input"]: {
		begin?: GraphQLTypes["timestamptz"],
	cancelation_effective?: GraphQLTypes["timestamptz"],
	cancelation_period?: number,
	cancelation_pronounced?: GraphQLTypes["timestamptz"],
	date?: GraphQLTypes["timestamptz"],
	deposit?: number,
	deposit_payable?: GraphQLTypes["timestamptz"],
	deposit_rate?: GraphQLTypes["deposit_rate_enum"],
	deposit_received?: GraphQLTypes["timestamptz"],
	deposit_type?: GraphQLTypes["deposit_type_enum"],
	end?: GraphQLTypes["timestamptz"],
	handover_move_in?: GraphQLTypes["timestamptz"],
	handover_move_out?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	min?: GraphQLTypes["timestamptz"],
	other_agreements?: string
};
	/** aggregate stddev on columns */
["rental_contract_stddev_fields"]: {
	__typename: "rental_contract_stddev_fields",
	cancelation_period?: number,
	deposit?: number
};
	/** order by stddev() on columns of table "rental_contract" */
["rental_contract_stddev_order_by"]: {
		cancelation_period?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["rental_contract_stddev_pop_fields"]: {
	__typename: "rental_contract_stddev_pop_fields",
	cancelation_period?: number,
	deposit?: number
};
	/** order by stddev_pop() on columns of table "rental_contract" */
["rental_contract_stddev_pop_order_by"]: {
		cancelation_period?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["rental_contract_stddev_samp_fields"]: {
	__typename: "rental_contract_stddev_samp_fields",
	cancelation_period?: number,
	deposit?: number
};
	/** order by stddev_samp() on columns of table "rental_contract" */
["rental_contract_stddev_samp_order_by"]: {
		cancelation_period?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"]
};
	/** aggregate sum on columns */
["rental_contract_sum_fields"]: {
	__typename: "rental_contract_sum_fields",
	cancelation_period?: number,
	deposit?: number
};
	/** order by sum() on columns of table "rental_contract" */
["rental_contract_sum_order_by"]: {
		cancelation_period?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"]
};
	/** update columns of table "rental_contract" */
["rental_contract_update_column"]: rental_contract_update_column;
	/** aggregate var_pop on columns */
["rental_contract_var_pop_fields"]: {
	__typename: "rental_contract_var_pop_fields",
	cancelation_period?: number,
	deposit?: number
};
	/** order by var_pop() on columns of table "rental_contract" */
["rental_contract_var_pop_order_by"]: {
		cancelation_period?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["rental_contract_var_samp_fields"]: {
	__typename: "rental_contract_var_samp_fields",
	cancelation_period?: number,
	deposit?: number
};
	/** order by var_samp() on columns of table "rental_contract" */
["rental_contract_var_samp_order_by"]: {
		cancelation_period?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["rental_contract_variance_fields"]: {
	__typename: "rental_contract_variance_fields",
	cancelation_period?: number,
	deposit?: number
};
	/** order by variance() on columns of table "rental_contract" */
["rental_contract_variance_order_by"]: {
		cancelation_period?: GraphQLTypes["order_by"],
	deposit?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "rental_costs" */
["rental_costs"]: {
	__typename: "rental_costs",
	costs: number,
	id: GraphQLTypes["uuid"],
	name: string,
	/** An object relationship */
	rent: GraphQLTypes["rental_rent"],
	rent_id: GraphQLTypes["uuid"],
	/** An object relationship */
	rental_costs_type: GraphQLTypes["rental_costs_type"],
	type: GraphQLTypes["rental_costs_type_enum"],
	/** An object relationship */
	unit: GraphQLTypes["unit"],
	unit_id: GraphQLTypes["uuid"],
	vat?: number
};
	/** aggregated selection of "rental_costs" */
["rental_costs_aggregate"]: {
	__typename: "rental_costs_aggregate",
	aggregate?: GraphQLTypes["rental_costs_aggregate_fields"],
	nodes: Array<GraphQLTypes["rental_costs"]>
};
	/** aggregate fields of "rental_costs" */
["rental_costs_aggregate_fields"]: {
	__typename: "rental_costs_aggregate_fields",
	avg?: GraphQLTypes["rental_costs_avg_fields"],
	count: number,
	max?: GraphQLTypes["rental_costs_max_fields"],
	min?: GraphQLTypes["rental_costs_min_fields"],
	stddev?: GraphQLTypes["rental_costs_stddev_fields"],
	stddev_pop?: GraphQLTypes["rental_costs_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["rental_costs_stddev_samp_fields"],
	sum?: GraphQLTypes["rental_costs_sum_fields"],
	var_pop?: GraphQLTypes["rental_costs_var_pop_fields"],
	var_samp?: GraphQLTypes["rental_costs_var_samp_fields"],
	variance?: GraphQLTypes["rental_costs_variance_fields"]
};
	/** order by aggregate values of table "rental_costs" */
["rental_costs_aggregate_order_by"]: {
		avg?: GraphQLTypes["rental_costs_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["rental_costs_max_order_by"],
	min?: GraphQLTypes["rental_costs_min_order_by"],
	stddev?: GraphQLTypes["rental_costs_stddev_order_by"],
	stddev_pop?: GraphQLTypes["rental_costs_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["rental_costs_stddev_samp_order_by"],
	sum?: GraphQLTypes["rental_costs_sum_order_by"],
	var_pop?: GraphQLTypes["rental_costs_var_pop_order_by"],
	var_samp?: GraphQLTypes["rental_costs_var_samp_order_by"],
	variance?: GraphQLTypes["rental_costs_variance_order_by"]
};
	/** input type for inserting array relation for remote table "rental_costs" */
["rental_costs_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["rental_costs_insert_input"]>,
	/** on conflict condition */
	on_conflict?: GraphQLTypes["rental_costs_on_conflict"]
};
	/** aggregate avg on columns */
["rental_costs_avg_fields"]: {
	__typename: "rental_costs_avg_fields",
	costs?: number,
	vat?: number
};
	/** order by avg() on columns of table "rental_costs" */
["rental_costs_avg_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "rental_costs". All fields are combined with a logical 'AND'. */
["rental_costs_bool_exp"]: {
		_and?: Array<GraphQLTypes["rental_costs_bool_exp"]>,
	_not?: GraphQLTypes["rental_costs_bool_exp"],
	_or?: Array<GraphQLTypes["rental_costs_bool_exp"]>,
	costs?: GraphQLTypes["Int_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	rent?: GraphQLTypes["rental_rent_bool_exp"],
	rent_id?: GraphQLTypes["uuid_comparison_exp"],
	rental_costs_type?: GraphQLTypes["rental_costs_type_bool_exp"],
	type?: GraphQLTypes["rental_costs_type_enum_comparison_exp"],
	unit?: GraphQLTypes["unit_bool_exp"],
	unit_id?: GraphQLTypes["uuid_comparison_exp"],
	vat?: GraphQLTypes["Int_comparison_exp"]
};
	/** unique or primary key constraints on table "rental_costs" */
["rental_costs_constraint"]: rental_costs_constraint;
	/** input type for incrementing numeric columns in table "rental_costs" */
["rental_costs_inc_input"]: {
		costs?: number,
	vat?: number
};
	/** input type for inserting data into table "rental_costs" */
["rental_costs_insert_input"]: {
		costs?: number,
	id?: GraphQLTypes["uuid"],
	name?: string,
	rent?: GraphQLTypes["rental_rent_obj_rel_insert_input"],
	rent_id?: GraphQLTypes["uuid"],
	rental_costs_type?: GraphQLTypes["rental_costs_type_obj_rel_insert_input"],
	type?: GraphQLTypes["rental_costs_type_enum"],
	unit?: GraphQLTypes["unit_obj_rel_insert_input"],
	unit_id?: GraphQLTypes["uuid"],
	vat?: number
};
	/** aggregate max on columns */
["rental_costs_max_fields"]: {
	__typename: "rental_costs_max_fields",
	costs?: number,
	id?: GraphQLTypes["uuid"],
	name?: string,
	rent_id?: GraphQLTypes["uuid"],
	unit_id?: GraphQLTypes["uuid"],
	vat?: number
};
	/** order by max() on columns of table "rental_costs" */
["rental_costs_max_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	rent_id?: GraphQLTypes["order_by"],
	unit_id?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["rental_costs_min_fields"]: {
	__typename: "rental_costs_min_fields",
	costs?: number,
	id?: GraphQLTypes["uuid"],
	name?: string,
	rent_id?: GraphQLTypes["uuid"],
	unit_id?: GraphQLTypes["uuid"],
	vat?: number
};
	/** order by min() on columns of table "rental_costs" */
["rental_costs_min_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	rent_id?: GraphQLTypes["order_by"],
	unit_id?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "rental_costs" */
["rental_costs_mutation_response"]: {
	__typename: "rental_costs_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["rental_costs"]>
};
	/** on conflict condition type for table "rental_costs" */
["rental_costs_on_conflict"]: {
		constraint: GraphQLTypes["rental_costs_constraint"],
	update_columns: Array<GraphQLTypes["rental_costs_update_column"]>,
	where?: GraphQLTypes["rental_costs_bool_exp"]
};
	/** Ordering options when selecting data from "rental_costs". */
["rental_costs_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	rent?: GraphQLTypes["rental_rent_order_by"],
	rent_id?: GraphQLTypes["order_by"],
	rental_costs_type?: GraphQLTypes["rental_costs_type_order_by"],
	type?: GraphQLTypes["order_by"],
	unit?: GraphQLTypes["unit_order_by"],
	unit_id?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: rental_costs */
["rental_costs_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "rental_costs" */
["rental_costs_select_column"]: rental_costs_select_column;
	/** input type for updating data in table "rental_costs" */
["rental_costs_set_input"]: {
		costs?: number,
	id?: GraphQLTypes["uuid"],
	name?: string,
	rent_id?: GraphQLTypes["uuid"],
	type?: GraphQLTypes["rental_costs_type_enum"],
	unit_id?: GraphQLTypes["uuid"],
	vat?: number
};
	/** aggregate stddev on columns */
["rental_costs_stddev_fields"]: {
	__typename: "rental_costs_stddev_fields",
	costs?: number,
	vat?: number
};
	/** order by stddev() on columns of table "rental_costs" */
["rental_costs_stddev_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["rental_costs_stddev_pop_fields"]: {
	__typename: "rental_costs_stddev_pop_fields",
	costs?: number,
	vat?: number
};
	/** order by stddev_pop() on columns of table "rental_costs" */
["rental_costs_stddev_pop_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["rental_costs_stddev_samp_fields"]: {
	__typename: "rental_costs_stddev_samp_fields",
	costs?: number,
	vat?: number
};
	/** order by stddev_samp() on columns of table "rental_costs" */
["rental_costs_stddev_samp_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** aggregate sum on columns */
["rental_costs_sum_fields"]: {
	__typename: "rental_costs_sum_fields",
	costs?: number,
	vat?: number
};
	/** order by sum() on columns of table "rental_costs" */
["rental_costs_sum_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "rental_costs_type" */
["rental_costs_type"]: {
	__typename: "rental_costs_type",
	name: string,
	/** An array relationship */
	rental_costs: Array<GraphQLTypes["rental_costs"]>,
	/** An aggregate relationship */
	rental_costs_aggregate: GraphQLTypes["rental_costs_aggregate"]
};
	/** aggregated selection of "rental_costs_type" */
["rental_costs_type_aggregate"]: {
	__typename: "rental_costs_type_aggregate",
	aggregate?: GraphQLTypes["rental_costs_type_aggregate_fields"],
	nodes: Array<GraphQLTypes["rental_costs_type"]>
};
	/** aggregate fields of "rental_costs_type" */
["rental_costs_type_aggregate_fields"]: {
	__typename: "rental_costs_type_aggregate_fields",
	count: number,
	max?: GraphQLTypes["rental_costs_type_max_fields"],
	min?: GraphQLTypes["rental_costs_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "rental_costs_type". All fields are combined with a logical 'AND'. */
["rental_costs_type_bool_exp"]: {
		_and?: Array<GraphQLTypes["rental_costs_type_bool_exp"]>,
	_not?: GraphQLTypes["rental_costs_type_bool_exp"],
	_or?: Array<GraphQLTypes["rental_costs_type_bool_exp"]>,
	name?: GraphQLTypes["String_comparison_exp"],
	rental_costs?: GraphQLTypes["rental_costs_bool_exp"]
};
	/** unique or primary key constraints on table "rental_costs_type" */
["rental_costs_type_constraint"]: rental_costs_type_constraint;
	["rental_costs_type_enum"]: rental_costs_type_enum;
	/** Boolean expression to compare columns of type "rental_costs_type_enum". All fields are combined with logical 'AND'. */
["rental_costs_type_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["rental_costs_type_enum"],
	_in?: Array<GraphQLTypes["rental_costs_type_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["rental_costs_type_enum"],
	_nin?: Array<GraphQLTypes["rental_costs_type_enum"]>
};
	/** input type for inserting data into table "rental_costs_type" */
["rental_costs_type_insert_input"]: {
		name?: string,
	rental_costs?: GraphQLTypes["rental_costs_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["rental_costs_type_max_fields"]: {
	__typename: "rental_costs_type_max_fields",
	name?: string
};
	/** aggregate min on columns */
["rental_costs_type_min_fields"]: {
	__typename: "rental_costs_type_min_fields",
	name?: string
};
	/** response of any mutation on the table "rental_costs_type" */
["rental_costs_type_mutation_response"]: {
	__typename: "rental_costs_type_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["rental_costs_type"]>
};
	/** input type for inserting object relation for remote table "rental_costs_type" */
["rental_costs_type_obj_rel_insert_input"]: {
		data: GraphQLTypes["rental_costs_type_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["rental_costs_type_on_conflict"]
};
	/** on conflict condition type for table "rental_costs_type" */
["rental_costs_type_on_conflict"]: {
		constraint: GraphQLTypes["rental_costs_type_constraint"],
	update_columns: Array<GraphQLTypes["rental_costs_type_update_column"]>,
	where?: GraphQLTypes["rental_costs_type_bool_exp"]
};
	/** Ordering options when selecting data from "rental_costs_type". */
["rental_costs_type_order_by"]: {
		name?: GraphQLTypes["order_by"],
	rental_costs_aggregate?: GraphQLTypes["rental_costs_aggregate_order_by"]
};
	/** primary key columns input for table: rental_costs_type */
["rental_costs_type_pk_columns_input"]: {
		name: string
};
	/** select columns of table "rental_costs_type" */
["rental_costs_type_select_column"]: rental_costs_type_select_column;
	/** input type for updating data in table "rental_costs_type" */
["rental_costs_type_set_input"]: {
		name?: string
};
	/** update columns of table "rental_costs_type" */
["rental_costs_type_update_column"]: rental_costs_type_update_column;
	/** update columns of table "rental_costs" */
["rental_costs_update_column"]: rental_costs_update_column;
	/** aggregate var_pop on columns */
["rental_costs_var_pop_fields"]: {
	__typename: "rental_costs_var_pop_fields",
	costs?: number,
	vat?: number
};
	/** order by var_pop() on columns of table "rental_costs" */
["rental_costs_var_pop_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["rental_costs_var_samp_fields"]: {
	__typename: "rental_costs_var_samp_fields",
	costs?: number,
	vat?: number
};
	/** order by var_samp() on columns of table "rental_costs" */
["rental_costs_var_samp_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["rental_costs_variance_fields"]: {
	__typename: "rental_costs_variance_fields",
	costs?: number,
	vat?: number
};
	/** order by variance() on columns of table "rental_costs" */
["rental_costs_variance_order_by"]: {
		costs?: GraphQLTypes["order_by"],
	vat?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "rental_rent" */
["rental_rent"]: {
	__typename: "rental_rent",
	announced?: GraphQLTypes["timestamptz"],
	begin: GraphQLTypes["timestamptz"],
	id: GraphQLTypes["uuid"],
	/** An object relationship */
	rental_contract: GraphQLTypes["rental_contract"],
	rental_contract_id: GraphQLTypes["uuid"],
	/** An array relationship */
	rental_costs: Array<GraphQLTypes["rental_costs"]>,
	/** An aggregate relationship */
	rental_costs_aggregate: GraphQLTypes["rental_costs_aggregate"]
};
	/** aggregated selection of "rental_rent" */
["rental_rent_aggregate"]: {
	__typename: "rental_rent_aggregate",
	aggregate?: GraphQLTypes["rental_rent_aggregate_fields"],
	nodes: Array<GraphQLTypes["rental_rent"]>
};
	/** aggregate fields of "rental_rent" */
["rental_rent_aggregate_fields"]: {
	__typename: "rental_rent_aggregate_fields",
	count: number,
	max?: GraphQLTypes["rental_rent_max_fields"],
	min?: GraphQLTypes["rental_rent_min_fields"]
};
	/** order by aggregate values of table "rental_rent" */
["rental_rent_aggregate_order_by"]: {
		count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["rental_rent_max_order_by"],
	min?: GraphQLTypes["rental_rent_min_order_by"]
};
	/** input type for inserting array relation for remote table "rental_rent" */
["rental_rent_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["rental_rent_insert_input"]>,
	/** on conflict condition */
	on_conflict?: GraphQLTypes["rental_rent_on_conflict"]
};
	/** Boolean expression to filter rows from the table "rental_rent". All fields are combined with a logical 'AND'. */
["rental_rent_bool_exp"]: {
		_and?: Array<GraphQLTypes["rental_rent_bool_exp"]>,
	_not?: GraphQLTypes["rental_rent_bool_exp"],
	_or?: Array<GraphQLTypes["rental_rent_bool_exp"]>,
	announced?: GraphQLTypes["timestamptz_comparison_exp"],
	begin?: GraphQLTypes["timestamptz_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	rental_contract?: GraphQLTypes["rental_contract_bool_exp"],
	rental_contract_id?: GraphQLTypes["uuid_comparison_exp"],
	rental_costs?: GraphQLTypes["rental_costs_bool_exp"]
};
	/** unique or primary key constraints on table "rental_rent" */
["rental_rent_constraint"]: rental_rent_constraint;
	/** input type for inserting data into table "rental_rent" */
["rental_rent_insert_input"]: {
		announced?: GraphQLTypes["timestamptz"],
	begin?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	rental_contract?: GraphQLTypes["rental_contract_obj_rel_insert_input"],
	rental_contract_id?: GraphQLTypes["uuid"],
	rental_costs?: GraphQLTypes["rental_costs_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["rental_rent_max_fields"]: {
	__typename: "rental_rent_max_fields",
	announced?: GraphQLTypes["timestamptz"],
	begin?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	rental_contract_id?: GraphQLTypes["uuid"]
};
	/** order by max() on columns of table "rental_rent" */
["rental_rent_max_order_by"]: {
		announced?: GraphQLTypes["order_by"],
	begin?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rental_contract_id?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["rental_rent_min_fields"]: {
	__typename: "rental_rent_min_fields",
	announced?: GraphQLTypes["timestamptz"],
	begin?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	rental_contract_id?: GraphQLTypes["uuid"]
};
	/** order by min() on columns of table "rental_rent" */
["rental_rent_min_order_by"]: {
		announced?: GraphQLTypes["order_by"],
	begin?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rental_contract_id?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "rental_rent" */
["rental_rent_mutation_response"]: {
	__typename: "rental_rent_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["rental_rent"]>
};
	/** input type for inserting object relation for remote table "rental_rent" */
["rental_rent_obj_rel_insert_input"]: {
		data: GraphQLTypes["rental_rent_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["rental_rent_on_conflict"]
};
	/** on conflict condition type for table "rental_rent" */
["rental_rent_on_conflict"]: {
		constraint: GraphQLTypes["rental_rent_constraint"],
	update_columns: Array<GraphQLTypes["rental_rent_update_column"]>,
	where?: GraphQLTypes["rental_rent_bool_exp"]
};
	/** Ordering options when selecting data from "rental_rent". */
["rental_rent_order_by"]: {
		announced?: GraphQLTypes["order_by"],
	begin?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	rental_contract?: GraphQLTypes["rental_contract_order_by"],
	rental_contract_id?: GraphQLTypes["order_by"],
	rental_costs_aggregate?: GraphQLTypes["rental_costs_aggregate_order_by"]
};
	/** primary key columns input for table: rental_rent */
["rental_rent_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "rental_rent" */
["rental_rent_select_column"]: rental_rent_select_column;
	/** input type for updating data in table "rental_rent" */
["rental_rent_set_input"]: {
		announced?: GraphQLTypes["timestamptz"],
	begin?: GraphQLTypes["timestamptz"],
	id?: GraphQLTypes["uuid"],
	rental_contract_id?: GraphQLTypes["uuid"]
};
	/** update columns of table "rental_rent" */
["rental_rent_update_column"]: rental_rent_update_column;
	["subscription_root"]: {
	__typename: "subscription_root",
	/** fetch data from the table: "color" */
	color: Array<GraphQLTypes["color"]>,
	/** fetch aggregated fields from the table: "color" */
	color_aggregate: GraphQLTypes["color_aggregate"],
	/** fetch data from the table: "color" using primary key columns */
	color_by_pk?: GraphQLTypes["color"],
	/** fetch data from the table: "country_code" */
	country_code: Array<GraphQLTypes["country_code"]>,
	/** fetch aggregated fields from the table: "country_code" */
	country_code_aggregate: GraphQLTypes["country_code_aggregate"],
	/** fetch data from the table: "country_code" using primary key columns */
	country_code_by_pk?: GraphQLTypes["country_code"],
	/** fetch data from the table: "deposit_rate" */
	deposit_rate: Array<GraphQLTypes["deposit_rate"]>,
	/** fetch aggregated fields from the table: "deposit_rate" */
	deposit_rate_aggregate: GraphQLTypes["deposit_rate_aggregate"],
	/** fetch data from the table: "deposit_rate" using primary key columns */
	deposit_rate_by_pk?: GraphQLTypes["deposit_rate"],
	/** fetch data from the table: "deposit_type" */
	deposit_type: Array<GraphQLTypes["deposit_type"]>,
	/** fetch aggregated fields from the table: "deposit_type" */
	deposit_type_aggregate: GraphQLTypes["deposit_type_aggregate"],
	/** fetch data from the table: "deposit_type" using primary key columns */
	deposit_type_by_pk?: GraphQLTypes["deposit_type"],
	/** fetch data from the table: "group" */
	group: Array<GraphQLTypes["group"]>,
	/** fetch aggregated fields from the table: "group" */
	group_aggregate: GraphQLTypes["group_aggregate"],
	/** fetch data from the table: "group" using primary key columns */
	group_by_pk?: GraphQLTypes["group"],
	/** fetch data from the table: "property" */
	property: Array<GraphQLTypes["property"]>,
	/** fetch aggregated fields from the table: "property" */
	property_aggregate: GraphQLTypes["property_aggregate"],
	/** fetch data from the table: "property" using primary key columns */
	property_by_pk?: GraphQLTypes["property"],
	/** fetch data from the table: "property_type" */
	property_type: Array<GraphQLTypes["property_type"]>,
	/** fetch aggregated fields from the table: "property_type" */
	property_type_aggregate: GraphQLTypes["property_type_aggregate"],
	/** fetch data from the table: "property_type" using primary key columns */
	property_type_by_pk?: GraphQLTypes["property_type"],
	/** fetch data from the table: "rent_index" */
	rent_index: Array<GraphQLTypes["rent_index"]>,
	/** fetch aggregated fields from the table: "rent_index" */
	rent_index_aggregate: GraphQLTypes["rent_index_aggregate"],
	/** fetch data from the table: "rent_index_area" */
	rent_index_area: Array<GraphQLTypes["rent_index_area"]>,
	/** fetch aggregated fields from the table: "rent_index_area" */
	rent_index_area_aggregate: GraphQLTypes["rent_index_area_aggregate"],
	/** fetch data from the table: "rent_index_area" using primary key columns */
	rent_index_area_by_pk?: GraphQLTypes["rent_index_area"],
	/** fetch data from the table: "rent_index" using primary key columns */
	rent_index_by_pk?: GraphQLTypes["rent_index"],
	/** fetch data from the table: "rent_index_layer_type" */
	rent_index_layer_type: Array<GraphQLTypes["rent_index_layer_type"]>,
	/** fetch aggregated fields from the table: "rent_index_layer_type" */
	rent_index_layer_type_aggregate: GraphQLTypes["rent_index_layer_type_aggregate"],
	/** fetch data from the table: "rent_index_layer_type" using primary key columns */
	rent_index_layer_type_by_pk?: GraphQLTypes["rent_index_layer_type"],
	/** fetch data from the table: "rental_contract" */
	rental_contract: Array<GraphQLTypes["rental_contract"]>,
	/** fetch aggregated fields from the table: "rental_contract" */
	rental_contract_aggregate: GraphQLTypes["rental_contract_aggregate"],
	/** fetch data from the table: "rental_contract" using primary key columns */
	rental_contract_by_pk?: GraphQLTypes["rental_contract"],
	/** An array relationship */
	rental_costs: Array<GraphQLTypes["rental_costs"]>,
	/** An aggregate relationship */
	rental_costs_aggregate: GraphQLTypes["rental_costs_aggregate"],
	/** fetch data from the table: "rental_costs" using primary key columns */
	rental_costs_by_pk?: GraphQLTypes["rental_costs"],
	/** fetch data from the table: "rental_costs_type" */
	rental_costs_type: Array<GraphQLTypes["rental_costs_type"]>,
	/** fetch aggregated fields from the table: "rental_costs_type" */
	rental_costs_type_aggregate: GraphQLTypes["rental_costs_type_aggregate"],
	/** fetch data from the table: "rental_costs_type" using primary key columns */
	rental_costs_type_by_pk?: GraphQLTypes["rental_costs_type"],
	/** fetch data from the table: "rental_rent" */
	rental_rent: Array<GraphQLTypes["rental_rent"]>,
	/** fetch aggregated fields from the table: "rental_rent" */
	rental_rent_aggregate: GraphQLTypes["rental_rent_aggregate"],
	/** fetch data from the table: "rental_rent" using primary key columns */
	rental_rent_by_pk?: GraphQLTypes["rental_rent"],
	/** fetch data from the table: "unit" */
	unit: Array<GraphQLTypes["unit"]>,
	/** fetch aggregated fields from the table: "unit" */
	unit_aggregate: GraphQLTypes["unit_aggregate"],
	/** fetch data from the table: "unit" using primary key columns */
	unit_by_pk?: GraphQLTypes["unit"],
	/** fetch data from the table: "unit_type" */
	unit_type: Array<GraphQLTypes["unit_type"]>,
	/** fetch aggregated fields from the table: "unit_type" */
	unit_type_aggregate: GraphQLTypes["unit_type_aggregate"],
	/** fetch data from the table: "unit_type" using primary key columns */
	unit_type_by_pk?: GraphQLTypes["unit_type"],
	/** fetch data from the table: "user" */
	user: Array<GraphQLTypes["user"]>,
	/** fetch aggregated fields from the table: "user" */
	user_aggregate: GraphQLTypes["user_aggregate"],
	/** fetch data from the table: "user" using primary key columns */
	user_by_pk?: GraphQLTypes["user"]
};
	["timestamptz"]:any;
	/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
["timestamptz_comparison_exp"]: {
		_eq?: GraphQLTypes["timestamptz"],
	_gt?: GraphQLTypes["timestamptz"],
	_gte?: GraphQLTypes["timestamptz"],
	_in?: Array<GraphQLTypes["timestamptz"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["timestamptz"],
	_lte?: GraphQLTypes["timestamptz"],
	_neq?: GraphQLTypes["timestamptz"],
	_nin?: Array<GraphQLTypes["timestamptz"]>
};
	/** columns and relationships of "unit" */
["unit"]: {
	__typename: "unit",
	id: GraphQLTypes["uuid"],
	level?: number,
	name?: string,
	/** An object relationship */
	property: GraphQLTypes["property"],
	property_id: GraphQLTypes["uuid"],
	/** An array relationship */
	rental_costs: Array<GraphQLTypes["rental_costs"]>,
	/** An aggregate relationship */
	rental_costs_aggregate: GraphQLTypes["rental_costs_aggregate"],
	rooms?: number,
	size?: number,
	size_heated?: number,
	type?: GraphQLTypes["unit_type_enum"],
	/** An object relationship */
	unit_type?: GraphQLTypes["unit_type"]
};
	/** aggregated selection of "unit" */
["unit_aggregate"]: {
	__typename: "unit_aggregate",
	aggregate?: GraphQLTypes["unit_aggregate_fields"],
	nodes: Array<GraphQLTypes["unit"]>
};
	/** aggregate fields of "unit" */
["unit_aggregate_fields"]: {
	__typename: "unit_aggregate_fields",
	avg?: GraphQLTypes["unit_avg_fields"],
	count: number,
	max?: GraphQLTypes["unit_max_fields"],
	min?: GraphQLTypes["unit_min_fields"],
	stddev?: GraphQLTypes["unit_stddev_fields"],
	stddev_pop?: GraphQLTypes["unit_stddev_pop_fields"],
	stddev_samp?: GraphQLTypes["unit_stddev_samp_fields"],
	sum?: GraphQLTypes["unit_sum_fields"],
	var_pop?: GraphQLTypes["unit_var_pop_fields"],
	var_samp?: GraphQLTypes["unit_var_samp_fields"],
	variance?: GraphQLTypes["unit_variance_fields"]
};
	/** order by aggregate values of table "unit" */
["unit_aggregate_order_by"]: {
		avg?: GraphQLTypes["unit_avg_order_by"],
	count?: GraphQLTypes["order_by"],
	max?: GraphQLTypes["unit_max_order_by"],
	min?: GraphQLTypes["unit_min_order_by"],
	stddev?: GraphQLTypes["unit_stddev_order_by"],
	stddev_pop?: GraphQLTypes["unit_stddev_pop_order_by"],
	stddev_samp?: GraphQLTypes["unit_stddev_samp_order_by"],
	sum?: GraphQLTypes["unit_sum_order_by"],
	var_pop?: GraphQLTypes["unit_var_pop_order_by"],
	var_samp?: GraphQLTypes["unit_var_samp_order_by"],
	variance?: GraphQLTypes["unit_variance_order_by"]
};
	/** input type for inserting array relation for remote table "unit" */
["unit_arr_rel_insert_input"]: {
		data: Array<GraphQLTypes["unit_insert_input"]>,
	/** on conflict condition */
	on_conflict?: GraphQLTypes["unit_on_conflict"]
};
	/** aggregate avg on columns */
["unit_avg_fields"]: {
	__typename: "unit_avg_fields",
	level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by avg() on columns of table "unit" */
["unit_avg_order_by"]: {
		level?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** Boolean expression to filter rows from the table "unit". All fields are combined with a logical 'AND'. */
["unit_bool_exp"]: {
		_and?: Array<GraphQLTypes["unit_bool_exp"]>,
	_not?: GraphQLTypes["unit_bool_exp"],
	_or?: Array<GraphQLTypes["unit_bool_exp"]>,
	id?: GraphQLTypes["uuid_comparison_exp"],
	level?: GraphQLTypes["Float_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	property?: GraphQLTypes["property_bool_exp"],
	property_id?: GraphQLTypes["uuid_comparison_exp"],
	rental_costs?: GraphQLTypes["rental_costs_bool_exp"],
	rooms?: GraphQLTypes["Float_comparison_exp"],
	size?: GraphQLTypes["Float_comparison_exp"],
	size_heated?: GraphQLTypes["Float_comparison_exp"],
	type?: GraphQLTypes["unit_type_enum_comparison_exp"],
	unit_type?: GraphQLTypes["unit_type_bool_exp"]
};
	/** unique or primary key constraints on table "unit" */
["unit_constraint"]: unit_constraint;
	/** input type for incrementing numeric columns in table "unit" */
["unit_inc_input"]: {
		level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** input type for inserting data into table "unit" */
["unit_insert_input"]: {
		id?: GraphQLTypes["uuid"],
	level?: number,
	name?: string,
	property?: GraphQLTypes["property_obj_rel_insert_input"],
	property_id?: GraphQLTypes["uuid"],
	rental_costs?: GraphQLTypes["rental_costs_arr_rel_insert_input"],
	rooms?: number,
	size?: number,
	size_heated?: number,
	type?: GraphQLTypes["unit_type_enum"],
	unit_type?: GraphQLTypes["unit_type_obj_rel_insert_input"]
};
	/** aggregate max on columns */
["unit_max_fields"]: {
	__typename: "unit_max_fields",
	id?: GraphQLTypes["uuid"],
	level?: number,
	name?: string,
	property_id?: GraphQLTypes["uuid"],
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by max() on columns of table "unit" */
["unit_max_order_by"]: {
		id?: GraphQLTypes["order_by"],
	level?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	property_id?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** aggregate min on columns */
["unit_min_fields"]: {
	__typename: "unit_min_fields",
	id?: GraphQLTypes["uuid"],
	level?: number,
	name?: string,
	property_id?: GraphQLTypes["uuid"],
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by min() on columns of table "unit" */
["unit_min_order_by"]: {
		id?: GraphQLTypes["order_by"],
	level?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	property_id?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** response of any mutation on the table "unit" */
["unit_mutation_response"]: {
	__typename: "unit_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["unit"]>
};
	/** input type for inserting object relation for remote table "unit" */
["unit_obj_rel_insert_input"]: {
		data: GraphQLTypes["unit_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["unit_on_conflict"]
};
	/** on conflict condition type for table "unit" */
["unit_on_conflict"]: {
		constraint: GraphQLTypes["unit_constraint"],
	update_columns: Array<GraphQLTypes["unit_update_column"]>,
	where?: GraphQLTypes["unit_bool_exp"]
};
	/** Ordering options when selecting data from "unit". */
["unit_order_by"]: {
		id?: GraphQLTypes["order_by"],
	level?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	property?: GraphQLTypes["property_order_by"],
	property_id?: GraphQLTypes["order_by"],
	rental_costs_aggregate?: GraphQLTypes["rental_costs_aggregate_order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"],
	type?: GraphQLTypes["order_by"],
	unit_type?: GraphQLTypes["unit_type_order_by"]
};
	/** primary key columns input for table: unit */
["unit_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "unit" */
["unit_select_column"]: unit_select_column;
	/** input type for updating data in table "unit" */
["unit_set_input"]: {
		id?: GraphQLTypes["uuid"],
	level?: number,
	name?: string,
	property_id?: GraphQLTypes["uuid"],
	rooms?: number,
	size?: number,
	size_heated?: number,
	type?: GraphQLTypes["unit_type_enum"]
};
	/** aggregate stddev on columns */
["unit_stddev_fields"]: {
	__typename: "unit_stddev_fields",
	level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by stddev() on columns of table "unit" */
["unit_stddev_order_by"]: {
		level?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_pop on columns */
["unit_stddev_pop_fields"]: {
	__typename: "unit_stddev_pop_fields",
	level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by stddev_pop() on columns of table "unit" */
["unit_stddev_pop_order_by"]: {
		level?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** aggregate stddev_samp on columns */
["unit_stddev_samp_fields"]: {
	__typename: "unit_stddev_samp_fields",
	level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by stddev_samp() on columns of table "unit" */
["unit_stddev_samp_order_by"]: {
		level?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** aggregate sum on columns */
["unit_sum_fields"]: {
	__typename: "unit_sum_fields",
	level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by sum() on columns of table "unit" */
["unit_sum_order_by"]: {
		level?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "unit_type" */
["unit_type"]: {
	__typename: "unit_type",
	description?: string,
	name: string,
	/** An array relationship */
	units: Array<GraphQLTypes["unit"]>,
	/** An aggregate relationship */
	units_aggregate: GraphQLTypes["unit_aggregate"]
};
	/** aggregated selection of "unit_type" */
["unit_type_aggregate"]: {
	__typename: "unit_type_aggregate",
	aggregate?: GraphQLTypes["unit_type_aggregate_fields"],
	nodes: Array<GraphQLTypes["unit_type"]>
};
	/** aggregate fields of "unit_type" */
["unit_type_aggregate_fields"]: {
	__typename: "unit_type_aggregate_fields",
	count: number,
	max?: GraphQLTypes["unit_type_max_fields"],
	min?: GraphQLTypes["unit_type_min_fields"]
};
	/** Boolean expression to filter rows from the table "unit_type". All fields are combined with a logical 'AND'. */
["unit_type_bool_exp"]: {
		_and?: Array<GraphQLTypes["unit_type_bool_exp"]>,
	_not?: GraphQLTypes["unit_type_bool_exp"],
	_or?: Array<GraphQLTypes["unit_type_bool_exp"]>,
	description?: GraphQLTypes["String_comparison_exp"],
	name?: GraphQLTypes["String_comparison_exp"],
	units?: GraphQLTypes["unit_bool_exp"]
};
	/** unique or primary key constraints on table "unit_type" */
["unit_type_constraint"]: unit_type_constraint;
	["unit_type_enum"]: unit_type_enum;
	/** Boolean expression to compare columns of type "unit_type_enum". All fields are combined with logical 'AND'. */
["unit_type_enum_comparison_exp"]: {
		_eq?: GraphQLTypes["unit_type_enum"],
	_in?: Array<GraphQLTypes["unit_type_enum"]>,
	_is_null?: boolean,
	_neq?: GraphQLTypes["unit_type_enum"],
	_nin?: Array<GraphQLTypes["unit_type_enum"]>
};
	/** input type for inserting data into table "unit_type" */
["unit_type_insert_input"]: {
		description?: string,
	name?: string,
	units?: GraphQLTypes["unit_arr_rel_insert_input"]
};
	/** aggregate max on columns */
["unit_type_max_fields"]: {
	__typename: "unit_type_max_fields",
	description?: string,
	name?: string
};
	/** aggregate min on columns */
["unit_type_min_fields"]: {
	__typename: "unit_type_min_fields",
	description?: string,
	name?: string
};
	/** response of any mutation on the table "unit_type" */
["unit_type_mutation_response"]: {
	__typename: "unit_type_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["unit_type"]>
};
	/** input type for inserting object relation for remote table "unit_type" */
["unit_type_obj_rel_insert_input"]: {
		data: GraphQLTypes["unit_type_insert_input"],
	/** on conflict condition */
	on_conflict?: GraphQLTypes["unit_type_on_conflict"]
};
	/** on conflict condition type for table "unit_type" */
["unit_type_on_conflict"]: {
		constraint: GraphQLTypes["unit_type_constraint"],
	update_columns: Array<GraphQLTypes["unit_type_update_column"]>,
	where?: GraphQLTypes["unit_type_bool_exp"]
};
	/** Ordering options when selecting data from "unit_type". */
["unit_type_order_by"]: {
		description?: GraphQLTypes["order_by"],
	name?: GraphQLTypes["order_by"],
	units_aggregate?: GraphQLTypes["unit_aggregate_order_by"]
};
	/** primary key columns input for table: unit_type */
["unit_type_pk_columns_input"]: {
		name: string
};
	/** select columns of table "unit_type" */
["unit_type_select_column"]: unit_type_select_column;
	/** input type for updating data in table "unit_type" */
["unit_type_set_input"]: {
		description?: string,
	name?: string
};
	/** update columns of table "unit_type" */
["unit_type_update_column"]: unit_type_update_column;
	/** update columns of table "unit" */
["unit_update_column"]: unit_update_column;
	/** aggregate var_pop on columns */
["unit_var_pop_fields"]: {
	__typename: "unit_var_pop_fields",
	level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by var_pop() on columns of table "unit" */
["unit_var_pop_order_by"]: {
		level?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** aggregate var_samp on columns */
["unit_var_samp_fields"]: {
	__typename: "unit_var_samp_fields",
	level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by var_samp() on columns of table "unit" */
["unit_var_samp_order_by"]: {
		level?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** aggregate variance on columns */
["unit_variance_fields"]: {
	__typename: "unit_variance_fields",
	level?: number,
	rooms?: number,
	size?: number,
	size_heated?: number
};
	/** order by variance() on columns of table "unit" */
["unit_variance_order_by"]: {
		level?: GraphQLTypes["order_by"],
	rooms?: GraphQLTypes["order_by"],
	size?: GraphQLTypes["order_by"],
	size_heated?: GraphQLTypes["order_by"]
};
	/** columns and relationships of "user" */
["user"]: {
	__typename: "user",
	active: boolean,
	email: string,
	id: GraphQLTypes["uuid"],
	password: string
};
	/** aggregated selection of "user" */
["user_aggregate"]: {
	__typename: "user_aggregate",
	aggregate?: GraphQLTypes["user_aggregate_fields"],
	nodes: Array<GraphQLTypes["user"]>
};
	/** aggregate fields of "user" */
["user_aggregate_fields"]: {
	__typename: "user_aggregate_fields",
	count: number,
	max?: GraphQLTypes["user_max_fields"],
	min?: GraphQLTypes["user_min_fields"]
};
	/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
["user_bool_exp"]: {
		_and?: Array<GraphQLTypes["user_bool_exp"]>,
	_not?: GraphQLTypes["user_bool_exp"],
	_or?: Array<GraphQLTypes["user_bool_exp"]>,
	active?: GraphQLTypes["Boolean_comparison_exp"],
	email?: GraphQLTypes["String_comparison_exp"],
	id?: GraphQLTypes["uuid_comparison_exp"],
	password?: GraphQLTypes["String_comparison_exp"]
};
	/** unique or primary key constraints on table "user" */
["user_constraint"]: user_constraint;
	/** input type for inserting data into table "user" */
["user_insert_input"]: {
		active?: boolean,
	email?: string,
	id?: GraphQLTypes["uuid"],
	password?: string
};
	/** aggregate max on columns */
["user_max_fields"]: {
	__typename: "user_max_fields",
	email?: string,
	id?: GraphQLTypes["uuid"],
	password?: string
};
	/** aggregate min on columns */
["user_min_fields"]: {
	__typename: "user_min_fields",
	email?: string,
	id?: GraphQLTypes["uuid"],
	password?: string
};
	/** response of any mutation on the table "user" */
["user_mutation_response"]: {
	__typename: "user_mutation_response",
	/** number of rows affected by the mutation */
	affected_rows: number,
	/** data from the rows affected by the mutation */
	returning: Array<GraphQLTypes["user"]>
};
	/** on conflict condition type for table "user" */
["user_on_conflict"]: {
		constraint: GraphQLTypes["user_constraint"],
	update_columns: Array<GraphQLTypes["user_update_column"]>,
	where?: GraphQLTypes["user_bool_exp"]
};
	/** Ordering options when selecting data from "user". */
["user_order_by"]: {
		active?: GraphQLTypes["order_by"],
	email?: GraphQLTypes["order_by"],
	id?: GraphQLTypes["order_by"],
	password?: GraphQLTypes["order_by"]
};
	/** primary key columns input for table: user */
["user_pk_columns_input"]: {
		id: GraphQLTypes["uuid"]
};
	/** select columns of table "user" */
["user_select_column"]: user_select_column;
	/** input type for updating data in table "user" */
["user_set_input"]: {
		active?: boolean,
	email?: string,
	id?: GraphQLTypes["uuid"],
	password?: string
};
	/** update columns of table "user" */
["user_update_column"]: user_update_column;
	["uuid"]:any;
	/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
["uuid_comparison_exp"]: {
		_eq?: GraphQLTypes["uuid"],
	_gt?: GraphQLTypes["uuid"],
	_gte?: GraphQLTypes["uuid"],
	_in?: Array<GraphQLTypes["uuid"]>,
	_is_null?: boolean,
	_lt?: GraphQLTypes["uuid"],
	_lte?: GraphQLTypes["uuid"],
	_neq?: GraphQLTypes["uuid"],
	_nin?: Array<GraphQLTypes["uuid"]>
}
    }
/** unique or primary key constraints on table "color" */
export const enum color_constraint {
	object_color_type_pkey = "object_color_type_pkey"
}
export const enum color_enum {
	AMBER = "AMBER",
	BLUE = "BLUE",
	CYAN = "CYAN",
	EMERALD = "EMERALD",
	FUCHSIA = "FUCHSIA",
	GRAY = "GRAY",
	GREEN = "GREEN",
	INDIGO = "INDIGO",
	LIME = "LIME",
	ORANGE = "ORANGE",
	PINK = "PINK",
	PURPLE = "PURPLE",
	RED = "RED",
	ROSE = "ROSE",
	SKY = "SKY",
	TEAL = "TEAL",
	VIOLET = "VIOLET",
	YELLOW = "YELLOW"
}
/** select columns of table "color" */
export const enum color_select_column {
	color = "color"
}
/** update columns of table "color" */
export const enum color_update_column {
	color = "color"
}
/** unique or primary key constraints on table "country_code" */
export const enum country_code_constraint {
	country_code_pkey = "country_code_pkey"
}
export const enum country_code_enum {
	AD = "AD",
	AE = "AE",
	AF = "AF",
	AG = "AG",
	AI = "AI",
	AL = "AL",
	AM = "AM",
	AN = "AN",
	AO = "AO",
	AQ = "AQ",
	AR = "AR",
	AS = "AS",
	AT = "AT",
	AU = "AU",
	AW = "AW",
	AZ = "AZ",
	BA = "BA",
	BB = "BB",
	BD = "BD",
	BE = "BE",
	BF = "BF",
	BG = "BG",
	BH = "BH",
	BI = "BI",
	BJ = "BJ",
	BM = "BM",
	BN = "BN",
	BO = "BO",
	BR = "BR",
	BS = "BS",
	BT = "BT",
	BV = "BV",
	BW = "BW",
	BY = "BY",
	BZ = "BZ",
	CA = "CA",
	CC = "CC",
	CD = "CD",
	CF = "CF",
	CG = "CG",
	CH = "CH",
	CI = "CI",
	CK = "CK",
	CL = "CL",
	CM = "CM",
	CN = "CN",
	CO = "CO",
	CR = "CR",
	CU = "CU",
	CV = "CV",
	CX = "CX",
	CY = "CY",
	CZ = "CZ",
	DE = "DE",
	DJ = "DJ",
	DK = "DK",
	DM = "DM",
	DO = "DO",
	DZ = "DZ",
	EC = "EC",
	EE = "EE",
	EG = "EG",
	EH = "EH",
	ER = "ER",
	ES = "ES",
	ET = "ET",
	FI = "FI",
	FJ = "FJ",
	FK = "FK",
	FM = "FM",
	FO = "FO",
	FR = "FR",
	GA = "GA",
	GB = "GB",
	GD = "GD",
	GE = "GE",
	GF = "GF",
	GH = "GH",
	GI = "GI",
	GL = "GL",
	GM = "GM",
	GN = "GN",
	GP = "GP",
	GQ = "GQ",
	GR = "GR",
	GS = "GS",
	GT = "GT",
	GU = "GU",
	GW = "GW",
	GY = "GY",
	HK = "HK",
	HM = "HM",
	HN = "HN",
	HR = "HR",
	HT = "HT",
	HU = "HU",
	ID = "ID",
	IE = "IE",
	IL = "IL",
	IN = "IN",
	IO = "IO",
	IQ = "IQ",
	IR = "IR",
	IS = "IS",
	IT = "IT",
	JM = "JM",
	JO = "JO",
	JP = "JP",
	KE = "KE",
	KG = "KG",
	KH = "KH",
	KI = "KI",
	KM = "KM",
	KN = "KN",
	KP = "KP",
	KR = "KR",
	KW = "KW",
	KY = "KY",
	KZ = "KZ",
	LA = "LA",
	LB = "LB",
	LC = "LC",
	LI = "LI",
	LK = "LK",
	LR = "LR",
	LS = "LS",
	LT = "LT",
	LU = "LU",
	LV = "LV",
	LY = "LY",
	MA = "MA",
	MC = "MC",
	MD = "MD",
	MG = "MG",
	MH = "MH",
	MK = "MK",
	ML = "ML",
	MM = "MM",
	MN = "MN",
	MO = "MO",
	MP = "MP",
	MQ = "MQ",
	MR = "MR",
	MS = "MS",
	MT = "MT",
	MU = "MU",
	MV = "MV",
	MW = "MW",
	MX = "MX",
	MY = "MY",
	MZ = "MZ",
	NA = "NA",
	NC = "NC",
	NE = "NE",
	NF = "NF",
	NG = "NG",
	NI = "NI",
	NL = "NL",
	NO = "NO",
	NP = "NP",
	NR = "NR",
	NU = "NU",
	NZ = "NZ",
	OM = "OM",
	PA = "PA",
	PE = "PE",
	PF = "PF",
	PG = "PG",
	PH = "PH",
	PK = "PK",
	PL = "PL",
	PM = "PM",
	PN = "PN",
	PR = "PR",
	PS = "PS",
	PT = "PT",
	PW = "PW",
	PY = "PY",
	QA = "QA",
	RE = "RE",
	RO = "RO",
	RU = "RU",
	RW = "RW",
	SA = "SA",
	SB = "SB",
	SC = "SC",
	SD = "SD",
	SE = "SE",
	SG = "SG",
	SH = "SH",
	SI = "SI",
	SJ = "SJ",
	SK = "SK",
	SL = "SL",
	SM = "SM",
	SN = "SN",
	SO = "SO",
	SR = "SR",
	ST = "ST",
	SV = "SV",
	SY = "SY",
	SZ = "SZ",
	TC = "TC",
	TD = "TD",
	TF = "TF",
	TG = "TG",
	TH = "TH",
	TJ = "TJ",
	TK = "TK",
	TM = "TM",
	TN = "TN",
	TO = "TO",
	TP = "TP",
	TR = "TR",
	TT = "TT",
	TV = "TV",
	TW = "TW",
	TZ = "TZ",
	UA = "UA",
	UG = "UG",
	UK = "UK",
	US = "US",
	UY = "UY",
	UZ = "UZ",
	VA = "VA",
	VC = "VC",
	VE = "VE",
	VG = "VG",
	VI = "VI",
	VN = "VN",
	VU = "VU",
	WF = "WF",
	WS = "WS",
	YE = "YE",
	YT = "YT",
	YU = "YU",
	ZA = "ZA",
	ZM = "ZM",
	ZW = "ZW"
}
/** select columns of table "country_code" */
export const enum country_code_select_column {
	code = "code",
	country = "country"
}
/** update columns of table "country_code" */
export const enum country_code_update_column {
	code = "code",
	country = "country"
}
/** unique or primary key constraints on table "deposit_rate" */
export const enum deposit_rate_constraint {
	deposit_rate_pkey = "deposit_rate_pkey"
}
export const enum deposit_rate_enum {
	DREIMONATE = "DREIMONATE",
	EINMALIG = "EINMALIG",
	SONSTIGES = "SONSTIGES"
}
/** select columns of table "deposit_rate" */
export const enum deposit_rate_select_column {
	name = "name"
}
/** update columns of table "deposit_rate" */
export const enum deposit_rate_update_column {
	name = "name"
}
/** unique or primary key constraints on table "deposit_type" */
export const enum deposit_type_constraint {
	deposit_type_pkey = "deposit_type_pkey"
}
export const enum deposit_type_enum {
	BAR = "BAR",
	BUERGSCHAFT = "BUERGSCHAFT",
	KEINE = "KEINE",
	SONSTIGES = "SONSTIGES",
	SPARBUCH = "SPARBUCH",
	UEBERWEISUNG = "UEBERWEISUNG"
}
/** select columns of table "deposit_type" */
export const enum deposit_type_select_column {
	name = "name"
}
/** update columns of table "deposit_type" */
export const enum deposit_type_update_column {
	name = "name"
}
/** unique or primary key constraints on table "group" */
export const enum group_constraint {
	object_pkey = "object_pkey"
}
/** select columns of table "group" */
export const enum group_select_column {
	abbreviation = "abbreviation",
	color = "color",
	id = "id",
	name = "name"
}
/** update columns of table "group" */
export const enum group_update_column {
	abbreviation = "abbreviation",
	color = "color",
	id = "id",
	name = "name"
}
/** column ordering options */
export const enum order_by {
	asc = "asc",
	asc_nulls_first = "asc_nulls_first",
	asc_nulls_last = "asc_nulls_last",
	desc = "desc",
	desc_nulls_first = "desc_nulls_first",
	desc_nulls_last = "desc_nulls_last"
}
/** unique or primary key constraints on table "property" */
export const enum property_constraint {
	property_pkey = "property_pkey"
}
/** select columns of table "property" */
export const enum property_select_column {
	city = "city",
	country = "country",
	id = "id",
	lat = "lat",
	lng = "lng",
	name = "name",
	number = "number",
	postal_code = "postal_code",
	region = "region",
	state = "state",
	street = "street",
	type = "type"
}
/** unique or primary key constraints on table "property_type" */
export const enum property_type_constraint {
	property_type_pkey = "property_type_pkey"
}
export const enum property_type_enum {
	BAUERNHAUS = "BAUERNHAUS",
	BUNGALOW = "BUNGALOW",
	BURG_SCHLOSS = "BURG_SCHLOSS",
	DOPPELHAUSHAELFTE = "DOPPELHAUSHAELFTE",
	EINPARTEIENHAUS = "EINPARTEIENHAUS",
	MEHRPARTEIENHAUS = "MEHRPARTEIENHAUS",
	REIHENECKHAUS = "REIHENECKHAUS",
	REIHENMITTELHAUS = "REIHENMITTELHAUS",
	SONSTIGES = "SONSTIGES",
	VILLA = "VILLA"
}
/** select columns of table "property_type" */
export const enum property_type_select_column {
	description = "description",
	name = "name"
}
/** update columns of table "property_type" */
export const enum property_type_update_column {
	description = "description",
	name = "name"
}
/** update columns of table "property" */
export const enum property_update_column {
	city = "city",
	country = "country",
	id = "id",
	lat = "lat",
	lng = "lng",
	name = "name",
	number = "number",
	postal_code = "postal_code",
	region = "region",
	state = "state",
	street = "street",
	type = "type"
}
/** unique or primary key constraints on table "rent_index_area" */
export const enum rent_index_area_constraint {
	rent_index_area_pkey = "rent_index_area_pkey"
}
/** select columns of table "rent_index_area" */
export const enum rent_index_area_select_column {
	id = "id",
	layer_type = "layer_type",
	polygons = "polygons"
}
/** update columns of table "rent_index_area" */
export const enum rent_index_area_update_column {
	id = "id",
	layer_type = "layer_type",
	polygons = "polygons"
}
/** unique or primary key constraints on table "rent_index" */
export const enum rent_index_constraint {
	rent_index_pkey = "rent_index_pkey"
}
/** unique or primary key constraints on table "rent_index_layer_type" */
export const enum rent_index_layer_type_constraint {
	rent_index_layer_type_pkey = "rent_index_layer_type_pkey"
}
export const enum rent_index_layer_type_enum {
	EXCELLENT = "EXCELLENT",
	GOOD = "GOOD",
	INDIVIDUAL1 = "INDIVIDUAL1",
	INDIVIDUAL2 = "INDIVIDUAL2",
	INDIVIDUAL3 = "INDIVIDUAL3",
	NORMAL = "NORMAL",
	POOR = "POOR"
}
/** select columns of table "rent_index_layer_type" */
export const enum rent_index_layer_type_select_column {
	description = "description",
	name = "name"
}
/** update columns of table "rent_index_layer_type" */
export const enum rent_index_layer_type_update_column {
	description = "description",
	name = "name"
}
/** select columns of table "rent_index" */
export const enum rent_index_select_column {
	city = "city",
	data = "data",
	date = "date",
	id = "id",
	postal_code = "postal_code"
}
/** update columns of table "rent_index" */
export const enum rent_index_update_column {
	city = "city",
	data = "data",
	date = "date",
	id = "id",
	postal_code = "postal_code"
}
/** unique or primary key constraints on table "rental_contract" */
export const enum rental_contract_constraint {
	contract_pkey = "contract_pkey"
}
/** select columns of table "rental_contract" */
export const enum rental_contract_select_column {
	begin = "begin",
	cancelation_effective = "cancelation_effective",
	cancelation_period = "cancelation_period",
	cancelation_pronounced = "cancelation_pronounced",
	date = "date",
	deposit = "deposit",
	deposit_payable = "deposit_payable",
	deposit_rate = "deposit_rate",
	deposit_received = "deposit_received",
	deposit_type = "deposit_type",
	end = "end",
	handover_move_in = "handover_move_in",
	handover_move_out = "handover_move_out",
	id = "id",
	min = "min",
	other_agreements = "other_agreements"
}
/** update columns of table "rental_contract" */
export const enum rental_contract_update_column {
	begin = "begin",
	cancelation_effective = "cancelation_effective",
	cancelation_period = "cancelation_period",
	cancelation_pronounced = "cancelation_pronounced",
	date = "date",
	deposit = "deposit",
	deposit_payable = "deposit_payable",
	deposit_rate = "deposit_rate",
	deposit_received = "deposit_received",
	deposit_type = "deposit_type",
	end = "end",
	handover_move_in = "handover_move_in",
	handover_move_out = "handover_move_out",
	id = "id",
	min = "min",
	other_agreements = "other_agreements"
}
/** unique or primary key constraints on table "rental_costs" */
export const enum rental_costs_constraint {
	rental_costs_pkey = "rental_costs_pkey"
}
/** select columns of table "rental_costs" */
export const enum rental_costs_select_column {
	costs = "costs",
	id = "id",
	name = "name",
	rent_id = "rent_id",
	type = "type",
	unit_id = "unit_id",
	vat = "vat"
}
/** unique or primary key constraints on table "rental_costs_type" */
export const enum rental_costs_type_constraint {
	rental_costs_type_pkey = "rental_costs_type_pkey"
}
export const enum rental_costs_type_enum {
	MIETKOSTEN = "MIETKOSTEN",
	NEBENKOSTEN = "NEBENKOSTEN",
	SONSTIGEKOSTEN = "SONSTIGEKOSTEN"
}
/** select columns of table "rental_costs_type" */
export const enum rental_costs_type_select_column {
	name = "name"
}
/** update columns of table "rental_costs_type" */
export const enum rental_costs_type_update_column {
	name = "name"
}
/** update columns of table "rental_costs" */
export const enum rental_costs_update_column {
	costs = "costs",
	id = "id",
	name = "name",
	rent_id = "rent_id",
	type = "type",
	unit_id = "unit_id",
	vat = "vat"
}
/** unique or primary key constraints on table "rental_rent" */
export const enum rental_rent_constraint {
	rental_rent_pkey = "rental_rent_pkey"
}
/** select columns of table "rental_rent" */
export const enum rental_rent_select_column {
	announced = "announced",
	begin = "begin",
	id = "id",
	rental_contract_id = "rental_contract_id"
}
/** update columns of table "rental_rent" */
export const enum rental_rent_update_column {
	announced = "announced",
	begin = "begin",
	id = "id",
	rental_contract_id = "rental_contract_id"
}
/** unique or primary key constraints on table "unit" */
export const enum unit_constraint {
	unit_pkey = "unit_pkey"
}
/** select columns of table "unit" */
export const enum unit_select_column {
	id = "id",
	level = "level",
	name = "name",
	property_id = "property_id",
	rooms = "rooms",
	size = "size",
	size_heated = "size_heated",
	type = "type"
}
/** unique or primary key constraints on table "unit_type" */
export const enum unit_type_constraint {
	unit_type_pkey = "unit_type_pkey"
}
export const enum unit_type_enum {
	BOOTSLIEGEPLATZ = "BOOTSLIEGEPLATZ",
	BUERO = "BUERO",
	CARPORT = "CARPORT",
	DACHBODEN = "DACHBODEN",
	DACHGESCHOSSWOHNUNG = "DACHGESCHOSSWOHNUNG",
	DOPPELGARAGE = "DOPPELGARAGE",
	DUPLEX = "DUPLEX",
	EINZELHANDEL = "EINZELHANDEL",
	ERDGESCHOSSWOHNUNG = "ERDGESCHOSSWOHNUNG",
	ETAGENWOHNUNG = "ETAGENWOHNUNG",
	GARAGE = "GARAGE",
	GARTEN = "GARTEN",
	GASTRONOMIE = "GASTRONOMIE",
	GEWERBEGRUNDSTUECK = "GEWERBEGRUNDSTUECK",
	HALLE = "HALLE",
	HOCHPARTERRE = "HOCHPARTERRE",
	HOTEL = "HOTEL",
	KELLER = "KELLER",
	LOFT = "LOFT",
	MAISONETTE = "MAISONETTE",
	PARKHAUS = "PARKHAUS",
	PARKPLATZLADESTATION = "PARKPLATZLADESTATION",
	PENTHOUSE = "PENTHOUSE",
	PRAXIS = "PRAXIS",
	PRODUKTION = "PRODUKTION",
	SONSTIGES = "SONSTIGES",
	SONSTIGE_GEWERBEEINHEIT = "SONSTIGE_GEWERBEEINHEIT",
	SONSTIGE_STELLPLATZ_GARAGE = "SONSTIGE_STELLPLATZ_GARAGE",
	SONSTIGE_WOHNEINHEIT = "SONSTIGE_WOHNEINHEIT",
	SOUTERRAIN = "SOUTERRAIN",
	SPEZIALGEWERBE = "SPEZIALGEWERBE",
	STELLPLATZ = "STELLPLATZ",
	TERASSENWOHNUNG = "TERASSENWOHNUNG",
	TIEFGARAGENSTELLPLATZ = "TIEFGARAGENSTELLPLATZ"
}
/** select columns of table "unit_type" */
export const enum unit_type_select_column {
	description = "description",
	name = "name"
}
/** update columns of table "unit_type" */
export const enum unit_type_update_column {
	description = "description",
	name = "name"
}
/** update columns of table "unit" */
export const enum unit_update_column {
	id = "id",
	level = "level",
	name = "name",
	property_id = "property_id",
	rooms = "rooms",
	size = "size",
	size_heated = "size_heated",
	type = "type"
}
/** unique or primary key constraints on table "user" */
export const enum user_constraint {
	user_pkey = "user_pkey",
	user_username_key = "user_username_key"
}
/** select columns of table "user" */
export const enum user_select_column {
	active = "active",
	email = "email",
	id = "id",
	password = "password"
}
/** update columns of table "user" */
export const enum user_update_column {
	active = "active",
	email = "email",
	id = "id",
	password = "password"
}
export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }


export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type WithTypeNameValue<T> = T & {
  __typename?: boolean;
};
type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
type IsArray<T, U> = T extends Array<infer R> ? InputType<R, U>[] : InputType<T, U>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;

type IsInterfaced<SRC extends DeepAnify<DST>, DST> = FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P]>
          : {}
        : never;
    }[keyof DST] &
      {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
      }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends boolean ? SRC[P] : IsArray<SRC[P], DST[P]>;
    };

export type MapType<SRC, DST> = SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST> : never;
export type InputType<SRC, DST> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P]>;
    } &
      MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>>
  : MapType<SRC, IsPayLoad<DST>>;
type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;
export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;
export type OperationOptions = {
  variables?: Record<string, any>;
  operationName?: string;
};
export type SubscriptionToGraphQL<Z, T> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z>; errors?: string[] }) => void) => void;
  open: () => void;
};
export type SelectionFunction<V> = <T>(t: T | V) => T;
export type fetchOptions = ArgsType<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (
  ...args: infer R
) => WebSocket
  ? R
  : never;
export type chainOptions =
  | [fetchOptions[0], fetchOptions[1] & {websocket?: websocketOptions}]
  | [fetchOptions[0]];
export type FetchFunction = (
  query: string,
  variables?: Record<string, any>,
) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .forEach((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).forEach((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


export const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string, operationName?: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${operationName ? ' ' + operationName : ''}${inspectVariables(buildQuery(tName, o))}`;
  

export const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  options?: OperationOptions,
) => fn(queryConstruct(t, tName, options?.operationName)(o), options?.variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


export const fullSubscriptionConstruct = (fn: SubscriptionFunction) => (
  t: 'query' | 'mutation' | 'subscription',
  tName: string,
) => (o: Record<any, any>, options?: OperationOptions) =>
  fn(queryConstruct(t, tName, options?.operationName)(o));


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


export const resolverFor = <
  X,
  T extends keyof ValueTypes,
  Z extends keyof ValueTypes[T],
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ValueTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any,source?: any) => any;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

export const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  

export const apiSubscription = (options: chainOptions) => (
    query: string,
  ) => {
    try {
      const queryString = options[0] + '?query=' + encodeURIComponent(query);
      const wsString = queryString.replace('http', 'ws');
      const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
      const webSocketOptions = options[1]?.websocket || [host];
      const ws = new WebSocket(...webSocketOptions);
      return {
        ws,
        on: (e: (args: any) => void) => {
          ws.onmessage = (event:any) => {
            if(event.data){
              const parsed = JSON.parse(event.data)
              const data = parsed.data
              if (data) {
                seekForAliases(data);
              }
              return e(data);
            }
          };
        },
        off: (e: (args: any) => void) => {
          ws.onclose = e;
        },
        error: (e: (args: any) => void) => {
          ws.onerror = e;
        },
        open: (e: () => void) => {
          ws.onopen = e;
        },
      };
    } catch {
      throw new Error('No websockets implemented');
    }
  };



const allOperations = {
    "query": "query_root",
    "mutation": "mutation_root",
    "subscription": "subscription_root"
}

export type GenericOperation<O> = O extends 'query'
  ? "query_root"
  : O extends 'mutation'
  ? "mutation_root"
  : "subscription_root"

export const Thunder = (fn: FetchFunction) => <
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions) =>
  fullChainConstruct(fn)(operation, allOperations[operation])(o as any, ops) as Promise<InputType<GraphQLTypes[R], Z>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));  
  
export const SubscriptionThunder = (fn: SubscriptionFunction) => <
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
) => <Z extends ValueTypes[R]>(
  o: Z | ValueTypes[R],
  ops?: OperationOptions
)=>
  fullSubscriptionConstruct(fn)(operation, allOperations[operation])(
    o as any,
    ops,
  ) as SubscriptionToGraphQL<Z, GraphQLTypes[R]>;

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends 'query' | 'mutation' | 'subscription',
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  operationName?: string,
) => queryConstruct(operation, allOperations[operation], operationName)(o as any);
export const Selector = <T extends keyof ValueTypes>(key: T) => ZeusSelect<ValueTypes[T]>();
  

export const Gql = Chain('https://vilicando.herokuapp.com/v1/graphql')