import { useEffect, useRef } from 'react';

export function usePrevious<T extends unknown>(value: T) {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}
