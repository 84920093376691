import { ComponentProps, Ref, forwardRef } from 'react';
import { Select } from '@vechaiui/forms';

// // die Arten der Gebäude => hier nur Gebäudetypen unabhängig von Einsatz (also MehrPARTEIENhaus statt MehrFAMILIENhaus)
export const PROPERTY_TYPES = {
  EINPARTEIENHAUS: 'Einparteienhaus',
  MEHRPARTEIENHAUS: 'Mehrparteienhaus',
  REIHENMITTELHAUS: 'Reihenmittelhaus',
  REIHENECKHAUS: 'Reiheneckhaus',
  BUNGALOW: 'Bungalow',
  BAUERNHAUS: 'Bauernhaus',
  DOPPELHAUSHAELFTE: 'Doppelhaushälfte',
  VILLA: 'Villa',
  BURG_SCHLOSS: 'Burg/Schloss',
  SONSTIGES: 'Sonstiges',
};

type PropertyTypeEditType = ComponentProps<typeof Select>;

export const PropertyTypeEdit = forwardRef(
  (props: PropertyTypeEditType, ref: Ref<HTMLSelectElement>) => {
    return (
      <Select ref={ref} {...props}>
        {(
          Object.keys(PROPERTY_TYPES) as Array<keyof typeof PROPERTY_TYPES>
        ).map((key) => (
          <option key={key} value={key}>
            {PROPERTY_TYPES[key]}
          </option>
        ))}
      </Select>
    );
  },
);
