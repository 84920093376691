import { Button, Icon, IconButton, Spinner, cx } from '@vechaiui/react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, PropsWithChildren, useState } from 'react';
import { PencilIcon, XIcon } from '@heroicons/react/outline';
import { SidebarIcon } from '../icons';
import { Tabs } from '../components';

type PageType = PropsWithChildren<{
  title?: string;
  actions?: React.ReactNode;
  sidebar?: React.ReactNode;
  loading?: boolean;
}>;

export function Page({ children, title, actions, sidebar, loading }: PageType) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  return (
    <>
      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <h2 className="text-lg font-medium text-neutral-900">
                  Filters
                </h2>
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-neutral-400"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4 p-4 border-t border-neutral-200">
                {sidebar}
              </form>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <main className="flex-grow flex flex-col">
        {!!title && (
          <div className="flex items-center justify-between bg-neutral-200 h-14 px-4 sm:px-6 lg:px-8">
            <h1 className="text-xl leading-6 font-semibold text-primary-700 inline-flex items-center">
              {title} <PencilIcon className="h-4 w-4 ml-2" />
            </h1>

            <Tabs items={[{ label: 'Test 1' }, { label: 'Test 2' }]} />

            <div className="h-8 flex items-center space-x-4">
              {actions}

              {!!sidebar && (
                <>
                  <Button
                    variant="solid"
                    onClick={() => setShowSidebar(!showSidebar)}
                    className="hidden lg:block static"
                  >
                    Filter
                  </Button>
                  <IconButton
                    variant="solid"
                    onClick={() => setMobileFiltersOpen(true)}
                    className="lg:hidden static"
                  >
                    <Icon as={SidebarIcon} label="gift" className="w-4 h-4" />
                  </IconButton>
                </>
              )}
            </div>
          </div>
        )}

        <section className="flex-grow flex">
          {/* Filters */}
          <Transition.Root
            show={showSidebar}
            className="relative overflow-x-hidden flex-shrink-0 hidden lg:block bg-white shadow z-10"
          >
            <Transition.Child
              enter="ease-in-out duration-500 sm:duration-700"
              enterFrom="w-0"
              enterTo="w-64"
              leave="ease-in-out duration-500 sm:duration-700"
              leaveFrom="w-64"
              leaveTo="w-0"
            >
              <div className="w-64" />
            </Transition.Child>

            <Transition.Child
              enter="ease-in-out duration-500 sm:duration-700"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500 sm:duration-700"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="absolute right-0 top-0 bottom-0 w-64 py-2 px-4 sm:px-6 lg:px-8"
            >
              <h2 className="py-2 mb-3 text-left text-xs font-medium text-neutral-600 uppercase tracking-wider">
                Filter
              </h2>
              {sidebar}
            </Transition.Child>
          </Transition.Root>

          {/* Product grid */}
          <div
            className={cx(
              'relative w-full px-4 sm:px-6 lg:px-8 py-6 bg-white shadow',
              loading ? 'flex items-center justify-center' : '',
            )}
          >
            {loading && <Spinner size="lg" className="text-primary-500" />}

            <Transition
              show={!loading}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              {children}
            </Transition>
          </div>
        </section>
      </main>
    </>
  );
}
