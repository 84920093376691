import {
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  XIcon,
} from '@heroicons/react/outline';

export type IconType = {
  status?: 'info' | 'success' | 'error' | 'warning';
};

export function Icon({ status }: IconType) {
  return (() => {
    switch (status) {
      case 'info':
        return (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationCircleIcon
              className="h-6 w-6 text-blue-600"
              aria-hidden="true"
            />
          </div>
        );
      case 'success':
        return (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-success-100 sm:mx-0 sm:h-10 sm:w-10">
            <CheckIcon
              className="h-6 w-6 text-success-600"
              aria-hidden="true"
            />
          </div>
        );
      case 'error':
        return (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-error-100 sm:mx-0 sm:h-10 sm:w-10">
            <XIcon className="h-6 w-6 text-error-600" aria-hidden="true" />
          </div>
        );
      case 'warning':
        return (
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-warning-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon
              className="h-6 w-6 text-warning-600"
              aria-hidden="true"
            />
          </div>
        );
      default:
        return null;
    }
  })();
}
