import { Transition } from '@headlessui/react';

export type OverlayType = {
  onClose?: () => void;
};

export function Overlay({ onClose }: OverlayType) {
  return (
    <Transition.Child
      className="absolute inset-0 bg-black bg-opacity-60 transition-opacity"
      aria-hidden="true"
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      onClick={onClose}
    />
  );
}
