import { Dialog } from '@headlessui/react';
import { PropsWithChildren } from 'react';
import { XIcon } from '@heroicons/react/outline';

export type DrawerHeaderType = PropsWithChildren<{
  title?: string;
  onClose?: () => void;
}>;

export function DrawerHeader({ children, title, onClose }: DrawerHeaderType) {
  return (
    <div className="py-6 px-4 bg-primary-700 sm:px-6">
      {!!title && (
        <div className="flex items-center justify-between">
          <Dialog.Title className="text-lg font-medium text-white">
            {title}
          </Dialog.Title>
          <div className="ml-3 h-7 flex items-center">
            <button
              type="button"
              className="bg-primary-700 rounded-md text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
              onClick={onClose}
            >
              <span className="sr-only">Schließen</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
      {!!children && (
        <div className="mt-1">
          <p className="text-sm text-primary-300">{children}</p>
        </div>
      )}
    </div>
  );
}
