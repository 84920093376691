import { ComponentProps } from 'react';
import { RadioGroup as DropdownRadioGroup } from '@radix-ui/react-dropdown-menu';
import { RadioGroup } from '@radix-ui/react-context-menu';
import { useHybridMenuContext } from './provider';

export type HybridMenuRadioGroupProps = ComponentProps<typeof RadioGroup> &
  ComponentProps<typeof DropdownRadioGroup>;

export const HybridMenuRadioGroup = (props: HybridMenuRadioGroupProps) => {
  const { asContext } = useHybridMenuContext();
  const RadioGroupComponent = asContext ? RadioGroup : DropdownRadioGroup;

  return <RadioGroupComponent {...props} />;
};
