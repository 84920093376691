import { ComponentProps, Ref, forwardRef } from 'react';
import { DotIcon } from '../../icons';
import { RadioItem as DropdownRadioItem } from '@radix-ui/react-dropdown-menu';
import { HybridMenuItemIndicator } from './item-indicator';
import { RadioItem } from '@radix-ui/react-context-menu';
import { cx } from '@vechaiui/react';
import { useHybridMenuContext } from './provider';

export type HybridMenuRadioItemType = ComponentProps<typeof RadioItem>;

export const HybridMenuRadioItem = forwardRef(
  (
    {
      className,
      disabled,
      onSelect,
      children,
      ...rest
    }: HybridMenuRadioItemType,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { asContext } = useHybridMenuContext();
    const RadioItemComponent = asContext ? RadioItem : DropdownRadioItem;

    return (
      <RadioItemComponent
        ref={ref}
        disabled={disabled}
        onSelect={(e) => {
          e.preventDefault();

          if (onSelect) onSelect(e);
        }}
        className={cx(
          'flex items-center w-full px-3 h-8 flex-shrink-0 text-sm text-left cursor-base focus:outline-none',
          'focus:bg-neutral-100 dark:focus:bg-neutral-700',
          disabled ? 'opacity-50' : '',
          className,
        )}
        {...rest}
      >
        {children}

        <span className="flex-grow" />

        <HybridMenuItemIndicator>
          <DotIcon className="h-2 px-0.5 w-auto text-primary-600" />
        </HybridMenuItemIndicator>
      </RadioItemComponent>
    );
  },
);
