import { ChevronDownIcon } from '@heroicons/react/outline';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Fragment, Ref, forwardRef, useMemo } from 'react';
import { cx } from '@vechaiui/react';

export type TreeSelectEditType = {
  value?: string | number;
  onChange?: (value: string | number) => void;
  placeholder?: string;
  items?: Array<{
    label: string;
    // icon?: any;
    items: Record<string | number, string | undefined>;
  }>;
};

export const TreeSelectEdit = forwardRef(
  (
    { items, value, onChange, placeholder = 'Wählen' }: TreeSelectEditType,
    ref: Ref<HTMLButtonElement>,
  ) => {
    const activeCategory = useMemo(
      () => items?.findIndex((item) => value && value in item.items),
      [items, value],
    );
    const activeItem = useMemo(
      () =>
        activeCategory !== undefined && value
          ? items?.[activeCategory]?.items[value]
          : undefined,
      [activeCategory, value],
    );

    return (
      <Menu as="div" className="relative inline-block text-left w-full">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                ref={ref}
                className="inline-flex justify-between items-center w-full h-8 rounded-md border border-neutral-300 shadow-sm px-3 bg-white text-sm text-neutral-700 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-100 focus:ring-primary-500"
              >
                {activeItem || placeholder}
                <ChevronDownIcon
                  aria-hidden="true"
                  className={cx(
                    '-mr-1 ml-2 w-4 h-4',
                    !open ? 'transform rotate-180 text-primary-500' : '',
                  )}
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-left absolute z-10 left-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-neutral-100 focus:outline-none">
                {items?.map((item, i) => (
                  <Disclosure key={i} defaultOpen={activeCategory === i}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={cx(
                            open
                              ? 'bg-neutral-100 text-neutral-800'
                              : 'text-neutral-600',
                            'group flex items-center justify-between px-4 py-2 text-sm w-full',
                          )}
                        >
                          <span className="inline-flex font-medium tracking-wide uppercase">
                            {item.label}
                          </span>

                          <ChevronDownIcon
                            className={cx(
                              'w-4 h-4',
                              !open
                                ? 'transform rotate-180 text-primary-500'
                                : '',
                            )}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="py-1">
                          {Object.keys(item.items).map((key) => (
                            <Menu.Item
                              key={key}
                              onClick={() => {
                                if (onChange) onChange(key);
                              }}
                            >
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={cx(
                                    active
                                      ? 'bg-neutral-100 text-neutral-900'
                                      : 'text-neutral-700',
                                    'block px-4 py-2 text-sm',
                                  )}
                                >
                                  {item.items[key]}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    );
  },
);
