import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
} from '@vechaiui/react';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSession } from '../graphql';

export function Login() {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const { login } = useSession();
  const onSubmit = useCallback(async ({ email, password }) => {
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_LAMBDA_HOST}/${process.env.REACT_APP_LAMBDA_PATH}/${process.env.REACT_APP_LAMBDA_AUTH_ENDPOINT}`,
        {
          method: 'POST',
          headers: { 'Content-type': 'application/json' },
          body: JSON.stringify({ email, password }),
        },
      );
      const { errors, ...user } = await response.json();

      if (errors) throw Error('Invalid login!');

      if (user) {
        await login(user.token);
        setLoading(false);
      }
    } catch (error) {
      setError('email', {
        type: 'value',
        message:
          'Benutzer nicht gefunden, nicht aktiv oder ungültiges Passwort!',
      });
      console.error(error);
      setLoading(false);
    }
  }, []);

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <h2 className="text-3xl font-extrabold text-neutral-900">
            Anmeldung
          </h2>

          <div className="mt-8">
            <div>
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <FormControl
                  required
                  disabled={!!loading}
                  invalid={!!errors.email}
                >
                  <FormLabel htmlFor="email">Email-Adresse</FormLabel>
                  <Input
                    {...register('email', {
                      required: 'Email-Adresse darf nicht leer sein',
                    })}
                    id="email"
                    type="email"
                    autoComplete="email"
                    placeholder="happy_people@vilicando.com"
                    size="lg"
                    variant="solid"
                  />
                  {!!errors.email && (
                    <FormHelperText className="text-error-600">
                      {errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  required
                  disabled={!!loading}
                  invalid={!!errors.password}
                >
                  <FormLabel htmlFor="password">Passwort</FormLabel>
                  <Input
                    {...register('password', {
                      required: 'Passwort darf nicht leer sein',
                      minLength: {
                        value: 5,
                        message: 'Passwort muss mindestens 5 Zeichen haben',
                      },
                    })}
                    id="password"
                    type="password"
                    autoComplete="password"
                    placeholder="*****"
                    size="lg"
                    variant="solid"
                  />
                  {!!errors.password && (
                    <FormHelperText className="text-error-600">
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <div className="flex items-center justify-between text-sm">
                  <Checkbox disabled checked>
                    Anmeldedaten speichern
                  </Checkbox>
                  <Link disabled>Passwort vergessen?</Link>
                </div>

                <Button.Group className="space-x-2">
                  <Button
                    type="submit"
                    disabled={!!loading}
                    loading={!!loading}
                    variant="solid"
                    color="primary"
                  >
                    Anmelden
                  </Button>

                  <Button disabled color="primary">
                    Registrieren
                  </Button>
                </Button.Group>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
