import { Ref, forwardRef, useMemo } from 'react';
import { TreeSelectEdit, TreeSelectEditType } from '.';
import { useTypedQuery } from '../../graphql';

type PropertyPickerType = Omit<TreeSelectEditType, 'items'>;

export const PropertyPicker = forwardRef(
  (props: PropertyPickerType, ref: Ref<HTMLButtonElement>) => {
    const { data } = useTypedQuery({
      property: [
        {},
        {
          id: true,
          name: true,
          type: true,
          street: true,
          number: true,
          city: true,
        },
      ],
    });

    const items = useMemo(() => {
      const cities: {
        [city: string]: { [key: string]: string };
      } = {};

      data?.property.forEach((property) => {
        if (!cities[property.city]) cities[property.city] = {};

        cities[property.city][property.id] =
          property.name || `${property.street} ${property.number}`;
      });

      return Object.keys(cities).map((label) => ({
        label,
        items: cities[label],
      }));
    }, [data?.property]);

    return <TreeSelectEdit ref={ref} items={items} {...props} />;
  },
);
