import { Icon, IconProps, cx } from '@vechaiui/react';

type HybridMenuIconProps = IconProps & {
  variant?: 'prefix' | 'suffix';
};

export function HybridMenuIcon({
  variant = 'prefix',
  ...rest
}: HybridMenuIconProps) {
  return (
    <Icon
      className={cx('w-4 h-4', variant === 'prefix' ? 'mr-3' : 'ml-3')}
      {...rest}
    />
  );
}
