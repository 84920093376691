import { ComponentProps, Ref, forwardRef } from 'react';
import { Select } from '@vechaiui/forms';

export const DEPOSIT_RATES = {
  EINMALIG: 'Einmalig',
  DREIMONATE: '3 Monatsrate',
  SONSTIGES: 'Sonstiges',
};

type DepositRateEditType = ComponentProps<typeof Select>;

export const DepositRateEdit = forwardRef(
  (props: DepositRateEditType, ref: Ref<HTMLSelectElement>) => {
    return (
      <Select ref={ref} {...props}>
        {(Object.keys(DEPOSIT_RATES) as Array<keyof typeof DEPOSIT_RATES>).map(
          (key) => (
            <option key={key} value={key}>
              {DEPOSIT_RATES[key]}
            </option>
          ),
        )}
      </Select>
    );
  },
);
