import { ComponentProps } from 'react';
import { Group as DropdownGroup } from '@radix-ui/react-dropdown-menu';
import { Group } from '@radix-ui/react-context-menu';
import { useHybridMenuContext } from './provider';

export type HybridMenuGroupProps = ComponentProps<typeof Group> &
  ComponentProps<typeof DropdownGroup>;

export const HybridMenuGroup = (props: HybridMenuGroupProps) => {
  const { asContext } = useHybridMenuContext();
  const GroupComponent = asContext ? Group : DropdownGroup;

  return <GroupComponent {...props} />;
};
