import { ForwardRefExoticComponent, ReactNode, Ref, forwardRef } from 'react';
import { HybridMenuCheckboxItem } from './checkbox-item';
import { HybridMenuContent } from './content';
import { HybridMenuGroup } from './group';
import { HybridMenuIcon } from './icon';
import { HybridMenuItem } from './item';
import { HybridMenuLabel } from './label';
import { HybridMenuProvider } from './provider';
import { HybridMenuRadioGroup } from './radio-group';
import { HybridMenuRadioItem } from './radio-item';
import { HybridMenuRoot } from './root';
import { HybridMenuSeparator } from './separator';
import { HybridMenuSortableGroup } from './sortable-group';
import { HybridMenuSortableItem } from './sortable-item';
import {
  HybridMenuTrigger,
  HybridMenuTriggerProps,
  HybridMenuTriggerType,
} from './trigger';
import { HybridMenuTriggerItem } from './trigger-item';
import { cx } from '@vechaiui/react';

export type HybridMenuProps = HybridMenuTriggerProps & {
  asContext?: boolean;
  asSubMenu?: boolean;
  items: ReactNode;
};
type HybridMenuType = ForwardRefExoticComponent<HybridMenuProps> & {
  CheckboxItem: typeof HybridMenuCheckboxItem;
  Group: typeof HybridMenuGroup;
  Icon: typeof HybridMenuIcon;
  Item: typeof HybridMenuItem;
  Label: typeof HybridMenuLabel;
  RadioGroup: typeof HybridMenuRadioGroup;
  RadioItem: typeof HybridMenuRadioItem;
  Separator: typeof HybridMenuSeparator;
  SortableGroup: typeof HybridMenuSortableGroup;
  SortableItem: typeof HybridMenuSortableItem;
};

export const _HybridMenu = forwardRef(
  (
    { asContext, asSubMenu, items, ...rest }: HybridMenuProps,
    ref: Ref<HybridMenuTriggerType>,
  ) => {
    const content = (
      <HybridMenuRoot>
        {asSubMenu ? (
          <HybridMenuTriggerItem {...rest} ref={ref} />
        ) : (
          <HybridMenuTrigger {...rest} ref={ref} />
        )}

        <HybridMenuContent
          className={cx(
            'z-40 w-56 min-w-max py-1 rounded-md shadow-sm outline-none',
            'bg-white border border-neutral-200',
            'dark:bg-neutral-800 dark:border-neutral-700',
          )}
          alignOffset={-5}
        >
          {items}
        </HybridMenuContent>
      </HybridMenuRoot>
    );

    return asSubMenu ? (
      content
    ) : (
      <HybridMenuProvider asContext={!!asContext}>{content}</HybridMenuProvider>
    );
  },
) as HybridMenuType;

_HybridMenu.CheckboxItem = HybridMenuCheckboxItem;
_HybridMenu.Group = HybridMenuGroup;
_HybridMenu.Icon = HybridMenuIcon;
_HybridMenu.Item = HybridMenuItem;
_HybridMenu.Label = HybridMenuLabel;
_HybridMenu.RadioGroup = HybridMenuRadioGroup;
_HybridMenu.RadioItem = HybridMenuRadioItem;
_HybridMenu.Separator = HybridMenuSeparator;
_HybridMenu.SortableGroup = HybridMenuSortableGroup;
_HybridMenu.SortableItem = HybridMenuSortableItem;

export const HybridMenu = _HybridMenu;
