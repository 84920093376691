import { ComponentProps } from 'react';
import { Separator as DropdownSeparator } from '@radix-ui/react-dropdown-menu';
import { Separator } from '@radix-ui/react-context-menu';
import { cx } from '@vechaiui/react';
import { useHybridMenuContext } from './provider';

export type HybridMenuSeparatorType = ComponentProps<typeof Separator>;

export const HybridMenuSeparator = ({
  className,
  ...rest
}: HybridMenuSeparatorType) => {
  const { asContext } = useHybridMenuContext();
  const SeparatorComponent = asContext ? Separator : DropdownSeparator;

  return (
    <SeparatorComponent
      className={cx('h-px my-1 bg-neutral-200 dark:bg-neutral-700', className)}
      {...rest}
    />
  );
};
