import { CirclePicker, CirclePickerProps } from 'react-color';
import { Ref, forwardRef, useCallback } from 'react';
import tw_colors from 'tailwindcss/colors';

const COLORS = {
  RED: tw_colors.red[600], // '#DC2626',
  ORANGE: tw_colors.orange[600], // '#EA580C',
  AMBER: tw_colors.amber[600], // '#D97706',
  YELLOW: tw_colors.yellow[600], // '#CA8A04',
  LIME: tw_colors.lime[600], // '#65A30D',
  GREEN: tw_colors.green[600], // '#16A34A',
  EMERAL: tw_colors.emerald[600], // '#059669',
  TEAL: tw_colors.teal[600], // '#0D9488',
  CYAN: tw_colors.cyan[600], // '#0891B2',
  SKY: tw_colors.sky[600], // '#0284C7',
  BLUE: tw_colors.blue[600], // '#2563EB',
  INDIGO: tw_colors.indigo[600], // '#4F46E5',
  VIOLET: tw_colors.violet[600], // '#7C3AED',
  PURPLE: tw_colors.purple[600], // '#9333EA',
  FUCHSIE: tw_colors.fuchsia[600], // '#C026D3',
  PINK: tw_colors.pink[600], // '#DB2777',
  ROSE: tw_colors.rose[600], // '#E11D48',
  GRAY: tw_colors.gray[600], // '#52525B',
} as const;
export const colors = (Object.keys(COLORS) as Array<ColorType>).map(
  (key) => COLORS[key],
);

type ColorType = keyof typeof COLORS;

export type ColorEditType = Omit<CirclePickerProps, 'onChange'> & {
  value?: ColorType;
  onChange?: (value?: ColorType) => void;
  className?: string;
};

function _ColorEdit(
  { value, onChange, ...rest }: ColorEditType,
  ref: Ref<CirclePicker>,
) {
  const _value = value ? COLORS[value] : 'primary';
  const _onChange = useCallback(
    onChange
      ? ({ hex }) => {
          const color = (Object.keys(COLORS) as Array<ColorType>).find(
            (key) => COLORS[key] === hex.toUpperCase(),
          );

          onChange(color);
        }
      : () => undefined,
    [],
  );

  return (
    <CirclePicker
      ref={ref}
      colors={colors}
      color={_value}
      onChange={_onChange}
      className="ml-4"
      width="100%"
      {...rest}
    />
  );
}

export const ColorEdit = forwardRef(_ColorEdit);
