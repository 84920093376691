import { BrowserRouter } from 'react-router-dom';
import { Layout } from './views';
import { Router } from './components';
import { SessionProvider } from './graphql';
import { VechaiProvider } from '@vechaiui/react';
import ReactDOM from 'react-dom';

import './assets/styles.css';

ReactDOM.render(
  <VechaiProvider>
    <SessionProvider uri={process.env.REACT_APP_HASURA_HOSTNAME}>
      <BrowserRouter>
        <Layout>
          <Router />
        </Layout>
      </BrowserRouter>
    </SessionProvider>
  </VechaiProvider>,
  document.getElementById('root'),
);
