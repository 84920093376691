import { CheckIcon } from '../../icons';
import { CheckboxItem } from '@radix-ui/react-context-menu';
import { ComponentProps, Ref, forwardRef } from 'react';
import { CheckboxItem as DropdownCheckboxItem } from '@radix-ui/react-dropdown-menu';
import { HybridMenuItemIndicator } from './item-indicator';
import { cx } from '@vechaiui/react';
import { useHybridMenuContext } from './provider';

export type HybridMenuCheckboxItemType = ComponentProps<typeof CheckboxItem>;

export const HybridMenuCheckboxItem = forwardRef(
  (
    {
      className,
      disabled,
      onSelect,
      children,
      ...rest
    }: HybridMenuCheckboxItemType,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { asContext } = useHybridMenuContext();
    const CheckboxItemComponent = asContext
      ? CheckboxItem
      : DropdownCheckboxItem;

    return (
      <CheckboxItemComponent
        ref={ref}
        disabled={disabled}
        onSelect={(e) => {
          e.preventDefault();

          if (onSelect) onSelect(e);
        }}
        className={cx(
          'flex items-center w-full px-3 h-8 flex-shrink-0 text-sm text-left cursor-base focus:outline-none',
          'focus:bg-neutral-100 dark:focus:bg-neutral-700',
          disabled ? 'opacity-50' : '',
          className,
        )}
        {...rest}
      >
        {children}

        <span className="flex-grow" />

        <HybridMenuItemIndicator>
          <CheckIcon className="h-3 w-auto text-primary-600" />
        </HybridMenuItemIndicator>
      </CheckboxItemComponent>
    );
  },
);
