import { ComponentProps, Ref, forwardRef } from 'react';
import { Select } from '@vechaiui/forms';

const COUNTRY_CODES = [
  { code: 'AF', country: 'Afghanistan' },
  { code: 'EG', country: 'Ägypten' },
  { code: 'AL', country: 'Albanien' },
  { code: 'DZ', country: 'Algerien' },
  { code: 'AD', country: 'Andorra' },
  { code: 'AO', country: 'Angola' },
  { code: 'AI', country: 'Anguilla' },
  { code: 'AQ', country: 'Antarktis' },
  { code: 'AG', country: 'Antigua und Barbuda' },
  { code: 'GQ', country: 'Äquatorial Guinea' },
  { code: 'AR', country: 'Argentinien' },
  { code: 'AM', country: 'Armenien' },
  { code: 'AW', country: 'Aruba' },
  { code: 'AZ', country: 'Aserbaidschan' },
  { code: 'ET', country: 'Äthiopien' },
  { code: 'AU', country: 'Australien' },
  { code: 'BS', country: 'Bahamas' },
  { code: 'BH', country: 'Bahrain' },
  { code: 'BD', country: 'Bangladesh' },
  { code: 'BB', country: 'Barbados' },
  { code: 'BE', country: 'Belgien' },
  { code: 'BZ', country: 'Belize' },
  { code: 'BJ', country: 'Benin' },
  { code: 'BM', country: 'Bermudas' },
  { code: 'BT', country: 'Bhutan' },
  { code: 'MM', country: 'Birma' },
  { code: 'BO', country: 'Bolivien' },
  { code: 'BA', country: 'Bosnien-Herzegowina' },
  { code: 'BW', country: 'Botswana' },
  { code: 'BV', country: 'Bouvet Inseln' },
  { code: 'BR', country: 'Brasilien' },
  { code: 'IO', country: 'Britisch-Indischer Ozean' },
  { code: 'BN', country: 'Brunei' },
  { code: 'BG', country: 'Bulgarien' },
  { code: 'BF', country: 'Burkina Faso' },
  { code: 'BI', country: 'Burundi' },
  { code: 'CL', country: 'Chile' },
  { code: 'CN', country: 'China' },
  { code: 'CX', country: 'Christmas Island' },
  { code: 'CK', country: 'Cook Inseln' },
  { code: 'CR', country: 'Costa Rica' },
  { code: 'DK', country: 'Dänemark' },
  { code: 'DE', country: 'Deutschland' },
  { code: 'DJ', country: 'Djibuti' },
  { code: 'DM', country: 'Dominika' },
  { code: 'DO', country: 'Dominikanische Republik' },
  { code: 'EC', country: 'Ecuador' },
  { code: 'SV', country: 'El Salvador' },
  { code: 'CI', country: 'Elfenbeinküste' },
  { code: 'ER', country: 'Eritrea' },
  { code: 'EE', country: 'Estland' },
  { code: 'FK', country: 'Falkland Inseln' },
  { code: 'FO', country: 'Färöer Inseln' },
  { code: 'FJ', country: 'Fidschi' },
  { code: 'FI', country: 'Finnland' },
  { code: 'FR', country: 'Frankreich' },
  { code: 'GF', country: 'französisch Guyana' },
  { code: 'PF', country: 'Französisch Polynesien' },
  { code: 'TF', country: 'Französisches Süd-Territorium' },
  { code: 'GA', country: 'Gabun' },
  { code: 'GM', country: 'Gambia' },
  { code: 'GE', country: 'Georgien' },
  { code: 'GH', country: 'Ghana' },
  { code: 'GI', country: 'Gibraltar' },
  { code: 'GD', country: 'Grenada' },
  { code: 'GR', country: 'Griechenland' },
  { code: 'GL', country: 'Grönland' },
  { code: 'UK', country: 'Großbritannien' },
  { code: 'GB', country: 'Großbritannien (UK)' },
  { code: 'GP', country: 'Guadeloupe' },
  { code: 'GU', country: 'Guam' },
  { code: 'GT', country: 'Guatemala' },
  { code: 'GN', country: 'Guinea' },
  { code: 'GW', country: 'Guinea Bissau' },
  { code: 'GY', country: 'Guyana' },
  { code: 'HT', country: 'Haiti' },
  { code: 'HM', country: 'Heard und McDonald Islands' },
  { code: 'HN', country: 'Honduras' },
  { code: 'HK', country: 'Hong Kong' },
  { code: 'IN', country: 'Indien' },
  { code: 'ID', country: 'Indonesien' },
  { code: 'IQ', country: 'Irak' },
  { code: 'IR', country: 'Iran' },
  { code: 'IE', country: 'Irland' },
  { code: 'IS', country: 'Island' },
  { code: 'IL', country: 'Israel' },
  { code: 'IT', country: 'Italien' },
  { code: 'JM', country: 'Jamaika' },
  { code: 'JP', country: 'Japan' },
  { code: 'YE', country: 'Jemen' },
  { code: 'JO', country: 'Jordanien' },
  { code: 'YU', country: 'Jugoslawien' },
  { code: 'KY', country: 'Kaiman Inseln' },
  { code: 'KH', country: 'Kambodscha' },
  { code: 'CM', country: 'Kamerun' },
  { code: 'CA', country: 'Kanada' },
  { code: 'CV', country: 'Kap Verde' },
  { code: 'KZ', country: 'Kasachstan' },
  { code: 'KE', country: 'Kenia' },
  { code: 'KG', country: 'Kirgisistan' },
  { code: 'KI', country: 'Kiribati' },
  { code: 'CC', country: 'Kokosinseln' },
  { code: 'CO', country: 'Kolumbien' },
  { code: 'KM', country: 'Komoren' },
  { code: 'CG', country: 'Kongo' },
  { code: 'CD', country: 'Demokratische Republik Kongo' },
  { code: 'HR', country: 'Kroatien' },
  { code: 'CU', country: 'Kuba' },
  { code: 'KW', country: 'Kuwait' },
  { code: 'LA', country: 'Laos' },
  { code: 'LS', country: 'Lesotho' },
  { code: 'LV', country: 'Lettland' },
  { code: 'LB', country: 'Libanon' },
  { code: 'LR', country: 'Liberia' },
  { code: 'LY', country: 'Libyen' },
  { code: 'LI', country: 'Liechtenstein' },
  { code: 'LT', country: 'Litauen' },
  { code: 'LU', country: 'Luxemburg' },
  { code: 'MO', country: 'Macao' },
  { code: 'MG', country: 'Madagaskar' },
  { code: 'MW', country: 'Malawi' },
  { code: 'MY', country: 'Malaysia' },
  { code: 'MV', country: 'Malediven' },
  { code: 'ML', country: 'Mali' },
  { code: 'MT', country: 'Malta' },
  { code: 'MP', country: 'Marianen' },
  { code: 'MA', country: 'Marokko' },
  { code: 'MH', country: 'Marshall Inseln' },
  { code: 'MQ', country: 'Martinique' },
  { code: 'MR', country: 'Mauretanien' },
  { code: 'MU', country: 'Mauritius' },
  { code: 'YT', country: 'Mayotte' },
  { code: 'MK', country: 'Mazedonien' },
  { code: 'MX', country: 'Mexiko' },
  { code: 'FM', country: 'Mikronesien' },
  { code: 'MZ', country: 'Mocambique' },
  { code: 'MD', country: 'Moldavien' },
  { code: 'MC', country: 'Monaco' },
  { code: 'MN', country: 'Mongolei' },
  { code: 'MS', country: 'Montserrat' },
  { code: 'NA', country: 'Namibia' },
  { code: 'NR', country: 'Nauru' },
  { code: 'NP', country: 'Nepal' },
  { code: 'NC', country: 'Neukaledonien' },
  { code: 'NZ', country: 'Neuseeland' },
  { code: 'NI', country: 'Nicaragua' },
  { code: 'NL', country: 'Niederlande' },
  { code: 'AN', country: 'Niederländische Antillen' },
  { code: 'NE', country: 'Niger' },
  { code: 'NG', country: 'Nigeria' },
  { code: 'NU', country: 'Niue' },
  { code: 'KP', country: 'Nord Korea' },
  { code: 'NF', country: 'Norfolk Inseln' },
  { code: 'NO', country: 'Norwegen' },
  { code: 'OM', country: 'Oman' },
  { code: 'AT', country: 'Österreich' },
  { code: 'PK', country: 'Pakistan' },
  { code: 'PS', country: 'Palästina' },
  { code: 'PW', country: 'Palau' },
  { code: 'PA', country: 'Panama' },
  { code: 'PG', country: 'Papua Neuguinea' },
  { code: 'PY', country: 'Paraguay' },
  { code: 'PE', country: 'Peru' },
  { code: 'PH', country: 'Philippinen' },
  { code: 'PN', country: 'Pitcairn' },
  { code: 'PL', country: 'Polen' },
  { code: 'PT', country: 'Portugal' },
  { code: 'PR', country: 'Puerto Rico' },
  { code: 'QA', country: 'Qatar' },
  { code: 'RE', country: 'Reunion' },
  { code: 'RW', country: 'Ruanda' },
  { code: 'RO', country: 'Rumänien' },
  { code: 'RU', country: 'Russland' },
  { code: 'LC', country: 'Saint Lucia' },
  { code: 'ZM', country: 'Sambia' },
  { code: 'AS', country: 'Samoa' },
  { code: 'WS', country: 'Samoa' },
  { code: 'SM', country: 'San Marino' },
  { code: 'ST', country: 'Sao Tome' },
  { code: 'SA', country: 'Saudi Arabien' },
  { code: 'SE', country: 'Schweden' },
  { code: 'CH', country: 'Schweiz' },
  { code: 'SN', country: 'Senegal' },
  { code: 'SC', country: 'Seychellen' },
  { code: 'SL', country: 'Sierra Leone' },
  { code: 'SG', country: 'Singapur' },
  { code: 'SK', country: 'Slowakei' },
  { code: 'SI', country: 'Slowenien' },
  { code: 'SB', country: 'Solomon Inseln' },
  { code: 'SO', country: 'Somalia' },
  { code: 'GS', country: 'Südgeorgien und die Südlichen Sandwichinseln' },
  { code: 'ES', country: 'Spanien' },
  { code: 'LK', country: 'Sri Lanka' },
  { code: 'SH', country: 'St. Helena' },
  { code: 'KN', country: 'St. Kitts Nevis Anguilla' },
  { code: 'PM', country: 'St. Pierre und Miquelon' },
  { code: 'VC', country: 'St. Vincent' },
  { code: 'KR', country: 'Süd Korea' },
  { code: 'ZA', country: 'Südafrika' },
  { code: 'SD', country: 'Sudan' },
  { code: 'SR', country: 'Surinam' },
  { code: 'SJ', country: 'Svalbard und Jan Mayen Islands' },
  { code: 'SZ', country: 'Swasiland' },
  { code: 'SY', country: 'Syrien' },
  { code: 'TJ', country: 'Tadschikistan' },
  { code: 'TW', country: 'Taiwan' },
  { code: 'TZ', country: 'Tansania' },
  { code: 'TH', country: 'Thailand' },
  { code: 'TP', country: 'Timor' },
  { code: 'TG', country: 'Togo' },
  { code: 'TK', country: 'Tokelau' },
  { code: 'TO', country: 'Tonga' },
  { code: 'TT', country: 'Trinidad Tobago' },
  { code: 'TD', country: 'Tschad' },
  { code: 'CZ', country: 'Tschechische Republik' },
  { code: 'TN', country: 'Tunesien' },
  { code: 'TR', country: 'Türkei' },
  { code: 'TM', country: 'Turkmenistan' },
  { code: 'TC', country: 'Turks und Kaikos Inseln' },
  { code: 'TV', country: 'Tuvalu' },
  { code: 'UG', country: 'Uganda' },
  { code: 'UA', country: 'Ukraine' },
  { code: 'HU', country: 'Ungarn' },
  { code: 'UY', country: 'Uruguay' },
  { code: 'UZ', country: 'Usbekistan' },
  { code: 'VU', country: 'Vanuatu' },
  { code: 'VA', country: 'Vatikan' },
  { code: 'VE', country: 'Venezuela' },
  { code: 'AE', country: 'Vereinigte Arabische Emirate' },
  { code: 'US', country: 'Vereinigte Staaten von Amerika' },
  { code: 'VN', country: 'Vietnam' },
  { code: 'VG', country: 'Virgin Island (Brit.)' },
  { code: 'VI', country: 'Virgin Island (USA)' },
  { code: 'WF', country: 'Wallis et Futuna' },
  { code: 'BY', country: 'Weissrussland' },
  { code: 'EH', country: 'Westsahara' },
  { code: 'CF', country: 'Zentralafrikanische Republik' },
  { code: 'ZW', country: 'Zimbabwe' },
  { code: 'CY', country: 'Zypern' },
];

type CountrySelectType = ComponentProps<typeof Select>;

export const CountrySelect = forwardRef(
  (props: CountrySelectType, ref: Ref<HTMLSelectElement>) => {
    return (
      <Select ref={ref} {...props}>
        {COUNTRY_CODES.map((entry) => (
          <option key={entry.code} value={entry.code}>
            {entry.country}
          </option>
        ))}
      </Select>
    );
  },
);
