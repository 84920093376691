import { ComponentProps } from 'react';
import { Root as DropdownRoot } from '@radix-ui/react-dropdown-menu';
import { Root } from '@radix-ui/react-context-menu';
import { useHybridMenuContext } from './provider';

export type HybridMenuRootProps = ComponentProps<typeof Root> &
  ComponentProps<typeof DropdownRoot>;

export const HybridMenuRoot = (props: HybridMenuRootProps) => {
  const { asContext } = useHybridMenuContext();
  const RootComponent = asContext ? Root : DropdownRoot;

  return <RootComponent {...props} />;
};
