import { ComponentProps } from 'react';
import { ItemIndicator as DropdownItemIndicator } from '@radix-ui/react-dropdown-menu';
import { ItemIndicator } from '@radix-ui/react-context-menu';
import { useHybridMenuContext } from './provider';

export type HybridMenuItemIndicatorProps = ComponentProps<
  typeof ItemIndicator
> &
  ComponentProps<typeof DropdownItemIndicator>;

export const HybridMenuItemIndicator = (
  props: HybridMenuItemIndicatorProps,
) => {
  const { asContext } = useHybridMenuContext();
  const ItemIndicatorComponent = asContext
    ? ItemIndicator
    : DropdownItemIndicator;

  return <ItemIndicatorComponent {...props} />;
};
