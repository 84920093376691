import { ComponentProps, Ref, forwardRef } from 'react';
import { Select } from '@vechaiui/forms';

export const DEPOSIT_TYPES = {
  KEINE: 'Keine Kaution',
  BAR: 'Barkaution',
  UEBERWEISUNG: 'Banküberweisung',
  SPARBUCH: 'Verpfändetes Sparbuch',
  BUERGSCHAFT: 'Bankbürgschaft',
  SONSTIGES: 'Sonstiges',
};

type DepositTypeEditType = ComponentProps<typeof Select>;

export const DepositTypeEdit = forwardRef(
  (props: DepositTypeEditType, ref: Ref<HTMLSelectElement>) => {
    return (
      <Select ref={ref} {...props}>
        {(Object.keys(DEPOSIT_TYPES) as Array<keyof typeof DEPOSIT_TYPES>).map(
          (key) => (
            <option key={key} value={key}>
              {DEPOSIT_TYPES[key]}
            </option>
          ),
        )}
      </Select>
    );
  },
);
