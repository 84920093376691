/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	Boolean_comparison_exp:{
		_eq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Float_comparison_exp:{
		_eq:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	Int_comparison_exp:{
		_eq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	String_comparison_exp:{
		_eq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_ilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_iregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_like:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nilike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_niregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nlike:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nregex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nsimilar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_regex:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_similar:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	color:{
		groups:{
			distinct_on:{
				type:"group_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"group_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"group_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		groups_aggregate:{
			distinct_on:{
				type:"group_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"group_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"group_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	color_aggregate_fields:{
		count:{
			columns:{
				type:"color_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	color_bool_exp:{
		_and:{
			type:"color_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"color_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"color_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		color:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		groups:{
			type:"group_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	color_constraint: "enum",
	color_enum: "enum",
	color_enum_comparison_exp:{
		_eq:{
			type:"color_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"color_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"color_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"color_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	color_insert_input:{
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		groups:{
			type:"group_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	color_obj_rel_insert_input:{
		data:{
			type:"color_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"color_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	color_on_conflict:{
		constraint:{
			type:"color_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"color_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"color_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	color_order_by:{
		color:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		groups_aggregate:{
			type:"group_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	color_pk_columns_input:{
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	color_select_column: "enum",
	color_set_input:{
		color:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	color_update_column: "enum",
	country_code:{
		properties:{
			distinct_on:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		properties_aggregate:{
			distinct_on:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	country_code_aggregate_fields:{
		count:{
			columns:{
				type:"country_code_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	country_code_bool_exp:{
		_and:{
			type:"country_code_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"country_code_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"country_code_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		code:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		properties:{
			type:"property_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	country_code_constraint: "enum",
	country_code_enum: "enum",
	country_code_enum_comparison_exp:{
		_eq:{
			type:"country_code_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"country_code_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"country_code_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"country_code_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	country_code_insert_input:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		properties:{
			type:"property_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	country_code_obj_rel_insert_input:{
		data:{
			type:"country_code_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"country_code_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	country_code_on_conflict:{
		constraint:{
			type:"country_code_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"country_code_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"country_code_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	country_code_order_by:{
		code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		properties_aggregate:{
			type:"property_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	country_code_pk_columns_input:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	country_code_select_column: "enum",
	country_code_set_input:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	country_code_update_column: "enum",
	date: "String",
	date_comparison_exp:{
		_eq:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"date",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"date",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	deposit_rate:{
		rental_contracts:{
			distinct_on:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_contract_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_contracts_aggregate:{
			distinct_on:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_contract_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	deposit_rate_aggregate_fields:{
		count:{
			columns:{
				type:"deposit_rate_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	deposit_rate_bool_exp:{
		_and:{
			type:"deposit_rate_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"deposit_rate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"deposit_rate_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contracts:{
			type:"rental_contract_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_rate_constraint: "enum",
	deposit_rate_enum: "enum",
	deposit_rate_enum_comparison_exp:{
		_eq:{
			type:"deposit_rate_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"deposit_rate_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"deposit_rate_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"deposit_rate_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	deposit_rate_insert_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contracts:{
			type:"rental_contract_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_rate_obj_rel_insert_input:{
		data:{
			type:"deposit_rate_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"deposit_rate_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_rate_on_conflict:{
		constraint:{
			type:"deposit_rate_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"deposit_rate_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"deposit_rate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_rate_order_by:{
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contracts_aggregate:{
			type:"rental_contract_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_rate_pk_columns_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	deposit_rate_select_column: "enum",
	deposit_rate_set_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_rate_update_column: "enum",
	deposit_type:{
		rental_contracts:{
			distinct_on:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_contract_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_contracts_aggregate:{
			distinct_on:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_contract_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	deposit_type_aggregate_fields:{
		count:{
			columns:{
				type:"deposit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	deposit_type_bool_exp:{
		_and:{
			type:"deposit_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"deposit_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"deposit_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contracts:{
			type:"rental_contract_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_type_constraint: "enum",
	deposit_type_enum: "enum",
	deposit_type_enum_comparison_exp:{
		_eq:{
			type:"deposit_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"deposit_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"deposit_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"deposit_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	deposit_type_insert_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contracts:{
			type:"rental_contract_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_type_obj_rel_insert_input:{
		data:{
			type:"deposit_type_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"deposit_type_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_type_on_conflict:{
		constraint:{
			type:"deposit_type_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"deposit_type_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"deposit_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_type_order_by:{
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contracts_aggregate:{
			type:"rental_contract_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_type_pk_columns_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	deposit_type_select_column: "enum",
	deposit_type_set_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	deposit_type_update_column: "enum",
	group_aggregate_fields:{
		count:{
			columns:{
				type:"group_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	group_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"group_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"group_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_arr_rel_insert_input:{
		data:{
			type:"group_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"group_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_bool_exp:{
		_and:{
			type:"group_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"group_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"group_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		abbreviation:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"color_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		object_color_type:{
			type:"color_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_constraint: "enum",
	group_insert_input:{
		abbreviation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"color_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		object_color_type:{
			type:"color_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_max_order_by:{
		abbreviation:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_min_order_by:{
		abbreviation:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_on_conflict:{
		constraint:{
			type:"group_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"group_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"group_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_order_by:{
		abbreviation:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		object_color_type:{
			type:"color_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	group_select_column: "enum",
	group_set_input:{
		abbreviation:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		color:{
			type:"color_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	group_update_column: "enum",
	jsonb: "String",
	jsonb_comparison_exp:{
		_contained_in:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_contains:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_eq:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_has_key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_has_keys_all:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_has_keys_any:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		_in:{
			type:"jsonb",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"jsonb",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	mutation_root:{
		delete_color:{
			where:{
				type:"color_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_color_by_pk:{
			color:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_country_code:{
			where:{
				type:"country_code_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_country_code_by_pk:{
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_deposit_rate:{
			where:{
				type:"deposit_rate_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_deposit_rate_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_deposit_type:{
			where:{
				type:"deposit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_deposit_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_group:{
			where:{
				type:"group_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_group_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_property:{
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_property_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_property_type:{
			where:{
				type:"property_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_property_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rent_index:{
			where:{
				type:"rent_index_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rent_index_area:{
			where:{
				type:"rent_index_area_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rent_index_area_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rent_index_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rent_index_layer_type:{
			where:{
				type:"rent_index_layer_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rent_index_layer_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rental_contract:{
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rental_contract_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rental_costs:{
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rental_costs_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rental_costs_type:{
			where:{
				type:"rental_costs_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rental_costs_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rental_rent:{
			where:{
				type:"rental_rent_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_rental_rent_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_unit:{
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_unit_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_unit_type:{
			where:{
				type:"unit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_unit_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_user:{
			where:{
				type:"user_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		delete_user_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		insert_color:{
			objects:{
				type:"color_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"color_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_color_one:{
			object:{
				type:"color_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"color_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_country_code:{
			objects:{
				type:"country_code_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"country_code_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_country_code_one:{
			object:{
				type:"country_code_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"country_code_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_deposit_rate:{
			objects:{
				type:"deposit_rate_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"deposit_rate_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_deposit_rate_one:{
			object:{
				type:"deposit_rate_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"deposit_rate_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_deposit_type:{
			objects:{
				type:"deposit_type_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"deposit_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_deposit_type_one:{
			object:{
				type:"deposit_type_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"deposit_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_group:{
			objects:{
				type:"group_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"group_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_group_one:{
			object:{
				type:"group_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"group_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_property:{
			objects:{
				type:"property_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"property_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_property_one:{
			object:{
				type:"property_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"property_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_property_type:{
			objects:{
				type:"property_type_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"property_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_property_type_one:{
			object:{
				type:"property_type_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"property_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rent_index:{
			objects:{
				type:"rent_index_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"rent_index_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rent_index_area:{
			objects:{
				type:"rent_index_area_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"rent_index_area_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rent_index_area_one:{
			object:{
				type:"rent_index_area_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"rent_index_area_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rent_index_layer_type:{
			objects:{
				type:"rent_index_layer_type_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"rent_index_layer_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rent_index_layer_type_one:{
			object:{
				type:"rent_index_layer_type_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"rent_index_layer_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rent_index_one:{
			object:{
				type:"rent_index_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"rent_index_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rental_contract:{
			objects:{
				type:"rental_contract_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"rental_contract_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rental_contract_one:{
			object:{
				type:"rental_contract_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"rental_contract_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rental_costs:{
			objects:{
				type:"rental_costs_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"rental_costs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rental_costs_one:{
			object:{
				type:"rental_costs_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"rental_costs_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rental_costs_type:{
			objects:{
				type:"rental_costs_type_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"rental_costs_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rental_costs_type_one:{
			object:{
				type:"rental_costs_type_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"rental_costs_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rental_rent:{
			objects:{
				type:"rental_rent_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"rental_rent_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_rental_rent_one:{
			object:{
				type:"rental_rent_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"rental_rent_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_unit:{
			objects:{
				type:"unit_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"unit_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_unit_one:{
			object:{
				type:"unit_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"unit_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_unit_type:{
			objects:{
				type:"unit_type_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"unit_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_unit_type_one:{
			object:{
				type:"unit_type_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"unit_type_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_user:{
			objects:{
				type:"user_insert_input",
				array:true,
				arrayRequired:true,
				required:true
			},
			on_conflict:{
				type:"user_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		insert_user_one:{
			object:{
				type:"user_insert_input",
				array:false,
				arrayRequired:false,
				required:true
			},
			on_conflict:{
				type:"user_on_conflict",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		update_color:{
			_set:{
				type:"color_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"color_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_color_by_pk:{
			_set:{
				type:"color_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"color_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_country_code:{
			_set:{
				type:"country_code_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"country_code_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_country_code_by_pk:{
			_set:{
				type:"country_code_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"country_code_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_deposit_rate:{
			_set:{
				type:"deposit_rate_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"deposit_rate_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_deposit_rate_by_pk:{
			_set:{
				type:"deposit_rate_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"deposit_rate_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_deposit_type:{
			_set:{
				type:"deposit_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"deposit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_deposit_type_by_pk:{
			_set:{
				type:"deposit_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"deposit_type_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_group:{
			_set:{
				type:"group_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"group_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_group_by_pk:{
			_set:{
				type:"group_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"group_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_property:{
			_inc:{
				type:"property_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"property_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_property_by_pk:{
			_inc:{
				type:"property_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"property_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"property_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_property_type:{
			_set:{
				type:"property_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"property_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_property_type_by_pk:{
			_set:{
				type:"property_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"property_type_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rent_index:{
			_append:{
				type:"rent_index_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"rent_index_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"rent_index_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"rent_index_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"rent_index_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"rent_index_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"rent_index_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"rent_index_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rent_index_area:{
			_append:{
				type:"rent_index_area_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"rent_index_area_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"rent_index_area_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"rent_index_area_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"rent_index_area_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"rent_index_area_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"rent_index_area_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rent_index_area_by_pk:{
			_append:{
				type:"rent_index_area_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"rent_index_area_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"rent_index_area_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"rent_index_area_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"rent_index_area_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"rent_index_area_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"rent_index_area_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rent_index_by_pk:{
			_append:{
				type:"rent_index_append_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_at_path:{
				type:"rent_index_delete_at_path_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_elem:{
				type:"rent_index_delete_elem_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_delete_key:{
				type:"rent_index_delete_key_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_inc:{
				type:"rent_index_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_prepend:{
				type:"rent_index_prepend_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"rent_index_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"rent_index_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rent_index_layer_type:{
			_set:{
				type:"rent_index_layer_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"rent_index_layer_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rent_index_layer_type_by_pk:{
			_set:{
				type:"rent_index_layer_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"rent_index_layer_type_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rental_contract:{
			_inc:{
				type:"rental_contract_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"rental_contract_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rental_contract_by_pk:{
			_inc:{
				type:"rental_contract_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"rental_contract_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"rental_contract_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rental_costs:{
			_inc:{
				type:"rental_costs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"rental_costs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rental_costs_by_pk:{
			_inc:{
				type:"rental_costs_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"rental_costs_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"rental_costs_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rental_costs_type:{
			_set:{
				type:"rental_costs_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"rental_costs_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rental_costs_type_by_pk:{
			_set:{
				type:"rental_costs_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"rental_costs_type_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rental_rent:{
			_set:{
				type:"rental_rent_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"rental_rent_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_rental_rent_by_pk:{
			_set:{
				type:"rental_rent_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"rental_rent_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_unit:{
			_inc:{
				type:"unit_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"unit_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_unit_by_pk:{
			_inc:{
				type:"unit_inc_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			_set:{
				type:"unit_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"unit_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_unit_type:{
			_set:{
				type:"unit_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"unit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_unit_type_by_pk:{
			_set:{
				type:"unit_type_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"unit_type_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_user:{
			_set:{
				type:"user_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			where:{
				type:"user_bool_exp",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		update_user_by_pk:{
			_set:{
				type:"user_set_input",
				array:false,
				arrayRequired:false,
				required:false
			},
			pk_columns:{
				type:"user_pk_columns_input",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	numeric: "String",
	numeric_comparison_exp:{
		_eq:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"numeric",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"numeric",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	order_by: "enum",
	property:{
		units:{
			distinct_on:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		units_aggregate:{
			distinct_on:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	property_aggregate_fields:{
		count:{
			columns:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	property_aggregate_order_by:{
		avg:{
			type:"property_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"property_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"property_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"property_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"property_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"property_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"property_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"property_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"property_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"property_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_arr_rel_insert_input:{
		data:{
			type:"property_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"property_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_avg_order_by:{
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_bool_exp:{
		_and:{
			type:"property_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"property_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"property_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		city:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"country_code_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_code:{
			type:"country_code_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lat:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_type:{
			type:"property_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		street:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"property_type_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		units:{
			type:"unit_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_constraint: "enum",
	property_inc_input:{
		lat:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_insert_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"country_code_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_code:{
			type:"country_code_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		lat:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_type:{
			type:"property_type_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		street:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"property_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		units:{
			type:"unit_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_max_order_by:{
		city:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		street:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_min_order_by:{
		city:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		street:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_obj_rel_insert_input:{
		data:{
			type:"property_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"property_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_on_conflict:{
		constraint:{
			type:"property_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"property_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"property_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_order_by:{
		city:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		country_code:{
			type:"country_code_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_type:{
			type:"property_type_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		street:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		units_aggregate:{
			type:"unit_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	property_select_column: "enum",
	property_set_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		country:{
			type:"country_code_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		lat:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		number:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		},
		region:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		state:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		street:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"property_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_stddev_order_by:{
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_stddev_pop_order_by:{
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_stddev_samp_order_by:{
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_sum_order_by:{
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_type:{
		properties:{
			distinct_on:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		properties_aggregate:{
			distinct_on:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	property_type_aggregate_fields:{
		count:{
			columns:{
				type:"property_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	property_type_bool_exp:{
		_and:{
			type:"property_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"property_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"property_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		properties:{
			type:"property_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_type_constraint: "enum",
	property_type_enum: "enum",
	property_type_enum_comparison_exp:{
		_eq:{
			type:"property_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"property_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"property_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"property_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	property_type_insert_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		properties:{
			type:"property_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_type_obj_rel_insert_input:{
		data:{
			type:"property_type_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"property_type_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_type_on_conflict:{
		constraint:{
			type:"property_type_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"property_type_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"property_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_type_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		properties_aggregate:{
			type:"property_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_type_pk_columns_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	property_type_select_column: "enum",
	property_type_set_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_type_update_column: "enum",
	property_update_column: "enum",
	property_var_pop_order_by:{
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_var_samp_order_by:{
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	property_variance_order_by:{
		lat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		lng:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	query_root:{
		color:{
			distinct_on:{
				type:"color_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"color_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"color_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		color_aggregate:{
			distinct_on:{
				type:"color_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"color_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"color_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		color_by_pk:{
			color:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		country_code:{
			distinct_on:{
				type:"country_code_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"country_code_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"country_code_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		country_code_aggregate:{
			distinct_on:{
				type:"country_code_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"country_code_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"country_code_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		country_code_by_pk:{
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deposit_rate:{
			distinct_on:{
				type:"deposit_rate_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"deposit_rate_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"deposit_rate_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		deposit_rate_aggregate:{
			distinct_on:{
				type:"deposit_rate_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"deposit_rate_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"deposit_rate_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		deposit_rate_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deposit_type:{
			distinct_on:{
				type:"deposit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"deposit_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"deposit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		deposit_type_aggregate:{
			distinct_on:{
				type:"deposit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"deposit_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"deposit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		deposit_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		group:{
			distinct_on:{
				type:"group_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"group_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"group_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		group_aggregate:{
			distinct_on:{
				type:"group_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"group_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"group_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		group_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		property:{
			distinct_on:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		property_aggregate:{
			distinct_on:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		property_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		property_type:{
			distinct_on:{
				type:"property_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		property_type_aggregate:{
			distinct_on:{
				type:"property_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		property_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rent_index:{
			distinct_on:{
				type:"rent_index_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_aggregate:{
			distinct_on:{
				type:"rent_index_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_area:{
			distinct_on:{
				type:"rent_index_area_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_area_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_area_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_area_aggregate:{
			distinct_on:{
				type:"rent_index_area_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_area_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_area_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_area_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rent_index_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rent_index_layer_type:{
			distinct_on:{
				type:"rent_index_layer_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_layer_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_layer_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_layer_type_aggregate:{
			distinct_on:{
				type:"rent_index_layer_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_layer_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_layer_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_layer_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rental_contract:{
			distinct_on:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_contract_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_contract_aggregate:{
			distinct_on:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_contract_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_contract_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rental_costs:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_aggregate:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rental_costs_type:{
			distinct_on:{
				type:"rental_costs_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_type_aggregate:{
			distinct_on:{
				type:"rental_costs_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rental_rent:{
			distinct_on:{
				type:"rental_rent_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_rent_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_rent_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_rent_aggregate:{
			distinct_on:{
				type:"rental_rent_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_rent_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_rent_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_rent_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		unit:{
			distinct_on:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unit_aggregate:{
			distinct_on:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unit_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		unit_type:{
			distinct_on:{
				type:"unit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unit_type_aggregate:{
			distinct_on:{
				type:"unit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unit_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		user:{
			distinct_on:{
				type:"user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"user_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		user_aggregate:{
			distinct_on:{
				type:"user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"user_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		user_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	rent_index:{
		data:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rent_index_aggregate_fields:{
		count:{
			columns:{
				type:"rent_index_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rent_index_append_input:{
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area:{
		polygons:{
			path:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rent_index_area_aggregate_fields:{
		count:{
			columns:{
				type:"rent_index_area_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rent_index_area_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"rent_index_area_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"rent_index_area_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_append_input:{
		polygons:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_arr_rel_insert_input:{
		data:{
			type:"rent_index_area_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"rent_index_area_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_bool_exp:{
		_and:{
			type:"rent_index_area_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"rent_index_area_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"rent_index_area_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		layer_type:{
			type:"rent_index_layer_type_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		polygons:{
			type:"jsonb_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_index_layer_type:{
			type:"rent_index_layer_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_constraint: "enum",
	rent_index_area_delete_at_path_input:{
		polygons:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	rent_index_area_delete_elem_input:{
		polygons:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_delete_key_input:{
		polygons:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_insert_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		layer_type:{
			type:"rent_index_layer_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		polygons:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_index_layer_type:{
			type:"rent_index_layer_type_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_on_conflict:{
		constraint:{
			type:"rent_index_area_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"rent_index_area_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"rent_index_area_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		layer_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		polygons:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_index_layer_type:{
			type:"rent_index_layer_type_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	rent_index_area_prepend_input:{
		polygons:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_select_column: "enum",
	rent_index_area_set_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		layer_type:{
			type:"rent_index_layer_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		polygons:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_area_update_column: "enum",
	rent_index_bool_exp:{
		_and:{
			type:"rent_index_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"rent_index_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"rent_index_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		city:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"numeric_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_constraint: "enum",
	rent_index_delete_at_path_input:{
		data:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	rent_index_delete_elem_input:{
		data:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_delete_key_input:{
		data:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_inc_input:{
		postal_code:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_insert_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_layer_type:{
		rent_index_areas:{
			distinct_on:{
				type:"rent_index_area_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_area_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_area_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_areas_aggregate:{
			distinct_on:{
				type:"rent_index_area_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_area_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_area_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rent_index_layer_type_aggregate_fields:{
		count:{
			columns:{
				type:"rent_index_layer_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rent_index_layer_type_bool_exp:{
		_and:{
			type:"rent_index_layer_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"rent_index_layer_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"rent_index_layer_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_index_areas:{
			type:"rent_index_area_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_layer_type_constraint: "enum",
	rent_index_layer_type_enum: "enum",
	rent_index_layer_type_enum_comparison_exp:{
		_eq:{
			type:"rent_index_layer_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"rent_index_layer_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"rent_index_layer_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"rent_index_layer_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	rent_index_layer_type_insert_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_index_areas:{
			type:"rent_index_area_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_layer_type_obj_rel_insert_input:{
		data:{
			type:"rent_index_layer_type_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"rent_index_layer_type_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_layer_type_on_conflict:{
		constraint:{
			type:"rent_index_layer_type_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"rent_index_layer_type_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"rent_index_layer_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_layer_type_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_index_areas_aggregate:{
			type:"rent_index_area_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_layer_type_pk_columns_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	rent_index_layer_type_select_column: "enum",
	rent_index_layer_type_set_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_layer_type_update_column: "enum",
	rent_index_on_conflict:{
		constraint:{
			type:"rent_index_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"rent_index_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"rent_index_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_order_by:{
		city:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	rent_index_prepend_input:{
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_select_column: "enum",
	rent_index_set_input:{
		city:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"jsonb",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"date",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		postal_code:{
			type:"numeric",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rent_index_update_column: "enum",
	rental_contract:{
		rental_rents:{
			distinct_on:{
				type:"rental_rent_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_rent_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_rent_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_rents_aggregate:{
			distinct_on:{
				type:"rental_rent_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_rent_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_rent_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rental_contract_aggregate_fields:{
		count:{
			columns:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rental_contract_aggregate_order_by:{
		avg:{
			type:"rental_contract_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"rental_contract_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"rental_contract_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"rental_contract_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"rental_contract_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"rental_contract_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"rental_contract_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"rental_contract_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"rental_contract_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"rental_contract_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_arr_rel_insert_input:{
		data:{
			type:"rental_contract_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"rental_contract_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_avg_order_by:{
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_bool_exp:{
		_and:{
			type:"rental_contract_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"rental_contract_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"rental_contract_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		begin:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_effective:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_period:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_pronounced:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		depositRate:{
			type:"deposit_rate_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		depositType:{
			type:"deposit_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_payable:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_rate:{
			type:"deposit_rate_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_received:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_type:{
			type:"deposit_type_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		end:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_in:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_out:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		other_agreements:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_rents:{
			type:"rental_rent_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_constraint: "enum",
	rental_contract_inc_input:{
		cancelation_period:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_insert_input:{
		begin:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_effective:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_period:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_pronounced:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		depositRate:{
			type:"deposit_rate_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		depositType:{
			type:"deposit_type_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_payable:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_rate:{
			type:"deposit_rate_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_received:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_type:{
			type:"deposit_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		end:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_in:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_out:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		other_agreements:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_rents:{
			type:"rental_rent_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_max_order_by:{
		begin:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_effective:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_pronounced:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_payable:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_received:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_in:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_out:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		other_agreements:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_min_order_by:{
		begin:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_effective:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_pronounced:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_payable:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_received:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_in:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_out:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		other_agreements:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_obj_rel_insert_input:{
		data:{
			type:"rental_contract_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"rental_contract_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_on_conflict:{
		constraint:{
			type:"rental_contract_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"rental_contract_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"rental_contract_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_order_by:{
		begin:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_effective:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_pronounced:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		depositRate:{
			type:"deposit_rate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		depositType:{
			type:"deposit_type_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_payable:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_rate:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_received:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		end:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_in:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_out:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		other_agreements:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_rents_aggregate:{
			type:"rental_rent_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	rental_contract_select_column: "enum",
	rental_contract_set_input:{
		begin:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_effective:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_period:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		cancelation_pronounced:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		date:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_payable:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_rate:{
			type:"deposit_rate_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_received:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit_type:{
			type:"deposit_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		end:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_in:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		handover_move_out:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		other_agreements:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_stddev_order_by:{
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_stddev_pop_order_by:{
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_stddev_samp_order_by:{
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_sum_order_by:{
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_update_column: "enum",
	rental_contract_var_pop_order_by:{
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_var_samp_order_by:{
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_contract_variance_order_by:{
		cancelation_period:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		deposit:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_aggregate_fields:{
		count:{
			columns:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rental_costs_aggregate_order_by:{
		avg:{
			type:"rental_costs_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"rental_costs_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"rental_costs_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"rental_costs_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"rental_costs_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"rental_costs_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"rental_costs_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"rental_costs_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"rental_costs_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"rental_costs_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_arr_rel_insert_input:{
		data:{
			type:"rental_costs_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"rental_costs_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_avg_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_bool_exp:{
		_and:{
			type:"rental_costs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"rental_costs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"rental_costs_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		costs:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent:{
			type:"rental_rent_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs_type:{
			type:"rental_costs_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"rental_costs_type_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit:{
			type:"unit_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"Int_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_constraint: "enum",
	rental_costs_inc_input:{
		costs:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_insert_input:{
		costs:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent:{
			type:"rental_rent_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs_type:{
			type:"rental_costs_type_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"rental_costs_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit:{
			type:"unit_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_max_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_min_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_on_conflict:{
		constraint:{
			type:"rental_costs_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"rental_costs_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"rental_costs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent:{
			type:"rental_rent_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs_type:{
			type:"rental_costs_type_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit:{
			type:"unit_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	rental_costs_select_column: "enum",
	rental_costs_set_input:{
		costs:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rent_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"rental_costs_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_stddev_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_stddev_pop_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_stddev_samp_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_sum_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_type:{
		rental_costs:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_aggregate:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rental_costs_type_aggregate_fields:{
		count:{
			columns:{
				type:"rental_costs_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rental_costs_type_bool_exp:{
		_and:{
			type:"rental_costs_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"rental_costs_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"rental_costs_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs:{
			type:"rental_costs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_type_constraint: "enum",
	rental_costs_type_enum: "enum",
	rental_costs_type_enum_comparison_exp:{
		_eq:{
			type:"rental_costs_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"rental_costs_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"rental_costs_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"rental_costs_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	rental_costs_type_insert_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs:{
			type:"rental_costs_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_type_obj_rel_insert_input:{
		data:{
			type:"rental_costs_type_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"rental_costs_type_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_type_on_conflict:{
		constraint:{
			type:"rental_costs_type_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"rental_costs_type_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"rental_costs_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_type_order_by:{
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs_aggregate:{
			type:"rental_costs_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_type_pk_columns_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	rental_costs_type_select_column: "enum",
	rental_costs_type_set_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_type_update_column: "enum",
	rental_costs_update_column: "enum",
	rental_costs_var_pop_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_var_samp_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_costs_variance_order_by:{
		costs:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		vat:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent:{
		rental_costs:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_aggregate:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rental_rent_aggregate_fields:{
		count:{
			columns:{
				type:"rental_rent_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	rental_rent_aggregate_order_by:{
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"rental_rent_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"rental_rent_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_arr_rel_insert_input:{
		data:{
			type:"rental_rent_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"rental_rent_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_bool_exp:{
		_and:{
			type:"rental_rent_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"rental_rent_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"rental_rent_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		announced:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		begin:{
			type:"timestamptz_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract:{
			type:"rental_contract_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract_id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs:{
			type:"rental_costs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_constraint: "enum",
	rental_rent_insert_input:{
		announced:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		begin:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract:{
			type:"rental_contract_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs:{
			type:"rental_costs_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_max_order_by:{
		announced:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		begin:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_min_order_by:{
		announced:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		begin:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_obj_rel_insert_input:{
		data:{
			type:"rental_rent_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"rental_rent_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_on_conflict:{
		constraint:{
			type:"rental_rent_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"rental_rent_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"rental_rent_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_order_by:{
		announced:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		begin:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract:{
			type:"rental_contract_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs_aggregate:{
			type:"rental_costs_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	rental_rent_select_column: "enum",
	rental_rent_set_input:{
		announced:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		begin:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_contract_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	rental_rent_update_column: "enum",
	subscription_root:{
		color:{
			distinct_on:{
				type:"color_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"color_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"color_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		color_aggregate:{
			distinct_on:{
				type:"color_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"color_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"color_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		color_by_pk:{
			color:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		country_code:{
			distinct_on:{
				type:"country_code_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"country_code_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"country_code_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		country_code_aggregate:{
			distinct_on:{
				type:"country_code_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"country_code_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"country_code_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		country_code_by_pk:{
			code:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deposit_rate:{
			distinct_on:{
				type:"deposit_rate_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"deposit_rate_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"deposit_rate_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		deposit_rate_aggregate:{
			distinct_on:{
				type:"deposit_rate_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"deposit_rate_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"deposit_rate_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		deposit_rate_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deposit_type:{
			distinct_on:{
				type:"deposit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"deposit_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"deposit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		deposit_type_aggregate:{
			distinct_on:{
				type:"deposit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"deposit_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"deposit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		deposit_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		group:{
			distinct_on:{
				type:"group_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"group_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"group_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		group_aggregate:{
			distinct_on:{
				type:"group_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"group_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"group_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		group_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		property:{
			distinct_on:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		property_aggregate:{
			distinct_on:{
				type:"property_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		property_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		property_type:{
			distinct_on:{
				type:"property_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		property_type_aggregate:{
			distinct_on:{
				type:"property_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"property_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"property_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		property_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rent_index:{
			distinct_on:{
				type:"rent_index_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_aggregate:{
			distinct_on:{
				type:"rent_index_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_area:{
			distinct_on:{
				type:"rent_index_area_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_area_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_area_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_area_aggregate:{
			distinct_on:{
				type:"rent_index_area_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_area_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_area_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_area_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rent_index_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rent_index_layer_type:{
			distinct_on:{
				type:"rent_index_layer_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_layer_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_layer_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_layer_type_aggregate:{
			distinct_on:{
				type:"rent_index_layer_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rent_index_layer_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rent_index_layer_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rent_index_layer_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rental_contract:{
			distinct_on:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_contract_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_contract_aggregate:{
			distinct_on:{
				type:"rental_contract_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_contract_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_contract_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_contract_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rental_costs:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_aggregate:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rental_costs_type:{
			distinct_on:{
				type:"rental_costs_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_type_aggregate:{
			distinct_on:{
				type:"rental_costs_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		rental_rent:{
			distinct_on:{
				type:"rental_rent_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_rent_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_rent_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_rent_aggregate:{
			distinct_on:{
				type:"rental_rent_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_rent_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_rent_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_rent_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		unit:{
			distinct_on:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unit_aggregate:{
			distinct_on:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unit_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		unit_type:{
			distinct_on:{
				type:"unit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unit_type_aggregate:{
			distinct_on:{
				type:"unit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_type_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_type_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		unit_type_by_pk:{
			name:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		user:{
			distinct_on:{
				type:"user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"user_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		user_aggregate:{
			distinct_on:{
				type:"user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"user_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"user_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		user_by_pk:{
			id:{
				type:"uuid",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	timestamptz: "String",
	timestamptz_comparison_exp:{
		_eq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"timestamptz",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"timestamptz",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	unit:{
		rental_costs:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		rental_costs_aggregate:{
			distinct_on:{
				type:"rental_costs_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"rental_costs_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"rental_costs_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	unit_aggregate_fields:{
		count:{
			columns:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	unit_aggregate_order_by:{
		avg:{
			type:"unit_avg_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		count:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		max:{
			type:"unit_max_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		min:{
			type:"unit_min_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev:{
			type:"unit_stddev_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_pop:{
			type:"unit_stddev_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		stddev_samp:{
			type:"unit_stddev_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		sum:{
			type:"unit_sum_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_pop:{
			type:"unit_var_pop_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		var_samp:{
			type:"unit_var_samp_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		variance:{
			type:"unit_variance_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_arr_rel_insert_input:{
		data:{
			type:"unit_insert_input",
			array:true,
			arrayRequired:true,
			required:true
		},
		on_conflict:{
			type:"unit_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_avg_order_by:{
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_bool_exp:{
		_and:{
			type:"unit_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"unit_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"unit_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"Float_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		property:{
			type:"property_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs:{
			type:"rental_costs_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"Float_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"Float_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"Float_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"unit_type_enum_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_type:{
			type:"unit_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_constraint: "enum",
	unit_inc_input:{
		level:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_insert_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		property:{
			type:"property_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs:{
			type:"rental_costs_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"unit_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_type:{
			type:"unit_type_obj_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_max_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_min_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_obj_rel_insert_input:{
		data:{
			type:"unit_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"unit_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_on_conflict:{
		constraint:{
			type:"unit_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"unit_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"unit_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_order_by:{
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		property:{
			type:"property_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rental_costs_aggregate:{
			type:"rental_costs_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		unit_type:{
			type:"unit_type_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	unit_select_column: "enum",
	unit_set_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		level:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		property_id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"unit_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_stddev_order_by:{
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_stddev_pop_order_by:{
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_stddev_samp_order_by:{
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_sum_order_by:{
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_type:{
		units:{
			distinct_on:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		units_aggregate:{
			distinct_on:{
				type:"unit_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			order_by:{
				type:"unit_order_by",
				array:true,
				arrayRequired:false,
				required:true
			},
			where:{
				type:"unit_bool_exp",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	unit_type_aggregate_fields:{
		count:{
			columns:{
				type:"unit_type_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	unit_type_bool_exp:{
		_and:{
			type:"unit_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"unit_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"unit_type_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		description:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		units:{
			type:"unit_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_type_constraint: "enum",
	unit_type_enum: "enum",
	unit_type_enum_comparison_exp:{
		_eq:{
			type:"unit_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"unit_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"unit_type_enum",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"unit_type_enum",
			array:true,
			arrayRequired:false,
			required:true
		}
	},
	unit_type_insert_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		units:{
			type:"unit_arr_rel_insert_input",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_type_obj_rel_insert_input:{
		data:{
			type:"unit_type_insert_input",
			array:false,
			arrayRequired:false,
			required:true
		},
		on_conflict:{
			type:"unit_type_on_conflict",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_type_on_conflict:{
		constraint:{
			type:"unit_type_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"unit_type_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"unit_type_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_type_order_by:{
		description:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		units_aggregate:{
			type:"unit_aggregate_order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_type_pk_columns_input:{
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	unit_type_select_column: "enum",
	unit_type_set_input:{
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_type_update_column: "enum",
	unit_update_column: "enum",
	unit_var_pop_order_by:{
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_var_samp_order_by:{
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	unit_variance_order_by:{
		level:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		rooms:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		size_heated:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	user_aggregate_fields:{
		count:{
			columns:{
				type:"user_select_column",
				array:true,
				arrayRequired:false,
				required:true
			},
			distinct:{
				type:"Boolean",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	user_bool_exp:{
		_and:{
			type:"user_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		_not:{
			type:"user_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		_or:{
			type:"user_bool_exp",
			array:true,
			arrayRequired:false,
			required:true
		},
		active:{
			type:"Boolean_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String_comparison_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	user_constraint: "enum",
	user_insert_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	user_on_conflict:{
		constraint:{
			type:"user_constraint",
			array:false,
			arrayRequired:false,
			required:true
		},
		update_columns:{
			type:"user_update_column",
			array:true,
			arrayRequired:true,
			required:true
		},
		where:{
			type:"user_bool_exp",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	user_order_by:{
		active:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"order_by",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	user_pk_columns_input:{
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:true
		}
	},
	user_select_column: "enum",
	user_set_input:{
		active:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		password:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	user_update_column: "enum",
	uuid: "String",
	uuid_comparison_exp:{
		_eq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_gte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_in:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		},
		_is_null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lt:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_lte:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_neq:{
			type:"uuid",
			array:false,
			arrayRequired:false,
			required:false
		},
		_nin:{
			type:"uuid",
			array:true,
			arrayRequired:false,
			required:true
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	cached:{
		ttl:"Int",
		refresh:"Boolean"
	},
	color:{
		color:"String",
		groups:"group",
		groups_aggregate:"group_aggregate"
	},
	color_aggregate:{
		aggregate:"color_aggregate_fields",
		nodes:"color"
	},
	color_aggregate_fields:{
		count:"Int",
		max:"color_max_fields",
		min:"color_min_fields"
	},
	color_max_fields:{
		color:"String"
	},
	color_min_fields:{
		color:"String"
	},
	color_mutation_response:{
		affected_rows:"Int",
		returning:"color"
	},
	country_code:{
		code:"String",
		country:"String",
		properties:"property",
		properties_aggregate:"property_aggregate"
	},
	country_code_aggregate:{
		aggregate:"country_code_aggregate_fields",
		nodes:"country_code"
	},
	country_code_aggregate_fields:{
		count:"Int",
		max:"country_code_max_fields",
		min:"country_code_min_fields"
	},
	country_code_max_fields:{
		code:"String",
		country:"String"
	},
	country_code_min_fields:{
		code:"String",
		country:"String"
	},
	country_code_mutation_response:{
		affected_rows:"Int",
		returning:"country_code"
	},
	deposit_rate:{
		name:"String",
		rental_contracts:"rental_contract",
		rental_contracts_aggregate:"rental_contract_aggregate"
	},
	deposit_rate_aggregate:{
		aggregate:"deposit_rate_aggregate_fields",
		nodes:"deposit_rate"
	},
	deposit_rate_aggregate_fields:{
		count:"Int",
		max:"deposit_rate_max_fields",
		min:"deposit_rate_min_fields"
	},
	deposit_rate_max_fields:{
		name:"String"
	},
	deposit_rate_min_fields:{
		name:"String"
	},
	deposit_rate_mutation_response:{
		affected_rows:"Int",
		returning:"deposit_rate"
	},
	deposit_type:{
		name:"String",
		rental_contracts:"rental_contract",
		rental_contracts_aggregate:"rental_contract_aggregate"
	},
	deposit_type_aggregate:{
		aggregate:"deposit_type_aggregate_fields",
		nodes:"deposit_type"
	},
	deposit_type_aggregate_fields:{
		count:"Int",
		max:"deposit_type_max_fields",
		min:"deposit_type_min_fields"
	},
	deposit_type_max_fields:{
		name:"String"
	},
	deposit_type_min_fields:{
		name:"String"
	},
	deposit_type_mutation_response:{
		affected_rows:"Int",
		returning:"deposit_type"
	},
	group:{
		abbreviation:"String",
		color:"color_enum",
		id:"uuid",
		name:"String",
		object_color_type:"color"
	},
	group_aggregate:{
		aggregate:"group_aggregate_fields",
		nodes:"group"
	},
	group_aggregate_fields:{
		count:"Int",
		max:"group_max_fields",
		min:"group_min_fields"
	},
	group_max_fields:{
		abbreviation:"String",
		id:"uuid",
		name:"String"
	},
	group_min_fields:{
		abbreviation:"String",
		id:"uuid",
		name:"String"
	},
	group_mutation_response:{
		affected_rows:"Int",
		returning:"group"
	},
	mutation_root:{
		delete_color:"color_mutation_response",
		delete_color_by_pk:"color",
		delete_country_code:"country_code_mutation_response",
		delete_country_code_by_pk:"country_code",
		delete_deposit_rate:"deposit_rate_mutation_response",
		delete_deposit_rate_by_pk:"deposit_rate",
		delete_deposit_type:"deposit_type_mutation_response",
		delete_deposit_type_by_pk:"deposit_type",
		delete_group:"group_mutation_response",
		delete_group_by_pk:"group",
		delete_property:"property_mutation_response",
		delete_property_by_pk:"property",
		delete_property_type:"property_type_mutation_response",
		delete_property_type_by_pk:"property_type",
		delete_rent_index:"rent_index_mutation_response",
		delete_rent_index_area:"rent_index_area_mutation_response",
		delete_rent_index_area_by_pk:"rent_index_area",
		delete_rent_index_by_pk:"rent_index",
		delete_rent_index_layer_type:"rent_index_layer_type_mutation_response",
		delete_rent_index_layer_type_by_pk:"rent_index_layer_type",
		delete_rental_contract:"rental_contract_mutation_response",
		delete_rental_contract_by_pk:"rental_contract",
		delete_rental_costs:"rental_costs_mutation_response",
		delete_rental_costs_by_pk:"rental_costs",
		delete_rental_costs_type:"rental_costs_type_mutation_response",
		delete_rental_costs_type_by_pk:"rental_costs_type",
		delete_rental_rent:"rental_rent_mutation_response",
		delete_rental_rent_by_pk:"rental_rent",
		delete_unit:"unit_mutation_response",
		delete_unit_by_pk:"unit",
		delete_unit_type:"unit_type_mutation_response",
		delete_unit_type_by_pk:"unit_type",
		delete_user:"user_mutation_response",
		delete_user_by_pk:"user",
		insert_color:"color_mutation_response",
		insert_color_one:"color",
		insert_country_code:"country_code_mutation_response",
		insert_country_code_one:"country_code",
		insert_deposit_rate:"deposit_rate_mutation_response",
		insert_deposit_rate_one:"deposit_rate",
		insert_deposit_type:"deposit_type_mutation_response",
		insert_deposit_type_one:"deposit_type",
		insert_group:"group_mutation_response",
		insert_group_one:"group",
		insert_property:"property_mutation_response",
		insert_property_one:"property",
		insert_property_type:"property_type_mutation_response",
		insert_property_type_one:"property_type",
		insert_rent_index:"rent_index_mutation_response",
		insert_rent_index_area:"rent_index_area_mutation_response",
		insert_rent_index_area_one:"rent_index_area",
		insert_rent_index_layer_type:"rent_index_layer_type_mutation_response",
		insert_rent_index_layer_type_one:"rent_index_layer_type",
		insert_rent_index_one:"rent_index",
		insert_rental_contract:"rental_contract_mutation_response",
		insert_rental_contract_one:"rental_contract",
		insert_rental_costs:"rental_costs_mutation_response",
		insert_rental_costs_one:"rental_costs",
		insert_rental_costs_type:"rental_costs_type_mutation_response",
		insert_rental_costs_type_one:"rental_costs_type",
		insert_rental_rent:"rental_rent_mutation_response",
		insert_rental_rent_one:"rental_rent",
		insert_unit:"unit_mutation_response",
		insert_unit_one:"unit",
		insert_unit_type:"unit_type_mutation_response",
		insert_unit_type_one:"unit_type",
		insert_user:"user_mutation_response",
		insert_user_one:"user",
		update_color:"color_mutation_response",
		update_color_by_pk:"color",
		update_country_code:"country_code_mutation_response",
		update_country_code_by_pk:"country_code",
		update_deposit_rate:"deposit_rate_mutation_response",
		update_deposit_rate_by_pk:"deposit_rate",
		update_deposit_type:"deposit_type_mutation_response",
		update_deposit_type_by_pk:"deposit_type",
		update_group:"group_mutation_response",
		update_group_by_pk:"group",
		update_property:"property_mutation_response",
		update_property_by_pk:"property",
		update_property_type:"property_type_mutation_response",
		update_property_type_by_pk:"property_type",
		update_rent_index:"rent_index_mutation_response",
		update_rent_index_area:"rent_index_area_mutation_response",
		update_rent_index_area_by_pk:"rent_index_area",
		update_rent_index_by_pk:"rent_index",
		update_rent_index_layer_type:"rent_index_layer_type_mutation_response",
		update_rent_index_layer_type_by_pk:"rent_index_layer_type",
		update_rental_contract:"rental_contract_mutation_response",
		update_rental_contract_by_pk:"rental_contract",
		update_rental_costs:"rental_costs_mutation_response",
		update_rental_costs_by_pk:"rental_costs",
		update_rental_costs_type:"rental_costs_type_mutation_response",
		update_rental_costs_type_by_pk:"rental_costs_type",
		update_rental_rent:"rental_rent_mutation_response",
		update_rental_rent_by_pk:"rental_rent",
		update_unit:"unit_mutation_response",
		update_unit_by_pk:"unit",
		update_unit_type:"unit_type_mutation_response",
		update_unit_type_by_pk:"unit_type",
		update_user:"user_mutation_response",
		update_user_by_pk:"user"
	},
	property:{
		city:"String",
		country:"country_code_enum",
		country_code:"country_code",
		id:"uuid",
		lat:"Int",
		lng:"Int",
		name:"String",
		number:"String",
		postal_code:"numeric",
		property_type:"property_type",
		region:"String",
		state:"String",
		street:"String",
		type:"property_type_enum",
		units:"unit",
		units_aggregate:"unit_aggregate"
	},
	property_aggregate:{
		aggregate:"property_aggregate_fields",
		nodes:"property"
	},
	property_aggregate_fields:{
		avg:"property_avg_fields",
		count:"Int",
		max:"property_max_fields",
		min:"property_min_fields",
		stddev:"property_stddev_fields",
		stddev_pop:"property_stddev_pop_fields",
		stddev_samp:"property_stddev_samp_fields",
		sum:"property_sum_fields",
		var_pop:"property_var_pop_fields",
		var_samp:"property_var_samp_fields",
		variance:"property_variance_fields"
	},
	property_avg_fields:{
		lat:"Float",
		lng:"Float",
		postal_code:"Float"
	},
	property_max_fields:{
		city:"String",
		id:"uuid",
		lat:"Int",
		lng:"Int",
		name:"String",
		number:"String",
		postal_code:"numeric",
		region:"String",
		state:"String",
		street:"String"
	},
	property_min_fields:{
		city:"String",
		id:"uuid",
		lat:"Int",
		lng:"Int",
		name:"String",
		number:"String",
		postal_code:"numeric",
		region:"String",
		state:"String",
		street:"String"
	},
	property_mutation_response:{
		affected_rows:"Int",
		returning:"property"
	},
	property_stddev_fields:{
		lat:"Float",
		lng:"Float",
		postal_code:"Float"
	},
	property_stddev_pop_fields:{
		lat:"Float",
		lng:"Float",
		postal_code:"Float"
	},
	property_stddev_samp_fields:{
		lat:"Float",
		lng:"Float",
		postal_code:"Float"
	},
	property_sum_fields:{
		lat:"Int",
		lng:"Int",
		postal_code:"numeric"
	},
	property_type:{
		description:"String",
		name:"String",
		properties:"property",
		properties_aggregate:"property_aggregate"
	},
	property_type_aggregate:{
		aggregate:"property_type_aggregate_fields",
		nodes:"property_type"
	},
	property_type_aggregate_fields:{
		count:"Int",
		max:"property_type_max_fields",
		min:"property_type_min_fields"
	},
	property_type_max_fields:{
		description:"String",
		name:"String"
	},
	property_type_min_fields:{
		description:"String",
		name:"String"
	},
	property_type_mutation_response:{
		affected_rows:"Int",
		returning:"property_type"
	},
	property_var_pop_fields:{
		lat:"Float",
		lng:"Float",
		postal_code:"Float"
	},
	property_var_samp_fields:{
		lat:"Float",
		lng:"Float",
		postal_code:"Float"
	},
	property_variance_fields:{
		lat:"Float",
		lng:"Float",
		postal_code:"Float"
	},
	query_root:{
		color:"color",
		color_aggregate:"color_aggregate",
		color_by_pk:"color",
		country_code:"country_code",
		country_code_aggregate:"country_code_aggregate",
		country_code_by_pk:"country_code",
		deposit_rate:"deposit_rate",
		deposit_rate_aggregate:"deposit_rate_aggregate",
		deposit_rate_by_pk:"deposit_rate",
		deposit_type:"deposit_type",
		deposit_type_aggregate:"deposit_type_aggregate",
		deposit_type_by_pk:"deposit_type",
		group:"group",
		group_aggregate:"group_aggregate",
		group_by_pk:"group",
		property:"property",
		property_aggregate:"property_aggregate",
		property_by_pk:"property",
		property_type:"property_type",
		property_type_aggregate:"property_type_aggregate",
		property_type_by_pk:"property_type",
		rent_index:"rent_index",
		rent_index_aggregate:"rent_index_aggregate",
		rent_index_area:"rent_index_area",
		rent_index_area_aggregate:"rent_index_area_aggregate",
		rent_index_area_by_pk:"rent_index_area",
		rent_index_by_pk:"rent_index",
		rent_index_layer_type:"rent_index_layer_type",
		rent_index_layer_type_aggregate:"rent_index_layer_type_aggregate",
		rent_index_layer_type_by_pk:"rent_index_layer_type",
		rental_contract:"rental_contract",
		rental_contract_aggregate:"rental_contract_aggregate",
		rental_contract_by_pk:"rental_contract",
		rental_costs:"rental_costs",
		rental_costs_aggregate:"rental_costs_aggregate",
		rental_costs_by_pk:"rental_costs",
		rental_costs_type:"rental_costs_type",
		rental_costs_type_aggregate:"rental_costs_type_aggregate",
		rental_costs_type_by_pk:"rental_costs_type",
		rental_rent:"rental_rent",
		rental_rent_aggregate:"rental_rent_aggregate",
		rental_rent_by_pk:"rental_rent",
		unit:"unit",
		unit_aggregate:"unit_aggregate",
		unit_by_pk:"unit",
		unit_type:"unit_type",
		unit_type_aggregate:"unit_type_aggregate",
		unit_type_by_pk:"unit_type",
		user:"user",
		user_aggregate:"user_aggregate",
		user_by_pk:"user"
	},
	rent_index:{
		city:"String",
		data:"jsonb",
		date:"date",
		id:"uuid",
		postal_code:"numeric"
	},
	rent_index_aggregate:{
		aggregate:"rent_index_aggregate_fields",
		nodes:"rent_index"
	},
	rent_index_aggregate_fields:{
		avg:"rent_index_avg_fields",
		count:"Int",
		max:"rent_index_max_fields",
		min:"rent_index_min_fields",
		stddev:"rent_index_stddev_fields",
		stddev_pop:"rent_index_stddev_pop_fields",
		stddev_samp:"rent_index_stddev_samp_fields",
		sum:"rent_index_sum_fields",
		var_pop:"rent_index_var_pop_fields",
		var_samp:"rent_index_var_samp_fields",
		variance:"rent_index_variance_fields"
	},
	rent_index_area:{
		id:"uuid",
		layer_type:"rent_index_layer_type_enum",
		polygons:"jsonb",
		rent_index_layer_type:"rent_index_layer_type"
	},
	rent_index_area_aggregate:{
		aggregate:"rent_index_area_aggregate_fields",
		nodes:"rent_index_area"
	},
	rent_index_area_aggregate_fields:{
		count:"Int",
		max:"rent_index_area_max_fields",
		min:"rent_index_area_min_fields"
	},
	rent_index_area_max_fields:{
		id:"uuid"
	},
	rent_index_area_min_fields:{
		id:"uuid"
	},
	rent_index_area_mutation_response:{
		affected_rows:"Int",
		returning:"rent_index_area"
	},
	rent_index_avg_fields:{
		postal_code:"Float"
	},
	rent_index_layer_type:{
		description:"String",
		name:"String",
		rent_index_areas:"rent_index_area",
		rent_index_areas_aggregate:"rent_index_area_aggregate"
	},
	rent_index_layer_type_aggregate:{
		aggregate:"rent_index_layer_type_aggregate_fields",
		nodes:"rent_index_layer_type"
	},
	rent_index_layer_type_aggregate_fields:{
		count:"Int",
		max:"rent_index_layer_type_max_fields",
		min:"rent_index_layer_type_min_fields"
	},
	rent_index_layer_type_max_fields:{
		description:"String",
		name:"String"
	},
	rent_index_layer_type_min_fields:{
		description:"String",
		name:"String"
	},
	rent_index_layer_type_mutation_response:{
		affected_rows:"Int",
		returning:"rent_index_layer_type"
	},
	rent_index_max_fields:{
		city:"String",
		date:"date",
		id:"uuid",
		postal_code:"numeric"
	},
	rent_index_min_fields:{
		city:"String",
		date:"date",
		id:"uuid",
		postal_code:"numeric"
	},
	rent_index_mutation_response:{
		affected_rows:"Int",
		returning:"rent_index"
	},
	rent_index_stddev_fields:{
		postal_code:"Float"
	},
	rent_index_stddev_pop_fields:{
		postal_code:"Float"
	},
	rent_index_stddev_samp_fields:{
		postal_code:"Float"
	},
	rent_index_sum_fields:{
		postal_code:"numeric"
	},
	rent_index_var_pop_fields:{
		postal_code:"Float"
	},
	rent_index_var_samp_fields:{
		postal_code:"Float"
	},
	rent_index_variance_fields:{
		postal_code:"Float"
	},
	rental_contract:{
		begin:"timestamptz",
		cancelation_effective:"timestamptz",
		cancelation_period:"Int",
		cancelation_pronounced:"timestamptz",
		date:"timestamptz",
		deposit:"Int",
		depositRate:"deposit_rate",
		depositType:"deposit_type",
		deposit_payable:"timestamptz",
		deposit_rate:"deposit_rate_enum",
		deposit_received:"timestamptz",
		deposit_type:"deposit_type_enum",
		end:"timestamptz",
		handover_move_in:"timestamptz",
		handover_move_out:"timestamptz",
		id:"uuid",
		min:"timestamptz",
		other_agreements:"String",
		rental_rents:"rental_rent",
		rental_rents_aggregate:"rental_rent_aggregate"
	},
	rental_contract_aggregate:{
		aggregate:"rental_contract_aggregate_fields",
		nodes:"rental_contract"
	},
	rental_contract_aggregate_fields:{
		avg:"rental_contract_avg_fields",
		count:"Int",
		max:"rental_contract_max_fields",
		min:"rental_contract_min_fields",
		stddev:"rental_contract_stddev_fields",
		stddev_pop:"rental_contract_stddev_pop_fields",
		stddev_samp:"rental_contract_stddev_samp_fields",
		sum:"rental_contract_sum_fields",
		var_pop:"rental_contract_var_pop_fields",
		var_samp:"rental_contract_var_samp_fields",
		variance:"rental_contract_variance_fields"
	},
	rental_contract_avg_fields:{
		cancelation_period:"Float",
		deposit:"Float"
	},
	rental_contract_max_fields:{
		begin:"timestamptz",
		cancelation_effective:"timestamptz",
		cancelation_period:"Int",
		cancelation_pronounced:"timestamptz",
		date:"timestamptz",
		deposit:"Int",
		deposit_payable:"timestamptz",
		deposit_received:"timestamptz",
		end:"timestamptz",
		handover_move_in:"timestamptz",
		handover_move_out:"timestamptz",
		id:"uuid",
		min:"timestamptz",
		other_agreements:"String"
	},
	rental_contract_min_fields:{
		begin:"timestamptz",
		cancelation_effective:"timestamptz",
		cancelation_period:"Int",
		cancelation_pronounced:"timestamptz",
		date:"timestamptz",
		deposit:"Int",
		deposit_payable:"timestamptz",
		deposit_received:"timestamptz",
		end:"timestamptz",
		handover_move_in:"timestamptz",
		handover_move_out:"timestamptz",
		id:"uuid",
		min:"timestamptz",
		other_agreements:"String"
	},
	rental_contract_mutation_response:{
		affected_rows:"Int",
		returning:"rental_contract"
	},
	rental_contract_stddev_fields:{
		cancelation_period:"Float",
		deposit:"Float"
	},
	rental_contract_stddev_pop_fields:{
		cancelation_period:"Float",
		deposit:"Float"
	},
	rental_contract_stddev_samp_fields:{
		cancelation_period:"Float",
		deposit:"Float"
	},
	rental_contract_sum_fields:{
		cancelation_period:"Int",
		deposit:"Int"
	},
	rental_contract_var_pop_fields:{
		cancelation_period:"Float",
		deposit:"Float"
	},
	rental_contract_var_samp_fields:{
		cancelation_period:"Float",
		deposit:"Float"
	},
	rental_contract_variance_fields:{
		cancelation_period:"Float",
		deposit:"Float"
	},
	rental_costs:{
		costs:"Int",
		id:"uuid",
		name:"String",
		rent:"rental_rent",
		rent_id:"uuid",
		rental_costs_type:"rental_costs_type",
		type:"rental_costs_type_enum",
		unit:"unit",
		unit_id:"uuid",
		vat:"Int"
	},
	rental_costs_aggregate:{
		aggregate:"rental_costs_aggregate_fields",
		nodes:"rental_costs"
	},
	rental_costs_aggregate_fields:{
		avg:"rental_costs_avg_fields",
		count:"Int",
		max:"rental_costs_max_fields",
		min:"rental_costs_min_fields",
		stddev:"rental_costs_stddev_fields",
		stddev_pop:"rental_costs_stddev_pop_fields",
		stddev_samp:"rental_costs_stddev_samp_fields",
		sum:"rental_costs_sum_fields",
		var_pop:"rental_costs_var_pop_fields",
		var_samp:"rental_costs_var_samp_fields",
		variance:"rental_costs_variance_fields"
	},
	rental_costs_avg_fields:{
		costs:"Float",
		vat:"Float"
	},
	rental_costs_max_fields:{
		costs:"Int",
		id:"uuid",
		name:"String",
		rent_id:"uuid",
		unit_id:"uuid",
		vat:"Int"
	},
	rental_costs_min_fields:{
		costs:"Int",
		id:"uuid",
		name:"String",
		rent_id:"uuid",
		unit_id:"uuid",
		vat:"Int"
	},
	rental_costs_mutation_response:{
		affected_rows:"Int",
		returning:"rental_costs"
	},
	rental_costs_stddev_fields:{
		costs:"Float",
		vat:"Float"
	},
	rental_costs_stddev_pop_fields:{
		costs:"Float",
		vat:"Float"
	},
	rental_costs_stddev_samp_fields:{
		costs:"Float",
		vat:"Float"
	},
	rental_costs_sum_fields:{
		costs:"Int",
		vat:"Int"
	},
	rental_costs_type:{
		name:"String",
		rental_costs:"rental_costs",
		rental_costs_aggregate:"rental_costs_aggregate"
	},
	rental_costs_type_aggregate:{
		aggregate:"rental_costs_type_aggregate_fields",
		nodes:"rental_costs_type"
	},
	rental_costs_type_aggregate_fields:{
		count:"Int",
		max:"rental_costs_type_max_fields",
		min:"rental_costs_type_min_fields"
	},
	rental_costs_type_max_fields:{
		name:"String"
	},
	rental_costs_type_min_fields:{
		name:"String"
	},
	rental_costs_type_mutation_response:{
		affected_rows:"Int",
		returning:"rental_costs_type"
	},
	rental_costs_var_pop_fields:{
		costs:"Float",
		vat:"Float"
	},
	rental_costs_var_samp_fields:{
		costs:"Float",
		vat:"Float"
	},
	rental_costs_variance_fields:{
		costs:"Float",
		vat:"Float"
	},
	rental_rent:{
		announced:"timestamptz",
		begin:"timestamptz",
		id:"uuid",
		rental_contract:"rental_contract",
		rental_contract_id:"uuid",
		rental_costs:"rental_costs",
		rental_costs_aggregate:"rental_costs_aggregate"
	},
	rental_rent_aggregate:{
		aggregate:"rental_rent_aggregate_fields",
		nodes:"rental_rent"
	},
	rental_rent_aggregate_fields:{
		count:"Int",
		max:"rental_rent_max_fields",
		min:"rental_rent_min_fields"
	},
	rental_rent_max_fields:{
		announced:"timestamptz",
		begin:"timestamptz",
		id:"uuid",
		rental_contract_id:"uuid"
	},
	rental_rent_min_fields:{
		announced:"timestamptz",
		begin:"timestamptz",
		id:"uuid",
		rental_contract_id:"uuid"
	},
	rental_rent_mutation_response:{
		affected_rows:"Int",
		returning:"rental_rent"
	},
	subscription_root:{
		color:"color",
		color_aggregate:"color_aggregate",
		color_by_pk:"color",
		country_code:"country_code",
		country_code_aggregate:"country_code_aggregate",
		country_code_by_pk:"country_code",
		deposit_rate:"deposit_rate",
		deposit_rate_aggregate:"deposit_rate_aggregate",
		deposit_rate_by_pk:"deposit_rate",
		deposit_type:"deposit_type",
		deposit_type_aggregate:"deposit_type_aggregate",
		deposit_type_by_pk:"deposit_type",
		group:"group",
		group_aggregate:"group_aggregate",
		group_by_pk:"group",
		property:"property",
		property_aggregate:"property_aggregate",
		property_by_pk:"property",
		property_type:"property_type",
		property_type_aggregate:"property_type_aggregate",
		property_type_by_pk:"property_type",
		rent_index:"rent_index",
		rent_index_aggregate:"rent_index_aggregate",
		rent_index_area:"rent_index_area",
		rent_index_area_aggregate:"rent_index_area_aggregate",
		rent_index_area_by_pk:"rent_index_area",
		rent_index_by_pk:"rent_index",
		rent_index_layer_type:"rent_index_layer_type",
		rent_index_layer_type_aggregate:"rent_index_layer_type_aggregate",
		rent_index_layer_type_by_pk:"rent_index_layer_type",
		rental_contract:"rental_contract",
		rental_contract_aggregate:"rental_contract_aggregate",
		rental_contract_by_pk:"rental_contract",
		rental_costs:"rental_costs",
		rental_costs_aggregate:"rental_costs_aggregate",
		rental_costs_by_pk:"rental_costs",
		rental_costs_type:"rental_costs_type",
		rental_costs_type_aggregate:"rental_costs_type_aggregate",
		rental_costs_type_by_pk:"rental_costs_type",
		rental_rent:"rental_rent",
		rental_rent_aggregate:"rental_rent_aggregate",
		rental_rent_by_pk:"rental_rent",
		unit:"unit",
		unit_aggregate:"unit_aggregate",
		unit_by_pk:"unit",
		unit_type:"unit_type",
		unit_type_aggregate:"unit_type_aggregate",
		unit_type_by_pk:"unit_type",
		user:"user",
		user_aggregate:"user_aggregate",
		user_by_pk:"user"
	},
	unit:{
		id:"uuid",
		level:"Float",
		name:"String",
		property:"property",
		property_id:"uuid",
		rental_costs:"rental_costs",
		rental_costs_aggregate:"rental_costs_aggregate",
		rooms:"Float",
		size:"Float",
		size_heated:"Float",
		type:"unit_type_enum",
		unit_type:"unit_type"
	},
	unit_aggregate:{
		aggregate:"unit_aggregate_fields",
		nodes:"unit"
	},
	unit_aggregate_fields:{
		avg:"unit_avg_fields",
		count:"Int",
		max:"unit_max_fields",
		min:"unit_min_fields",
		stddev:"unit_stddev_fields",
		stddev_pop:"unit_stddev_pop_fields",
		stddev_samp:"unit_stddev_samp_fields",
		sum:"unit_sum_fields",
		var_pop:"unit_var_pop_fields",
		var_samp:"unit_var_samp_fields",
		variance:"unit_variance_fields"
	},
	unit_avg_fields:{
		level:"Float",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_max_fields:{
		id:"uuid",
		level:"Float",
		name:"String",
		property_id:"uuid",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_min_fields:{
		id:"uuid",
		level:"Float",
		name:"String",
		property_id:"uuid",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_mutation_response:{
		affected_rows:"Int",
		returning:"unit"
	},
	unit_stddev_fields:{
		level:"Float",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_stddev_pop_fields:{
		level:"Float",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_stddev_samp_fields:{
		level:"Float",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_sum_fields:{
		level:"Float",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_type:{
		description:"String",
		name:"String",
		units:"unit",
		units_aggregate:"unit_aggregate"
	},
	unit_type_aggregate:{
		aggregate:"unit_type_aggregate_fields",
		nodes:"unit_type"
	},
	unit_type_aggregate_fields:{
		count:"Int",
		max:"unit_type_max_fields",
		min:"unit_type_min_fields"
	},
	unit_type_max_fields:{
		description:"String",
		name:"String"
	},
	unit_type_min_fields:{
		description:"String",
		name:"String"
	},
	unit_type_mutation_response:{
		affected_rows:"Int",
		returning:"unit_type"
	},
	unit_var_pop_fields:{
		level:"Float",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_var_samp_fields:{
		level:"Float",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	unit_variance_fields:{
		level:"Float",
		rooms:"Float",
		size:"Float",
		size_heated:"Float"
	},
	user:{
		active:"Boolean",
		email:"String",
		id:"uuid",
		password:"String"
	},
	user_aggregate:{
		aggregate:"user_aggregate_fields",
		nodes:"user"
	},
	user_aggregate_fields:{
		count:"Int",
		max:"user_max_fields",
		min:"user_min_fields"
	},
	user_max_fields:{
		email:"String",
		id:"uuid",
		password:"String"
	},
	user_min_fields:{
		email:"String",
		id:"uuid",
		password:"String"
	},
	user_mutation_response:{
		affected_rows:"Int",
		returning:"user"
	}
}