import { ComponentProps, Ref, forwardRef } from 'react';
import { Item as DropdownItem } from '@radix-ui/react-dropdown-menu';
import { Item } from '@radix-ui/react-context-menu';
import { cx } from '@vechaiui/react';
import { useHybridMenuContext } from './provider';

export type HybridMenuItemType = ComponentProps<typeof Item>;

export const HybridMenuItem = forwardRef(
  (
    { className, disabled, ...rest }: HybridMenuItemType,
    ref: Ref<HTMLDivElement>,
  ) => {
    const { asContext } = useHybridMenuContext();
    const ItemComponent = asContext ? Item : DropdownItem;

    return (
      <ItemComponent
        ref={ref}
        disabled={disabled}
        className={cx(
          'flex items-center w-full px-3 h-8 flex-shrink-0 text-sm text-left cursor-base focus:outline-none',
          'focus:bg-neutral-100',
          'dark:focus:bg-neutral-700',
          disabled ? 'opacity-50' : '',
          className,
        )}
        {...rest}
      />
    );
  },
);
