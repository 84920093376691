import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cx } from '@vechaiui/react';
import { useSession } from '../../graphql';

export function Header() {
  const { pathname } = useLocation();
  const { logout } = useSession();

  const user = {
    imageUrl:
      'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  };
  const navigation = useMemo(
    () => [
      { name: 'Dashboard', href: '/', current: pathname === '/' },
      {
        name: 'Einheiten',
        href: '/units',
        current: pathname === '/units',
      },
    ],
    [pathname],
  );
  const userNavigation = useMemo(
    () => [{ name: 'Abmelden', href: '#', onClick: logout }],
    [logout],
  );

  return (
    <Disclosure as="nav" className="bg-primary-600 shadow z-0">
      {({ open }) => (
        <>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <Link to="/">
                    <h1 className="text-2xl tracking-tight font-black font-logo text-secondary-300">
                      vilicando
                      <span className="font-normal text-primary-100">.com</span>
                    </h1>
                  </Link>
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={cx(
                          item.current
                            ? 'bg-secondary-300 text-secondary-700 shadow'
                            : 'text-white hover:bg-primary-500 hover:bg-opacity-75',
                          'px-3 py-2 rounded-md text-sm font-medium hover:shadow-inner',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  <button
                    type="button"
                    className="p-1 bg-primary-600 rounded-full text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-600 focus:ring-white"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="max-w-xs bg-primary-600 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-600 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user.imageUrl}
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <Link
                                to={item.href}
                                onClick={item.onClick}
                                className={cx(
                                  active ? 'bg-neutral-100' : '',
                                  'block px-4 py-2 text-sm text-neutral-700',
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-primary-600 inline-flex items-center justify-center p-2 rounded-md text-primary-200 hover:text-white hover:bg-primary-500 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-primary-600 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={cx(
                    item.current
                      ? 'bg-secondary-300 text-primary-700'
                      : 'text-white hover:bg-primary-500 hover:bg-opacity-75',
                    'block px-3 py-2 rounded-md text-base font-medium',
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="pt-4 pb-3 px-2 space-y-1 border-t border-primary-700">
              {userNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  onClick={item.onClick}
                  className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-primary-500 hover:bg-opacity-75"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
