import { Badge } from '@vechaiui/react';
import {
  Cell,
  Column,
  useColumnOrder,
  useExpanded,
  useGroupBy,
  useSortBy,
  useTable,
} from 'react-table';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/outline';
import {
  EllipsisVerticalIcon,
  GroupIcon,
  SettingsIcon,
  UnGroupIcon,
} from '../../icons';
import { HybridMenuProps } from '../hybrid-menu';
import { SortableColumnsMenu } from './sortable-columns-menu';
import { useMemo, useState } from 'react';

export type TableViewType = 'detail' | 'compact';
export type TableCellType<D extends Record<string, unknown>, V> = Cell<D, V> & {
  view: TableViewType;
};

type TableType<D extends Record<string, unknown>> = {
  columns: Array<Column<D>>;
  data: Array<D>;
  contextMenu?: (
    props: Omit<HybridMenuProps, 'value' | 'items'> & { value: D },
  ) => JSX.Element;
};

export function Table<D extends Record<string, unknown>>({
  columns: _columns,
  data,
  contextMenu: ContextMenu,
}: TableType<D>) {
  const [view, setView] = useState<TableViewType>('detail');

  const columns = useMemo(() => {
    const columns = [..._columns];

    if (ContextMenu)
      columns.push({
        id: 'edit',
        Header: ({ allColumns, setColumnOrder }) => (
          <SortableColumnsMenu
            columns={allColumns}
            setColumnOrder={setColumnOrder}
            view={view}
            onChangeView={setView}
            className="block float-right p-0.5 border border-transparent rounded-full text-neutral-500 hover:text-primary-600 hover:ring-2 hover:ring-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <SettingsIcon className="h-4 w-auto text-primary-600 fill-current" />
          </SortableColumnsMenu>
        ),
        Cell: ({ row }: Cell<D, unknown>) => (
          <ContextMenu
            value={row.original}
            className="block float-right p-0.5 border border-transparent rounded-full text-neutral-500 hover:text-primary-600 hover:ring-2 hover:ring-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            <EllipsisVerticalIcon className="h-4 w-4 text-primary-600" />
          </ContextMenu>
        ),
      });

    return columns;
  }, [view, _columns]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
    isAllRowsExpanded,
    toggleAllRowsExpanded,
  } = useTable<D>(
    { columns, data },
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
  );

  return (
    <div className="absolute inset-0 max-w-full overflow-auto">
      <table {...getTableProps()} className="min-w-full divide-y">
        <thead>
          {headerGroups.map((headerGroup) => (
            <SortableColumnsMenu
              {...headerGroup.getHeaderGroupProps()}
              asContext
              columns={allColumns}
              setColumnOrder={setColumnOrder}
              view={view}
              onChangeView={setView}
              asChild
            >
              <tr className="bg-neutral-100">
                {headerGroup.headers.map((column, j) => (
                  <th
                    {...column.getHeaderProps()}
                    className={`${
                      j < headerGroup.headers.length - 1
                        ? 'px-2 sm:px-3 lg:px-4'
                        : 'px-0'
                    } first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8 py-2 text-left text-xs last:text-right font-medium text-neutral-600 uppercase tracking-wider`}
                  >
                    <div className="flex items-center space-x-4">
                      {!j && column.canGroupBy && column.isGrouped ? (
                        isAllRowsExpanded ? (
                          <ChevronDownIcon
                            onClick={() => toggleAllRowsExpanded(false)}
                            className="h-4 w-auto cursor-pointer"
                          />
                        ) : (
                          <ChevronRightIcon
                            onClick={() => toggleAllRowsExpanded(true)}
                            className="h-4 w-auto cursor-pointer"
                          />
                        )
                      ) : null}

                      <span
                        className="flex-grow"
                        {...column.getSortByToggleProps()}
                      >
                        {column.render('Header', { view })}
                      </span>

                      {column.isSorted || column.canGroupBy ? (
                        <div className="flex space-x-2">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ChevronDownIcon
                                onClick={() => column.toggleSortBy(false)}
                                className="h-4 w-auto cursor-pointer"
                              />
                            ) : (
                              <ChevronUpIcon
                                onClick={() => column.toggleSortBy(true)}
                                className="h-4 w-auto cursor-pointer"
                              />
                            )
                          ) : null}

                          {column.canGroupBy ? (
                            column.isGrouped ? (
                              <UnGroupIcon
                                {...column.getGroupByToggleProps()}
                                className="h-4 w-auto text-primary-600"
                              />
                            ) : (
                              <GroupIcon
                                {...column.getGroupByToggleProps()}
                                className="h-4 w-auto text-primary-600"
                              />
                            )
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            </SortableColumnsMenu>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            const content = (
              <tr>
                {row.cells.map((cell, k) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={`${
                        k < row.cells.length - 1
                          ? 'px-2 sm:px-3 lg:px-4'
                          : 'px-0'
                      } first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8 py-3 whitespace-nowrap text-sm text-neutral-900 last:text-right`}
                    >
                      {cell.isGrouped ? (
                        <div className="flex items-center">
                          {row.isExpanded ? (
                            <ChevronDownIcon
                              {...row.getToggleRowExpandedProps()}
                              className="h-4 w-auto"
                            />
                          ) : (
                            <ChevronRightIcon
                              {...row.getToggleRowExpandedProps()}
                              className="h-4 w-auto"
                            />
                          )}

                          <span className="flex-grow mx-4">
                            {cell.render('Cell', { view })}
                          </span>

                          <Badge color="primary">{row.subRows.length}</Badge>
                        </div>
                      ) : cell.isAggregated ? (
                        cell.render('Aggregated', { view })
                      ) : cell.isPlaceholder ? null : (
                        cell.render('Cell', { view })
                      )}
                    </td>
                  );
                })}
              </tr>
            );

            return ContextMenu ? (
              <ContextMenu
                {...row.getRowProps()}
                value={row.original}
                asContext
                asChild
                className={i % 2 === 0 ? 'bg-white' : 'bg-neutral-50 border-y'}
              >
                {content}
              </ContextMenu>
            ) : (
              content
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
