import { ComponentProps } from 'react';
import { Content } from '@radix-ui/react-context-menu';
import { Content as DropdownContent } from '@radix-ui/react-dropdown-menu';
import { useHybridMenuContext } from './provider';

export type HybridMenuContentProps = ComponentProps<typeof Content> &
  ComponentProps<typeof DropdownContent>;

export const HybridMenuContent = (props: HybridMenuContentProps) => {
  const { asContext } = useHybridMenuContext();
  const ContentComponent = asContext ? Content : DropdownContent;

  return <ContentComponent {...props} />;
};
