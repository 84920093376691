import {
  Children,
  PropsWithChildren,
  cloneElement,
  isValidElement,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HybridMenuGroup, HybridMenuGroupProps } from './group';
import { HybridMenuSortableItemType } from './sortable-item';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isTouchDevice } from '../../utils';

const DnDBackend = isTouchDevice() ? TouchBackend : HTML5Backend;

type HybridMenuSortableGroupProps = HybridMenuGroupProps &
  PropsWithChildren<{
    onChangeOrder: (oldIndex: number, newIndex: number) => void;
  }>;

export function HybridMenuSortableGroup({
  onChangeOrder,
  children,
  ...rest
}: HybridMenuSortableGroupProps) {
  return (
    <DndProvider backend={DnDBackend}>
      <HybridMenuGroup {...rest}>
        {Children.map(children, (child, index) =>
          isValidElement(child)
            ? cloneElement(child, {
                index,
                onHover: (item: HybridMenuSortableItemType) => {
                  if (item.index === index) return;

                  onChangeOrder(item.index || 0, index);
                  item.index = index;
                },
              })
            : child,
        )}
      </HybridMenuGroup>
    </DndProvider>
  );
}
