export function Footer() {
  return (
    <footer className="flex items-center justify-center h-10 bg-neutral-200 text-neutral-500 text-sm">
      Powered by
      <span className="ml-1 tracking-tight text-primary-600 font-logo">
        vilicando.com
      </span>
    </footer>
  );
}
