import { GraphQLTypes, InputType, ValueTypes, Zeus } from './zeus/index';
import {
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import type {
  LazyQueryHookOptions,
  MutationHookOptions,
  QueryHookOptions,
  SubscriptionHookOptions,
} from '@apollo/client';

export function useTypedMutation<Z, O extends 'mutation_root'>(
  mutation: Z | ValueTypes[O],
  options?: MutationHookOptions<InputType<GraphQLTypes[O], Z>> & {
    operationName?: string;
  },
) {
  const { operationName, ...rest } = options || {};

  return useMutation<InputType<GraphQLTypes[O], Z>>(
    gql(Zeus('mutation', mutation, operationName)),
    rest,
  );
}
export function useTypedQuery<Z, O extends 'query_root'>(
  query: Z | ValueTypes[O],
  options?: QueryHookOptions<InputType<GraphQLTypes[O], Z>> & {
    operationName?: string;
  },
) {
  const { operationName, ...rest } = options || {};

  return useQuery<InputType<GraphQLTypes[O], Z>>(
    gql(Zeus('query', query, operationName)),
    rest,
  );
}
export function useTypedLazyQuery<Z, O extends 'query_root'>(
  LazyQuery: Z | ValueTypes[O],
  options?: LazyQueryHookOptions<InputType<GraphQLTypes[O], Z>> & {
    operationName?: string;
  },
) {
  const { operationName, ...rest } = options || {};

  return useLazyQuery<InputType<GraphQLTypes[O], Z>>(
    gql(Zeus('query', LazyQuery, operationName)),
    rest,
  );
}
export function useTypedSubscription<Z, O extends 'subscription_root'>(
  subscription: Z | ValueTypes[O],
  options?: SubscriptionHookOptions<InputType<GraphQLTypes[O], Z>> & {
    operationName?: string;
  },
) {
  const { operationName, ...rest } = options || {};

  return useSubscription<InputType<GraphQLTypes[O], Z>>(
    gql(Zeus('subscription', subscription, operationName)),
    rest,
  );
}
