import { Login } from '../views';
import { PropsWithChildren, Suspense, lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSession } from '../graphql';
import NProgress from 'nprogress';

const Home = lazy(() => import('../pages/home'));
const Units = lazy(() => import('../pages/units'));

const LazyLoad = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  });

  return null;
};

function PrivatePage({ children }: PropsWithChildren<unknown>) {
  const { session } = useSession();

  return session.id ? <>{children}</> : <Login />;
}

export function Router() {
  return (
    <Suspense fallback={<LazyLoad />}>
      <Routes>
        <Route path="/">
          <Route
            path="units"
            element={
              <PrivatePage>
                <Units />
              </PrivatePage>
            }
          />
          <Route
            index
            element={
              <PrivatePage>
                <Home />
              </PrivatePage>
            }
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
