import { Badge, Icon, cx } from '@vechaiui/react';
import { ElementType, Fragment } from 'react';
import { Tab } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

type TabsType = {
  bgColor?: string;
  items?: Array<{ label?: string; icon?: ElementType; count?: number }>;
};

export function Tabs({
  items,
  bgColor = 'bg-white' /* bg-neutral-100 */,
}: TabsType) {
  return (
    <Tab.Group
      as="div"
      className="flex flex-col -mb-px self-end"
      defaultIndex={0}
    >
      <Tab.List
        aria-label="tabs example"
        className="flex flex-row justify-start"
      >
        {items?.map((tab, i) => (
          <Tab as={Fragment} key={i}>
            {({ selected }) => (
              <div
                className={cx(
                  'px-3 py-2 -mb-px text-sm text-center whitespace-nowrap cursor-base focus:outline-none',
                  'flex-shrink-0 inline-block',
                  'hover:text-neutral-900',
                  selected
                    ? `${bgColor} text-neutral-700 rounded-tl-md rounded-tr-md shadow`
                    : 'bg-transparent text-neutral-600',
                )}
              >
                {!!tab.icon && (
                  <Icon
                    as={tab.icon}
                    label={tab.label || 'Tab Icon'}
                    className={cx(
                      'w-4 h-4 mr-1',
                      selected ? 'text-primary-600' : '',
                    )}
                  />
                )}

                <span className="ml-1">{tab.label}</span>

                {!!tab.count && <Badge className="ml-2">{tab.count}</Badge>}

                {selected && (
                  <Icon
                    as={XIcon}
                    label="Tab schließen"
                    className="h-3 w-3 ml-3 text-neutral-500"
                  />
                )}
              </div>
            )}
          </Tab>
        ))}
      </Tab.List>
      {/* <Tab.Panels>
        {items?.map((tab, i) => (
          <Tab.Panel
            key={i}
            // value={tab.value}
            className="p-4 flex-grow-1"
          >
            {tab.content}
          </Tab.Panel>
        ))}
      </Tab.Panels> */}
    </Tab.Group>
  );
}
