import { ColumnInstance, IdType } from 'react-table';
import { HybridMenu, HybridMenuProps } from '../hybrid-menu';
import { TableViewType } from '.';
import { cx } from '@vechaiui/react';
import { useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataType = any;

type SortableColumnsMenuProps = Omit<HybridMenuProps, 'items'> & {
  columns: Array<ColumnInstance<DataType>>;
  setColumnOrder: (
    updater:
      | ((columnOrder: IdType<DataType>[]) => IdType<DataType>[])
      | IdType<DataType>[],
  ) => void;
  view?: TableViewType;
  onChangeView?: (view: TableViewType) => void;
};

export function SortableColumnsMenu({
  asContext,
  columns,
  setColumnOrder,
  view,
  onChangeView,
  children,
  ...rest
}: SortableColumnsMenuProps) {
  const updateColumnOrder = useCallback(
    (oldIndex: number, newIndex: number) => {
      const array = columns.map((d) => d.id);
      const item = array[oldIndex];

      array.splice(oldIndex, 1); // delete on old index
      array.splice(newIndex, 0, item); // insert on new index

      setColumnOrder(array);
    },
    [columns, setColumnOrder],
  );

  return (
    <HybridMenu
      {...rest}
      asContext={asContext}
      items={
        <>
          <HybridMenu
            asSubMenu
            className={cx(
              'flex items-center w-full px-3 h-8 flex-shrink-0 text-sm text-left cursor-base focus:outline-none',
              'focus:bg-neutral-100 dark:focus:bg-neutral-700',
            )}
            items={
              <HybridMenu.SortableGroup onChangeOrder={updateColumnOrder}>
                {columns.map((column, i) => {
                  const { checked, onChange, ...rest } =
                    column.getToggleHiddenProps();

                  return i < columns.length - 1 ? (
                    <HybridMenu.SortableItem
                      key={i}
                      id={column.id}
                      as={HybridMenu.CheckboxItem}
                      checked={checked}
                      disabled={column.isGrouped}
                      onSelect={(e) =>
                        onChange({
                          ...e,
                          target: { ...e.target, checked: !checked },
                        })
                      }
                      {...rest}
                    >
                      {column.render('Header', { view: 'compact' })}
                    </HybridMenu.SortableItem>
                  ) : null;
                })}
              </HybridMenu.SortableGroup>
            }
          >
            <span className="flex-1 mr-2">Anordnung</span>
          </HybridMenu>

          {onChangeView ? (
            <HybridMenu
              asSubMenu
              className={cx(
                'flex items-center w-full px-3 h-8 flex-shrink-0 text-sm text-left cursor-base focus:outline-none',
                'focus:bg-neutral-100 dark:focus:bg-neutral-700',
              )}
              items={
                <HybridMenu.RadioGroup
                  value={view}
                  onValueChange={(value) =>
                    onChangeView(value as TableViewType)
                  }
                >
                  <HybridMenu.RadioItem value="detail">
                    Detailansicht
                  </HybridMenu.RadioItem>
                  <HybridMenu.RadioItem value="compact">
                    Kompaktansicht
                  </HybridMenu.RadioItem>
                </HybridMenu.RadioGroup>
              }
            >
              <span className="flex-1 mr-2">Ansicht</span>
            </HybridMenu>
          ) : null}
        </>
      }
    >
      {children}
    </HybridMenu>
  );
}
