import { Footer } from './footer';
import { Header } from './header';
import { PropsWithChildren } from 'react';
import { useSession } from '../../graphql';

type LayoutType = PropsWithChildren<unknown>;

export function Layout({ children }: LayoutType) {
  const { session } = useSession();

  return session.id ? (
    <div className="min-h-screen bg-white flex flex-col">
      <Header />

      {children}

      <Footer />
    </div>
  ) : (
    <>{children}</>
  );
}
