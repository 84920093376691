import { ComponentProps, Ref, forwardRef } from 'react';
import { Trigger as DropdownTrigger } from '@radix-ui/react-dropdown-menu';
import { Trigger } from '@radix-ui/react-context-menu';
import { useHybridMenuContext } from './provider';

export type HybridMenuTriggerType = HTMLSpanElement &
  HTMLButtonElement &
  HTMLDivElement;
export type HybridMenuTriggerProps = ComponentProps<typeof Trigger> &
  ComponentProps<typeof DropdownTrigger>;

export const HybridMenuTrigger = forwardRef(
  (props: HybridMenuTriggerProps, ref: Ref<HybridMenuTriggerType>) => {
    const { asContext } = useHybridMenuContext();

    return asContext ? (
      <Trigger {...props} ref={ref} />
    ) : (
      <DropdownTrigger {...props} ref={ref} />
    );
  },
);
