import { PropsWithChildren } from 'react';

export type DrawerContentType = PropsWithChildren<unknown>;

export function DrawerContent({ children }: DrawerContentType) {
  return (
    <div className="min-h-0 flex-1 overflow-y-scroll relative py-6 px-4 sm:px-6">
      {children}
    </div>
  );
}
