import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  useMessage,
} from '@vechaiui/react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  UnpackNestedValue,
  UseFormProps,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { Dialog } from './dialog';
import { Drawer, DrawerType } from './drawer';
import { ErrorMessage } from '@hookform/error-message';
import { FetchResult, MutationFunctionOptions } from '@apollo/client';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { usePrevious } from '../utils';

type FieldType<T> = {
  name: Path<T>;
  title: string;
  placeholder?: string;
  required?: string;
  render: (
    props: ControllerRenderProps<T>,
    origin?: UnpackNestedValue<T>,
  ) => JSX.Element;
  options?: RegisterOptions;
};
type FieldsType<T> = Array<FieldType<T> | FieldsType<T>>;

export type FormType<T> = DrawerType & {
  name: string;
  value?: T;
  description?: ReactNode;
  fields: FieldsType<T>;
  onSave: (value: UnpackNestedValue<T>) => Promise<FetchResult>;
  onDelete?: (options?: MutationFunctionOptions) => Promise<FetchResult>;
  onClose: () => void;
  form?: UseFormReturn<T>;
  subForm?: ReactNode;
  options?: UseFormProps<T>;
};

export function Form<T extends FieldValues>({
  name,
  value,
  description,
  fields,
  onSave,
  onDelete,
  onClose,
  options,
  form = useForm<T>(options),
  subForm,
  ...props
}: FormType<T>) {
  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const message = useMessage();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, dirtyFields },
  } = form;

  const prevValue = usePrevious(value);
  useEffect(() => {
    if (prevValue?.id !== value?.id) {
      reset();

      if (value)
        (Object.keys(value) as Array<Path<T>>).map((key) =>
          setValue(key, value[key]),
        );
    }
  }, [value?.id, prevValue?.id]);

  const onSubmit = useCallback(
    handleSubmit(async (val) => {
      setLoading(true);

      onSave({ id: value?.id, ...val }).then(({ data }) => {
        if (data) {
          message({
            message: `${name} erfolgreich ${
              value?.id ? 'bearbeitet' : 'angelegt'
            }!`,
            status: 'success',
            position: 'bottom-left',
          });
          onClose();
        } else {
          message({
            message: 'Unbekannter Fehler aufgetreten!',
            status: 'error',
            position: 'bottom-left',
          });
        }

        setLoading(false);
      });
    }),
    [value, name, onSave],
  );

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const onCloseDeleteDialog = useCallback(async () => {
    setOpenDeleteDialog(false);

    if (onDelete && value?.id) {
      setLoading(true);

      onDelete({ variables: { id: value.id } }).then(({ data }) => {
        if (data) {
          message({
            message: `${name} erfolgreich gelöscht!`,
            status: 'success',
            position: 'bottom-left',
          });
          onClose();
        } else {
          message({
            message: 'Unbekannter Fehler aufgetreten!',
            status: 'error',
            position: 'bottom-left',
          });
        }

        setLoading(false);
      });
    }
  }, [value?.id, name, onDelete]);

  const [openDialog, setOpenDialog] = useState(false);
  const onCloseDialog = useCallback(() => {
    setOpenDialog(false);
    onClose();
  }, [onClose]);
  const onCloseForm = useCallback(() => {
    if (Object.keys(dirtyFields).length) setOpenDialog(true);
    else onClose();
  }, [onClose, dirtyFields]);

  const getFields = useCallback(
    (fields: FieldsType<T>, index = 0) =>
      fields.map((field, i) =>
        Array.isArray(field) ? (
          <div key={i} className="flex space-x-4">
            {getFields(field, index + i)}
          </div>
        ) : (
          <FormControl
            key={field.name}
            disabled={!!loading}
            required={!!field.required}
            invalid={!!(field.name in errors)}
          >
            <FormLabel htmlFor={field.name}>{field.title}</FormLabel>

            <Controller
              control={control}
              name={field.name}
              rules={{ required: field.required }}
              render={({ field: _field }) =>
                field.render(
                  {
                    ..._field,
                    value:
                      _field.value ||
                      ('' as UnpackNestedValue<PathValue<T, Path<T>>>),
                    ref: (el: HTMLInputElement) => {
                      _field.ref(el);

                      // only first input will be focused
                      if (!(index + i) && el)
                        (
                          ref as React.MutableRefObject<HTMLInputElement>
                        ).current = el;
                    },
                  },
                  watch(),
                )
              }
            />

            <ErrorMessage
              errors={errors}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              name={field.name}
              render={({ message }) => (
                <FormHelperText className="text-error-600">
                  {message}
                </FormHelperText>
              )}
            />
          </FormControl>
        ),
      ),
    [],
  );

  return (
    <Drawer open={!!value} onClose={onCloseForm} initialFocus={ref} {...props}>
      <Drawer.Overlay />
      <Drawer.Bar>
        <Drawer.Header
          title={value?.id ? `${name} bearbeiten` : `Neue ${name}`}
        >
          {description}
        </Drawer.Header>
        <Drawer.Content>
          <form onSubmit={onSubmit}>
            <div className="space-y-6">{getFields(fields)}</div>
          </form>
        </Drawer.Content>
        <Drawer.Footer>
          <Button.Group className="space-x-2 w-full flex">
            <Button onClick={onCloseForm} className="flex-1">
              Abbrechen
            </Button>
            {!!onDelete && (
              <Button
                color="primary"
                variant="light"
                loading={loading}
                disabled={!value?.id}
                onClick={() => setOpenDeleteDialog(true)}
                className="flex-1"
              >
                Löschen
              </Button>
            )}
            <Button
              color="primary"
              variant="solid"
              loading={loading}
              onClick={onSubmit}
              className="flex-1"
            >
              Speichern
            </Button>
          </Button.Group>
        </Drawer.Footer>
      </Drawer.Bar>

      <Dialog
        open={openDialog}
        title="Änderungen verwerfen?"
        status="warning"
        onOk={onCloseDialog}
        onCancel={() => setOpenDialog(false)}
      >
        Es wurden ungespeicherte Daten gefunden. Möchten Sie diese wirklich
        verwerfen?
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        title={`${name} löschen?`}
        status="error"
        onOk={onCloseDeleteDialog}
        onCancel={() => setOpenDeleteDialog(false)}
      >
        Möchten Sie das Element wirklich löschen? Dies kann nicht rückgängig
        gemacht werden!
      </Dialog>

      {subForm}
    </Drawer>
  );
}
