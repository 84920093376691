import { ChevronRightIcon } from '@heroicons/react/outline';
import { ComponentProps, Ref, forwardRef } from 'react';
import { TriggerItem as DropdownTriggerItem } from '@radix-ui/react-dropdown-menu';
import { HybridMenuIcon } from './icon';
import { HybridMenuTriggerType } from './trigger';
import { TriggerItem } from '@radix-ui/react-context-menu';
import { useHybridMenuContext } from './provider';

export type HybridMenuTriggerItemProps = ComponentProps<typeof TriggerItem> &
  ComponentProps<typeof DropdownTriggerItem>;

export const HybridMenuTriggerItem = forwardRef(
  (
    { children, ...rest }: HybridMenuTriggerItemProps,
    ref: Ref<HybridMenuTriggerType>,
  ) => {
    const { asContext } = useHybridMenuContext();
    const TriggerComponent = asContext ? TriggerItem : DropdownTriggerItem;

    return (
      <TriggerComponent {...rest} ref={ref}>
        {children}

        <HybridMenuIcon
          as={ChevronRightIcon}
          label="chevron-right"
          variant="suffix"
        />
      </TriggerComponent>
    );
  },
);
