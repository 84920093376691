import {
  ContractIcon,
  PropertyRegularIcon,
  UnitRegularIcon,
} from '../../icons';
import { HybridMenu, HybridMenuProps } from '../../components';
import { TagIcon } from '@heroicons/react/outline';
import { cx } from '@vechaiui/react';

type ContextMenuType = Omit<HybridMenuProps, 'items'> & {
  onEditContract?: () => void;
  onEditUnit?: () => void;
  onEditProperty?: () => void;
};

export function ContextMenu({
  onEditContract,
  onEditUnit,
  onEditProperty,
  ...rest
}: ContextMenuType) {
  return (
    <HybridMenu
      items={
        <>
          <HybridMenu.Label indented>Aktionen</HybridMenu.Label>
          {!!onEditContract && (
            <HybridMenu.Item onSelect={onEditContract}>
              <HybridMenu.Icon as={ContractIcon} label="arrows-expand" />
              Mietvertrag bearbeiten
            </HybridMenu.Item>
          )}
          {!!onEditUnit && (
            <HybridMenu.Item onSelect={onEditUnit}>
              <HybridMenu.Icon as={UnitRegularIcon} label="arrows-expand" />
              Einheit bearbeiten
            </HybridMenu.Item>
          )}
          <HybridMenu.Item onSelect={onEditProperty}>
            <HybridMenu.Icon as={PropertyRegularIcon} label="arrows-expand" />
            Immobilie bearbeiten
          </HybridMenu.Item>

          <HybridMenu.Separator />

          <HybridMenu
            asSubMenu
            className={cx(
              'flex items-center w-full px-3 h-8 flex-shrink-0 text-sm text-left cursor-base focus:outline-none',
              'focus:bg-neutral-100 dark:focus:bg-neutral-700',
            )}
            items={
              <>
                <HybridMenu.CheckboxItem disabled checked>
                  <span className="w-2 h-2 mr-3 bg-red-500 rounded-full" />
                  Bug
                </HybridMenu.CheckboxItem>
                <HybridMenu.CheckboxItem disabled>
                  <span className="w-2 h-2 mr-3 bg-purple-500 rounded-full" />
                  Feature
                </HybridMenu.CheckboxItem>
                <HybridMenu.CheckboxItem disabled checked>
                  <span className="w-2 h-2 mr-3 bg-blue-500 rounded-full" />
                  Improvement
                </HybridMenu.CheckboxItem>
              </>
            }
          >
            <HybridMenu.Icon as={TagIcon} label="tag" />
            <span className="flex-1 mr-2">Label</span>
          </HybridMenu>
        </>
      }
      {...rest}
    />
  );
}
