import { Ref, forwardRef } from 'react';
import { TreeSelectEdit, TreeSelectEditType } from '.';

// die Arten der einzelnen Einheiten, die "in" den Gebäuden dann sein können
export const UNIT_TYPES = {
  ERDGESCHOSSWOHNUNG: 'Erdgeschosswohnung',
  SOUTERRAIN: 'Souterrainwohnung',
  TERASSENWOHNUNG: 'Terrassenwohnung',
  HOCHPARTERRE: 'Hochparterrewohnung',
  ETAGENWOHNUNG: 'Etagenwohnung',
  DACHGESCHOSSWOHNUNG: 'Dachgeschosswohnung',
  LOFT: 'Loftwohnung',
  MAISONETTE: 'Maisonettewohnung',
  PENTHOUSE: 'Penthousewohnung',
  SONSTIGE_WOHNEINHEIT: 'Sonstiges',
  BUERO: 'Büro',
  PRAXIS: 'Praxis',
  GASTRONOMIE: 'Gastronomie',
  HOTEL: 'Hotel',
  HALLE: 'Halle', // => Gebäude? Dafür hier Lager oder sowas ergänzen?
  PRODUKTION: 'Produktion',
  EINZELHANDEL: 'Einzelhandel',
  SPEZIALGEWERBE: 'Spezialgewerbe',
  GEWERBEGRUNDSTUECK: 'Gewerbegrundstück', // => Gebäude?
  SONSTIGE_GEWERBEEINHEIT: 'Sonstiges',
  STELLPLATZ: 'Stellplatz',
  GARAGE: 'Garage',
  DOPPELGARAGE: 'Doppelgarage',
  CARPORT: 'Carport',
  DUPLEX: 'Duplex-Garagenstellplatz',
  BOOTSLIEGEPLATZ: 'Bootsliegeplatz',
  PARKHAUS: 'Parkhaus', // => Gebäude?
  TIEFGARAGENSTELLPLATZ: 'Tiefgaragenstellplatz',
  PARKPLATZLADESTATION: 'Parkplatz mit Ladestation',
  SONSTIGE_STELLPLATZ_GARAGE: 'Sonstiges',
  KELLER: 'Keller',
  DACHBODEN: 'Dachboden',
  GARTEN: 'Garten',
  SONSTIGES: 'Sonstiges',
};

const items = [
  {
    label: 'Wohneinheit',
    items: {
      ERDGESCHOSSWOHNUNG: UNIT_TYPES['ERDGESCHOSSWOHNUNG'],
      SOUTERRAIN: UNIT_TYPES['SOUTERRAIN'],
      TERASSENWOHNUNG: UNIT_TYPES['TERASSENWOHNUNG'],
      HOCHPARTERRE: UNIT_TYPES['HOCHPARTERRE'],
      ETAGENWOHNUNG: UNIT_TYPES['ETAGENWOHNUNG'],
      DACHGESCHOSSWOHNUNG: UNIT_TYPES['DACHGESCHOSSWOHNUNG'],
      LOFT: UNIT_TYPES['LOFT'],
      MAISONETTE: UNIT_TYPES['MAISONETTE'],
      PENTHOUSE: UNIT_TYPES['PENTHOUSE'],
      SONSTIGE_WOHNEINHEIT: UNIT_TYPES['SONSTIGE_WOHNEINHEIT'],
    },
  },
  {
    label: 'Gewerbeeinheit',
    items: {
      BUERO: UNIT_TYPES['BUERO'],
      PRAXIS: UNIT_TYPES['PRAXIS'],
      GASTRONOMIE: UNIT_TYPES['GASTRONOMIE'],
      HOTEL: UNIT_TYPES['HOTEL'],
      HALLE: UNIT_TYPES['HALLE'], // => Gebäude? Dafür hier Lager oder sowas ergänzen?
      PRODUKTION: UNIT_TYPES['PRODUKTION'],
      EINZELHANDEL: UNIT_TYPES['EINZELHANDEL'],
      SPEZIALGEWERBE: UNIT_TYPES['SPEZIALGEWERBE'],
      GEWERBEGRUNDSTUECK: UNIT_TYPES['GEWERBEGRUNDSTUECK'], // => Gebäude?
      SONSTIGE_GEWERBEEINHEIT: UNIT_TYPES['SONSTIGE_GEWERBEEINHEIT'],
    },
  },
  {
    label: 'Stellplatz',
    items: {
      STELLPLATZ: UNIT_TYPES['STELLPLATZ'],
      GARAGE: UNIT_TYPES['GARAGE'],
      DOPPELGARAGE: UNIT_TYPES['DOPPELGARAGE'],
      CARPORT: UNIT_TYPES['CARPORT'],
      DUPLEX: UNIT_TYPES['DUPLEX'],
      BOOTSLIEGEPLATZ: UNIT_TYPES['BOOTSLIEGEPLATZ'],
      PARKHAUS: UNIT_TYPES['PARKHAUS'], // => Gebäude?
      TIEFGARAGENSTELLPLATZ: UNIT_TYPES['TIEFGARAGENSTELLPLATZ'],
      PARKPLATZLADESTATION: UNIT_TYPES['PARKPLATZLADESTATION'],
      SONSTIGE_STELLPLATZ_GARAGE: UNIT_TYPES['SONSTIGE_STELLPLATZ_GARAGE'],
    },
  },
  {
    label: 'Sonstiges',
    items: {
      KELLER: UNIT_TYPES['KELLER'],
      DACHBODEN: UNIT_TYPES['DACHBODEN'],
      GARTEN: UNIT_TYPES['GARTEN'],
      SONSTIGES: UNIT_TYPES['SONSTIGES'],
    },
  },
];

type UnitTypeEditType = Omit<TreeSelectEditType, 'items'>;

export const UnitTypeEdit = forwardRef(
  (props: UnitTypeEditType, ref: Ref<HTMLButtonElement>) => {
    return <TreeSelectEdit ref={ref} items={items} {...props} />;
  },
);
