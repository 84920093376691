import {
  Children,
  Fragment,
  PropsWithChildren,
  cloneElement,
  isValidElement,
  useEffect,
  useState,
} from 'react';
import { Dialog, Transition, TransitionEvents } from '@headlessui/react';
import { DrawerBar } from './bar';
import { DrawerContent } from './content';
import { DrawerFooter } from './footer';
import { DrawerHeader } from './header';
import { Overlay } from '../overlay';

export type DrawerType = PropsWithChildren<
  TransitionEvents & {
    open?: boolean;
    onClose: () => void;
    setParentOffset?: (_: number) => void;
    initialFocus?: React.MutableRefObject<HTMLElement | null>;
  }
>;

export function Drawer({
  children,
  open,
  onClose,
  setParentOffset,
  initialFocus,
  ...props
}: DrawerType) {
  const [offset, setOffset] = useState<number>(0);
  useEffect(() => {
    if (setParentOffset) setParentOffset(open ? offset - 180 : 0);
  }, [open, offset]);

  return (
    <Transition.Root show={open} as={Fragment} {...props}>
      <Dialog
        className="fixed inset-0 overflow-hidden"
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <div className="absolute inset-0 overflow-hidden">
          {Children.map(children, (child) =>
            isValidElement(child)
              ? cloneElement(child, {
                  onClose: child.props.onClose || onClose,
                  offset: child.props.offset || offset,
                  setParentOffset: setOffset,
                })
              : child,
          )}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
Drawer.Root = Drawer;
Drawer.Bar = DrawerBar;
Drawer.Content = DrawerContent;
Drawer.Footer = DrawerFooter;
Drawer.Header = DrawerHeader;
Drawer.Overlay = Overlay;
