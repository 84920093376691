import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import jwt from 'jsonwebtoken';

const scheme = (proto: string) => {
  return typeof window !== 'undefined' && window.location.protocol === 'https:'
    ? `${proto}s`
    : proto;
};

const getSession = (token: string | null) => {
  const {
    'https://hasura.io/jwt/claims': hasura,
    exp,
    ...rest
  } = jwt.decode(token || '', { json: true }) || {};

  return {
    ...rest,
    exp,
    isExpired: (exp || 0) < Date.now() / 1000,
    id: hasura?.['x-hasura-user-id'],
    games: hasura?.['x-hasura-game-ids'],
    role: hasura?.['x-hasura-default-role'],
  };
};

type SessionType = {
  name?: string;
  iat?: number;
  sub?: string;
  exp?: number;
  isExpired?: boolean;
  id?: string;
  role?: string;
  [k: string]: unknown;
};

type SessionContextType = {
  session: SessionType;
  token: string | null;
  login: (token: string) => Promise<void>;
  logout: () => Promise<void>;
};

const SessionContext = createContext<SessionContextType>({
  session: {},
  token: null,
  login: () => new Promise(() => undefined),
  logout: () => new Promise(() => undefined),
});

export function useSession() {
  return useContext(SessionContext);
}

type SessionProviderType = PropsWithChildren<{
  uri?: string;
  subscriptions?: boolean;
}>;

export function SessionProvider({
  children,
  uri,
  subscriptions = true,
}: SessionProviderType) {
  const token =
    typeof window !== 'undefined' ? localStorage.getItem('token') : null;
  const [session, setSession] = useState<SessionType>(getSession(token));

  const client = useMemo(() => {
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    const wsLink =
      subscriptions && typeof window !== 'undefined'
        ? new WebSocketLink({
            uri: `${scheme('ws')}://${uri}/v1/graphql`,
            options: {
              reconnect: true,
              connectionParams: async () => ({
                headers,
              }),
            },
          })
        : undefined;
    const link = new HttpLink({
      headers,
      uri: `${scheme('http')}://${uri}/v1/graphql`,
    });

    return new ApolloClient({
      link: wsLink
        ? split(
            ({ query }) => {
              const definition = getMainDefinition(query);

              return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
              );
            },
            wsLink,
            link,
          )
        : link,
      cache: new InMemoryCache(),
    });
  }, [uri, token]);

  const login = useCallback(async (token: string) => {
    try {
      localStorage.setItem('token', token);
      setSession(getSession(token));
    } catch (error) {
      console.error('Error while login', error);
    }
  }, []);

  const logout = useCallback(async () => {
    const persistor = new CachePersistor({
      cache: client.cache,
      storage: localStorage,
    });

    try {
      // await client.resetStore(); // clears only login
      await client.clearStore(); // clears also the cache!
      await persistor.purge();
      localStorage.clear();
      setSession({});
    } catch (error) {
      console.error('Error while logout', error);
    }
  }, [client]);

  useEffect(() => {
    if (session.isExpired) logout();
  }, [session.isExpired]);

  return (
    <SessionContext.Provider value={{ session, token, login, logout }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </SessionContext.Provider>
  );
}
