import { ComponentProps } from 'react';
import { Label as DropdownLabel } from '@radix-ui/react-dropdown-menu';
import { Label } from '@radix-ui/react-context-menu';
import { cx } from '@vechaiui/utils';
import { useHybridMenuContext } from './provider';

export type HybridMenuLabelProps = ComponentProps<typeof Label> &
  ComponentProps<typeof DropdownLabel> & { indented?: boolean };

export const HybridMenuLabel = ({
  indented,
  ...rest
}: HybridMenuLabelProps) => {
  const { asContext } = useHybridMenuContext();
  const LabelComponent = asContext ? Label : DropdownLabel;

  return (
    <LabelComponent
      {...rest}
      className={cx(
        indented ? 'pl-10 pr-3' : 'px-3',
        'pt-2 pb-0.5',
        'text-neutral-500 text-xs font-medium tracking-wide uppercase',
      )}
    />
  );
};
