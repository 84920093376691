import {
  Children,
  Fragment,
  PropsWithChildren,
  cloneElement,
  isValidElement,
} from 'react';
import { Transition } from '@headlessui/react';

export type DrawerBarType = PropsWithChildren<{
  offset?: number;
  onClose?: () => void;
}>;

export function DrawerBar({ offset, onClose, children }: DrawerBarType) {
  return (
    <div className="fixed inset-y-0 right-0 max-w-full flex">
      <Transition.Child
        as={Fragment}
        enter="transform transition ease-in-out duration-500 sm:duration-700"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-500 sm:duration-700"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div className="w-screen max-w-md">
          <div
            className="bg-white h-full shadow-xl flex flex-col divide-y divide-neutral-200 transform ease-in-out duration-500 sm:duration-700"
            style={{ transform: `translateX(${offset}px)` }}
          >
            {Children.map(children, (child) =>
              isValidElement(child)
                ? cloneElement(child, {
                    onClose: child.props.onClose || onClose,
                  })
                : child,
            )}
          </div>
        </div>
      </Transition.Child>
    </div>
  );
}
