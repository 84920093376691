import { PropsWithChildren, createContext, useContext } from 'react';

type HypridMenuContextType = { asContext?: boolean };
type HybridMenuProviderType = PropsWithChildren<HypridMenuContextType>;

const HybridMenuContext = createContext<HypridMenuContextType>({});

export function useHybridMenuContext() {
  return useContext(HybridMenuContext);
}

export const HybridMenuProvider = ({
  asContext,
  children,
}: HybridMenuProviderType) => {
  return (
    <HybridMenuContext.Provider value={{ asContext }}>
      {children}
    </HybridMenuContext.Provider>
  );
};
