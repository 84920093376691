import { ComponentProps, useRef } from 'react';
import { HybridMenuItem } from './item';
import { Kbd, cx } from '@vechaiui/react';
import { MenuAlt4Icon } from '@heroicons/react/outline';
import { useDrag, useDrop } from 'react-dnd';

export type HybridMenuSortableItemType = ComponentProps<
  typeof HybridMenuItem
> & {
  id: string;
  index?: number;
  as?: typeof HybridMenuItem;
  onHover?: (item: HybridMenuSortableItemType) => void;
};

export function HybridMenuSortableItem({
  id,
  index,
  as: ItemComponent = HybridMenuItem,
  onHover,
  disabled,
  children,
  ...rest
}: HybridMenuSortableItemType) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId, isOver }, drop] = useDrop({
    accept: 'MenuItem',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item: HybridMenuSortableItemType) => {
      if (!ref.current) return;

      if (onHover) onHover(item);
    },
  });
  const [, drag, preview] = useDrag({
    type: 'MenuItem',
    item: () => ({ id, index }),
  });
  preview(drop(ref));

  return (
    <ItemComponent
      ref={ref}
      className={cx(
        'justify-between',
        isOver && !disabled ? 'bg-neutral-100' : '',
      )}
      data-handler-id={handlerId}
      disabled={disabled}
      {...rest}
    >
      <Kbd
        ref={drag}
        className={cx(
          'mr-3',
          !disabled ? 'cursor-move' : 'cursor-not-allowed bg-white',
        )}
      >
        <MenuAlt4Icon
          className={cx('w-3 h-3', !disabled ? '' : 'text-neutral-400')}
        />
      </Kbd>

      {children}
    </ItemComponent>
  );
}
